import axios from 'axios';
import auth from '../auth';

const API_URL = `${process.env.VUE_APP_API_URL}/talents`;

/**
 * Gets a specific talent's informations
 * @param {string} talentId : id of the talent we're trying to get
 */
const getTalent = async (talentId) => {
  try {
    const response = await axios.get(`${API_URL}/${talentId}`);
    return response.data;
  } catch (error) { throw error; }
};

/**
 * Gets the connected talent's informations
 */
const getMe = async () => {
  try {
    const response = await axios.get(`${API_URL}/me`);
    return response.data;
  } catch (error) { throw error; }
};
/**
 * Updates the ownerId of the selected talent
 * @param {string} talentId
 * @param {string} ownerId
 * @returns the new informations
 */
const putTalent = async (talentId, ownerId) => {
  const body = {
    ownerId,
  };
  try {
    const response = await axios.put(`${API_URL}/${talentId}`, body);
    return response.data;
  } catch (error) { throw error; }
};

/**
 * Updates the connected talent's informations
 * @param {string} firstname
 * @param {string} lastname
 * @param {string} oldPassword : can be null
 * @param {string} newPassword : can be null
 * @param {string} confirmationPassword : can be null
 * @param {string} phone
 * @returns the new informations
 */
const putMe = async (firstname, lastname, oldPassword, newPassword, confirmationPassword, phone) => {
  const body = {
    firstname,
    lastname,
    oldPassword,
    newPassword,
    confirmationPassword,
    phone,
  };
  try {
    const response = await axios.put(`${API_URL}/me`, body);
    return response.data;
  } catch (error) { throw error; }
};

/**
 * Deletes the specified user
 * @param {string} talentId : the Id of the user you want to delete
 * @returns nothing
 */
const deleteOne = async (talentId) => {
  try {
    await axios.delete(`${API_URL}/${talentId}`);
    return;
  } catch (error) { throw error; }
};

/**
 * Récupère une liste de talents
 * @param {number} limit : nombre de résultats demandés
 * @param {number} offset : offset de la liste
 * @param {uuid} companyId : entreprise des talents
 * @param {boolean} admin : récupérer les talents admins ou non
 * @param {Array<string>} searchName : recherche sur le nom des talents
 * @param {Array<Country>} country : pays de résidence des talents
 * @param {Array<string>} searchFunction : recherche sur la fonction des talents
 * @param {Array<TimeInRole>} timeInRole : temps passé dans le role
 * @param {Array<TimeInCompany>} timeInCompany : temps passé dans l'entreprise
 * @param {Array<Seniority>} seniority : ancienneté
 * @param {Array<string>} searchOwner : recherche sur le nom de l'owner
 * @param {string} searchAll : recherche sur le nom et la fonction
 * @param {string} noCycleId : permet récupérer tous les talents qui n’ont pas de reviews dans un cycle
 * @returns liste de talents
 */
const getTalents = async (
  limit,
  offset,
  companyId,
  ownerId,
  searchName,
  cycleId,
  country,
  searchFunction,
  timeInRole,
  timeInCompany,
  seniority,
  searchOwner,
  admin,
  searchAll,
  noCycleId,
) => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        companyId,
        ...(admin && { admin }),
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(ownerId && { ownerId }),
        ...(searchName && { searchName }),
        ...(cycleId && { cycleId }),
        ...(country && { country }),
        ...(searchFunction && { searchFunction }),
        ...(timeInRole && { timeInRole }),
        ...(timeInCompany && { timeInCompany }),
        ...(seniority && { seniority }),
        ...(searchOwner && { searchOwner }),
        ...(searchAll && { searchAll }),
        ...(noCycleId && { noCycleId }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Créer un nouveau talent
 * @param {Object} talent : talent à créer, avec sa talentVersion. Voir swagger pour le détail
 * @returns le nouveau talent
 */
const createTalent = async (talent) => {
  try {
    const response = await axios.post(API_URL, talent);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const editTalent = async (talentId, talentBody) => {
  try {
    const response = await axios.put(`${API_URL}/${talentId}`, talentBody);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const downloadTemplate = async () => {
  try {
    const token = auth.getToken();
    window.open(`${API_URL}/import/template?token=${token}`, '_blank');
  } catch (error) { throw error; }
};

const uploadCSV = async (file) => {
  const formData = new FormData();
  formData.append('file', new Blob([file], { type: 'text/csv' }));
  try {
    const response = await axios.post(`${API_URL}/import`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) { throw error; }
};

const deleteAll = async () => {
  try {
    await axios.delete(API_URL);
  } catch (error) { throw error; }
};

const api = {};
api.getTalents = getTalents;
api.getTalent = getTalent;
api.getMe = getMe;
api.putTalent = putTalent;
api.putMe = putMe;
api.createTalent = createTalent;
api.deleteOne = deleteOne;
api.editTalent = editTalent;
api.downloadTemplate = downloadTemplate;
api.uploadCSV = uploadCSV;
api.deleteAll = deleteAll;

export default api;
