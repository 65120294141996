<template>
  <section class="account-edit">
    <Loader v-if="loading" />

    <form v-else class="account-details" @submit.prevent="saveInfos">
      <div class="account-details-title">
        <app-subtitle>Account details</app-subtitle>
        <div class="buttons">
          <router-link :to="{ name: 'account' }" class="no-style">
            <app-button :class="['medium', 'minimal']">Cancel</app-button>
          </router-link>
          <app-button :class="['medium']" class="save-btn" :disabled="loading" type="submit">Save</app-button>
        </div>
      </div>
      <div class="account-details-infos" v-if="userInfos">
        <div class="avatar">
          <talent-icon :user="getConnectedUser"/>
        </div>
        <div v-if="!isSuperAdmin" class="account-details-info">
          <p class="info-label">First name</p>
          <app-input type="text" required v-model="userInfos.firstname" class="info" :class="{ error: errors.firstname }" :helper="errors.firstname"/>
        </div>
        <div v-if="!isSuperAdmin" class="account-details-info">
          <p class="info-label">Surname</p>
          <app-input type="text" required v-model="userInfos.lastname" class="info" :class="{ error: errors.lastname }" :helper="errors.lastname"/>
        </div>
        <div class="account-details-info">
          <p class="info-label">Email</p>
          <app-input type="email" disabled v-model="userInfos.email" class="info"/>
        </div>
        <div class="account-details-info">
          <p class="info-label">Old password</p>
          <app-input type="password" v-model="userInfos.oldPassword" class="info" :class="{ error: errors.oldPassword }" :helper="errors.oldPassword" :disabled="getConnectedUser.impersonate"/>
        </div>
        <div class="account-details-info">
          <p class="info-label">New password</p>
          <app-input type="password" v-model="userInfos.newPassword" class="info" :class="{ error: errors.newPassword }" :helper="errors.newPassword" :disabled="getConnectedUser.impersonate"/>
        </div>
        <div class="account-details-info">
          <p class="info-label">Confirm new password</p>
          <app-input type="password" v-model="userInfos.confirmationPassword" class="info" :class="{ error: errors.confirmationPassword }" :helper="errors.confirmationPassword" :disabled="getConnectedUser.impersonate"/>
        </div>
        <div v-if="!isSuperAdmin" class="account-details-info">
          <p class="info-label">Phone</p>
          <app-input type="text" v-model="userInfos.phone" class="info" :class="{ error: errors.phone }" :helper="errors.phone"/>
        </div>
      </div>
      <div  class="subscription-details" v-if="!isSuperAdmin && userInfos.company">
        <div class="subscription-details-info">
          <p class="info-label">Subscription</p>
          <p class="info">{{ getSubscriptionLabel }}</p>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import talentApi from '@/services/api/talent';
import superAdminApi from '@/services/api/super-admin';
import SubscriptionLabel from '@/services/enums/subscription-label.enum';
import TalentIcon from '@/components/TalentIcon.vue';
import ls from 'local-storage';
import UserRole from '../../services/enums/user-role.enums';
import Loader from '../../components/Loader.vue';

export default {
  components: { TalentIcon, Loader },
  name: 'account-edit',
  data() {
    return {
      userInfos: {
        firstname: '',
        lastname: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        confirmationPassword: '',
        phone: '',
      },
      userId: '',
      errors: {
        firstname: '',
        lastname: '',
        phone: '',
        oldPassword: '',
        newPassword: '',
        confirmationPassword: '',
      },
      hasErrors: false,
      loading: false,
      isSuperAdmin: false,
    };
  },
  mounted() {
    this.getUserInfos();
  },
  methods: {
    async getUserInfos() {
      const user = ls('USER');
      if (user) {
        let infos;
        this.loading = true;
        if (user.role !== UserRole.SUPER_ADMIN) {
          infos = await talentApi.getMe();
          this.userInfos = {
            firstname: infos.data.firstname || '',
            lastname: infos.data.lastname || '',
            email: infos.data.email || '',
            oldPassword: '',
            newPassword: '',
            confirmationPassword: '',
            phone: infos.data.phone || '',
          };
          this.userId = user.talentId;
          this.isSuperAdmin = false;
        } else {
          infos = await superAdminApi.getMe();
          this.userInfos = {
            email: infos.data.email || '',
            oldPassword: '',
            newPassword: '',
            confirmationPassword: '',
          };
          this.userId = user.superAdminId;
          this.isSuperAdmin = true;
        }
        this.loading = false;
      }
    },
    async saveInfos() {
      this.hasErrors = false;
      if (this.userInfos.oldPassword.length > 0 || this.userInfos.newPassword.length > 0 || this.userInfos.confirmationPassword.length > 0) {
        if (this.userInfos.oldPassword.length < 1) {
          this.addError('oldPassword', 'You need to confirm your old password before changing it');
          this.hasErrors = true;
        }
        if (this.userInfos.newPassword.length < 1 && this.userInfos.confirmationPassword.length < 1) {
          this.addError('oldPassword', 'You don\'t need to confirm your old password if you don\'t want to change it');
          this.hasErrors = true;
        }
        if (this.userInfos.newPassword !== this.userInfos.confirmationPassword) {
          this.addError('confirmationPassword', 'Your new password and your confirmation must be the same');
          this.hasErrors = true;
        }
        if (this.userInfos.newPassword.length < 8 || this.userInfos.newPassword.length > 32) {
          this.addError('newPassword', 'Your password must be at least 8 characters and at most 32 characters long');
          this.hasErrors = true;
        }
      }
      if (!this.hasErrors) {
        this.loading = true;

        try {
          if (!this.isSuperAdmin) {
            await talentApi.putMe(this.userInfos.firstname, this.userInfos.lastname, this.userInfos.oldPassword, this.userInfos.newPassword, this.userInfos.confirmationPassword, this.userInfos.phone);
            this.$notification.show({
              text: 'Talent\'s informations have successfully been modified',
            });
          } else {
            await superAdminApi.updatePassword(this.userInfos.oldPassword, this.userInfos.newPassword, this.userInfos.confirmationPassword);
            this.$notification.show({
              text: 'Password have successfully been modified',
            });
          }
          this.$router.push({ name: 'account' });
          this.loading = false;
        } catch (error) {
          const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
          this.$message.show({
            title: 'Error',
            text: `An error has occured : ${messages}`,
            confirmText: 'Ok',
            hasCancel: false,
          });
          this.loading = false;
        }
      }
    },
    /**
     * Ajoute un erreur à un input
     * @param field : Le nom du field qui contient l'erreur. Attention à bien l'initialiser avec une string vide dans l'objet errors !
     * @param errorMessage : Le message d'erreur qui s'affichera en dessous de l'input.
     */
    addError(field, errorMessage) {
      this.errors[field] = errorMessage;
      const unwatch = this.$watch(`userInfos.${field}`, () => {
        this.errors[field] = null;
        if (unwatch) { unwatch(); }
      });
    },
  },
  computed: {
    getSubscriptionLabel() {
      switch (this.userInfos.company.subscription) {
        case 'BASIC':
          return SubscriptionLabel.BASIC;
        case 'STANDARD':
          return SubscriptionLabel.STANDARD;
        case 'PRO':
          return SubscriptionLabel.PRO;
        default:
          return 'None';
      }
    },
    getConnectedUser() {
      const user = authApi.getUser();
      if (this.isSuperAdmin) {
        return {
          superAdminId: this.userInfos.superAdminId,
          role: this.userInfos.role,
        };
      }
      return {
        talentId: this.userInfos.talentId,
        firstname: this.userInfos.firstname,
        lastname: this.userInfos.lastname,
        role: this.userInfos.role,
        ...(user.impersonate && { impersonate: user.impersonate }),
      };
    },
  },
};
</script>

<style lang="sass">
.account-edit
  padding: 1rem
  display: flex
  justify-content: center
  .loader
    margin: 5rem auto
  .account-details
    width: 50vw
    .account-details-title
      display: flex
      align-items: center
      justify-content: space-between
      border-bottom: 2px solid $light-color
      .buttons
        display: flex
        .save-btn
         margin-left: 5px
    .account-details-infos
      padding: 18px 0
      border-bottom: 2px solid $light-color
      position: relative
      .avatar
        position: absolute
        left: -150px
        transform: scale(2)
      .account-details-info
        display: flex
        margin: 16px 0
        .info-label
          width: 40%
          font-weight: 700
        .info
          width: 100%
          color: $link-color
  .subscription-details
    padding: 34px 0
    .subscription-details-info
      display: flex
      .info-label
        width: 40%
        font-weight: 700
      .info
        color: $link-color
        text-transform: capitalize
</style>
