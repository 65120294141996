<template>
  <section class="resolve-talents-errors">
    <div class="table-container">
      <app-table class="table">
        <template v-slot:headers>
          <div class="cell shrink talent-selector">
            <app-checkbox :input="globalSelect" @input="addOrRemoveAllTalents"/>
          </div>
          <div class="cell auto">Name <svg-triangle /></div>
          <div class="cell auto">Role title</div>
          <div class="cell auto">Function</div>
          <div class="cell auto">Owned by</div>
          <div class="cell shrink action-label">
            <app-button size="medium">Assign author</app-button>
          </div>
          <div class="cell shrink action-label">
            <app-button kind="minimal" size="medium">Remove</app-button>
          </div>
        </template>
        <template v-slot:body>
          <div class="row grid-x align-middle talents-line" v-for="talent in talents" :key="talent.talentId">
            <div class="cell shrink talent-selector">
              <app-checkbox :input="!!selectedTalents.find((t) => t.talentId === talent.talentId)" @input="addOrRemoveTalent(talent)"/>
            </div>
            <div class="cell auto talent-name">
              <talent-icon :user="talent"></talent-icon>
              <div class="talent-info">
                <div>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</div>
                <div class="talent-email cell">{{ talent.email }}</div>
              </div>
            </div>
            <div class="cell auto">{{ talent.talentVersion.role }}</div>
            <div class="cell auto">{{ talent.talentVersion.function }}</div>
            <div class="cell auto">
              <talent-icon v-if="talent.owner" :user="talent.owner" isLong></talent-icon>
              <div v-else class="pending-owner"></div>
            </div>
            <div class="cell shrink action-cell">
              <app-button size="medium" @click="assignOwnerToOneTalent(talent)">Assign author</app-button>
            </div>
            <div class="cell shrink action-cell">
              <app-button kind="minimal" size="medium" @click="removeTalentFromSelection(talent)">Remove</app-button>
            </div>
          </div>
        </template>
      </app-table>
    </div>
  </section>
</template>
<script>

// Components
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';
import TalentIcon from './TalentIcon.vue';

export default {
  name: 'resolve-talents-errors',
  components: {
    TalentIcon,
    'svg-triangle': SvgTriangle,
  },
  props: {
    talents: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedErrors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedTalents: [],
      globalSelect: [],
    };
  },
  watch: {
    selectedErrors() {
      this.selectedTalents = this.selectedErrors.map((se) => se);
      this.verifyGlobalSelect();
    },
    talents() {
      if (this.talents.length < 1) {
        this.$emit('update:exit');
      }
    },
  },
  methods: {
    addOrRemoveTalent(talent) {
      const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      if (index < 0) {
        this.selectedTalents.push(talent);
      } else {
        this.selectedTalents.splice(index, 1);
      }
      this.$emit('update:input', this.selectedTalents);
      this.verifyGlobalSelect();
    },
    addOrRemoveAllTalents() {
      let allPageIsSelected = true;
      this.talents.forEach((talent) => {
        if (!this.selectedTalents.find((t) => t.talentId === talent.talentId)) {
          allPageIsSelected = false;
        }
      });
      if (!allPageIsSelected) {
        this.talents.forEach((talent) => {
          if (!this.selectedTalents.find((t) => t.talentId === talent.talentId)) {
            this.selectedTalents.push(talent);
          }
        });
      } else {
        this.talents.forEach((talent) => {
          const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
          this.selectedTalents.splice(index, 1);
        });
      }
      this.$emit('update:input', this.selectedTalents);
      this.verifyGlobalSelect();
    },
    assignOwnerToOneTalent(talent) {
      this.$emit('update:talentToAssignTo', talent);
    },
    removeTalentFromSelection(talent) {
      this.$emit('update:talentToRemove', talent);
    },
    verifyGlobalSelect() {
      let allPageIsSelected = true;
      this.talents.forEach((talent) => {
        if (!this.selectedTalents.find((t) => t.talentId === talent.talentId)) {
          allPageIsSelected = false;
        }
      });
      this.globalSelect = allPageIsSelected;
    },
  },
};
</script>

<style lang="sass">
.resolve-talents-errors
  .table-container
    @include table-container
    .table
      .talent-selector
        padding-right: 16px
        .app-checkbox input:not(:checked) ~ span
          border: 2px solid $medium-gray
      .body .row
          @include clickable-row
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .pending-owner
            @include pending-owner
          .action-cell
            .app-button
              margin-left: 16px
      .headers
        .action-label
          visibility: hidden
          height: 1px
          .app-button
              margin-left: 16px
</style>
