const ReviewStatusLabel = Object.freeze({
  DRAFT: 'New',
  NEW: 'New',
  PROCESSING: 'Editing',
  EDITING: 'Editing',
  COMPLETED: 'Completed',
  TALENT_DELETED: 'Deleted',
});

export default ReviewStatusLabel;
