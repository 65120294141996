<template>
  <div class="talent-select">
    <app-search v-model="search"></app-search>
    <app-table :loading="loading">
      <template v-slot:body>
        <div class="row grid-x align-middle" v-for="talent in talents" :key="talent.talentId" @click.prevent="handleRadioClick(talent)">
          <app-radio v-model="selectedTalent" :value="talent" class="cell shrink"></app-radio>
          <talent-icon :user="talent" class="cell shrink"></talent-icon>
          <b class="cell small-2">{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</b>
          <p class="cell auto">{{ talent.email }}</p>
          <app-tag class="cell small-2">{{ talent.talentVersion.role }}</app-tag>
          <app-tag class="cell small-2"> {{ talent.talentVersion.function }}</app-tag>
        </div>
      </template>
      <template v-slot:empty-table v-if="errorMessage">
        <div class="empty">{{ errorMessage }}</div>
      </template>
    </app-table>
    <pagination v-bind="metadata" @update:page="updatePage"></pagination>
  </div>
</template>

<script>
import talentApi from '@/services/api/talent';
import auth from '@/services/auth';
import utils from '@/services/utils';
import Pagination from '../../components/Pagination.vue';
import TalentIcon from '../../components/TalentIcon.vue';

export default {
  components: { TalentIcon, Pagination },
  name: 'edit-talent',
  emits: ['update:selected-talent'],
  props: {
    selectingOwnedTalent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      talents: [],
      selectedTalent: {},
      metadata: {
        limit: 5,
        offset: 0,
        count: 0,
      },
      loading: false,
      user: auth.getUser(),
      errorMessage: '',
    };
  },
  watch: {
    search() {
      this.debouncedGetTalents(this.metadata.limit, 0);
    },
  },
  methods: {
    async getTalents(limit, offset) {
      this.loading = true;
      try {
        const response = await talentApi.getTalents(
          limit,
          offset,
          this.user.companyId,
          this.selectingOwnedTalent ? this.user.talentId : null,
          this.search,
        );
        this.talents = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.errorMessage = `An error occured while fetching talents. ${messages}`;
        this.talents = [];
      }
      this.loading = false;
    },
    handleRadioClick(talent) {
      if (this.selectedTalent === talent) {
        this.selectedTalent = {};
      } else {
        this.selectedTalent = talent;
      }
      this.$emit('update:selected-talent', this.selectedTalent);
    },
    updatePage({ limit, offset }) {
      this.getTalents(limit, offset);
    },
  },
  created() {
    this.debouncedGetTalents = utils.debounce(this.getTalents, 400);
  },
  mounted() {
    this.getTalents(this.metadata.limit, this.metadata.offset);
  },
};
</script>

<style lang="sass">
.talent-select
  z-index: 100
  .app-search
    margin-bottom: 1rem
  .app-table .body .row
    cursor: pointer
    background: $white
    border: 2px solid $light-color
    font-size: 11px
    b
      color: $black
      font-size: 16px
      margin: 0 0.3rem
    .app-tag
      font-size: 13px
      margin: 0 0.2rem
    &:hover
      border-color: $main-color
      .app-radio .checkmark
        border-color: $main-color
  .pagination
    margin-top: 1rem
    &, .app-select, .app-button
      font-size: 13px
</style>
