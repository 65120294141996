<template>
  <section class="cycle-segments-at-risk" :class="{ 'is-pdf': isPDF }">

    <!-- Risques de biais globaux des segments du cycle -->
    <div class="page">
      <app-subtitle>{{ isPDF ? 'Overview' : 'Segments at risk' }}</app-subtitle>
      <p>The following graph shows the overview of the bias risks within your cycle. Each bubble represents a group within your cycle. The position of the bubble combines the presence of biases in the group and the intensity of biases. This graph can be used to compare the results of different cycles. Hover over the bubbles to get details</p>
      <div class="graph-legend-card graph-card" v-if="isPDF">
        <h3>Graph legends</h3>
        <div class="graph-card-content">
          <bubble-chart-legend type="size" direction="row" />
          <bubble-chart-legend type="color" direction="row" />
        </div>
      </div>
      <graph-card title="Risk of bias in segments" class="segment-bias-risk">
        <div class="segment-graph-container">
          <app-bubble-chart :stats="segmentsBiasRisk" chartId="segments-bias-risk" :options="{ axisTitle: { yAxis: 'Average intensity of typical biases', xAxis: 'Average presence of typical biases' }}"/>
          <div class="legend-container" v-if="!isPDF">
            <bubble-chart-legend type="color"/>
            <bubble-chart-legend type="size"/>
          </div>
          <div v-else class="legend-container">
            <bubble-chart-legend type="color" direction="column" :data="segmentsBiasRisk" />
          </div>
        </div>
      </graph-card>
    </div>

    <!-- Risques de biais par TB pour les 3 segments les plus à risques -->
    <div class="page">
      <app-subtitle>Biggest bias for the most affected groups</app-subtitle>
      <p>Some groups are more affected by bias risks than others. This section presents the 3 groups which are most at risk of bias. Hover over the bubbles to get details</p>
      <div class="graph-card-container" v-if="mostAffectedSegments.length">
        <graph-card v-for="segmentBiasRisk in mostAffectedSegments" :key="segmentBiasRisk.label" :title="segmentBiasRisk.label">
          <app-bubble-chart :stats="segmentBiasRisk.typicalBiasRisks" :chartId="`typical-bias-risk-${segmentBiasRisk.label}`" :options="{ radius: 8 }"/>
          <bubble-chart-legend v-if="isPDF" type="color" :data="segmentBiasRisk.typicalBiasRisks" />
        </graph-card>
      </div>
      <p v-else class="data-placeholder">No segments found at risk</p>
    </div>

    <!-- Typical bias les plus présents dans les segments -->
    <div class="page">
      <app-subtitle>Most present typical biases across the segments</app-subtitle>
      <p>Some bias risks are more present than others. This section shows the 3 most present bias risks. Hover over the bubbles to get details</p>
      <div class="typical-bias-container" v-if="mostPresentTypicalBiases.length">
        <typical-bias v-for="typicalBiasRisk in mostPresentTypicalBiases" :key="typicalBiasRisk.reference" :typicalBias="typicalBiasRisk">
        </typical-bias>
      </div>
      <p v-else class="data-placeholder">No typical biases found</p>
    </div>
  </section>
</template>

<script>
// Vue mixins
import StatsDisplay from '@/mixins/StatsDisplay.vue';

// Composants
import BubbleChartLegend from './BubbleChartLegend.vue';
import TypicalBias from './TypicalBias.vue';
import GraphCard from './GraphCard.vue';

export default {
  mixins: [StatsDisplay], // Pour les fonctions getPoolLabel, getBiasSeverity
  components: { GraphCard, TypicalBias, BubbleChartLegend },
  props: {
    biasStats: {
      type: Object,
      required: true,
    },
    typicalBiases: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPDF: this.$route.meta.isPDF,
    };
  },
  computed: {
    segmentsBiasRisk() {
      return this.biasStats.segmentsBiasRisk
        .filter((segment) => segment.nbAuthors >= +process.env.VUE_APP_NB_WRITERS_MIN_SEGMENT)
        .map((segmentBiasRisk) => ({
          label: this.getPoolLabel(segmentBiasRisk.pool),
          presence: segmentBiasRisk.biasRisk.presence,
          intensity: segmentBiasRisk.biasRisk.intensity,
          talentRatio: segmentBiasRisk.talentRatio,
          level: segmentBiasRisk.biasRisk.level,
        }));
    },
    mostAffectedSegments() {
      return this.biasStats.segmentsBiasRisk
        .filter((segment) => segment.biasRisk.distance)
        .filter((segment) => segment.nbAuthors >= +process.env.VUE_APP_NB_WRITERS_MIN_SEGMENT)
        .slice(0, 3)
        .map((segmentBiasRisk) => ({
          label: this.getPoolLabel(segmentBiasRisk.pool),
          typicalBiasRisks: segmentBiasRisk.typicalBiasRisks.filter((typicalBiasRisk) => typicalBiasRisk.biasRisk.distance).map((typicalBiasRisk) => ({
            label: this.typicalBiases[typicalBiasRisk.reference].label,
            presence: segmentBiasRisk.biasRisk.presence,
            intensity: segmentBiasRisk.biasRisk.intensity,
            level: segmentBiasRisk.biasRisk.level,
          })),
        }));
    },
    mostPresentTypicalBiases() {
      return this.biasStats.typicalBiasRisks.filter((tb) => tb.biasRisk.distance).slice(0, 3).map((typicalBias) => this.typicalBiases[typicalBias.reference]);
    },
  },
  methods: {
  },
};
</script>

<style lang="sass">
.cycle-segments-at-risk
  .page
    .graph-card
      .segment-graph-container
        display: flex
        gap: 1rem
        .app-bubble-chart
          flex-grow: 2
          max-width: calc(100vh + 140px)
        .bubble-chart-legend:not(:first-child)
          margin-top: 50%
    .graph-card-container, .typical-bias-container
      width: 100%
      display: flex
      gap: 1rem
      .graph-card
        width: 100%
        .graph-card-title
          display: flex
          align-items: center

    .data-placeholder
      @include stats-placeholder

  &.is-pdf
    .graph-card
      margin: 16px 0
    .graph-legend-card
      h3
        margin-bottom: 8px
      .graph-card-content
        display: flex
        flex-direction: column
        gap: 16px
    .segment-bias-risk
      page-break-inside: avoid
      break-inside: avoid
      .segment-graph-container
        max-width: 100%
        .app-bubble-chart
          flex-grow: 2
          flex-shrink: 2

</style>
