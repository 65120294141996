import AppEvent from './utils/AppEvent';
import AppMessage from './components/AppMessage.vue';
import AppModal from './components/AppModal.vue';
import AppCheckbox from './components/AppCheckbox.vue';
import AppRadio from './components/AppRadio.vue';
import AppSwitch from './components/AppSwitch.vue';
import AppButton from './components/AppButton.vue';
import AppCard from './components/AppCard.vue';
import AppNotifications from './components/AppNotifications.vue';
import AppSelect from './components/AppSelect.vue';
import AppInput from './components/AppInput.vue';
import AppDatepicker from './components/AppDatepicker.vue';
import AppTitle from './components/AppTitle.vue';
import AppSubtitle from './components/AppSubtitle.vue';
import AppLabel from './components/AppLabel.vue';
import AppSlider from './components/AppSlider.vue';
import AppProgress from './components/AppProgress.vue';
import AppTable from './components/AppTable.vue';
import AppTooltip from './components/AppTooltip.vue';
import AppTab from './components/AppTab.vue';
import AppTag from './components/AppTag.vue';
import AppLink from './components/AppLink.vue';
import AppSpinner from './components/AppSpinner.vue';
import AppSearch from './components/AppSearch.vue';
import AppAccordion from './components/AppAccordion.vue';
import AppStickyFooter from './components/AppStickyFooter.vue';
import AppGaugeChart from './components/AppGaugeChart.vue';
import AppGaugeScale from './components/AppGaugeScale.vue';
import AppPieChart from './components/AppPieChart.vue';
import AppChart from './components/AppChart.vue';
import AppScoreChart from './components/AppScoreChart.vue';
import AppProgressBar from './components/AppProgressBar.vue';
import AppBubbleChart from './components/AppBubbleChart.vue';

const basics = {

  install(Vue) {
    Vue.component('app-message', AppMessage);
    Vue.component('app-modal', AppModal);
    Vue.component('app-checkbox', AppCheckbox);
    Vue.component('app-radio', AppRadio);
    Vue.component('app-switch', AppSwitch);
    Vue.component('app-button', AppButton);
    Vue.component('app-card', AppCard);
    Vue.component('app-notifications', AppNotifications);
    Vue.component('app-select', AppSelect);
    Vue.component('app-input', AppInput);
    Vue.component('app-datepicker', AppDatepicker);
    Vue.component('app-title', AppTitle);
    Vue.component('app-subtitle', AppSubtitle);
    Vue.component('app-label', AppLabel);
    Vue.component('app-slider', AppSlider);
    Vue.component('app-progress', AppProgress);
    Vue.component('app-table', AppTable);
    Vue.component('app-tooltip', AppTooltip);
    Vue.component('app-tab', AppTab);
    Vue.component('app-tag', AppTag);
    Vue.component('app-link', AppLink);
    Vue.component('app-spinner', AppSpinner);
    Vue.component('app-search', AppSearch);
    Vue.component('app-accordion', AppAccordion);
    Vue.component('app-sticky-footer', AppStickyFooter);
    Vue.component('app-gauge-chart', AppGaugeChart);
    Vue.component('app-gauge-scale', AppGaugeScale);
    Vue.component('app-pie-chart', AppPieChart);
    Vue.component('app-bubble-chart', AppBubbleChart);
    Vue.component('app-chart', AppChart);
    Vue.component('app-score-chart', AppScoreChart);
    Vue.component('app-progress-bar', AppProgressBar);

    // eslint-disable-next-line
    Vue.prototype.$message = {
      show(params) {
        AppEvent.$emit('app-message-show', params);
      },
    };

    // eslint-disable-next-line
    Vue.prototype.$notification = {
      show(params) {
        AppEvent.$emit('app-notification-show', params);
      },
    };
  },
};

export default basics;
