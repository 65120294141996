<template>
  <div class="not-found">
    <div  class="grid-container">
      <div class="grid-x grid-margin-x grid-padding-x">
        <div class="cell medium-5 small-12">
          <p>404</p>
          <p>Oops, sorry we lost you for a minute...</p>
          <p>But we are working hard to get you back on Talentuum!</p>
        </div>
        <div class="cell medium-auto small-12">
          <img :src="Img" />
        </div>
      </div>
    </div>
    <div class="custom-shape-divider-top">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z" class="shape-fill"></path>
      </svg>
    </div>
  </div>
</template>

<script>
import Img from '@/assets/img/cycles.svg';

export default {
  name: 'not-found',
  data() {
    return {
      Img,
    };
  },
};
</script>

<style lang="sass">
.not-found
  min-height: calc(100vh - #{$header-height} - #{var(--wrapper-padding-bottom)})
  display: flex
  flex-direction: column
  justify-content: center
  .grid-container
    z-index: 1
  .cell:last-child
    display: flex
  p
    margin: 0
    &:first-child
      color: $main-color
      font-size: $global-font-size * 6
      font-weight: bold
    &:not(:first-child)
      color: $text-color
      font-size: $global-font-size * 4
      font-weight: bold
    &:last-child
      font-size: $global-font-size * 2
      font-weight: initial
  .custom-shape-divider-top
    position: absolute
    top: 0
    right: 0
    width: 60%
    overflow: hidden
    line-height: 0
    svg
      position: relative
      display: block
      width: calc(147% + 1.3px)
      height: calc(100vh - #{$header-height} - #{var(--wrapper-padding-bottom)})
    .shape-fill
      fill: $main-bg-color
</style>
