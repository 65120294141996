<script>
// ENUMS
import GenderLabel from '@/services/enums/gender-label.enum';
import NationalityLabel from '@/services/enums/nationality-label.enum';
import EthnicityLabel from '@/services/enums/ethnicity-label.enum';
import SeniorityBiasLabel from '@/services/enums/seniority-bias-label.enum';
import BiasSeverity from '@/services/enums/bias-severity.enum';

export default {
  methods: {
    // Détermines le niveau de biais
    getBiasSeverity(value) {
      switch (true) {
        case Math.abs(value) > 30: return BiasSeverity.SEVERE;
        case Math.abs(value) < 10: return BiasSeverity.LOW;
        default: return BiasSeverity.MODERATE;
      }
    },
    // Détermine la fin de phrase de description d'un score
    getScoreDescriptionHTML(score, pool) {
      const group = pool ? `${this.getPoolLabel(pool).toLowerCase()} Talents` : 'all groups';
      switch (true) {
        case score < 20: return `managed to <b>moderately reduce</b> their bias flags on <b>${group}</b> reviews.`;
        case score > 99: return `managed to <b>remove all</b> their bias flags on <b>${group}</b> reviews.`;
        default: return `managed to <b>significantly reduce</b> their bias flags on <b>${group}</b> reviews`;
      }
    },

    // Retourne le label d'une pool dans une comparaison
    getPoolLabel(pool) {
      let label = null;
      if (pool.ethnicity) {
        label = label ? `${label} ${this.ethnicityLabel(pool.ethnicity)}` : this.ethnicityLabel(pool.ethnicity);
      }
      if (pool.nationality) {
        label = label ? `${label} ${this.nationalityLabel(pool.nationality)}` : this.nationalityLabel(pool.nationality);
      }
      if (pool.gender) {
        label = label ? `${label} ${GenderLabel[pool.gender]}` : GenderLabel[pool.gender];
      }
      if (pool.seniority) {
        label = label ? `${label} ${SeniorityBiasLabel[pool.seniority]}` : SeniorityBiasLabel[pool.seniority];
      }

      return label;
    },

    ethnicityLabel(ethnicity) {
      return EthnicityLabel[ethnicity] || 'Other ethnicities';
    },
    nationalityLabel(nationality) {
      return NationalityLabel[nationality] || 'Other nationalities';
    },

    // Détermines la phrase de description de la répartition des auteurs par genre
    getWriterGenderDescriptionHTML() {
      if (this.writersStats.gender.prevalents.length > 1) {
        this.writerGenderDescriptionHTML = 'Your writer gender split is <b>totally equal.</b>';
      } else {
        const prevalentData = this.writersStats.gender.details.find((detail) => detail.group === this.writersStats.gender.prevalents[0]);
        if (prevalentData.value <= 55) {
          this.writerGenderDescriptionHTML = 'Your writer gender split is <b>totally equal.</b>';
        } else if (prevalentData.value < 60) {
          this.writerGenderDescriptionHTML = `A <b>small majority</b> of your writers are <b>${GenderLabel[prevalentData.group]}</b>. Your writer gender split is <b>relatively equal</b>.`;
        } else {
          this.writerGenderDescriptionHTML = `A <b>majority</b> of your writers are <b>${GenderLabel[prevalentData.group]}</b>. Your writer gender split is <b>unequal</b>.`;
        }
      }
    },
  },
};
</script>
