<template>
  <section class="cycle-setup-start">
    <div>
      <app-title>Setup a new cycle</app-title>
      <p>{{ text }}</p>
      <app-button @click="$emit('update:step', 1)">Start<arrow-right class="trailing"/></app-button>
    </div>
  </section>
</template>

<script>
import arrowRight from '../../../assets/img/icons/24px/arrow-right.svg?inline';

export default {
  components: {
    'arrow-right': arrowRight,
  },
  props: {
    text: {
      default: 'Text',
    },
  },
};
</script>

<style lang="sass">
.cycle-setup-start
  display: flex
  justify-content: center
  align-items: center
  height: calc(100vh - #{$header-height} - 120px) // Screen height - header height - footer height
  &>div
    width: 60vw
    max-width: 800px
    h1
      margin: 0
      font-size: 36px
    p
      margin: 3em 0
      color: $link-color
      font-size: 20px
      line-height: 25px
    .app-button
      font-size: 20px
      padding: 18px 32px
</style>
