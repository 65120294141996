<template>
  <app-tooltip class="info" :position="position" :class="{ 'hide-tooltip': hideTooltip }" >
    <slot>{{ message }}</slot>
    <template v-slot:app-tooltip-wrapper>
      <div class="information-container" @click="hideTooltip = !hideTooltip" tabindex="-1" @blur="hideTooltip = true">
        <SvgInformation />
      </div>
    </template>
  </app-tooltip>
</template>

<script>
import SvgInformation from '@/assets/img/icons/24px/information.svg?inline';

export default {
  name: 'info',
  components: {
    SvgInformation,
  },
  props: {
    message: {
      type: String,
      required: false,
    },
    position: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      hideTooltip: true,
    };
  },
};
</script>

<style lang="sass">
.app-tooltip.info
  color: $white
  .information-container
    cursor: pointer
    padding: 0.3rem
    display: flex
    justify-content: center
    align-items: center
    svg
      pointer-events: none
      vertical-align: bottom
      transform: scale(0.7, 0.7)
      path
        fill: $common-gray
  &.hide-tooltip
    .app-tooltip-container
      opacity: 0
      pointer-events: none
</style>
