<template>
  <div class="app-progress-bar progress-bar-baseline" :class="{ ...Object.fromEntries([[kind, true]]), complete: displayedRate >= 100}">
    <div class="progress-bar-fill" :style="{ width: `${displayedRate}%`}"></div>
  </div>
</template>

<script>
export default {
  name: 'app-progress-bar',
  props: {
    rate: [Number],
    kind: {
      type: String,
      default: 'default',
      validator: (val) => ['default', 'severe', 'moderate', 'low'].indexOf(val) !== -1,
    },
  },
  computed: {
    displayedRate() {
      return Math.round(Math.abs(this.rate));
    },
  },
};
</script>

<style lang="sass">
.app-progress-bar.progress-bar-baseline
  display: inline-block
  height: 16px
  width: 100%
  background-color: $light-color
  border-radius: 10px
  .progress-bar-fill
    height: 100%
    background-color: $main-color
    border-radius: 10px 0 0 10px

  &.complete
    .progress-bar-fill
      border-radius: 10px

  &.moderate
    background-color: $error-bg-color
    .progress-bar-fill
      background-color: $error-color

  &.low
    background-color: #FAF1D9
    .progress-bar-fill
      background-color: #E8B943

  &.severe
    background-color: $black-bias-bg
    .progress-bar-fill
      background-color: $black
</style>
