<template>
  <div class="app-card">
      <div class="image-container">
        <img :src="image" :alt="title">
      </div>
      <div class="container">
        <h3>{{ title }}</h3>
        <h4>{{ subtitle }}</h4>
        <p>{{ text }}</p>
        <app-button @click="buttonAction" size="large" kind="primary">{{ buttonText }}</app-button>
      </div>
  </div>
</template>

<script>
import AppButton from './AppButton.vue';

export default {
  components: { AppButton },

  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    text: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    buttonAction: {
      type: Function,
    },
    buttonType: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="sass">
.app-card
  height: 100%
  border-radius: $global-border-radius
  overflow: hidden
  background: white
  box-shadow: 0px 2px 8px rgba(103, 102, 98, 0.2)

  .image-container
    display: flex
    height: 280px
    background-color: $background-color
    img
      width: 50%
      height: 70%
      object-fit: contain
      margin: auto

  .container
    padding: 32px 126px
    min-height: 200px
    text-align: center
    h3
      margin: 0
      font-size: 12px
      font-weight: 900
      color: $main-color
      text-transform: uppercase
      letter-spacing: 1.5px
    h4
      margin: 16px 0
      font-size: 32px
      font-weight: 700
      color: $text-color
    p
      margin: 0
      font-size: 20px
      font-weight: 400
      color: $common-gray
    .app-button
      margin: 32px auto 0 auto

@media (max-width: 640px)
  .app-card
    .container
      padding: 16px 63px
</style>
