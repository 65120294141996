<template>
  <div class="app-chart">
    <canvas :id="chartId"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(...registerables);
Chart.register(annotationPlugin);
Chart.register(zoomPlugin);

export default {
  name: 'app-chart',
  props: {
    data: {
      type: Object,
      required: true,
    },
    chartId: { // THE ID MUST BE UNIQUE
      type: String,
      required: true,
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        const ctx = document.getElementById(this.chartId);
        ctx.clear();
        // eslint-disable-next-line no-unused-vars
        const chart = new Chart(ctx, this.data);
      },
    },
  },
  mounted() {
    const ctx = document.getElementById(this.chartId);
    const chart = new Chart(ctx, this.data);

    // Ajuste la taille au print
    window.addEventListener('beforeprint', () => {
      chart.responsive = false;
      chart.resize();
    });
    window.addEventListener('afterprint', () => {
      chart.responsive = true;
      chart.resize();
    });
  },
};
</script>

<style lang="sass">
.app-chart
  max-width: 300px
  position: relative
  canvas
    min-width: 0
    max-width: 100%
</style>
