<template>
  <div class="talent-icon" :class="{ long: isLong, big }">
    <div class="initials" :class="colorClass">
      <template v-if="user.talentId" >
        <span class='first-name'>{{ this.firstnameInitial }}</span>
        <span class='last-name'>{{ this.lastnameInitial }}</span>
      </template>
      <template v-else>
        <span class='last-name'>E</span>
      </template>
    </div>
    <div class="full-name" v-if="isLong" :class="colorClass">
      {{ this.user.lastname }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'talent-icon',
  props: {
    user: {
      default: {
        talentId: '',
        firstname: '-',
        lastname: '-',
      },
    },
    isLong: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorClass: '',
      colors: ['blue', 'green', 'main', 'orange', 'purple'],
    };
  },
  computed: {
    // Gets the first letter of the firstname
    firstnameInitial() {
      return this.user.firstname.charAt(0);
    },
    // Gets the first letter of the lastname
    lastnameInitial() {
      return this.user.lastname.charAt(0);
    },
  },
  mounted() {
    if (this.user.talentId) {
      this.getColor();
    } else {
      const [, , main] = this.colors;
      this.colorClass = main;
    }
  },
  watch: {
    user() {
      if (this.user.talentId) {
        this.getColor();
      } else {
        const [, , main] = this.colors;
        this.colorClass = main;
      }
    },
  },
  methods: {
    getColor() {
      // Takes all the numbers of the UUID and adds them
      const numbers = this.user.talentId.replace(/\D/g, '');
      let total = 0;
      for (let i = 0; i < numbers.length; i += 1) {
        total += parseInt(numbers[i], 10);
      }
      // Select a color depending on the result
      this.colorClass = this.colors[total % this.colors.length];
    },
  },
};
</script>

<style lang="sass">
.talent-icon
  cursor: pointer
  .initials
    width: 32px
    height: 32px
    border-radius: 100px
    display: flex
    justify-content: center
    align-items: center
    background: $text-color
    color: white
    font-weight: bold
    text-transform: uppercase
    &.main
      background: $main-color
    &.blue
      background: $common-blue
    &.green
      background: $common-green
    &.orange
      background: $common-orange
    &.purple
      background: $common-dark-purple
  .full-name
    display: inline-block
    background: rgba(119, 119, 119, 0.3)
    padding: 8px 12px 6px 42px
    line-height: 16px
    height: 32px
    border-radius: 16px
    &.main
      background: rgba(92, 51, 195, 0.3)
      color: darken($main-color, 25%)
    &.blue
      background: rgba(0, 147, 229, 0.3)
      color: darken($common-blue, 25%)
    &.green
      background: rgba(43, 215, 204, 0.3)
      color: darken($common-green, 25%)
    &.orange
      background: rgba(250, 105, 58, 0.3)
      color: darken($common-orange, 25%)
    &.purple
      background: rgba(96, 61, 124, 0.3)
      color: darken($common-dark-purple, 25%)
  &.long
    position: relative
    .initials
      position: absolute
      top: 0
      left: 0
  &.big
    margin: 2rem
    .initials
      width: 4rem
      height: 4rem
      font-size: x-large
  &:hover
    .initials
      &.main
        background: darken($main-color, 10%)
      &.blue
        background: darken($common-blue, 10%)
      &.green
        background: darken($common-green, 10%)
      &.orange
        background: darken($common-orange, 10%)
      &.purple
        background: darken($common-dark-purple, 10%)
  &.focus
    box-shadow: 0 0 0 8px rgba(92, 51, 195, 0.4)
    border-radius: 16px
</style>
