<template>
  <div class="app-input" :class="{ error, 'error-main-color': errorMainColor }">
    <textarea
      v-if="multiline"
      ref="textarea"
      :value="value"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :required="required"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      @invalid.prevent="handleInvalid"
    ></textarea>
    <input
      v-else
      ref="input"
      :type="type"
      :value="value"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :required="required"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      @invalid.prevent="handleInvalid"
    />
    <p class="helper-text" v-if="helper">{{ helper }}</p>
    <p class="helper-text" v-if="validationError">{{ validationError }}</p>
    <img v-if="type && type === 'password'" :src="isPasswordVisible ? visible : hidden" alt="" @click="handleClick">
  </div>
</template>

<script>
import hidden from '../../assets/img/icons/24px/hidden.svg';
import visible from '../../assets/img/icons/24px/visible.svg';

export default {
  props: {
    value: [String, Array, Number],
    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password', 'tel'].indexOf(val) !== -1,
    },
    autocomplete: {
      default: 'off',
    },
    helper: {
      default: '',
    },
    disabled: {
      default: false,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    min: { // Minimim number of words required
      type: Number,
    },
    max: { // Maximum number of words required
      type: Number,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMainColor: { // Affiche les errors avec la couleur principale
      type: Boolean,
    },
  },
  data() {
    return {
      hidden,
      visible,
      isInputPassword: false,
      isPasswordVisible: false,
      validationError: null,
    };
  },
  computed: {
    input() {
      return this.value;
    },
    elementRef() {
      return this.multiline ? 'textarea' : 'input';
    },
    nbWords() {
      return this.value.replace(/\s+/g, ' ').trim().split(' ').length;
    },
    error() {
      return !!this.validationError;
    },
  },
  watch: {
    isPasswordVisible() {
      switch (this.isPasswordVisible) {
        case true:
          this.$refs.input.type = 'text';
          break;
        default:
          this.$refs.input.type = 'password';
          break;
      }
    },
    value() {
      this.checkCustomValidation();
    },
  },
  mounted() {
    this.isInputPassword = (this.type && this.type === 'password');
    this.checkCustomValidation();
  },
  methods: {
    focus() {
      this.$refs[this.elementRef].focus();
    },
    handleClick() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    handleInvalid() {
      this.validationError = this.$refs[this.elementRef].validationMessage;
    },
    checkCustomValidation() {
      this.validationError = null;
      if (this.max || this.min) {
        if (this.nbWords > this.max || this.nbWords < this.min) {
          this.$refs[this.elementRef].setCustomValidity(`Please answer in ${this.min} to ${this.max} words.`);
        } else {
          this.$refs[this.elementRef].setCustomValidity('');
        }
      }
    },
  },
};
</script>

<style lang="sass">
.app-input
  position: relative
  display: flex
  flex-direction: column
  input, textarea
    width: 100%
    padding: 13px 16px
    background: $white
    font-size: $global-font-size
    border-radius: $global-border-radius
    border: 2px solid $light-color
    transition: all 0.2s ease-in-out
    color: $link-color
    &:hover
      border-color: darken($light-color, 10%)
    &:focus
      outline: 0
      border-color: $main-color
      color: $black
    &:disabled
      background: $light-color
      &:hover
        border-color: $light-color
  textarea
    resize: none
    flex-grow: 1
    &:disabled
      height: fit-content
  img
    position: absolute
    margin: auto
    right: 16px
    top: 0.9rem
    cursor: pointer
  .helper-text
    margin: 8px 0 20px
    color: $link-color
  &.error
    input, textarea
      border: 2px solid $error-color
      &:hover
        border-color: $error-color
    .helper-text
      color: $error-color
      font-weight: 700
  &.error.error-main-color
    input, textarea
      border-color: $main-color
      &:hover
        border-color: $main-color
    .helper-text
      color: $main-color
</style>
