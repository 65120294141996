<template>
  <div class="form-preview">

    <!-- Accordion: Section du formulaire -->
    <app-accordion v-for="(section, index) in sections" :key="section.key" :label="section.label">
      <template v-slot:after-filler>
        <app-tag v-if="sectionErrors[section.key]" state="main">
          <SvgFlag />
          {{ sectionErrors[section.key] }} errors
        </app-tag>
        <app-button kind="subtle" size="tiny" class="edit-section-btn" @click.stop="goToSection(index)">
          <SvgEdit class="leading"/>
          Edit
        </app-button>
      </template>

      <!-- Body de l'accordéon -->
      <template v-slot:accordion-body>
        <!-- nudge -->
        <nudge :text="nudges[section.key]" v-if="nudges[section.key]">
        </nudge>
        <!-- formulaire -->
        <form :name="section.key">
          <form-question
            v-for="question in section.questions"
            :key="question.questionId"
            :question="question"
            :max="question.appliedMax"
            :min="question.appliedMin"
            disabled
          >
          </form-question>
        </form>
      </template>
    </app-accordion>

    <!-- Sticky Error banner -->
    <app-sticky-footer v-if="error" type="error">
      <SvgAlert />
      {{ error }}
    </app-sticky-footer>

    <!-- Sticky footer -->
    <app-sticky-footer v-if="sections.length" type="actions">
      <app-button kind="secondary" @click="$router.go(-1)" :disabled="savingSection">Save and continue later</app-button>
      <app-button @click="sendReview" :disabled="!isFormValid">Send for bias check</app-button>
    </app-sticky-footer>
  </div>
</template>

<script>
import copyApi from '@/services/api/copy';

import FormSectionLabel from '@/services/enums/form-section-label.enum';
import QuestionType from '@/services/enums/question-type.enum';
import CopyKey from '@/services/enums/copy-key.enum';

import SvgAlert from '@/assets/img/icons/24px/alert.svg?inline';
import SvgFlag from '@/assets/img/icons/24px/flag.svg?inline';
import SvgEdit from '@/assets/img/icons/24px/edit.svg?inline';

import Nudge from '@/components/Nudge.vue';
import FormQuestion from './FormQuestion.vue';

export default {
  name: 'form-preview',
  components: {
    SvgEdit,
    SvgFlag,
    Nudge,
    FormQuestion,
    SvgAlert,
  },
  props: {
    initialQuestions: Array,
  },
  emits: [
    'update:preview', // Emis lorsque l'utilisateur veut sortir de la preview
    'update:review', // Emis lorsque la review doit être envoyée au bias check (modifier le statut à PROCESSING)
  ],
  data() {
    return {
      sections: [],
      nudges: Object.fromEntries(Object.entries(FormSectionLabel).map(([key]) => ([key, null]))),
      QuestionType,
      openSectionIndex: +this.$route.hash.slice('#section-'.length) || 0,
      savingSection: false,
      sectionErrors: Object.fromEntries(Object.entries(FormSectionLabel).map(([key]) => ([key, 0]))),
      error: '',
    };
  },
  computed: {
    isFormValid() {
      return !Object.values(this.sectionErrors).find((val) => val > 0);
    },
  },
  methods: {
    // Initialises les sections, ordonne les questions dans chaque section
    initSections() {
      const sections = Object.entries(FormSectionLabel).map(([key, label]) => ({
        key,
        label,
        questions: this.initialQuestions
          .filter((question) => question.section === key)
          .sort((qA, qB) => qA.questionOrder - qB.questionOrder)
          .map((question) => {
            let { min } = question;
            if (question.mandatoryDependency) {
              const dependency = this.initialQuestions.find((q) => q.questionReference === question.mandatoryDependency);
              if (dependency.answer.value !== question.mandatoryDependencyValue) {
                min = 0;
              }
            }
            return {
              ...question,
              appliedMin: +min,
              appliedMax: +question.max,
            };
          }),
      }));
      this.sections = sections;
    },
    // Navigue vers l'édition de la section
    goToSection(index) {
      this.$emit('update:preview', false);
      this.$router.replace({ hash: `#section-${index}`, query: { ...this.$route.query, showErrors: true } });
    },
    // Requêtes API : récupère les nudges de chaque section
    async initNudges() {
      try {
        await Promise.all(Object.entries(FormSectionLabel).map(async ([key]) => {
          const response = await copyApi.getCopy(CopyKey[`NUDGE_${key}`]);
          this.nudges[key] = response.data.copyText;
        }));
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          text: `We are unable to fetch the nudges. ${messages}`,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    sendReview() {
      this.checkValidity();
      if (this.isFormValid) {
        this.$emit('update:review');
      }
    },
    checkValidity() {
      this.sectionErrors = Object.fromEntries(Object.entries(FormSectionLabel).map(([key]) => ([key, 0])));
      for (let j = 0; j < document.forms.length; j += 1) {
        const { elements, name } = document.forms[j];
        for (let i = 0; i < elements.length; i += 1) {
          const el = elements[i];
          el.disabled = false;
          const isValid = el.checkValidity();
          el.disabled = true;
          if (!isValid) this.sectionErrors[name] += 1;
        }
      }
    },
  },
  // Initialises les sections, nudges et lance la sauvegarde automatique
  async mounted() {
    this.initSections();
    await this.initNudges();
    this.checkValidity();
  },
};
</script>

<style lang="sass">
.form-preview
  .app-accordion
    @include form-section
    .accordion-header
      .edit-section-btn
        background: $light-color
        padding: 0.4rem 1rem
        svg
          width: 80%
          height: 80%
          transform: translateY(-0.15rem)
        &:hover ~ .open-btn
          background: initial
</style>
