import axios from 'axios';
import QuestionType from '@/services/enums/question-type.enum';
import auth from '../auth';

const API_URL = `${process.env.VUE_APP_API_URL}/reviews`;

/**
 *
 *
 * @param {int} offset int
 * @param {int} limit int
 * @param {uuid} cycleId string
 * @param {uuid} talentId string
 * @param {uuid} writerId string
 * @param {enum} status
 *  NEW = 'NEW',
 * PROCESSING = 'PROCESSING',
 * EDITING = 'EDITING',
 * COMPLETED = 'COMPLETED',
 * @param {boolean} archived
 * @param {string} search
 * @return {*}
 * @param {Array<Country>} country : pays de résidence des talents
 * @param {Array<string>} searchFunction : recherche sur la fonction des talents
 * @param {Array<TimeInRole>} timeInRole : temps passé dans le role
 * @param {Array<TimeInCompany>} timeInCompany : temps passé dans l'entreprise
 * @param {Array<Seniority>} seniority : ancienneté
 * @param {Array<string>} searchOwner : recherche sur le nom de l'owner
 * @param {string} filterCycle
 * @return {*}
 */
const getReviews = async (
  limit,
  offset,
  cycleId,
  talentId,
  writerId,
  status,
  archived,
  search,
  country,
  searchFunction,
  timeInRole,
  timeInCompany,
  seniority,
  searchOwner,
  filterCycle,
) => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(cycleId && { cycleId }),
        ...(talentId && { talentId }),
        ...(writerId && { writerId }),
        ...(status && { status }),
        ...(search && { search }),
        ...(country && { country }),
        ...(searchFunction && { searchFunction }),
        ...(timeInRole && { timeInRole }),
        ...(timeInCompany && { timeInCompany }),
        ...(seniority && { seniority }),
        ...(searchOwner && { searchOwner }),
        ...(filterCycle && { filterCycle }),
        archived,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Détails d'une review.
 * Modifie aussi answer.values pour les questions TEXT ou SELECT avec max 1 qui requiert un string et non un array
 * @param {uuid} reviewId : id de la review
 * @returns détails d'une review
 */
const getReview = async (reviewId) => {
  try {
    const response = await axios.get(`${API_URL}/${reviewId}`);
    const questions = response.data.data.questions.map((question) => {
      const { answer } = question;
      if (question.type === QuestionType.TEXT || (question.type === QuestionType.SELECT && question.max <= 1)) {
        answer.value = answer.value[0] || '';
      }
      return { ...question, answer };
    });
    return {
      data: {
        ...response.data.data,
        questions,
      },
    };
  } catch (error) { throw error; }
};

/**
 * @param {*} talentId uuid
 * @return {*}
 */
const createCycleless = async (talentId) => {
  try {
    const body = {
      talentId,
    };
    const response = await axios.post(API_URL, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @param {*} talentId uuid
 * @return {*}
 */
const deleteOne = async (reviewId) => {
  try {
    const response = await axios.delete(`${API_URL}/${reviewId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifie le statut ou le talent de la review
 * @param {uuid} reviewId : review à modifier
 * @param {ReviewStatus} status : nouveau statut de la review (optionnel)
 * @param {uuid} talentId : nouveau talent de la review
 * @returns
 */
const updateOne = async (reviewId, status, talentId) => {
  try {
    const response = await axios.put(`${API_URL}/${reviewId}`, {
      status,
      talentId,
    });
    const questions = response.data.data.questions.map((question) => {
      const { answer } = question;
      if (question.type === QuestionType.TEXT || (question.type === QuestionType.SELECT && question.max <= 1)) {
        answer.value = answer.value[0] || '';
      }
      return { ...question, answer };
    });
    return {
      data: {
        ...response.data.data,
        questions,
      },
    };
  } catch (error) { throw error; }
};

const downloadPDF = async (reviewId) => {
  try {
    const token = auth.getToken();
    window.open(`${API_URL}/${reviewId}/export?token=${token}`, '_blank');
  } catch (error) { throw error; }
};

const api = {};
api.getReviews = getReviews;
api.getReview = getReview;
api.createCycleless = createCycleless;
api.deleteOne = deleteOne;
api.updateOne = updateOne;
api.downloadPDF = downloadPDF;

export default api;
