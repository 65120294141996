<template>
  <div class="app-bubble-chart" :class="{ 'is-pdf': isPDF }">
    <div :class="(options && options.axisTitle && !isPDF) ? 'grid' : 'block'" v-if="stats.length">
      <div>
        <h3 class="axis-title" v-if="options && options.axisTitle"> {{ options.axisTitle.yAxis }} </h3>
      </div>
      <div/>
      <div class="app-chart-container">
        <app-chart :data="data" :chartId="chartId"/>
      </div>
      <div>
        <h3 class="axis-title" v-if="options && options.axisTitle"> {{ options.axisTitle.xAxis }} </h3>
      </div>
    </div>
    <p v-else>Not enough data available</p>
  </div>
</template>

<script>

/**
 * Composant AppBubbleChart :
 * Affiche un graphe en bulles
 */

// Enum
import RISK_LEVEL from '@/services/enums/risk-level.enum';

import AppChart from './AppChart.vue';

// CONSTANTES
// police
const FONT = '"Lato", Helvetica, Arial, sans-serif';
// couleurs générales
const LIGHTGREY = '#EFEEED';
const GREY = '#B4B2AE';
const BACKGROUNDPURPLE = '#1C0032';
// couleurs des bulles
const GREEN = '#15A93F';
const YELLOW = '#E8B943';
const ORANGE = '#FA693A';
const RED = '#EF4040';
const OPACITY = 0.7;

// echelle
const STEP = 25;

export default {
  name: 'AppBubbleChart',
  components: {
    AppChart,
  },
  props: {
    stats: { // Attendu sour la forme [{ label: string, presence: number, intensity: number, talentRatio: number, level: string }]
      type: Array,
      required: true,
    },
    chartId: { // THE ID MUST BE UNIQUE
      type: String,
      required: true,
    },
    options: { // Attendu sous la forme :
    // options: {
    //     radius: 14,                  // rayon remplaçant la population
    //     axisTitle: {                 // titres des axes, ne seront pas affichés si non définis
    //       xAxis: 'Nom de l'axe x',
    //       yAxis: 'Nom de l'axe y',
    //     },
    //   },
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isPDF: this.$route.meta.isPDF,
      // Constantes
      FONT,
      LIGHTGREY,
      GREY,
      BACKGROUNDPURPLE,
      GREEN,
      YELLOW,
      ORANGE,
      RED,
      OPACITY,
      STEP,
    };
  },
  methods: {
    hexToRGB(hex, alpha) { // Fonction convertissant une couleur hexadecimale en rgb
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      const a = (alpha !== undefined ? alpha : 1);

      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    colorize(context) { // Fonction permettant de colorier chaque point en fonction de son risque
      const seg = context.raw;

      let color = '';

      switch (seg.level) {
        case RISK_LEVEL.LOW:
          color = this.GREEN;
          break;
        case RISK_LEVEL.MEDIUM:
          color = this.YELLOW;
          break;
        case RISK_LEVEL.HIGH:
          color = this.ORANGE;
          break;
        case RISK_LEVEL.VERY_HIGH:
          color = this.RED;
          break;
        default:
          break;
      }

      return this.hexToRGB(color, this.OPACITY);
    },
    formatData(data) { // Formate les données retournées à graphjs
      const newData = [];

      if (this.options && this.options.radius) {
        data.forEach((element) => {
          newData.push({
            label: element.label,
            x: element.presence,
            y: element.intensity,
            r: this.isPDF ? Math.round(this.options.radius / 2) : this.options.radius,
            level: element.level,
          });
        });

        return newData;
      }

      // Taille du rayon en fonction de la population
      data.forEach((element) => {
        let radius = 0;
        if (element.talentRatio <= 20) {
          radius = 8;
        } else if (element.talentRatio <= 40) {
          radius = 16;
        } else if (element.talentRatio <= 70) {
          radius = 22;
        } else {
          radius = 34;
        }

        newData.push({
          label: element.label,
          x: element.presence,
          y: element.intensity,
          r: this.isPDF ? Math.round(radius / 2) : radius,
          level: element.level,
        });
      });

      return newData;
    },
    getAxesPoints() { // Donne la liste de points pour chaques ticks du graph
      const points = {
        originPoint: { // Point à l'origine
          type: 'point',
          xScaleID: 'xAxis',
          yScaleID: 'yAxis',
          xValue: 0,
          yValue: 0,
          radius: this.isPDF ? 2 : 3,
          borderColor: this.GREY,
          backgroundColor: this.GREY,
        },
      };

      for (let i = 25; i <= 100; i += this.STEP) {
        points[`xPoint${i}`] = { // Points de l'axe x
          type: 'point',
          xScaleID: 'xAxis',
          yScaleID: 'yAxis',
          xValue: i,
          yValue: 0,
          radius: this.isPDF ? 2 : 3,
          borderColor: this.GREY,
          backgroundColor: this.GREY,
        };
        points[`yPoint${i}`] = { // Points de l'axe y
          type: 'point',
          xScaleID: 'xAxis',
          yScaleID: 'yAxis',
          xValue: 0,
          yValue: i,
          radius: this.isPDF ? 2 : 3,
          borderColor: this.GREY,
          backgroundColor: this.GREY,
        };
      }

      return points;
    },
  },
  computed: {
    data() { // Données pour le bubble chart
      return {
        type: 'bubble',
        data: {
          datasets: [{
            data: this.formatData(this.stats),
          }],
        },
        options: {
          maintainAspectRatio: true,
          aspectRatio: 1,
          responsive: true,
          scales: {
            xAxis: {
              display: true,
              min: 0,
              max: 100,
              ticks: {
                stepSize: this.STEP, // Pas
                font: {
                  family: this.FONT,
                  size: this.isPDF ? 11 : 16,
                },
              },
              grid: {
                // Ne pas afficher les grilles
                display: false,
                drawOnChartArea: false,
                // La bordure correspond à l'axe x
                borderColor: this.LIGHTGREY,
                borderWidth: 3,
              },
            },
            yAxis: {
              display: true,
              min: 0,
              max: 100,
              ticks: {
                stepSize: this.STEP, // Pas
                font: {
                  family: this.FONT,
                  size: this.isPDF ? 11 : 16,
                },
                major: {
                  enable: true,
                },
              },
              grid: {
                // Ne pas afficher les grilles
                display: false,
                drawOnChartArea: false,
                // La bordure correspond à l'axe y
                borderColor: this.LIGHTGREY,
                borderWidth: 3,
              },
            },
          },
          plugins: {
            annotation: {
              clip: false,
              drawTime: 'afterDraw',
              annotations: this.getAxesPoints,
            },
            legend: false,
            tooltip: {
              mode: 'nearest',
              callbacks: { // Permet d'afficher le label correspondant pour chaque point
                title: (context) => context[0].raw.label, // Nom du segment
                label(context) {
                  // Dans le cas où plusieurs points sont 'affichés' dans la même tooltip, n'afficher que le premier
                  const labelIndex = this.dataPoints.findIndex((element) => element.raw.label === context.raw.label);
                  if (!labelIndex) {
                    const presence = `${context.raw.x.toFixed(2)}%`;
                    const intensity = `${context.raw.y.toFixed(2)}%`;
                    return [`${presence} Presence`, `${intensity} Intensity`];
                  }
                  return '';
                }, // % de presence et % d'intensité
                // Dans le cas où plusieurs points sont 'affichés' dans la même tooltip, afficher les autres labels dans le footer
                // footer: (context) => {
                //   if (context.length > 1) {
                //     context.shift();
                //     return ['intersecting with:', ...context.map((tooltipItem) => `  ${tooltipItem.raw.label}`)];
                //   }
                //   return '';
                // },
              },
              caretPadding: (context) => (context.tooltipItems[0] ? context.tooltipItems[0].raw.r + 12 : 0), // (caretPadding: radius + padding)
              // caretPadding rajoute un padding en fonction du centre, permet de commencer le padding à partir de l'extrémité du cercle
              // le tooltip n'est créé qu'une fois une bulle séléctionnée, "context.tooltipItems[0] ?" permet d'éviter l'erreur à la création du tooltip
              titleFont: {
                family: this.FONT,
                size: 16,
              },
              bodyFont: {
                family: this.FONT,
                size: 14,
              },
              footerFont: {
                family: this.FONT,
                size: 12,
                weight: 'normal',
              },
              padding: 16,
              displayColors: false,
              usePointStyle: true,
              backgroundColor: this.BACKGROUNDPURPLE,
              // intersect: false,
            },
            // Pour ajouter la possibilité de zoomer
            // zoom: {
            //   zoom: {
            //     wheel: {
            //       enabled: true,
            //     },
            //     pinch: {
            //       enabled: true,
            //     },
            //     mode: 'xy',
            //   },
            //   pan: {
            //     enabled: true,
            //     mode: 'xy',
            //   },
            //   limits: {
            //     x: { min: 0, max: 100 },
            //     y: { min: 0, max: 100 },
            //   },
            // },
          },
          elements: {
            point: { // Permet de colorier chaque point en fonction de son niveau de risque
              backgroundColor: this.colorize.bind(false),
              borderWidth: 0,
            },
          },
        },
      };
    },
  },
};
</script>
<style lang="sass">
.app-bubble-chart
  padding: 20px
  background-color: $background-color

  .grid
    display: grid
    grid-template-rows: 40px 4fr
    grid-template-columns: 4fr 140px

    .app-chart
      padding: 20px 30px 0 20px

    div:not(.app-chart-container)
      position: relative

      .axis-title
        width: 140px
        height: 40px
        position: absolute
        bottom: 0

  .block
    div
      &:first-child h3
        float: left
        max-width: 15ch
      &:last-child h3
        float: right
        max-width: 15ch

  .app-chart-container
    width: 100%
    .app-chart
      max-width: 100%
      min-width: 0px
      width: 100%
  > div h3
    margin: 0
    color: $common-gray
    text-align: center
    font-size: 16px

  &.is-pdf
    padding: 5px
    .axis-title
      font-size: 11px

</style>
