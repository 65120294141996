<template>
  <div class="templates">
    <div class="title-bar grid-x align-middle grid-margin-x">
      <app-title class="cell auto">Template</app-title>
    </div>
    <div class="grid-x container align-center">
      <Loader v-if="loading" />

      <div v-else class="cell medium-12 copy">
        <!-- Accordion: Section du formulaire -->
        <app-accordion
          v-for="(section, index) in sections"
          :key="section.key"
          :label="section.label"
          :showOpen="index === 0"
          :id="`section-${index}`"
        >
          <!-- Body de l'accordéon -->
          <template v-slot:accordion-body>
            <!-- formulaire -->
            <form :name="section.key">
              <template-form-question
                v-for="(question, index) in section.questions"
                :key="question.questionId"
                :question="question"
                @update:question="(templateUpdated) => saveQuestion(templateUpdated, index)"
                disabled
              >
              </template-form-question>
            </form>
          </template>
        </app-accordion>
      </div>
    </div>
  </div>
</template>

<script>
import templateApi from '@/services/api/template';
import FormSectionLabel from '@/services/enums/form-section-label.enum';
import TemplateFormQuestion from './TemplateFormQuestion.vue';

import Loader from '../../components/Loader.vue';

export default {
  components: { TemplateFormQuestion, Loader },
  name: 'templates',
  data() {
    return {
      templates: [],
      sections: [],
      loading: false,
    };
  },
  watch: {
    templates() {
      return this.initSections();
    },
  },
  mounted() {
    this.getTemplates();
  },
  methods: {
    async getTemplates() {
      this.loading = true;
      try {
        const response = await templateApi.getTemplates();
        this.templates = response.data;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Templates could not be fetched. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    // Initialises les sections, ordonne les questions dans chaque section
    initSections() {
      const sections = Object.entries(FormSectionLabel).map(([key, label]) => ({
        key,
        label,
        questions: this.templates
          .filter((question) => question.section === key)
          .sort((qA, qB) => qA.questionOrder - qB.questionOrder),
        open: false,
      }));
      this.sections = sections;
    },
    saveQuestion(templateUpdated, templateIndex) {
      this.$set(this.templates, templateIndex, templateUpdated);
    },
  },
};
</script>

<style lang="sass">
.templates
  @include screen
  .container
    .app-subtitle
      margin: 0 0 12px 24px
      color: $main-color
      font-size: 20px
  .app-accordion
    @include form-section
</style>
