<template>
  <section class="forgot-password">
    <div class="grid-x align-center align-middle">
      <form class="cell small-11 medium-4" ref="login" @submit.prevent="sendEmail">
        <div>
          <p>Enter your email address so we can send you an email with a password reset link</p>
        </div>
        <div>
          <app-label>Email</app-label>
          <app-input autocomplete="email" type="email" required v-model="user.email" :class="{ error: hasError }"  :helper="errorMessage"/>
        </div>
        <div>
          <app-button type="submit" id="login-btn" :disabled="isLoading">Send<arrowRight/></app-button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import arrowRight from '../assets/img/icons/24px/arrow-right.svg?inline';
import apiAuth from '../services/auth';

export default {
  name: 'forgot-password',
  components: {
    arrowRight,
  },
  props: {
    isSuperAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: {
        email: '',
      },
      hasError: false,
      errorMessage: '',
      isLoading: false,
    };
  },
  methods: {
    async sendEmail() {
      this.isLoading = true;
      this.hasError = false;
      this.errorMessage = '';
      try {
        if (this.isSuperAdmin) {
          await apiAuth.resetPasswordSuperAdmin(this.user.email);
        } else {
          await apiAuth.resetPasswordTalent(this.user.email);
        }
      } catch (error) {
        this.hasError = true;
        this.errorMessage = 'An issue occured: please try again';
      }

      if (!this.hasError) {
        this.$message.show({
          title: 'Success',
          text: 'An email was just sent, have a look at your inbox ! You will be automatically redirected to the login page.',
          confirmText: 'Ok',
          hasCancel: false,
          onConfirm: () => this.redirectToLogin(),
        });
      }
      this.isLoading = false;
    },
    redirectToLogin() {
      if (this.isSuperAdmin) {
        this.$router.push({ name: 'super-admin-login' });
      } else {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style lang="sass">
.wrapper
  min-height: 100vh
.forgot-password
  min-height: calc(100vh - #{$header-height} - var(--wrapper-padding-bottom))
  > div
    min-height: inherit
  p
    margin: 0
    color: $common-gray
    font-size: 20px
    margin-bottom: 3rem
  form
    .app-button
      margin-top: 48px
</style>
