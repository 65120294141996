// Data source : https://stefangabos.github.io/world_countries/

const SeniorityLabel = Object.freeze({
  INDIVIDUAL_CONTRIBUTOR: 'Individual contributor',
  MANAGER: 'Manager',
  BUSINESS_UNIT_LEADER: 'Business unit leader',
  FUNCTIONAL_LEADER: 'Functional leader',
  SENIOR_EXECUTIVE: 'Senior executive',
});

export default SeniorityLabel;
