<template>
  <div
    class="app-gauge-chart"
    :class="{
      black: biasLevel === BiasSeverity.SEVERE,
      error: biasLevel === BiasSeverity.MODERATE,
      yellow: biasLevel === BiasSeverity.LOW,
      negative: index < 0,
      small,
    }">
    <div class="gauge-value" :style="{ width: `${Math.min(roundedIndex, 50)}%` }" :class="{ full: roundedIndex >= 50}">
      <p class="gauge-value-number">
        {{ roundedIndex > 50 ? '50+' : roundedIndex }}
      </p>
    </div>
  </div>
</template>

<script>
import StatsDisplay from '@/mixins/StatsDisplay.vue';
import BiasSeverity from '@/services/enums/bias-severity.enum';

export default {
  name: 'app-gauge-chart',
  mixins: [StatsDisplay],
  props: {
    index: {
      type: Number,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      BiasSeverity,
    };
  },
  computed: {
    biasLevel() {
      return this.getBiasSeverity(this.index);
    },
    roundedIndex() {
      return Math.round(Math.abs(this.index));
    },
  },
};
</script>

<style lang="sass">
.app-gauge-chart
  height: 16px
  border-radius: 100px
  background: $light-color
  margin: 0 20px
  position: relative
  display: flex
  justify-content: center
  width: 100%
  max-width: 620px
  .gauge-value
    height: 100%
    background: gray
    position: relative
    transform: translateX(-50%)
    z-index: 10
    &-number
      position: absolute
      bottom: -30px
      left: -0.5em
      font-size: 11px
      line-height: 16px
      color: $common-gray
    &.full
      border-radius: 100px 0 0 100px
  &:after
    content: ""
    display: block
    height: 56px
    width: 2px
    background: $light-color
    position: absolute
    top: -20px
    left: calc(50% - 1px)
  &.error
    background: linear-gradient(90deg, $error-bg-color 0%, $error-bg-color 50%, $light-color 50%, $light-color 100%)
    .gauge-value
      background: $error-color
  &.yellow
    background: linear-gradient(90deg, #faf1d9 0%, #faf1d9 50%, $light-color 50%, $light-color 100%)
    .gauge-value
      background: #E8B943
  &.black
    background: linear-gradient(90deg, $black-bias-bg 0%, $black-bias-bg 50%, $light-color 50%, $light-color 100%)
    .gauge-value
      background: $black

  &.small
    margin: 0

  &.negative
    .gauge-value
      transform: translateX(50%)
      &-number
        left: unset
        right: -0.5em
      &.full
        border-radius: 0 100px 100px 0
    &.error
      background: linear-gradient(90deg, $light-color 0%, $light-color 50%, $error-bg-color 50%, $error-bg-color 100%)
    &.yellow
      background: linear-gradient(90deg, $light-color 0%, $light-color 50%, #faf1d9 50%, #faf1d9 100%)
</style>
