<template>
  <h2 class="app-subtitle"><slot></slot></h2>
</template>

<script>
export default {
};
</script>

<style lang="sass">
.app-subtitle
  position: relative
  padding: 0.5rem 0
  font-weight: bold
  font-size: 2rem
  text-align: left
</style>
