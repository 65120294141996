import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Récupère la liste des flags d'une review
 * @param {uuid} reviewId : review
 * @returns liste des flags d'une review
 */
const getFlags = async (reviewId) => {
  try {
    const response = await axios.get(`${API_URL}/reviews/${reviewId}/flags`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Marque le flag en résolu (resolved) et/ou donne un feedback (useful)
 * @param {uuid} flagId : flag
 * @param {boolean} resolved : s'il est résolu
 * @param {boolean} useful : s'il a été util
 * @returns le flag mis à jour
 */
const resolveFlag = async (flagId, useful, resolved) => {
  try {
    const response = await axios.put(`${API_URL}/flags/${flagId}`, { resolved, useful });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getFlags = getFlags;
api.resolveFlag = resolveFlag;

export default api;
