<template>
  <section class="cycle" :class="{loading: isLoading}">
    <Loader v-if="isLoading" />

    <template v-else>
      <app-link size="small" @click="$router.push({ name: 'cycles' })"><arrowRight />Back to Cycles</app-link>

      <div class="grid-x head">
        <app-title class="cell auto" v-if="cycle">{{ cycle.name }}</app-title>
        <div class="cell shrink">
          <app-button size="medium" @click="downloadPDF" v-if="cycle.status === CycleStatus.COMPLETED"><download class="leading"/>Export</app-button>
        </div>
        <div class="cell shrink">
          <app-button size="medium" kind="secondary" @click="$router.push({ name: 'cycle-settings', params: { cycleId: $route.params.cycleId }})"><settings class="leading"/>Settings</app-button>
        </div>
      </div>

      <app-tab @click="activeTab = $event" :tabs="tabs.map((tab) => tab.label)"/>
      <div>
        <p class="data-placeholder" v-if="uncompleteCyclePlaceholder">{{ uncompleteCyclePlaceholder }}</p>
        <CycleTalents v-if="tabs[activeTab].key === 'talents'" @update:tab="activeTab = $event" :talentsStats="talentsStats" :answersStats="answersStats"/>
        <CycleSegmentAtRisk v-if="tabs[activeTab].key === 'segment-at-risk'" :biasStats="biasStats" :typicalBiases="typicalBiases"/>
        <CycleTypicalBiasAcrossSegments v-if="tabs[activeTab].key === 'typical-bias-risks'" :biasStats="biasStats" :typicalBiases="typicalBiases"/>
        <CycleWhereDoBiasesComeFrom v-if="tabs[activeTab].key === 'where-do-biases-come-from'" :biasFemaleStats="biasFemaleStats" :biasMaleStats="biasMaleStats" :typicalBiases="typicalBiases"/>
        <CycleDriveForChange v-if="tabs[activeTab].key === 'drive-for-change'" :biasStats="biasStats"/>
        <CycleBiasInSegments v-if="tabs[activeTab].key === 'bias-in-segments'" :segmentsData="biasStats.segmentsBiasRisk" :typicalBiases="typicalBiases"/>
        <CycleReviews v-if="tabs[activeTab].key === 'reviews'"/>
      </div>
    </template>
  </section>
</template>

<script>
// API
import cycleApi from '@/services/api/cycle';
import typicalBiasApi from '@/services/api/typical-bias';

// ENUMS
import BiasStatsDatapool from '@/services/enums/bias-stats-datapool.enum';
import CycleStatus from '@/services/enums/cycle-status.enum';

// SVG
import arrowRight from '@/assets/img/icons/16px/arrow-right.svg?inline';
import download from '@/assets/img/icons/24px/download.svg?inline';
import settings from '@/assets/img/icons/24px/edit.svg?inline';

// TABS
import CycleTalents from './cycle-tabs/CycleTalents.vue';
import CycleSegmentAtRisk from './cycle-tabs/CycleSegmentAtRisk.vue';
import CycleTypicalBiasAcrossSegments from './cycle-tabs/CycleTypicalBiasAcrossSegments.vue';
import CycleWhereDoBiasesComeFrom from './cycle-tabs/CycleWhereDoBiasesComeFrom.vue';
import CycleBiasInSegments from './cycle-tabs/CycleBiasInSegments.vue';
import CycleDriveForChange from './cycle-tabs/CycleDriveForChange.vue';
import CycleReviews from './cycle-tabs/CycleReviews.vue';

import Loader from '../../components/Loader.vue';

export default {
  name: 'cycle',
  components: {
    arrowRight,
    download,
    settings,
    CycleTalents,
    CycleSegmentAtRisk,
    CycleTypicalBiasAcrossSegments,
    CycleWhereDoBiasesComeFrom,
    CycleBiasInSegments,
    CycleDriveForChange,
    CycleReviews,
    Loader,
  },
  data() {
    return {
      cycleId: this.$route.params.cycleId,
      cycle: null,
      activeTab: Number(this.$route.query.activeTab) || 0,
      isLoading: true,
      talentsStats: null,
      biasStats: null,
      biasFemaleStats: null,
      biasMaleStats: null,
      answersStats: null,
      uncompleteCyclePlaceholder: null, // Phrase à afficher au lieu des stats lorsque le cycle n'est pas complété
      tabs: [],
      typicalBiases: [],
      CycleStatus,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    cycle() {
      switch (this.cycle.status) {
        case CycleStatus.DRAFT: {
          this.uncompleteCyclePlaceholder = 'This cycle has not started yet.\nNo insights available.';
          this.tabs = [{
            label: 'Description of Cycle talents',
            key: 'talents',
          }, {
            label: 'TGPs',
            key: 'reviews',
          }];
          break;
        }
        case CycleStatus.ONGOING: {
          this.uncompleteCyclePlaceholder = 'This is an ongoing cycle.\nNo insights available.';
          this.tabs = [{
            label: 'Description of Cycle talents',
            key: 'talents',
          }, {
            label: 'TGPs',
            key: 'reviews',
          }];
          break;
        }
        default: {
          this.uncompleteCyclePlaceholder = null;
          this.tabs = [{
            label: 'Description of Cycle talents',
            key: 'talents',
          }, {
            label: 'Segment at risk',
            key: 'segment-at-risk',
          }, {
            label: 'Typical Bias risks accross the segments',
            key: 'typical-bias-risks',
          }, {
            label: 'Where do biases com from ?',
            key: 'where-do-biases-come-from',
          }, {
            label: 'Drive for change',
            key: 'drive-for-change',
          }, {
            label: 'Bias in segments',
            key: 'bias-in-segments',
          }, {
            label: 'TGPs',
            key: 'reviews',
          }];
          break;
        }
      }
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.getCycle();
      await this.getStats();
      await this.getTypicalBiases();
      this.isLoading = false;
    },
    async getCycle() {
      try {
        const response = await cycleApi.getCycle(this.cycleId);
        this.cycle = response.data;
      } catch (err) {
        if (err.response.status === 404) {
          this.$router.push({ name: 'not-found' });
        }
        const messages = typeof err.response.data.message === 'string' ? err.response.data.message : err.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the cycle. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    async getTalentsStats() {
      try {
        const response = await cycleApi.getTalentsStats(this.cycleId);
        this.talentsStats = response.data;
      } catch (e) {
        throw e;
      }
    },
    async getBiasStats(datapool) {
      try {
        const response = await cycleApi.getBiasStats(this.cycleId, datapool);
        switch (datapool) {
          case BiasStatsDatapool.FEMALE_WRITERS: this.biasFemaleStats = response.data; break;
          case BiasStatsDatapool.MALE_WRITERS: this.biasMaleStats = response.data; break;
          default: this.biasStats = response.data; break;
        }
      } catch (error) {
        throw error;
      }
    },
    async getAnswersStats() {
      try {
        const response = await cycleApi.getAnswersStats(this.cycleId);
        this.answersStats = response.data;
      } catch (error) {
        throw error;
      }
    },
    async getTypicalBiases() {
      try {
        const response = await typicalBiasApi.getTypicalBiases(this.cycleId);
        this.typicalBiases = Object.fromEntries(response.data.map((typicalBias) => [typicalBias.reference, typicalBias]));
      } catch (error) {
        throw error;
      }
    },
    async getStats() {
      try {
        const promises = [this.getTalentsStats()];
        if (this.cycle.status === CycleStatus.COMPLETED) {
          promises.concat([
            this.getAnswersStats(),
            this.getBiasStats(),
            this.getBiasStats(BiasStatsDatapool.FEMALE_WRITERS),
            this.getBiasStats(BiasStatsDatapool.MALE_WRITERS),
          ]);
        }
        await Promise.all(promises);
        if (this.biasStats && this.biasStats.nbWriters < +process.env.VUE_APP_NB_WRITERS_MIN_CYCLE) {
          this.uncompleteCyclePlaceholder = 'This cycle has too few authors. No insights available.';
          this.tabs = [{
            label: 'Description of Cycle talents',
            key: 'talents',
          }, {
            label: 'TGPs',
            key: 'reviews',
          }];
        }
      } catch (error) {
        const text = Array.isArray(error.response.data.message) ? error.response.data.message.join('\n') : error.response.data.message;
        this.$message.show({
          text,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    async downloadPDF() {
      await cycleApi.downloadPDF(this.cycle.cycleId);
    },
  },
};
</script>

<style lang="sass">
section.cycle
  @include screen
  &.loading
    min-height: calc(100vh - #{$header-height} - #{var(--wrapper-padding-bottom)})
    display: flex
    align-items: center
    justify-content: center
  .head
    align-items: center
    .app-button
      margin-left: 1rem
  .app-link
    @include back-link
  h2
    font-size: 1.17em
    color: $main-secondary-color
    margin-top: 56px
  .page
    > .app-subtitle + p
      max-width: 60%
      margin-bottom: 56px
  .data-placeholder
    @include stats-placeholder
</style>
