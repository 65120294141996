// Data source : https://stefangabos.github.io/world_countries/

const TimeInCompanyLabel = Object.freeze({
  YEAR_0_TO_2: 'Less than 2 years',
  YEAR_2_TO_5: '2 to 5 years',
  YEAR_6_TO_14: '6 to 14 years',
  YEAR_15_PLUS: 'More than 15 years',
});

export default TimeInCompanyLabel;
