<template>
  <section class="cycle-edit-talents">
    <div class="content-wrapper">
      <app-link @click="goToSettings">
        <SvgArrowRight />
        Back to Cycle settings
      </app-link>
      <app-title>Edit Talent List {{ addingTalents ? '- Add talents' : '' }}</app-title>

      <!-- Barre de recherche avec boutons de filtres -->
      <div class="search-bar">
        <app-search placeholder="Search..." v-model="searchName"></app-search>
        <div class="buttons-container">
          <div class="action-container">
            <app-button @click="setAddingTalents(!addingTalents)" size="medium">
              <SvgPlus v-if="!addingTalents" class="leading" />
              {{ addingTalents ? 'See talent list' : 'Add talents' }}
            </app-button>
          </div>
          <app-button v-if="filtersCount > 0" kind="minimal" size="medium" class="clear-filters-button" @click="$refs.talentFilters.clearAll()">
            <SvgCross class="leading" />
            Clear filters
          </app-button>
          <app-button kind="minimal" size="medium" @click="openFilters = true">
            Filters {{ filtersCount ? `(${filtersCount})` : '' }}
            <SvgFilter class="trailing" />
          </app-button>
        </div>
      </div>
      <!-- Filtres de talents -->
      <talent-filters :show="openFilters" @close="openFilters = false" ref="talentFilters"></talent-filters>

      <!-- Table de talents avecvec pagination -->
      <div class="table-container">
        <app-table class="table" :loading="loading">
          <template v-slot:headers>
            <div class="cell shrink talent-selector">
              <app-checkbox :input="selectedAll" @input="toggleAll"/>
            </div>
            <div class="cell auto">Name <SvgTriangle /></div>
            <div class="cell auto">Role title</div>
            <div class="cell auto">Function</div>
            <div class="cell auto">Owned by</div>
            <div class="cell auto"></div>
          </template>
          <template v-slot:body>
            <div class="row grid-x align-middle talents-line" v-for="talent in talents" :key="talent.talentId">
              <div class="cell shrink talent-selector">
                <app-checkbox :input="isSelected(talent)" @input="toggleTalent(talent)"/>
              </div>
              <div class="cell auto talent-name">
                <talent-icon :user="talent"></talent-icon>
                <div class="talent-info">
                  <div>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</div>
                  <div class="talent-email cell">{{ talent.email }}</div>
                </div>
              </div>
              <div class="cell auto">{{ talent.talentVersion.role }}</div>
              <div class="cell auto">{{ talent.talentVersion.function }}</div>
              <div class="cell auto">
                <talent-icon v-if="talent.owner" :user="talent.owner" isLong></talent-icon>
                <div v-else class="pending-owner"></div>
              </div>
              <div class="cell auto">
                <template v-if="addingTalents">
                  <app-button kind="minimal" size="medium" :disabled="isSelected(talent)" @click="assignOwnerModal.talents=talent; assignOwnerModal.show = true;">Assign author</app-button>
                </template>
                <template v-else>
                  <app-button kind="primary" size="medium" @click="assignOwnerModal.talents=talent; assignOwnerModal.show = true;">Assign author</app-button>
                  <app-button kind="minimal" size="medium" @click="editCycle(talent)">Remove</app-button>
                </template>
              </div>
            </div>
          </template>
        </app-table>
        <pagination :limit="metadata.limit" :offset="metadata.offset" :count="metadata.count" @update:page="changePage"/>
      </div>

      <!-- Assign owner modal -->
      <app-modal title="Select talent" :show="assignOwnerModal.show" @update:show="assignOwnerModal.show = false">
        <talent-select @update:selected-talent="assignOwnerModal.owner = $event"></talent-select>
        <template v-slot:footer>
          <app-button @click="assignOwnerModal.show = false" kind="minimal">Cancel</app-button>
          <app-button @click="assignOwner()" :disabled="assignOwnerModal.saving || !assignOwnerModal.owner">
            <div v-if="assignOwnerModal.saving" class="loader-assigning">Assigning author<app-spinner class="spinner-in-btn"></app-spinner></div>
            <span v-else>Save</span>
          </app-button>
        </template>
      </app-modal>

    </div>

    <!-- Sticky footer -->
    <app-sticky-footer v-if="selectedTalents.length">
      {{ selectedTalents.length }} talents selected
      <template v-if="addingTalents && selectedWithoutOwners.length"> ({{ selectedWithoutOwners.length }} without authors)</template>
      <div>
        <app-button kind="secondary" @click="selectedTalents = []">Deselect</app-button>
        <template v-if="addingTalents">
          <app-button kind="secondary" @click="assignOwnerModal.talents = selectedTalents; assignOwnerModal.show = true">Assign author</app-button>
          <app-button :disabled="!!selectedWithoutOwners.length" @click="editCycle()">Add talents</app-button>
        </template>
        <template v-else>
          <app-button kind="secondary" @click="editCycle()">Remove talents</app-button>
          <app-button @click="assignOwnerModal.talents = selectedTalents; assignOwnerModal.show = true">Assign author</app-button>
        </template>
      </div>
    </app-sticky-footer>

  </section>
</template>

<script>
import talentApi from '@/services/api/talent';
import cycleApi from '@/services/api/cycle';
import authApi from '@/services/auth';
import utils from '@/services/utils';

import SvgArrowRight from '@/assets/img/icons/16px/arrow-right.svg?inline';
import SvgCross from '@/assets/img/icons/24px/cross.svg?inline';
import SvgFilter from '@/assets/img/icons/24px/filter.svg?inline';
import SvgPlus from '@/assets/img/icons/24px/plus.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

import TalentIcon from '@/components/TalentIcon.vue';
import Pagination from '@/components/Pagination.vue';
import TalentFilters from '../talents/TalentFilters.vue';
import TalentSelect from '../talents/TalentSelect.vue';

export default {
  name: 'cycle-edit-talents',
  components: {
    SvgArrowRight,
    SvgFilter,
    SvgCross,
    SvgPlus,
    SvgTriangle,
    TalentFilters,
    TalentIcon,
    Pagination,
    TalentSelect,
  },
  data() {
    return {
      cycleId: this.$route.params.cycleId,
      addingTalents: this.$route.query.addingTalents === 'true',
      loading: false,
      user: authApi.getUser(),
      openFilters: false,
      talents: [],
      selectedTalents: [],
      searchName: this.$route.query.searchName || '',
      metadata: {
        limit: 10,
        offset: 0,
        count: 0,
      },
      groupRequestsNumber: process.env.VUE_APP_GROUP_REQUESTS_NUMBER,
      groupRequestsInterval: process.env.VUE_APP_GROUP_REQUESTS_INTERVAL,
      assignOwnerModal: {
        show: false,
        saving: false,
        owner: null,
        talents: [],
      },
    };
  },
  computed: {
    filtersCount() {
      const timeInRole = utils.initArray(this.$route.query.timeInRole);
      const timeInCompany = utils.initArray(this.$route.query.timeInCompany);
      const seniority = utils.initArray(this.$route.query.seniority);
      const filterFunction = utils.initArray(this.$route.query.filterFunction);
      const filterOwner = utils.initArray(this.$route.query.filterOwner);
      const filterCountry = utils.initArray(this.$route.query.filterCountry);
      return filterFunction.length + filterOwner.length + filterCountry.length + timeInRole.length + timeInCompany.length + seniority.length;
    },
    // Si toutes les lignes du tableau sont cochées
    selectedAll() {
      return !!this.selectedTalents.length && this.talents.every((talent) => this.isSelected(talent));
    },
    // Les talents cochés mais sans owners
    selectedWithoutOwners() {
      return this.selectedTalents.filter((talent) => !talent.owner);
    },
  },
  watch: {
    searchName() {
      this.debouncedUpdateQuery({ offset: 0, searchName: this.searchName });
    },
    // Lorsqu'on change de 'tableau', tout reset
    addingTalents() {
      this.$refs.talentFilters.clearAll();
      this.searchName = '';
      this.selectedTalents = [];
    },
    // reset le data de la modale
    'assignOwnerModal.show': {
      handler() {
        if (!this.assignOwnerModal.show) {
          this.assignOwnerModal.owner = null;
          this.assignOwnerModal.talents = [];
          this.assignOwnerModal.saving = false;
        }
      },
    },
    $route() {
      if (this.$route.query.addingTalents !== this.addingTalents.toString()) {
        this.addingTalents = this.$route.query.addingTalents === 'true';
      }
      this.getTalents(this.$route.query.limit, this.$route.query.offset);
    },
  },
  methods: {
    // Change la page du tableau
    changePage({ limit, offset }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    setAddingTalents(addingTalents) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          addingTalents,
        },
      });
    },
    // Si le talent est sélectionné
    isSelected(talent) {
      const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      return index >= 0;
    },
    // Toggle la sélection d'un talent
    toggleTalent(talent) {
      const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      if (index < 0) {
        this.selectedTalents.push(talent);
      } else {
        this.selectedTalents.splice(index, 1);
      }
    },
    // Sélectionnes ou Déselectionne tous les talents affichés
    toggleAll() {
      if (this.selectedAll) {
        this.talents.forEach((talent) => this.toggleTalent(talent));
      } else {
        this.talents.forEach((talent) => !this.isSelected(talent) && this.selectedTalents.push(talent));
      }
    },
    // Navigue vers les settings du cycle
    goToSettings() {
      this.$router.push({ name: 'cycle-settings', params: { cycleId: this.cycleId } });
    },
    // Requête API editer la liste de talents
    async editCycle(talent = null) {
      this.loading = true;
      const talents = (talent ? [talent] : this.selectedTalents).map((t) => t.talentId);
      const body = this.addingTalents ? { talentsAdded: talents } : { talentsDeleted: talents };
      try {
        await cycleApi.editCycle(this.cycleId, body);
        this.$notification.show({ text: 'Talents list updated successfully' });
        this.selectedTalents = [];
        this.getTalents(this.metadata.limit, this.metadata.offset);
      } catch (error) {
        this.loading = false;
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Error while updating the cycle talents list. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    // Requête API : assigne un owner au talent
    async assignOwner() {
      this.assignOwnerModal.saving = true;
      const fromSelected = Array.isArray(this.assignOwnerModal.talents);
      const talents = fromSelected ? this.assignOwnerModal.talents : [this.assignOwnerModal.talents];
      const slicingTalents = talents.length > this.groupRequestsNumber;
      const processingTalents = slicingTalents ? talents.slice(0, this.groupRequestsNumber) : talents;
      try {
        await Promise.all(processingTalents.map(async (talent) => talentApi.editTalent(talent.talentId, { ownerId: this.assignOwnerModal.owner.talentId })));
        if (slicingTalents) {
          this.assignOwnerModal.talents.splice(0, this.groupRequestsNumber);
          this.assignOwnerTimeout = setTimeout(this.assignOwner, this.groupRequestsInterval);
        } else {
          this.assignOwnerModal.show = false;
          this.getTalents(this.metadata.limit, this.metadata.offset);
          this.$notification.show({ text: 'Author assigned successfully' });
          if (fromSelected) {
            this.selectedTalents = [];
          }
        }
      } catch (error) {
        this.getTalents(this.metadata.limit, this.metadata.offset);
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Error while updating the cycle talents list. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    // Requête API : récupère les talents du cycle ou hors du cycle
    async getTalents(limit, offset) {
      this.loading = true;
      try {
        const response = await talentApi.getTalents(
          limit,
          offset,
          this.user.companyId,
          null,
          this.searchName,
          this.addingTalents ? null : this.cycleId,
          this.$route.query.filterCountry,
          this.$route.query.filterFunction,
          this.$route.query.timeInRole,
          this.$route.query.timeInCompany,
          this.$route.query.seniority,
          this.$route.query.filterOwner,
          null,
          null,
          this.addingTalents ? this.cycleId : null,
        );
        this.talents = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the talents. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    const { limit, offset } = this.$route.query;
    this.getTalents(limit || this.metadata.limit, offset || this.metadata.offset);
    this.debouncedUpdateQuery = utils.debounce((query) => {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    }, 400);
  },
  beforeDestroy() {
    clearInterval(this.assignOwnerTimeout);
  },
};
</script>

<style lang="sass">
.cycle-edit-talents
  @include screen
  display: flex
  flex-direction: column
  justify-content: space-between
  .app-link
    @include back-link
  .search-bar
    display: flex
    align-items: center
    justify-content: space-between
    background: $white
    padding: 16px 24px
    margin: 8px 0
    .app-search
      width: 50%
    .buttons-container
      flex-grow: 1
      display: flex
      justify-content: flex-end
      .app-button
        margin: 0 0.5rem
      .action-container
        border-right: 2px $light-color solid
        padding: 0 1.5rem
  .table-container
    @include table-container
    .app-table
      .body .row > .cell:last-child, .headers .cell:last-child
        min-width: 250px
      .body .row
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .pending-owner
            @include pending-owner
          & > .cell:last-child
            display: flex
            justify-content: flex-end
            .app-button
              margin-left: 0.5rem
  .modal-wrapper
    .modal-content
      padding: 2rem
    .loader-assigning
      display: flex
      align-items: center
      .spinner-in-btn
        width: 20px
        height: 20px
        margin-left: 16px
        span
          border-color: $white
          border-width: 2px
  .svg-triangle
    margin: 0 0 0 10px
</style>
