<template>
  <section class="talent" @click="closeSmallMenus">
    <app-link @click="$router.push({ name: 'talents' })">
      <SvgArrowRight />
      Back to Talent list
    </app-link>

    <!-- Loader -->
    <Loader v-if="loading" />

    <!-- Affichage du talent -->
    <template v-else>
      <!-- Titre avec boutons d'actions -->
      <div class="title-bar">
        <app-title>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</app-title>
        <template v-if="editing">
          <app-button kind="minimal" size="medium" @click="editing = false">Cancel</app-button>
          <app-button kind="primary" size="medium" @click="$refs.talentProfile.submit()">Save profile</app-button>
        </template>
        <app-button v-else size="medium" @click="editing = true;">Edit profile</app-button>
      </div>

      <app-tab :tabs="tabLabels" ref="tabs"></app-tab>

      <div class="tab-container" :class="tabs[activeTab].key">
        <!-- Onglet Profile -->
        <template v-if="tabs[activeTab].key === 'profile'">
          <talent-icon :user="talent" big></talent-icon>
          <talent-profile :initialTalent="talent" canAssignOwner :editMode="editing" ref="talentProfile" @submitted="editing = false" @update:talent="talent = $event" @click-assign-owner="openOwnerModal = true" :assignedOwner="assignedOwner || talent.owner"></talent-profile>
          <app-modal :show="openOwnerModal" @update:show="openOwnerModal = false" title="Select author">
            <talent-select @update:selected-talent="tmpOwner = $event"></talent-select>
             <template v-slot:footer>
              <app-button kind="minimal" @click="openOwnerModal = false; tmpOwner = null; assignedOwner = null">Cancel</app-button>
              <app-button @click="assignedOwner = tmpOwner; tmpOwner = null; openOwnerModal = false;">Save</app-button>
            </template>
          </app-modal>
        </template>
        <!-- Onglet Cycles -->
        <template v-if="tabs[activeTab].key === 'cycles'">
          <app-table :loading="tabs[activeTab].loading">
            <template v-slot:headers>
              <div class="cell auto">Cycle name <SvgTriangle /></div>
              <div class="cell auto">Participants</div>
              <div class="cell auto">Release date</div>
              <div class="cell auto">End date</div>
              <div class="cell auto">Authors</div>
              <div class="cell auto">Status</div>
              <div class="cell auto"></div>
            </template>
            <template v-slot:body>
              <div class="row cycle grid-x align-middle" v-for="cycle in tabs[activeTab].data" :key="cycle.cycleId" @click="$router.push({ name: 'cycle', params: { cycleId: cycle.cycleId }})">
                <div class="cell auto cycle-name"><b>{{ cycle.name }}</b></div>
                <div class="cell auto">{{ cycle.numberReviews }}</div>
                <div class="cell auto">{{ utils.formatDate(cycle.startDate) }}</div>
                <div class="cell auto">{{ utils.formatDate(cycle.endDate) }}</div>
                <div class="cell auto">{{ cycle.numberWriters }}</div>
                <div class="cell auto">
                  <app-tag v-if="isBeforeToday(cycle.startDate)">
                    <SvgCycleDraft />
                    Draft
                  </app-tag>
                  <app-tag v-else-if="isBeforeToday(cycle.endDate)" state="main">
                    <SvgCycleOngoing />
                    Ongoing
                  </app-tag>
                  <app-tag v-else state="success">
                    <SvgTick />
                    Completed
                  </app-tag>
                </div>
                <div class="cell auto">
                </div>
              </div>
            </template>
          </app-table>
          <pagination v-bind="tabs[activeTab].metadata" @update:page="changePage"></pagination>
        </template>
        <!-- Onglet Talent Growth Plans -->
        <template v-if="tabs[activeTab].key === 'reviews'">
          <app-table :loading="tabs[activeTab].loading">
            <template v-slot:headers>
              <div class="cell auto talent-name-header">Name</div>
              <div class="cell auto">Role title</div>
              <div class="cell auto">Function</div>
              <div class="cell auto">Cycle</div>
              <div class="cell auto">End date <SvgTriangle /></div>
              <div class="cell auto">Status</div>
              <div class="cell auto">Bias check</div>
              <div class="cell shrink"></div>
            </template>
            <template v-slot:body>
              <!-- Tableau des reviews COMPLETED -->
              <div class="row grid-x align-middle" :class="review.status.toLowerCase()" v-for="review in tabs[activeTab].data" :key="review.reviewId" @click="goToReview(review)">
                <div class="cell auto talent-name">
                  <talent-icon :user="review.talent"></talent-icon>
                  <div class="talent-info">
                    <div>{{ review.talent.firstname | capitalize }} {{ review.talent.lastname | uppercase }}</div>
                    <div class="talent-email cell">{{ review.talent.email }}</div>
                  </div>
                </div>
                <div class="cell auto">{{ review.talentVersion.role }}</div>
                <div class="cell auto">{{ review.talentVersion.function }}</div>
                <div class="cell auto">{{ review.cycle ? review.cycle.name : 'N/A' }}</div>
                <div class="cell auto">{{ review.cycle ?  utils.formatDate(review.cycle.endDate) : 'N/A' }}</div>
                <div class="cell auto">
                  <app-tag :state="utils.getReviewEditTagState(review.status)">
                    <SvgTick />
                    {{ ReviewStatusLabel[review.status] }}
                  </app-tag>
                </div>
                <div class="cell auto">
                  <app-tag :state="utils.getBiasCheckTagState(review.status)">
                    <BiasCheckComplete/>
                    {{ BiasCheckLabel[review.status] }}
                  </app-tag>
                </div>
                <div class="cell shrink">
                  <app-tooltip class="suppr-menu" :isMenu="true" position="bottom-right" v-if="!review.cycle">
                    <app-button @click.stop="$refs['review-delete'].show(review.reviewId)" kind="minimal" size="tiny">
                        <SvgDelete class="leading"/>
                        Delete
                    </app-button>
                    <template v-slot:app-tooltip-wrapper>
                      <app-button kind="minimal" size="tiny" class="overflow-button no-focus" @click.stop="openSmallMenu">
                        <svg-overflow />
                      </app-button>
                    </template>
                  </app-tooltip>
                </div>
              </div>
            </template>
          </app-table>
          <pagination v-bind="tabs[activeTab].metadata" @update:page="changePage"/>
          <review-delete ref="review-delete" @delete="getReviews"></review-delete>
        </template>
      </div>
    </template>
     <div v-if="editing && tabs[activeTab].key === 'profile'" class="profile-edit-footer">
      <app-button kind="minimal" @click="editing = false">Cancel</app-button>
      <app-button kind="primary" @click="$refs.talentProfile.submit()">Save profile</app-button>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import utils from '@/services/utils';
import talentApi from '@/services/api/talent';
import cycleApi from '@/services/api/cycle';
import reviewApi from '@/services/api/review';
import SvgArrowRight from '@/assets/img/icons/16px/arrow-right.svg?inline';
import SvgCycleDraft from '@/assets/img/icons/24px/cycle-draft.svg?inline';
import SvgCycleOngoing from '@/assets/img/icons/24px/cycle-ongoing.svg?inline';
import SvgTick from '@/assets/img/icons/24px/tick.svg?inline';
import BiasCheckComplete from '@/assets/img/icons/24px/bias-check-complete.svg?inline';
import SvgOverflow from '@/assets/img/icons/24px/overflow.svg?inline';
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

// Enums
import ReviewStatus from '@/services/enums/review-status.enum';
import ReviewStatusLabel from '@/services/enums/review-status-label.enum';
import BiasCheckLabel from '@/services/enums/bias-check-label.enum';

import Pagination from '@/components/Pagination.vue';
import TalentIcon from '@/components/TalentIcon.vue';
import TalentProfile from './TalentProfile.vue';
import TalentSelect from './TalentSelect.vue';
import ReviewDelete from '../reviews/ReviewDelete.vue';

import Loader from '../../components/Loader.vue';

export default {
  name: 'talent',
  components: {
    SvgArrowRight,
    TalentProfile,
    TalentIcon,
    TalentSelect,
    Pagination,
    ReviewDelete,
    SvgCycleDraft,
    SvgCycleOngoing,
    SvgTick,
    BiasCheckComplete,
    SvgOverflow,
    SvgDelete,
    SvgTriangle,
    Loader,
  },
  data() {
    return {
      talentId: this.$route.params.talentId,
      talent: {},
      loading: true,
      editing: false,
      activeTab: this.$route.query.activeTab || 0,
      tmpOwner: null,
      assignedOwner: null,
      tabs: [{
        key: 'profile',
        label: 'Profile',
      }, {
        key: 'cycles',
        label: 'Cycles',
        loading: false,
        data: [],
        metadata: {
          limit: 10,
          offset: 0,
          count: null,
        },
      }, {
        key: 'reviews',
        label: 'Talent Growth Plans',
        loading: false,
        data: [],
        metadata: {
          limit: 10,
          offset: 0,
          count: null,
        },
      }],
      oneSmallMenuIsOpened: false,
      openOwnerModal: false,
      utils,
      ReviewStatusLabel,
      BiasCheckLabel,
    };
  },
  computed: {
    tabLabels() {
      return this.tabs.map((tab) => tab.label);
    },
  },
  watch: {
    editing() {
      const { editing } = this.$route.query;
      if (this.editing && this.activeTab !== 0) {
        this.$router.replace({ query: { ...this.$route.query, activeTab: '0' } });
      }
      if (!this.editing) {
        this.assignedOwner = null;
        this.tmpOwner = null;
      }
      if (!this.editing && editing !== 'false') {
        this.$router.replace({ query: { ...this.$route.query, editing: 'false' } });
      } else if (this.editing && editing !== 'true') {
        this.$router.replace({ query: { ...this.$route.query, editing: 'true' } });
      }
    },
    // Surveille le changement d'onglet ou de pagination
    $route() {
      const {
        activeTab, limit, offset, editing,
      } = this.$route.query;
      this.editing = editing === 'true';
      // Si l'onglet change
      if (+activeTab !== this.activeTab || this.activeTab === 0) {
        this.activeTab = !activeTab ? 0 : +activeTab;
        this.editing = this.editing && this.activeTab === 0;
        // To avoid NavigationDuplicated error
        if (this.tabs[this.activeTab].metadata && (+limit !== this.tabs[activeTab].metadata.limit || +offset !== this.tabs[activeTab].metadata.offset)) {
          this.$router.push({
            query: {
              ...this.$route.query,
              limit: this.tabs[activeTab].metadata.limit,
              offset: this.tabs[activeTab].metadata.offset,
            },
          });
        }
      } else if (+limit !== this.tabs[this.activeTab].metadata.limit || +offset !== this.tabs[this.activeTab].metadata.offset) {
        switch (this.activeTab) {
          case 1:
            this.getCycles(limit, offset);
            break;
          case 2:
            this.getReviews(limit, offset);
            break;
          default:
            break;
        }
      }
    },
  },
  methods: {
    isBeforeToday(date) {
      return dayjs(date).isBefore(dayjs());
    },
    isAfterToday(date) {
      return dayjs(date).isAfter(dayjs());
    },
    goToReview(review) {
      if (review.status === ReviewStatus.COMPLETED) {
        this.$router.push({ name: 'review', params: { reviewId: review.reviewId } });
      }
    },
    // Changement de pagination (tableaux)
    changePage({ limit, offset }) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    async getTalent() {
      this.loading = true;
      try {
        const response = await talentApi.getTalent(this.talentId);
        this.talent = response.data;
      } catch (error) {
        if (error.response.status === 404) {
          this.$router.push({ name: 'not-found' });
        }
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `The talent could not be fetched. ${messages}`,
        });
      }
      this.loading = false;
    },
    async getCycles(limit, offset) {
      this.tabs[1].loading = true;
      try {
        const response = await cycleApi.getCycles(limit || this.tabs[1].metadata.limit, offset || this.tabs[1].metadata.offset, null, this.talentId);
        this.tabs[1].data = response.data;
        this.tabs[1].metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Cycles could not be fetched. ${messages}`,
        });
      }
      this.tabs[1].loading = false;
    },
    async getReviews(limit, offset) {
      this.tabs[2].loading = true;
      try {
        const response = await reviewApi.getReviews(limit || this.tabs[2].metadata.limit, offset || this.tabs[2].metadata.offset, null, this.talentId);
        this.tabs[2].data = response.data;
        this.tabs[2].metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Talent Growth Plans could not be fetched. ${messages}`,
        });
      }
      this.tabs[2].loading = false;
    },
    openSmallMenu(e) {
      if (!e.currentTarget.parentElement.previousSibling.classList.contains('opened')) {
        if (this.oneSmallMenuIsOpened) {
          this.closeSmallMenus();
        }
        e.currentTarget.parentElement.previousSibling.classList.add('opened');
        this.oneSmallMenuIsOpened = true;
      } else {
        this.closeSmallMenus();
      }
    },
    closeSmallMenus() {
      if (this.oneSmallMenuIsOpened) {
        const openedMenus = document.querySelectorAll('.suppr-menu .opened');
        openedMenus.forEach((openedMenu) => {
          openedMenu.classList.remove('opened');
        });
        this.oneSmallMenuIsOpened = false;
      }
    },
  },
  mounted() {
    this.getTalent();
    const {
      limit, offset, activeTab, editing,
    } = this.$route.query;
    switch (+activeTab) {
      case 1:
        this.getCycles(limit, offset);
        this.getReviews();
        break;
      case 2:
        this.getCycles();
        this.getReviews(limit, offset);
        break;
      default:
        this.getCycles();
        this.getReviews();
    }
    this.editing = editing === 'true';
  },
};
</script>

<style lang="sass">
section.talent
  @include screen

  > .loader
    margin: 5rem auto

  .app-link
    @include back-link

  .title-bar
    display: flex
    align-items: center
    .app-title
      flex-grow: 1
    .app-button
      margin-left: 16px

  .profile-edit-footer
    display: flex
    justify-content: center
    padding: 1.5rem
    margin-top: 2px
    background-color: $white
    .app-button
      margin: 0 2rem

  .tab-container
    background: $white
    border-radius: $global-border-radius
    padding: 2rem
    &.profile
      display: flex
      padding-right: 12%
      .talent-icon
        margin-right: 12%
      .talent-profile
        flex-grow: 1
      .modal-content
        padding: 2rem
    &.cycles
      @include table-container
      .app-table
        .body .row
          @include clickable-row
    &.reviews
      @include table-container
      .app-table
        .body .row .cell:last-child, .headers .cell:last-child
          width: 2rem
        .body .row
          &.completed
            @include clickable-row
          b
            color: $black
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .suppr-menu
            .app-tooltip-container
              display: none
              background: $white
              position: absolute
              z-index: 5
              right: -7px !important
              box-shadow: 0px 2px 8px 0px rgba(103, 102, 98, 0.2)
              border-radius: 4px
              .app-button
                width: 100%
                border-radius: 0
              &.opened
                display: block
            .overflow-button
              @include icon-button
              width: 2.5rem
              height: 2.5rem
  .svg-triangle
    margin: 0 0 0 10px
</style>
