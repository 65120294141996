<template>
  <section class="reset-password">
    <div class="grid-x align-center align-middle">
      <form class="cell small-11 medium-4" ref="login" @submit.prevent="reset">
        <div>
          <app-label>Password</app-label>
          <app-input autocomplete="password" type="password" required v-model="user.password" :class="{ error: hasError }" />
        </div>
        <div>
          <app-label>Confirm</app-label>
          <app-input autocomplete="password" type="password" required v-model="user.confirm" :class="{ error: hasError }" />
        </div>

        <div v-if="hasError">
          <ul>
            <li class="error" v-for="(message, index) in errorMessages" :key="`reset-password-error-${index}`">{{ message }}</li>
          </ul>
        </div>

        <div>
          <app-button type="submit" id="login-btn" :disabled="isLoading">Send<arrowRight/></app-button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import arrowRight from '../assets/img/icons/24px/arrow-right.svg?inline';
import apiAuth from '../services/auth';

export default {
  name: 'reset-password',
  components: {
    arrowRight,
  },
  props: {
    isSuperAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: {
        password: '',
        confirm: '',
      },
      hasError: false,
      errorMessages: [],
      isLoading: false,
    };
  },
  mounted() {
    if (!this.$route.query.token) {
      this.redirectToLogin();
    }
  },
  methods: {
    async reset() {
      this.isLoading = true;
      this.hasError = false;
      this.errorMessages = [];
      try {
        if (this.isSuperAdmin) {
          await apiAuth.setNewPasswordSuperAdmin(this.user.password, this.user.confirm, this.$route.query.token);
        } else {
          await apiAuth.setNewPasswordTalent(this.user.password, this.user.confirm, this.$route.query.token);
        }
      } catch (error) {
        this.hasError = true;
        this.errorMessages = ['An issue occured, please try again'];
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errorMessages.push((error.response.data.message));
          else this.errorMessages = [...this.errorMessages, ...error.response.data.message];
        }
      }

      if (!this.hasError) {
        this.$message.show({
          title: 'Success',
          text: 'Your password was reset successfully ! You will be automatically redirected to the login page.',
          confirmText: 'Ok',
          hasCancel: false,
          onConfirm: () => this.redirectToLogin(),
        });
      }
      this.isLoading = false;
    },
    redirectToLogin() {
      if (this.isSuperAdmin) {
        this.$router.push({ name: 'super-admin-login' });
      } else {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style lang="sass">
.wrapper
  min-height: 100vh
.reset-password
  min-height: calc(100vh - #{$header-height} - var(--wrapper-padding-bottom))
  > div
    min-height: inherit
  p
    margin: 0
    color: $common-gray
    font-size: 20px
    margin-bottom: 3rem
  form
    .app-button
      margin-top: 48px
  .error
    color: $error-color
    font-weight: 700
    font-size: $global-font-size
    &:first-letter
      text-transform: capitalize
</style>
