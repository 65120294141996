<template>
  <section class="talents-import">
    <custom-header :to="{ name: 'talents' }" title="Add new talent (CSV upload)"></custom-header>
    <div>
      <app-title>Add Talent</app-title>

      <!-- Table des talents à importer -->
      <talents-parsing
        :file="file"
        ref="talentsParsing"
        @update:count="count = $event"
        @update:file="file = $event"
        @uploaded="$router.go(-1)"
      ></talents-parsing>
    </div>

    <!-- Footer d'actions -->
    <app-sticky-footer type="actions">
      <app-button kind="secondary" @click="exitImport">Cancel</app-button>
      <app-button @click="$refs.talentsParsing.uploadCSV()">Add {{ count }} talents</app-button>
    </app-sticky-footer>
  </section>
</template>

<script>
import CustomHeader from '@/components/CustomHeader.vue';
import TalentsParsing from './TalentsParsing.vue';

export default {
  name: 'talents-import',
  components: {
    TalentsParsing,
    CustomHeader,
  },
  props: {
    inputFile: File,
  },
  data() {
    return {
      count: 0,
      file: this.inputFile,
    };
  },
  methods: {
    // Message de confirmation avant d'annuler/partir
    exitImport() {
      this.$message.show({
        title: 'If you exit now you will lose any changes you might have made. Are you sure?',
        hasCancel: true,
        cancelText: 'Cancel',
        confirmText: 'Yes, cancel',
        onConfirm: () => this.$router.go(-1),
      });
    },
  },
  mounted() {
    if (!this.file) {
      // permet de revenir aux talents si pas de fichier (par ex. au refresh)
      this.$router.go(-1);
    }
  },
};
</script>

<style lang="sass">
.talents-import
  @include screen
  display: flex
  flex-direction: column
  justify-content: space-between
  .table-container
    @include table-container
    .table-title
      font-size: 20px
      margin-bottom: 2rem
      display: flex
      justify-content: space-between
    .app-table
      .body .row
        .talent-name
          display: flex
          .talent-info
            margin-left: 0.5rem
            div:first-child
              color: $black
              font-weight: 700
            div:last-child
              font-size: 13px
        .row-actions
          display: flex
          justify-content: flex-end
          .app-button
            margin-left: 1rem
</style>
