<template>
  <form class="company-info" :class="{ disabled: !editMode, loading }" ref="companyForm">
    <Loader v-if="loading" />
    <template v-else>
      <div class="form-section">
        <p class="title">Company Details</p>
        <div class="form-input">
          <label><b>Name</b></label>
          <app-input v-if="editMode" v-model="company.name" placeholder="Company name" type="text" required></app-input>
          <p class="value" v-else>{{ initialCompany.name }}</p>
        </div>
        <div class="form-input">
          <label><b>Category</b></label>
          <app-input v-if="editMode" v-model="company.category" placeholder="Category" type="text" required></app-input>
          <p class="value" v-else>{{ initialCompany.category }}</p>
        </div>
        <div class="form-input comment">
          <label><b>Comment</b></label>
          <app-input v-if="editMode" v-model="company.comment" placeholder="Comment" type="text" :multiline=true :max=1024></app-input>
          <p class="value" v-else>{{ initialCompany.comment }}</p>
        </div>
      </div>
      <div class="form-section">
        <p class="title">Subscription</p>
        <div class="form-input">
          <label><b>Subscription type</b></label>
          <app-select v-if="editMode" v-model="company.subscription" :options="subscriptionOptions" required></app-select>
          <p class="value" v-else>{{ SubscriptionLabel[initialCompany.subscription] }}</p>
        </div>
        <div class="form-input">
          <label><b>Admins</b></label>
          <app-input v-if="editMode" v-model="company.maxAdmins" type="number" required></app-input>
          <p class="value" v-else>{{ initialCompany.maxAdmins }}</p>
        </div>
        <div class="form-input">
          <label><b>Team size</b></label>
          <app-input v-if="editMode" v-model="company.maxTalents" type="number" required></app-input>
          <p class="value" v-else>{{ initialCompany.maxTalents }}</p>
        </div>
        <div class="form-input">
          <label>
            <b>Setup date</b><br>
            {{ editMode ? 'Optional' : '' }}
          </label>
          <app-datepicker v-if="editMode" v-model="company.setupDate" placeholder="DD/MM/YY"></app-datepicker>
          <p class="value" v-else>{{ utils.formatDate(initialCompany.setupDate) }}</p>
        </div>
      </div>
      <div class="form-section">
        <p class="title">Contact</p>
        <div class="form-input contact-name">
          <label><b>Contact name</b></label>
          <app-input v-if="editMode" v-model="company.contact.firstname" placeholder="Firstname" required></app-input>
          <app-input v-if="editMode" v-model="company.contact.lastname" placeholder="Surname" required></app-input>
          <p class="value" v-else>{{ initialCompany.contact.firstname }} {{ initialCompany.contact.lastname }}</p>
        </div>
        <div class="form-input">
          <label><b>Fonction</b></label>
          <app-input v-if="editMode" v-model="company.contact.function" placeholder="Fonction" required></app-input>
          <p class="value" v-else>{{ initialCompany.contact.function }}</p>
        </div>
        <div class="form-input">
          <label><b>Email</b></label>
          <app-input v-if="editMode" v-model="company.contact.email" placeholder="Email" required type="email"></app-input>
          <p class="value" v-else>{{ company.contact.email }}</p>
        </div>
        <div class="form-input">
          <label><b>Phone</b></label>
          <app-input v-if="editMode" v-model="company.contact.phone" placeholder="+44 00000000" required></app-input>
          <p class="value" v-else>{{ company.contact.phone }}</p>
        </div>
        <div class="form-input">
          <label><b>Invoicing address</b></label>
          <app-input v-if="editMode" v-model="company.address.address" placeholder="4 privet drive" required></app-input>
          <p class="value" v-else>{{ company.address.address }}</p>
        </div>
        <div class="form-input">
          <label><b>Post code</b></label>
          <app-input v-if="editMode" v-model="company.address.zip" placeholder="44000" required></app-input>
          <p class="value" v-else>{{ company.address.zip  }}</p>
        </div>
        <div class="form-input">
          <label><b>City</b></label>
          <app-input v-if="editMode" v-model="company.address.city" placeholder="London" required></app-input>
          <p class="value" v-else>{{ company.address.city }}</p>
        </div>
        <div class="form-input">
          <label><b>Country</b></label>
          <app-input v-if="editMode" v-model="company.address.country" placeholder="United Kingdom" required></app-input>
          <p class="value" v-else>{{ company.address.country }}</p>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import companyApi from '@/services/api/company';
import SubscriptionLabel from '@/services/enums/subscription-label.enum';
import utils from '@/services/utils';
import dayjs from 'dayjs';
import Loader from '../../components/Loader.vue';

export default {
  name: 'company-info',
  emits: [
    'submitted', // Form soumis avec succès
    'update:company', // company modifiée avec succès. Contient la company màj
  ],
  components: {
    Loader,
  },
  props: {
    // Si ce formulaire est pour une nouvelle company
    isNew: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    // Informations initiales de la company
    initialCompany: {
      type: Object,
    },
  },
  data() {
    return {
      company: {
        name: '',
        category: '',
        email: '',
        phone: '',
        subscription: '',
        maxAdmins: 0,
        maxTalents: 0,
        setupDate: new Date(),
        contact: {
          name: '',
          function: '',
          email: '',
          phone: '',
        },
        address: {
          address: '',
          city: '',
          zip: '',
          country: '',
        },
      },
      loading: false,
      SubscriptionLabel,
      subscriptionOptions: utils.enumToOptions(SubscriptionLabel),
      utils,
    };
  },
  watch: {
    initialCompany() {
      this.company = { ...this.company };
    },
  },
  methods: {
    async submit() {
      if (this.$refs.companyForm.reportValidity()) {
        let error = false;
        this.loading = true;
        if (this.isNew) {
          error = await this.createCompany();
        } else {
          error = await this.editCompany();
        }
        this.loading = false;
        if (!error) this.$emit('submitted');
      }
    },
    // Requête API : Création d'une entreprise
    async createCompany() {
      try {
        this.company.address.zip = String(this.company.address.zip);
        await companyApi.createCompany(this.company);
        this.$notification.show({ text: `Company ${this.company.name} was successfully added !` });
        return false;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          text: `The company could not be added. ${messages}`,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
        return true;
      }
    },
    // Requête API : Modification d'une entreprise
    async editCompany() {
      try {
        this.company.address.zip = String(this.company.address.zip);
        const response = await companyApi.editCompany(this.company.companyId, this.company);
        this.$notification.show({ text: `Company ${this.company.name} was successfully updated !` });
        this.$emit('update:company', response.data);
        return false;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          text: `The company could not be updated. ${messages}`,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
        return true;
      }
    },
  },
  mounted() {
    if (this.initialCompany) {
      this.company = { ...this.initialCompany, setupDate: dayjs(this.company.setupDate).toDate() };
    }
  },
};
</script>

<style lang="sass">
.company-info
  .loader
    margin: 5rem auto
  .form-section
    padding: 1rem 0
    border-bottom: 2px solid $light-color
    .title
      color: $main-color
      text-transform: uppercase
      font-size: 12px
      font-weight: 900
    .form-input
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin: 8px 0
      &.comment
        height: initial
      label
        font-size: 13px
        color: $common-gray
        width: 30%
        position: relative
        top: 1rem
        b
          font-size: 16px
          color: $black
      &.contact-name
        .app-input
          width: 34%
          &:last-child
            margin-left: 2%
      .app-input, .app-datepicker
        width: 70%
        max-width: none
      .app-select
        width: 70%
        .container
          max-width: none
  .form-section:last-child
    border-bottom: none

  &.disabled
    .form-input
      align-items: center
      height: 49px
      label
        position: initial
      .value
        width: 70%
        text-align: left
        color: $common-gray
</style>
