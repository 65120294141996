<template>
  <nav class="navigation">
    <div class="main-navigation" v-if="connectedUser.role === UserRole.ADMIN || connectedUser.role === UserRole.SUPER_ADMIN">
      <router-link :to="{ name: userHome }">
        <img src="../assets/img/home.svg">
        <span>Home</span>
      </router-link>
      <!-- ADMIN -->
      <template v-if="connectedUser.role === UserRole.ADMIN">
        <router-link :to="{ name: 'cycles' }">
          <img src="../assets/img/talent-growth-builder.svg">
          <span>Talent Growth Builder</span>
        </router-link>
        <router-link :to="{ name: 'talents' }">
          <img src="../assets/img/talent.svg">
          <span>Talent</span>
        </router-link>
      </template>
      <!-- SUPERADMIN -->
      <template v-if="connectedUser.role === UserRole.SUPER_ADMIN">
        <router-link :to="{ name: 'templates' }">
          <span>Template</span>
        </router-link>
        <router-link :to="{ name: 'copy' }">
          <span>Copy</span>
        </router-link>
        <router-link :to="{ name: 'thresholds' }">
          <span>Thresholds</span>
        </router-link>
        <router-link :to="{ name: 'typical-biases' }">
          <span>Typical Bias</span>
        </router-link>
      </template>
    </div>
    <div v-else></div>
    <div class="secondary-navigation">
      <router-link :to="{ name: 'reviews' }" class="no-style" v-if="connectedUser.role === UserRole.ADMIN">
        <app-button class="talent-growth-btn" :class="['medium', 'negative']">My Talent Growth Plan</app-button>
      </router-link>
      <a :href="FAQLink" target="_blank_" rel="noopener noreferrer" class="no-style FAQ">
        <img src="../assets/img/help.svg">
      </a>
      <talent-icon :user="connectedUser" class="account-btn" :class="{ 'focus': this.$route.name === 'account' || this.$route.name === 'account-edit' }"></talent-icon>
    </div>
    <div class="dropdown-account-menu" :class="{ opened: accountMenuOpened }">
      <router-link :to="{ name: 'account', params: { userId: connectedUser.talentId  || connectedUser.superAdminId } }" class="go-to-account-btn dropdown-item no-style">My account</router-link>
      <button class="logout-btn dropdown-item" @click="logout()">Sign out</button>
    </div>
  </nav>
</template>

<script>
import auth from '@/services/auth';
import TalentIcon from '@/components/TalentIcon.vue';
import UserRole from '../services/enums/user-role.enums';

export default {
  components: { TalentIcon },
  name: 'navigation',
  computed: {
    connectedUser() {
      const user = auth.getUser();

      if (user.role === this.UserRole.SUPER_ADMIN) {
        return {
          superAdminId: user.superAdminId,
          role: user.role,
        };
      }
      return {
        talentId: user.talentId,
        firstname: user.firstname,
        lastname: user.lastname,
        role: user.role,
        ...(user.impersonate && { impersonate: user.impersonate }),
      };
    },
    userHome() {
      if (this.connectedUser.role === this.UserRole.ADMIN) {
        return 'home';
      }
      if (this.connectedUser.role === this.UserRole.SUPER_ADMIN) {
        return 'companies';
      }
      return 'home';
    },
    FAQLink() {
      switch (this.connectedUser.role) {
        case UserRole.TALENT: return process.env.VUE_APP_FAQ_OWNER;
        case UserRole.ADMIN: return process.env.VUE_APP_FAQ_ADMIN;
        default: return process.env.VUE_APP_FAQ_ADMIN;
      }
    },
  },
  data() {
    return {
      accountMenuOpened: false,
      UserRole,
    };
  },
  mounted() {
    document.querySelector('.account-btn').addEventListener('click', this.openMenu);
    document.querySelector('.go-to-account-btn').addEventListener('click', this.openMenu);
  },
  methods: {
    openMenu() {
      this.accountMenuOpened = !this.accountMenuOpened;
    },
    logout() {
      if (this.connectedUser.role === this.UserRole.SUPER_ADMIN || this.connectedUser.impersonate) {
        this.$router.push({ name: 'super-admin-login' });
      } else {
        this.$router.push({ name: 'login' });
      }
      auth.logout();
      this.accountMenuOpened = false;
    },
  },
};
</script>

<style lang="sass">
nav
  height: 100%
  flex-grow: 1
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  .main-navigation
    display: flex
    height: 100%
    a
      font-weight: bold
      border: 0 !important
      display: flex
      flex-direction: column
      align-items: center
      height: 100%
      padding: 1rem 2.2em
      img
        max-width: 16px
        max-height: 16px
      span
        margin-top: 5px
        color: white
      &:hover
        background: rgba(255, 255, 255, 0.1)
      &.router-link-exact-active
        background: $main-color
      &:focus
        box-shadow: inset 0 0 0 8px rgba(92, 51, 195, 0.4) !important
  .secondary-navigation
    display: flex
    align-items: center
    padding-right: 28px
    .FAQ
      background: transparent
      border: none
      padding: 0
      cursor: pointer
      outline: none
      margin: 0 32px
  .dropdown-account-menu
    position: absolute
    top: 100%
    right: 0
    background: $header-bg-color
    border-radius: 0 0 0 4px
    display: none
    .dropdown-item
      display: block
      color: white
      padding: 1em 2.2em
      cursor: pointer
      border: none
      background: transparent
      outline: none
      width: 100%
      &.logout-btn
        border-radius: 0 0 0 4px
      &:hover
        background: $main-color
    &.opened
      display: block
</style>
