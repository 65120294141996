<template>
  <div class="app-search" :class="{ disabled }">
    <div class="svg-search" @click="$emit('search')">
      <svg-search class="svg-search"/>
    </div>
    <input
      type="text"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      @keydown.enter="$emit('search')"
    />
    <app-button kind="minimal" size="tiny" v-if="value.length && !disabled" @click="$emit('input', '')">
      <svg-cross class="svg-cross" />
    </app-button>
    <slot></slot>
  </div>
</template>

<script>
import SvgSearch from '../../assets/img/icons/24px/search.svg?inline';
import SvgCross from '../../assets/img/icons/16px/cross.svg?inline';

export default {
  components: {
    'svg-search': SvgSearch,
    'svg-cross': SvgCross,
  },
  props: {
    value: [String],
    placeholder: {
      type: String,
      default: 'Search...',
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="sass">
.app-search
  display: flex
  position: relative
  padding: 8px 24px 8px 16px
  align-items: center
  background: $white
  border: 2px solid $light-color
  border-radius: $global-border-radius
  transition: all ease-in-out 0.2s
  .svg-search
    margin-right: 0.5rem
    path
      fill: $common-gray
  input
    width: 100%
    border: none
    color: $common-gray
    outline: none
    &::placeholder
      color: $medium-gray
  .app-button
    @include icon-button
    width: 2rem
    height: 2rem
    position: absolute
    top: 10%
    right: 0.5rem

  &:hover
    border-color: $medium-gray
  &:focus-within
    border-color: $main-color
    .svg-search path
      fill: $main-color
  &.disabled
    background: $light-color
    border-color: $light-color
    .svg-search path
      fill: $medium-gray

</style>
