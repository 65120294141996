<template>
  <app-modal class="talents-import-modal" :show="show" v-on="$listeners">
    <!-- Elements cachés -->
    <div class="hidden">
      <input type="file" accept=".csv" ref="fileInput" @change="goToImport"/>
    </div>
    <app-title>Ensure your CSV file is compatible</app-title>
    <p>Please download and check our CSV template before uploading your talents list to Talentuum:</p>
    <app-button kind="secondary" @click="downloadTemplate"><SvgDownload class="leading" />Download CSV Template</app-button>
    <template v-slot:footer>
      <app-button kind="minimal" @click="$emit('update:show', false)">Cancel</app-button>
      <app-button @click="selectFile">Upload Talent list</app-button>
    </template>
  </app-modal>
</template>

<script>
import SvgDownload from '@/assets/img/icons/24px/download.svg?inline';
import talentApi from '@/services/api/talent';

export default {
  name: 'talents-import-modal',
  components: {
    SvgDownload,
  },
  emits: [
    'update:show', // montrer la modale ou pas
    'update:file', // sélection du fichier
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async downloadTemplate() {
      try {
        await talentApi.downloadTemplate();
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `The template could not be downloaded. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    goToImport() {
      const inputFile = this.$refs.fileInput.files[0];
      this.$emit('update:file', inputFile);
      this.$emit('update:show', false);
    },
  },
};
</script>

<style lang="sass">
.talents-import-modal
  .modal-wrapper
    width: 500px
    .app-title
      font-size: 20px
    p
      color: $common-gray
      margin-bottom: 2rem
    .app-button
      width: 100%
      justify-content: center
    .modal-content, .modal-footer
      padding: 2rem
      .hidden
        width: 0
        height: 0
        display: none
</style>
