<template>
  <div class="app-sticky-footer" :class="type">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'app-sticky-footer',
  props: {
    type: {
      type: String,
      validator: (val) => ['error', 'actions', 'default'].indexOf(val) !== -1,
      default: 'default',
    },
  },
  data() {
    return {};
  },
  // Mounted() et Destroyed() permettent d'ajouter/enlever le padding du wrapper pour cacher le vrai footer
  mounted() {
    document.querySelector('.wrapper').classList.add('hasStickyFooter'); // Permet que le footer aille en bas de la page.
  },
  destroyed() {
    // Ne pas remettre le padding si d'autres app-sticky-footer sont présent
    const otherEl = document.querySelector('.app-sticky-footer');
    if (!otherEl) {
      document.querySelector('.wrapper').classList.remove('hasStickyFooter');
    }
  },
};
</script>

<style lang="sass">
.app-sticky-footer
  @include sticky-bar
  bottom: 0
  display: flex
  align-items: center
  justify-content: space-between
  padding-top: 2rem
  padding-bottom: 2rem
  background: $white
  z-index: 100
  color: $common-gray
  font-size: 16px
  box-shadow: 0px 4px 12px rgba(103, 102, 98, 0.3)
  div
    display: flex
    .app-button
      margin-left: 1rem

  &.actions
    justify-content: center
    .app-button
      margin: 0 1rem
  &.error
    padding: 0.5rem 0
    bottom: 8rem
    justify-content: center
    align-items: center
    background: $error-bg-color
    color: $error-secondary-color
    box-shadow: none
    svg
      margin-right: 0.7rem
      path
        fill: currentcolor
</style>
