<template>
  <section class="cycle-settings">
    <app-link @click="$router.push({ name: 'cycle', params: { cycleId }})">
      <SvgArrowRight />
      Back to Cycle
    </app-link>
    <Loader v-if="loading" />
    <template v-else-if="cycle">
      <app-title>{{ cycle.name }} settings</app-title>
      <p class="description">
        Hi {{ user.firstname }}, you can see and edit the setting of your Cycle. For instance, you can give your Cycle a new name, include or remove talents in your Cycle, and change a Cycle start and end date (providing you do that before the dates you want to change).
      </p>

      <!-- Subsection : name -->
      <div class="subsection name">
        <div class="subsection-title">
          <p><b>Name</b></p>
          <app-button v-if="!fields.name.editing" kind="subtle" size="medium" @click="fields.name.editing = true"><SvgEdit class="leading" />Edit</app-button>
          <template v-else>
            <app-button kind="minimal" size="medium" @click="fields.name.editing = false" :disabled="fields.name.loading">Cancel</app-button>
            <app-button size="medium" :disabled="fields.name.loading" @click="editCycle(fields.name.value)">Save</app-button>
          </template>
        </div>
        <div class="subsection-field name">
          <label>
            <b>Cycle name</b>
            <div class="field-value">
              <app-input v-if="fields.name.editing" v-model="fields.name.value"></app-input>
              <p v-else>{{ cycle.name }}</p>
            </div>
          </label>
        </div>
      </div>

      <!-- Subsection: Form structure -->
      <div class="subsection form">
        <div class="subsection-title">
          <p><b>Form structure</b></p>
        </div>
        <div class="subsection-body">
          <div v-for="section in sections" :key="section.key" class="form-section">
            <p><b>{{ section.label }}</b></p>
            <div v-for="question in section.optionalQuestions" :key="question.questionId" class="form-section-question">
              <label>
                <app-checkbox disabled :input="true"></app-checkbox>
                {{ question.title }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Subsection: Talent list -->
      <div class="subsection talents">
        <div class="subsection-title">
          <p><b>Talent list</b></p>
          <app-button kind="subtle" size="medium" v-if="talentsEditable" @click="$router.push({ name: 'cycle-edit-talents', params: { cycleId } })"><SvgEdit class="leading" />Edit</app-button>
        </div>
        <div class="table-container">
          <app-table :loading="fields.talents.loading">
            <template v-slot:headers>
              <div class="cell auto">Name <SvgTriangle /></div>
              <div class="cell auto">Role title</div>
              <div class="cell auto">Function</div>
              <div class="cell auto">Owned by</div>
              <div class="cell shrink"></div>
            </template>
            <template v-slot:body>
              <div class="row grid-x align-middle" v-for="talent in fields.talents.data" :key="talent.talentId">
                <div class="cell auto talent-name">
                  <talent-icon :user="talent"></talent-icon>
                  <div class="talent-info">
                    <div>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</div>
                    <div class="talent-email cell">{{ talent.email }}</div>
                  </div>
                </div>
                <div class="cell auto">{{ talent.talentVersion.role }}</div>
                <div class="cell auto">{{ talent.talentVersion.function }}</div>
                <div class="cell auto">
                  <talent-icon v-if="talent.owner" :user="talent.owner" isLong></talent-icon>
                  <div v-else class="pending-owner"></div>
                </div>
                <div class="cell shrink"></div>
              </div>
            </template>
          </app-table>
          <pagination v-bind="fields.talents.metadata" @update:page="changePage"></pagination>
        </div>
      </div>

      <!-- Subsection: Cycle timeline -->
      <div class="subsection timeline">
        <div class="subsection-title">
          <p><b>Cycle timeline</b></p>
          <template v-if="startDateEditable || endDateEditable">
            <app-button v-if="!fields.timeline.editing" kind="subtle" size="medium" @click="fields.timeline.editing = true"><SvgEdit class="leading" />Edit</app-button>
            <template v-else>
              <app-button kind="minimal" size="medium" @click="fields.timeline.editing = false" :disabled="fields.timeline.loading">Cancel</app-button>
              <app-button size="medium" :disabled="fields.timeline.loading" @click="editCycle(null, { startDate: fields.timeline.startDate, endDate: fields.timeline.endDate })">Save</app-button>
            </template>
          </template>
        </div>
        <div class="subsection-body">
          <div class="subsection-field">
            <label @click.prevent>
              <b>Cycle release date</b>
              <div class="field-value">
                <app-datepicker v-if="fields.timeline.editing && startDateEditable" v-model="fields.timeline.startDate" :disabledDates="{to: yesterday, from: fields.timeline.startDate}"></app-datepicker>
                <p v-else>{{ utils.formatDate(cycle.startDate) }}</p>
              </div>
            </label>
            <label @click.prevent>
              <b>Cycle release date</b>
              <div class="field-value">
                <app-datepicker v-if="fields.timeline.editing && endDateEditable" v-model="fields.timeline.endDate" :disabledDates="{to: fields.timeline.startDate}"></app-datepicker>
                <p v-else>{{ utils.formatDate(cycle.endDate) }}</p>
              </div>
            </label>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import cycleApi from '@/services/api/cycle';
import talentApi from '@/services/api/talent';
import auth from '@/services/auth';
import utils from '@/services/utils';

import SvgArrowRight from '@/assets/img/icons/16px/arrow-right.svg?inline';
import SvgEdit from '@/assets/img/icons/24px/edit.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

import FormSectionLabel from '@/services/enums/form-section-label.enum';
import CycleStatus from '@/services/enums/cycle-status.enum';

import TalentIcon from '@/components/TalentIcon.vue';
import Pagination from '@/components/Pagination.vue';

import Loader from '../../components/Loader.vue';

export default {
  name: 'cycle-settings',
  components: {
    SvgArrowRight,
    SvgEdit,
    SvgTriangle,
    TalentIcon,
    Pagination,
    Loader,
  },
  data() {
    return {
      user: auth.getUser(),
      cycleId: this.$route.params.cycleId,
      cycle: null,
      loading: false,
      fields: {
        name: {
          editing: false,
          loading: false,
          value: '',
        },
        talents: {
          data: [],
          metadata: {
            limit: this.$route.limit || 5,
            offset: this.$route.offset || 0,
            count: 0,
          },
          loading: true,
        },
        timeline: {
          editing: false,
          loading: false,
          endDate: null,
          startDate: null,
        },
      },
      utils,
    };
  },
  computed: {
    startDateEditable() {
      return this.cycle.status === CycleStatus.DRAFT;
    },
    endDateEditable() {
      return this.startDateEditable || this.cycle.status === CycleStatus.ONGOING;
    },
    talentsEditable() {
      return this.cycle.status === CycleStatus.DRAFT;
    },
    sections() {
      const { questions } = this.cycle;
      return Object.entries(FormSectionLabel).map(([key, label]) => ({
        key,
        label,
        optionalQuestions: questions.filter((question) => question.section === key && question.optional).sort((qA, qB) => qA.questionOrder - qB.questionOrder),
      }));
    },
  },
  watch: {
    cycle() {
      this.fields.name.value = this.cycle.name;
      this.fields.timeline.endDate = new Date(this.cycle.endDate);
      this.fields.timeline.startDate = new Date(this.cycle.startDate);
    },
  },
  methods: {
    // Change la page du tableau
    changePage({ limit, offset }) {
      this.getTalents(limit, offset);
    },
    async getCycle() {
      this.loading = true;
      try {
        const response = await cycleApi.getCycle(this.cycleId);
        this.cycle = response.data;
      } catch (err) {
        const messages = typeof err.response.data.message === 'string' ? err.response.data.message : err.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the cycle. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    async getTalents(limit, offset) {
      this.fields.talents.loading = true;
      try {
        const response = await talentApi.getTalents(limit, offset, this.user.companyId, null, null, this.cycleId);
        this.fields.talents.data = response.data;
        this.fields.talents.metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the talents of this cycle. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.fields.talents.loading = false;
    },
    async editCycle(name, timeline) {
      const editingField = name ? 'name' : 'timeline';
      this.fields[editingField].loading = true;
      try {
        const response = await cycleApi.editCycle(this.cycleId, {
          ...(name && { name }),
          ...timeline,
        });
        this.cycle = response.data;
        this.fields[editingField].editing = false;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          text: `Error while updating the cycle. ${messages}`,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.fields[editingField].loading = false;
    },
  },
  mounted() {
    this.getCycle();
    this.getTalents(this.$route.query.limit || this.fields.talents.metadata.limit, this.$route.query.offset || this.fields.talents.metadata.offset);
  },
};
</script>

<style lang="sass">
.cycle-settings
  @include screen
  .app-link
    @include back-link
  p.description
    color: $common-gray
    max-width: 800px
  .subsection
    padding: 3rem 0
    position: relative
    &:not(:last-child)::after
      content: ''
      background: $light-color
      height: 2px
      width: calc(100% - 2rem)
      position: absolute
      left: 1.5rem
      bottom: 0
    .subsection-title
      display: flex
      justify-content: flex-end
      align-items: center
      padding: 1.5rem
      p
        flex-grow: 2
        font-size: 18px
      .app-button
        margin-left: 1.5rem
        &.subtle
          background: $light-color
          &:hover
            background: darken($light-color, 10%)
    .subsection-field
      background: $white
      padding: 0 1.5rem
      border-radius: $global-border-radius
      label
        height: 5rem
        display: flex
        justify-content: flex-start
        align-items: center
        border-bottom: 2px solid $light-color
        b
          width: 15rem
        .app-input, .app-datepicker
          min-width: 400px
        &:last-child
          border: none
    .form-section
      background: $white
      border-left: 4px solid
      border-radius: 4px
      padding: 1rem 2rem
      margin-bottom: 16px
      p b
        font-size: 20px
        margin: 0
      .form-section-question
        &:first-of-type
          margin-top: 2rem
        label
          width: 100%
          display: flex
          align-items: center
          padding: 0.3rem 0.5rem
          margin: 0.8rem 0
          background: $light-color
          border-radius: $global-border-radius
          opacity: 0.6
          .app-checkbox
            margin-right: 16px
            .checkmark
              opacity: 1
      &:nth-child(1)
        border-color: $main-color
        p b
          color: $main-color
      &:nth-child(2)
        border-color: $common-blue
        p b
          color: $common-blue
      &:nth-child(3)
        border-color: $common-green
        p b
          color: $common-green
      &:nth-child(4)
        border-color: $common-orange
        p b
          color: $common-orange
      &:nth-child(5)
        border-color: $common-dark-purple
        p b
          color: $common-dark-purple
  .subsection.talents
    .table-container
      @include table-container
      .app-table
        .body .row .cell:last-child, .headers .cell:last-child
          width: 2rem
        .body .row
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .pending-owner
            @include pending-owner
  .svg-triangle
    margin: 0 0 0 10px
</style>
