import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getFlagGroups = async (typicalBiasId) => {
  try {
    const response = await axios.get(`${API_URL}/typical-biases/${typicalBiasId}/flag-groups`);
    return response.data;
  } catch (error) { throw error; }
};

const updateFlagGroup = async (flagGroupId, data) => {
  try {
    const response = await axios.put(`${API_URL}/flag-groups/${flagGroupId}`, data);
    return response.data;
  } catch (error) { throw error; }
};

const createFlagGroup = async (typicalBiasId, data) => {
  try {
    const response = await axios.post(`${API_URL}/typical-biases/${typicalBiasId}/flag-groups`, data);
    return response.data;
  } catch (error) { throw error; }
};

const deleteFlagGroup = async (flagGroupId) => {
  try {
    await axios.delete(`${API_URL}/flag-groups/${flagGroupId}`);
  } catch (error) { throw error; }
};

const api = {};
api.getFlagGroups = getFlagGroups;
api.updateFlagGroup = updateFlagGroup;
api.createFlagGroup = createFlagGroup;
api.deleteFlagGroup = deleteFlagGroup;

export default api;
