<template>
  <div>
    <transition name="message">
    <div class="message-wrapper" :class="{ destructive: isDestructive }" v-if="visible">
      <div class="message-text">
        <h2>{{ title }}</h2>
        <p>{{ text }}</p>
      </div>
      <div class="message-buttons">
        <app-button kind="minimal" v-if="hasCancel" @click="hide">{{ cancelText }}</app-button>
        <app-button v-if="isDestructive" kind="destructive" @click="confirm"><svg-delete />{{ confirmText }}</app-button>
        <app-button v-else kind="primary" @click="confirm">{{ confirmText }}</app-button>
      </div>
    </div>
    </transition>
    <transition name="screen">
      <div class="message-screen" v-if="visible" @click="hide()"></div>
    </transition>
  </div>
</template>

<script>
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import AppEvent from '../utils/AppEvent';

export default {
  components: {
    'svg-delete': SvgDelete,
  },
  data() {
    return {
      visible: false,
      title: '',
      text: '',
      confirmText: 'Confirm',
      cancelText: 'Cancel',
      hasCancel: true,
      isDestructive: false,

      onConfirm: {},
    };
  },
  methods: {
    hide() {
      this.visible = false;
      document.documentElement.style.overflow = 'scroll';
    },
    confirm() {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm();
        this.hide();
      } else {
        this.hide();
      }
    },
    show(params) {
      this.visible = true;
      document.documentElement.style.overflow = 'hidden';

      this.title = params.title;
      this.text = params.text;

      if (params.onConfirm !== undefined) this.onConfirm = params.onConfirm;
      if (params.confirmText !== undefined) this.confirmText = params.confirmText;
      if (params.cancelText !== undefined) this.cancelText = params.cancelText;
      if (params.isDestructive !== undefined) this.isDestructive = params.isDestructive;
      this.hasCancel = !!params.hasCancel;
    },
  },
  beforeMount() {
    AppEvent.$on('app-message-show', (params) => {
      this.show(params);
    });
  },
  beforeDestroy() {
    AppEvent.$off('app-message-show', (params) => {
      this.show(params);
    });
    document.documentElement.style.overflow = 'scroll';
  },
};
</script>

<style lang="sass">
.message-wrapper
  position: fixed
  top: 50%
  left: 50%
  width: 500px
  border-radius: $global-border-radius
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
  background: white
  z-index: 1000
  transform: translate(-50%, -50%)
  text-align: left
  &.destructive
    padding: 0
    h2
      padding: 1rem 1rem 0 1rem
    p
      color: $link-color
      padding: 0 1rem 1rem 1rem
    .message-buttons
      background: $background-color
      padding: 1rem
      border-radius: 0 0 $global-border-radius $global-border-radius

.message-screen
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: rgba(0,0,0,0.4)
  z-index: 800

.message-text
    padding: 40px
    p
      color: $link-color
      margin: 0

.message-buttons
  display: flex
  margin-top: 2rem
  display: flex
  justify-content: center
  background: $background-color
  padding: 32px
  border-radius: 0 0 $global-border-radius $global-border-radius
  .app-button
    margin: 0 0.5rem

.screen-enter-active, .screen-leave-active
  transition: opacity .9s

.screen-enter, .screen-leave-to
  opacity: 0

.message-enter-active, .message-leave-active
  transition: transform .5s, opacity .3s

.message-enter, .message-leave-to
  opacity: 0
  transform: translateY(-130px) translateX(-50%)

</style>
