import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/companies`;

/**
 * Récupère une liste d'entreprises
 * @param {number} limit : nombre de résultats demandés
 * @param {number} offset : offset de la liste
 * @param {string} search : recherche sur le nom de l'entreprise
 * @param {OrderBy} order : tri par ordre croissant ou decroissant sur le nom
 * @returns liste d'entreprises
 */
const getCompanies = async (
  limit,
  offset,
  search,
  order,
) => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(order && { order }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'une entreprise
 *
 * @param {{ name: string, category: string, subscription: SubscriptionType, maxAdmins: number, maxTalents: number, setupDate: Date, address: AddressObject, contact: CompanyContact }} company
 * @returns {*}
 */
const createCompany = async (company) => {
  try {
    const response = await axios.post(API_URL, company);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mise à jour d'une entreprise
 *
 * @param {string} companyId
 * @param {{ name: string, category: string, subscription: SubscriptionType, maxAdmins: number, maxTalents: number, setupDate: Date, address: AddressObject, contact: CompanyContact }} company
 * @return {*}
 */
const editCompany = async (companyId, company) => {
  try {
    const response = await axios.put(`${API_URL}/${companyId}`, company);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération d'une entreprise
 *
 * @param {string} companyId
 * @return {*}
 */
const getCompany = async (companyId) => {
  try {
    const response = await axios.get(`${API_URL}/${companyId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Retire les droits administrateur pour le talent sélectionné
 *
 * @param {string} companyId
 * @param {string} talentId
 * @returns
 */
const revokeAdminRights = async (companyId, talentId) => {
  try {
    const response = await axios.delete(`${API_URL}/${companyId}/admins/${talentId}`);
    return response.data;
  } catch (error) { throw error; }
};

/**
 * Edite un admin
 *
 * @param {string} companyId
 * @param {string} talentId
 * @param {{ firstname: string, lastname: string, email: string, phone: string, function: string }} body
 * @returns
 */
const editAdmin = async (companyId, talentId, body) => {
  try {
    const response = await axios.put(`${API_URL}/${companyId}/admins/${talentId}`, body);
    return response.data;
  } catch (error) { throw error; }
};

/**
 * Créer un admin
 *
 * @param {string} companyId
 * @param {{ firstname: string, lastname: string, email: string, phone: string, function: string }} body
 * @returns
 */
const createAdmin = async (companyId, body) => {
  try {
    const response = await axios.post(`${API_URL}/${companyId}/admins`, body);
    return response.data;
  } catch (error) { throw error; }
};

/**
 * Supprime une entreprise et ses enfants
 * talents + talents version + cycles + questions + reviews + answers + flags
 *
 * @param {string} companyId
 * @returns
 */
const deleteCompany = async (companyId) => {
  try {
    const response = await axios.delete(`${API_URL}/${companyId}`);
    return response.data;
  } catch (error) { throw error; }
};

const api = {};
api.getCompanies = getCompanies;
api.createCompany = createCompany;
api.editCompany = editCompany;
api.getCompany = getCompany;
api.revokeAdminRights = revokeAdminRights;
api.editAdmin = editAdmin;
api.createAdmin = createAdmin;
api.deleteCompany = deleteCompany;

export default api;
