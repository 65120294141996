<template>
  <div class="review-delete"></div>
</template>

<script>
import reviewApi from '../../services/api/review';

/**
 * Pour utiliser ce composant :
 * - insérer-le quelque part dans le DOM, n'importe où
 * - ajouter-lui une référence
 * - appeler $refs[sa-référence].show(reviewId) pour supprimer la review indiquée
 */

export default {
  name: 'review-delete',
  emits: ['delete'], // émis après suppression de la review
  methods: {
    async show(reviewId) {
      this.$message.show({
        title: 'You\'re about the delete this TGP. Are you sure?',
        text: 'Deleting a review is definitive and irreversible. Do you want to proceed?',
        hasCancel: true,
        cancelText: 'Cancel',
        confirmText: 'Yes, delete',
        isDestructive: true,
        onConfirm: async () => {
          try {
            await reviewApi.deleteOne(reviewId);
            this.$notification.show({
              text: 'Review deleted successfully',
            });
            this.$emit('delete');
          } catch (error) {
            const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
            this.$message.show({
              title: 'Error',
              text: `You cannot delete this review. ${messages}`,
              hasCancel: false,
              confirmText: 'Ok',
              isDestructive: false,
              onConfirm: null,
            });
          }
        },
      });
    },
  },
};
</script>
