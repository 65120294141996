<template>
  <section class="review">
     <!-- Changement de talent -->
    <app-modal title="Select talent" :show="showTalentSelect" @update:show="showTalentSelect = false">
      <talent-select selectingOwnedTalent @update:selected-talent="tmpTalent = $event"></talent-select>
      <template v-slot:footer>
        <app-button @click="showTalentSelect = false" kind="minimal">Cancel</app-button>
        <app-button @click="updateReview(null, tmpTalent.talentId)">Select new talent</app-button>
      </template>
    </app-modal>

    <Loader v-if="loading" />

    <!-- Review chargée -->
    <div v-else-if="review" class="review-container">
      <!-- Sticky message bar ou juste Back to list -->
      <div class="message-bar" :class="{ complete: review.status === ReviewStatus.COMPLETED }">
        <div class="keep-left">
          <app-link @click="goBack">
            <SvgArrowRight />
            Back to list
          </app-link>
          <app-tag v-if="review.status === ReviewStatus.PROCESSING" class="processing">
            <SvgBiasCheckProcessing />
            This TGP is being processed. You’ll be able to complete it after reviewing your bias-check.
          </app-tag>
          <div v-if="review.status === ReviewStatus.EDITING" class="editing-message" :class="{ success: !numberUnresolvedFlags }">
            <template v-if="numberUnresolvedFlags">
              <SvgFlag />
              <b>{{ numberUnresolvedFlags }} unresolved flags</b>
              <app-tag state="main">These flags are only visible to you</app-tag>
            </template>
            <template v-else>
              <b>No unresolved flags</b>
            </template>
          </div>
        </div>
        <app-tag v-if="isEditable" class="autosaved">
          <app-spinner small currentcolor v-if="saving"></app-spinner>
          <SvgTick v-else/>
          Autosaved {{ utils.formatTime(lastSaved) }}
        </app-tag>
      </div>

      <!-- Titre avec boutons d'actions -->
      <div class="title-bar">
        <app-title>Talent Growth Plan : <span class="boldless">{{ review.talent.firstname | capitalize }} {{ review.talent.lastname | uppercase }}</span></app-title>
        <app-button v-if="!review.cycle && review.status === ReviewStatus.NEW" kind="minimal" size="medium" @click="showTalentSelect = true">
          <SvgEdit class="leading" />
          Change Talent
        </app-button>
        <app-button v-if="!review.cycle" kind="minimal" size="medium" @click="$refs['review-delete'].show(review.reviewId)">
          <SvgDelete class="leading"/>
          Delete TGP
        </app-button>
        <review-delete @delete="goBack" ref="review-delete"></review-delete>
        <app-button v-if="review.status === ReviewStatus.COMPLETED || review.status === ReviewStatus.EDITING" size="medium" @click="downloadPDF">
          <SvgDownload class="leading" />
          Export
        </app-button>
      </div>
      <!-- Statuts de la review -->
      <div class="status-bar">
        <span class="status edit">
          <span class="label">Status</span>
          <app-tag :state="utils.getReviewEditTagState(review.status)">
            <template v-if="review.status === ReviewStatus.COMPLETED">
              <SvgTick />
              Complete
            </template>
            <template v-else>
              <SvgEdit />
              Editing
            </template>
          </app-tag>
        </span>
        <span class="status bias-check">
          <span class="label">Bias Check</span>
          <app-tag :state="utils.getBiasCheckTagState(review.status)">
            <SvgBiasCheckNotSent v-if="review.status === ReviewStatus.NEW"/>
            <SvgBiasCheckProcessing v-else-if="review.status === ReviewStatus.PROCESSING" />
            <SvgBiasCheckComplete v-else />
            {{ BiasCheckLabel[review.status] }}
          </app-tag>
        </span>
      </div>
      <!-- Détails de la review (cycle, talent) -->
      <div class="review-details">
        <div class="cycle details">
          <p>Talent growth Plan details</p>
          <table>
            <tbody>
              <tr>
                <td>Cycle</td>
                <td>{{ review.cycle ? review.cycle.name : 'N/A' }}</td>
              </tr>
              <tr>
                <td>Cycle start date</td>
                <td>{{ review.cycle ? utils.formatDate(review.cycle.startDate) : 'N/A' }}</td>
              </tr>
              <tr>
                <td>Cycle end date</td>
                <td>{{ review.cycle ? utils.formatDate(review.cycle.endDate) : 'N/A' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="talent details">
          <p>Talent details</p>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{{ review.talent.firstname }}</td>
              </tr>
              <tr>
                <td>Surname</td>
                <td>{{ review.talent.lastname }}</td>
              </tr>
              <tr>
                <td>Role title</td>
                <td>{{ review.talent.talentVersion.role }}</td>
              </tr>
              <tr>
                <td>Function</td>
                <td>{{ review.talent.talentVersion.function }}</td>
              </tr>
              <tr>
                <td>Author</td>
                <td>{{ review.writer.firstname }} {{ review.writer.lastname }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Formulaires -->
      <template v-if="review.status === ReviewStatus.NEW">
        <form-new
          v-if="!showPreview"
          :initialQuestions="review.questions"
          @update:last-saved="lastSaved = Date.now()"
          @update:last-saved-waiting="saving = $event"
          @update:preview="getReview(); showPreview = true">
        </form-new>
        <form-preview v-else :initialQuestions="review.questions" @update:preview="showPreview = false" @update:review="updateReview(ReviewStatus.PROCESSING)"></form-preview>
      </template>
      <form-edit
        v-else-if="review.status === ReviewStatus.EDITING"
        :review="review"
        @update:last-saved="lastSaved = Date.now()"
        @update:last-saved-waiting="saving = $event"
        @update:review="updateReview(ReviewStatus.COMPLETED)"
        v-bind:numberUnresolvedFlags.sync="numberUnresolvedFlags"
      ></form-edit>
      <form-complete v-else :review="review"></form-complete>
    </div>

  </section>
</template>

<script>
import reviewApi from '@/services/api/review';
import utils from '@/services/utils';

// Enums
import ReviewStatus from '@/services/enums/review-status.enum';
import BiasCheckLabel from '@/services/enums/bias-check-label.enum';

// SVG
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import SvgEdit from '@/assets/img/icons/24px/edit.svg?inline';
import SvgTick from '@/assets/img/icons/24px/tick.svg?inline';
import SvgDownload from '@/assets/img/icons/24px/download.svg?inline';
import SvgBiasCheckNotSent from '@/assets/img/icons/24px/bias-check-not-sent.svg?inline';
import SvgBiasCheckProcessing from '@/assets/img/icons/24px/bias-check-processing.svg?inline';
import SvgBiasCheckComplete from '@/assets/img/icons/24px/bias-check-complete.svg?inline';
import SvgArrowRight from '@/assets/img/icons/16px/arrow-right.svg?inline';
import SvgFlag from '@/assets/img/icons/24px/flag.svg?inline';

// Composants
import TalentSelect from '@/views/talents/TalentSelect.vue';
import FormEdit from './forms/FormEdit.vue';
import FormNew from './forms/FormNew.vue';
import FormComplete from './forms/FormComplete.vue';
import FormPreview from './forms/FormPreview.vue';
import ReviewDelete from './ReviewDelete.vue';

import Loader from '../../components/Loader.vue';

export default {
  name: 'review',
  components: {
    SvgDelete,
    SvgEdit,
    SvgTick,
    SvgDownload,
    SvgBiasCheckNotSent,
    SvgBiasCheckProcessing,
    SvgBiasCheckComplete,
    SvgArrowRight,
    SvgFlag,
    FormEdit,
    FormNew,
    FormComplete,
    FormPreview,
    ReviewDelete,
    TalentSelect,
    Loader,
  },
  data() {
    return {
      review: null,
      loading: true,
      lastSaved: Date.now(),
      saving: false,
      showPreview: false,
      // Data pour le changement de talent
      showTalentSelect: false,
      tmpTalent: null,
      updatingTalent: false,

      numberUnresolvedFlags: null,

      ReviewStatus,
      BiasCheckLabel,
      utils,
      intervalId: null,
    };
  },
  computed: {
    // Si la review est éditable
    isEditable() {
      return this.review && (this.review.status === ReviewStatus.NEW || this.review.status === ReviewStatus.EDITING);
    },
    isProcessing() {
      return this.review && (this.review.status === ReviewStatus.PROCESSING);
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // Requête API : récupère la review
    async getReview() {
      this.loading = true;
      try {
        const response = await reviewApi.getReview(this.$route.params.reviewId);
        this.review = response.data;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        const text = `The review could not be fetched. ${messages}`;
        if (error.response.status === 404) {
          this.$router.push({ name: 'not-found' });
        }
        this.$message.show({
          text,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    // Requête API : met à jour le statut de la review ou le talent évalué
    async updateReview(status, talentId) {
      this.loading = true;
      try {
        const response = await reviewApi.updateOne(this.review.reviewId, status, talentId);
        this.review = response.data;
        if (talentId) {
          this.showTalentSelect = false;
        } else {
          this.$router.push({
            name: 'review-sent',
            params: { status },
          });
        }
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        const text = status ? `This TGP could not be sent to bias check. ${messages}` : `The talent could not be updated. ${messages}.`;
        this.$message.show({
          text,
          title: 'Error',
          hasCancel: false,
          confirmText: 'Ok',
          isDestructive: false,
          onConfirm: null,
        });
      }
      this.loading = false;
    },
    async downloadPDF() {
      await reviewApi.downloadPDF(this.review.reviewId);
    },
    async recallGetReview() {
      const callback = async () => {
        await this.getReview();
        if (!this.isProcessing) this.stopRecall();
      };
      // Lance et ré-exécute l'appel toutes les deux minutes
      this.intervalId = setInterval(callback, 120000);
    },
    stopRecall() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
  },
  async mounted() {
    await this.getReview();
    // Si la review est en cours de contrôle par le bias check alors on ré-appel getReview pour voir si il a terminé et donc si le status a changé
    if (this.isProcessing) this.recallGetReview();
    else this.stopRecall();
  },
  beforeDestroy() {
    this.stopRecall();
  },
};
</script>

<style lang="sass">
.review
  @include screen
  & > .loader
    margin: 20rem auto
  .review-container
    .message-bar
      @include screen
      display: flex
      align-items: center
      justify-content: space-between
      padding-top: 1.5rem
      padding-bottom: 1.5rem
      transform: translateX(-4rem)
      .keep-left
        display: flex
        align-items: center
        .app-link
          @include back-link
          margin-top: 0
        .app-tag.processing
          background: transparent
        .editing-message
          display: flex
          align-items: center
          color: $main-color
          svg path
            fill: currentcolor
          b
            margin: 0 1rem
          &.success
            color: $success-color
            b
              margin: 0

      &:not(.complete)
        @include sticky-bar
        top: $header-height
        z-index: 100
        margin-bottom: 2rem
        background-color: $white
        box-shadow: 0px 2px 8px rgba(103, 102, 98, 0.2)
    .title-bar
      display: flex
      align-items: center
      .app-title
        flex-grow: 1
        margin-top: 0
        .boldless
          font-weight: 400
      .app-button
        margin-left: 8px
    .status-bar
      display: flex
      width: 100%
      padding-bottom: 3rem
      border-bottom: 2px solid $light-color
      .status
        display: flex
        align-items: center
        margin-right: 4rem
        .label
          text-transform: uppercase
          margin-right: 1rem
    .review-details
      display: flex
      align-items: stretch
      margin-top: 2rem
      .details
        flex-grow: 1
        p
          color: $main-color
          text-transform: uppercase
          margin-top: 0
          font-size: 12px
          font-weight: 900
        table
          border-spacing: 0 1rem
          tr
            color: $common-gray
            td:first-child
              color: $black
              font-weight: 700
              padding-right: 4rem
        &:last-child
          padding-left: 1.5rem
          border-left: 2px solid $light-color

</style>
