<template>
  <section class="thresholds">
    <div class="thresholds-content">
      <div class="title-bar grid-x align-middle grid-margin-x">
        <app-title class="cell auto">Thresholds</app-title>
      </div>
    </div>
    <div class="table-container">
      <app-table class="table" :loading="loading">
        <template v-slot:headers>
          <div class="cell auto name">Threshold <SvgTriangle /></div>
          <div class="cell auto">Low risk</div>
          <div class="cell auto">Medium risk</div>
          <div class="cell auto">High risk</div>
          <div class="cell auto">Very high risk</div>
        </template>
        <template v-slot:body>
          <div class="row grid-x align-middle" v-for="threshold in thresholds" :key="threshold.thresholdId" @click="openThreshold(threshold)">
            <div class="cell auto name">{{ ThresholdLabel[threshold.thresholdType] }}</div>
            <div class="cell auto">{{ `${threshold.low / 100}%` }}</div>
            <div class="cell auto">{{ `${threshold.medium / 100}%` }}</div>
            <div class="cell auto">{{ `${threshold.high / 100}%` }}</div>
            <div class="cell auto">{{ `${threshold.veryHigh / 100}%` }}</div>
          </div>
        </template>
      </app-table>
    </div>
    <app-modal class="threshold-modal" :show="openThresholdModal" @update:show="openThresholdModal = false" :title="`Threshold - ${selectedThreshold ? ThresholdLabel[selectedThreshold.thresholdType] : ''}`">
      <form v-if="selectedThreshold">
        <div class="form-input">
          <app-label>Low risk</app-label>
          <app-input type="number" v-model="selectedThresholdValues.low" step="1" />
        </div>
        <div class="form-input">
          <app-label>Medium risk</app-label>
          <app-input type="number" v-model="selectedThresholdValues.medium" step="1" />
        </div>
        <div class="form-input">
          <app-label>High risk</app-label>
          <app-input type="number" v-model="selectedThresholdValues.high" step="1" />
        </div>
        <div class="form-input">
          <app-label>Very high risk</app-label>
          <app-input type="number" v-model="selectedThresholdValues.veryHigh" step="1" />
        </div>
      </form>
      <template v-slot:footer>
        <app-button kind="minimal" @click="openAdminModal = false">Cancel</app-button>
        <app-button @click="updateThreshold()">Save</app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import thresholdApi from '@/services/api/threshold';

import ThresholdLabel from '@/services/enums/threshold-label.enum';

import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

export default {
  name: 'thresholds',
  components: {
    SvgTriangle,
  },
  data() {
    return {
      thresholds: [],
      loading: false,
      ThresholdLabel,
      openThresholdModal: false,
      selectedThreshold: null,
      selectedThresholdValues: {
        low: 0,
        medium: 0,
        high: 0,
        veryHigh: 0,
      },
    };
  },
  mounted() {
    this.getThresholds();
  },
  methods: {
    async getThresholds() {
      this.loading = true;
      try {
        const thresholds = await thresholdApi.getThresholds();
        this.thresholds = thresholds.data;
        this.loading = false;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the thresholds. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    openThreshold(threshold) {
      this.selectedThreshold = threshold;
      this.selectedThresholdValues = {
        low: threshold.low / 100,
        medium: threshold.medium / 100,
        high: threshold.high / 100,
        veryHigh: threshold.veryHigh / 100,
      };
      this.openThresholdModal = true;
    },
    async updateThreshold() {
      const body = {
        low: this.selectedThresholdValues.low * 100,
        medium: this.selectedThresholdValues.medium * 100,
        high: this.selectedThresholdValues.high * 100,
        veryHigh: this.selectedThresholdValues.veryHigh * 100,
      };
      try {
        const updatedThreshold = await thresholdApi.updateThreshold(this.selectedThreshold.thresholdId, body);
        this.$notification.show({ text: 'Threshold updated successfully' });
        this.thresholds = this.thresholds.map((threshold) => {
          if (threshold.thresholdId === updatedThreshold.data.thresholdId) {
            return updatedThreshold.data;
          }
          return threshold;
        });
        this.selectedThreshold = null;
        this.selectedThresholdValues = {
          low: 0,
          medium: 0,
          high: 0,
          veryHigh: 0,
        };
        this.openThresholdModal = false;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to update the threshold. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.thresholds
  @include screen
  .table-container
    @include table-container
    .headers, .body
      .cell:not(.name)
        text-align: right
      .cell.name
        font-weight: bold
        color: black
    .body
      .row
        cursor: pointer
        &:hover
          box-shadow: 0px 4px 12px 0px rgba(103, 102, 98, 0.3)
  .threshold-modal
    form
      padding: 50px 160px
      .form-input
        display: flex
        justify-content: space-between
        align-items: flex-start
        margin: 8px 0
        .app-input
          width: 70%
          -moz-appearance: textfield
          position: relative
          &::after
            content: "%"
            position: absolute
            font-weight: bold
            right: 12px
            top: calc(50% - 8px)
        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
          -webkit-appearance: none
          margin: 0
</style>
