<template>
  <section class="cycle-setup-overview">
    <p class="intro">Here you can check that you are happy with the cycle you have created. Are you happy with the Talent Growth Plan form structure? Have you included all the talents you need? Are the timing rights? If not, go back and change. If all good, you are ready to launch your new cycle and unleash the power of Talentuum!</p>
    <section class="sub-section cycle-name-section">
      <div class="section-header">
        <app-subtitle>Name</app-subtitle>
        <app-button size="tiny" kind="minimal" @click="$emit('update:step', 1)"><app-tag><svg-editing/>Edit</app-tag></app-button>
      </div>
      <div class="section-body">
        <app-label>Cycle name</app-label>
        <p>{{ cycleBody.name }}</p>
      </div>
    </section>
    <section class="sub-section form-structure-section">
      <div class="section-header">
        <app-subtitle>Form structure</app-subtitle>
        <app-button size="tiny" kind="minimal" @click="$emit('update:step', 2)"><app-tag><svg-editing/>Edit</app-tag></app-button>
      </div>
      <div class="section-body">
        <section class="form-section" v-for="section in sections" :key="section.key">
          <app-subtitle>{{ section.label }}</app-subtitle>
          <div class="optional-question" v-for="question in section.optionalQuestions" :key="question.questionReference">
            <app-checkbox :input="true" disabled></app-checkbox>
            <label>{{ question.title }}</label>
          </div>
        </section>
      </div>
    </section>
    <section class="sub-section talent-list-section">
      <div class="section-header">
        <app-subtitle>Talent list</app-subtitle>
        <app-button size="tiny" kind="minimal" @click="$emit('update:step', 3)"><app-tag><svg-editing/>Edit</app-tag></app-button>
      </div>
      <div class="section-body">
        <div class="table-container">
          <app-table class="table" :loading="loading">
            <template v-slot:headers>
              <div class="cell auto">Name <svg-triangle /></div>
              <div class="cell auto">Role title</div>
              <div class="cell auto">Function</div>
              <div class="cell auto">Owned by</div>
            </template>
            <template v-slot:body>
              <div class="row grid-x align-middle talents-line" v-for="talent in displayedTalents" :key="talent.talentId">
                <div class="cell auto talent-name">
                  <talent-icon :user="talent"></talent-icon>
                  <div class="talent-info">
                    <div>{{ talent.firstname }} {{ talent.lastname }}</div>
                    <div class="talent-email cell">{{ talent.email }}</div>
                  </div>
                </div>
                <div class="cell auto">{{ talent.talentVersion.role }}</div>
                <div class="cell auto">{{ talent.talentVersion.function }}</div>
                <div class="cell auto">
                  <talent-icon v-if="talent.owner" :user="talent.owner" isLong></talent-icon>
                  <div v-else class="pending-owner"></div>
                </div>
              </div>
            </template>
          </app-table>
          <pagination :limit="metadata.limit" :offset="metadata.offset" :count="metadata.count" @update:page="changePage"/>
        </div>
      </div>
    </section>
    <section class="sub-section cycle-timeline-section">
      <div class="section-header">
        <app-subtitle>Cycle timeline</app-subtitle>
        <app-button size="tiny" kind="minimal" @click="$emit('update:step', 4)"><app-tag><svg-editing/>Edit</app-tag></app-button>
      </div>
      <div class="section-body">
        <div>
          <app-label>Cycle release date</app-label>
          <p>{{ formatDate(cycleBody.startDate) }}</p>
        </div>
        <div>
          <app-label>Cycle end date</app-label>
          <p>{{ formatDate(cycleBody.endDate) }}</p>
        </div>
      </div>
    </section>
    <app-sticky-footer type="actions">
      <app-button kind="secondary" @click="$emit('update:step', 4)">Back</app-button>
      <app-button @click="openConfirm">Setup cycle</app-button>
    </app-sticky-footer>
  </section>
</template>

<script>

import Editing from '@/assets/img/icons/24px/edit.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

import FormSectionLabel from '@/services/enums/form-section-label.enum';
import cycleApi from '@/services/api/cycle';
import utils from '@/services/utils';

import TalentIcon from '@/components/TalentIcon.vue';
import Pagination from '@/components/Pagination.vue';

import ls from 'local-storage';

export default {
  components: {
    TalentIcon,
    Pagination,
    'svg-editing': Editing,
    'svg-triangle': SvgTriangle,
  },
  props: {
    cycleBody: {
      type: Object,
    },
  },
  data() {
    return {
      sections: [],
      loading: false,
      metadata: {
        limit: 5,
        offset: 0,
        count: this.cycleBody?.talents?.length,
      },
      userFirstname: '',
    };
  },
  computed: {
    displayedTalents() {
      return this.cycleBody.talents.slice(this.metadata.offset, this.metadata.limit + this.metadata.offset);
    },
  },
  mounted() {
    this.initSections();
    const user = ls('USER');
    this.userFirstname = user.firstname;
  },
  methods: {
    async initSections() {
      const sections = await Promise.all(Object.entries(FormSectionLabel).map(async ([key, label]) => ({
        key,
        label,
        optionalQuestions: this.cycleBody.questions.filter((question) => question.section === key && question.optional),
      })));
      this.sections = sections;
    },
    changePage({ limit, offset }) {
      this.metadata.limit = limit;
      this.metadata.offset = offset;
    },
    formatDate(date) {
      return utils.formatDate(date);
    },
    openConfirm() {
      this.$message.show({
        title: 'An email will be sent to all your talent authors the cycle release day',
        text: `Hi ${this.userFirstname}, are you really ready you activate this cycle? When you do, all your Talent Growth Plans authors will receive an email to get them in action. Just making sure you are ready to go, ${this.userFirstname}!`,
        hasCancel: true,
        confirmText: 'Finish',
        onConfirm: async () => {
          // disable buttons to avoid multiple clicks
          document.querySelectorAll('.message-buttons button').forEach((el) => { el.disabled = true; }); // eslint-disable-line no-param-reassign
          try {
            const newBody = {
              name: this.cycleBody.name,
              startDate: this.cycleBody.startDate,
              endDate: this.cycleBody.endDate,
              talents: this.cycleBody.talents.map((talent) => talent.talentId),
              questions: this.cycleBody.questions.map((question) => ({ questionReference: question.questionReference, options: { values: question.options.values.filter((o) => !!o) } })),
            };
            await cycleApi.createCycle(newBody);
            this.$emit('update:step', 6);
          } catch (error) {
            const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
            this.$message.show({
              title: 'Error',
              text: `An error occured. ${messages}`,
              hasCancel: false,
              confirmText: 'Ok',
              isDestructive: false,
              onConfirm: null,
            });
          }
          document.querySelectorAll('.message-buttons button').forEach((el) => { el.disabled = false; }); // eslint-disable-line no-param-reassign
        },
      });
    },
  },
};
</script>

<style lang="sass">
.cycle-setup-overview
  .intro
    max-width: 60%
  p
    color: $common-gray
  .sub-section
    padding-bottom: 56px
    padding-top: 46px
    position: relative
    .section-header
      display: flex
      align-items: center
      justify-content: space-between
      padding: 0 24px 20px 24px
      .app-subtitle
        font-size: 20px
      .app-button
        &:hover
          background: transparent
        &:focus
          box-shadow: none
    .section-body
      background: $white
      padding: 30px 24px
      border-radius: 4px
      .app-label
        padding: 0
    &:after
      content: ""
      width: calc(100% - 24px * 2)
      height: 2px
      background: $light-color
      position: absolute
      bottom: 0
      left: 24px
    &.cycle-name-section
      .section-body
        display: flex
        align-items: center
        p
          margin: 0
          font-size: 16px
          padding-left: 121px
    &.form-structure-section
      .section-body
        padding: 30px 0
        background: transparent
        .form-section
          background: $white
          border-left: 4px solid
          border-radius: 4px
          padding: 24px
          margin-bottom: 16px
          .app-subtitle
            font-size: 20px
            margin: 0
          .optional-question
            border: 1px solid $light-color
            padding: 20px
            display: flex
            align-items: center
            margin-top: 8px
            &:first-of-type
              margin-top: 32px
            .app-checkbox
              margin-right: 16px
            label
              color: $common-gray
          &:nth-child(1)
            border-color: $main-color
            .app-subtitle
              color: $main-color
          &:nth-child(2)
            border-color: $common-blue
            .app-subtitle
              color: $common-blue
          &:nth-child(3)
            border-color: $common-green
            .app-subtitle
              color: $common-green
          &:nth-child(4)
            border-color: $common-orange
            .app-subtitle
              color: $common-orange
          &:nth-child(5)
            border-color: $common-dark-purple
            .app-subtitle
              color: $common-dark-purple
    &.talent-list-section
      .table-container
        @include table-container
        .table
          .talent-selector
            padding-right: 16px
            .app-checkbox input:not(:checked) ~ span
              border: 2px solid $medium-gray
          .body .row .cell:last-child, .headers .cell:last-child
            width: 2rem
            position: relative
          .body .row
              @include clickable-row
              .talent-name
                display: flex
                .talent-info
                  margin-left: 0.5rem
                  div:first-child
                    color: $black
                    font-weight: 700
                  div:last-child
                    font-size: 13px
              .pending-owner
                @include pending-owner
              .overflow-button
                @include icon-button
                width: 2.5rem
                height: 2.5rem
    &.cycle-timeline-section
      .section-body
        padding: 0 24px
        &>div
          display: flex
          align-items: center
          padding: 30px 0
          .app-label
            width: 130px
          p
            margin: 0
            font-size: 16px
            padding-left: 121px
          &:not(:last-of-type)
            border-bottom: 2px solid $light-color
  .svg-triangle
    margin: 0 0 0 10px
</style>
