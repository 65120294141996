<template>
  <section class="add-talents">
    <template v-if="parsingCSV">
      <talents-parsing
        :file="file"
        @update:file="file = $event"
        @update:count="parsingCount = $event"
        @uploaded="parsingCSV = false; getTalents(metadata.limit, 0)"
        ref="talentsParsing"
      ></talents-parsing>
      <app-sticky-footer type="actions">
        <app-button kind="secondary" @click="parsingCSV = false">Back</app-button>
        <app-button @click="$refs.talentsParsing.uploadCSV()">Add {{ parsingCount }} talents</app-button>
      </app-sticky-footer>
    </template>

    <template v-else>
      <div v-if="!talents.length" class="table-container empty">
        <img :src="SvgEmptyTalents" />
          <p>Looks like there’s nothing here yet. Add your first talent to get started!</p>
          <app-button size="medium" @click="showImportModal = true">
            <SvgUpload class="leading"/>Upload .csv
          </app-button>
          <app-button size="medium" kind="subtle" @click="showNewTalentModal = true">
            <SvgPlus class="leading"/>Add talent manually
          </app-button>
      </div>
      <template v-else>
        <div class="table-container">
          <app-table :loading="loading" :empty="!talents.length">
            <template v-slot:headers>
              <div class="cell auto">Name <SvgTriangle /></div>
              <div class="cell auto">Role title</div>
              <div class="cell auto">Function</div>
              <div class="cell auto"></div>
            </template>
            <template v-slot:body>
              <div class="row grid-x align-middle" v-for="talent in talents" :key="talent.talentId">
                <div class="cell auto talent-name">
                  <talent-icon :user="talent"></talent-icon>
                  <div class="talent-info">
                    <div>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase}}</div>
                    <div class="talent-email cell">{{ talent.email }}</div>
                  </div>
                </div>
                <div class="cell auto">{{ talent.talentVersion.role }}</div>
                <div class="cell auto">{{ talent.talentVersion.function }}</div>
                <div class="cell auto row-actions">
                  <app-button kind="minimal" size="medium" @click="talentBeingEdited = talent">Edit</app-button>
                  <app-button kind="minimal" size="medium" @click="deleteTalent(talent)">Delete</app-button>
                </div>
              </div>
            </template>
          </app-table>
          <pagination v-bind="metadata" @update:page="getTalents($event.limit, $event.offset)"></pagination>
          <div class="actions-container">
            <app-button size="medium" @click="showImportModal = true">
              <SvgUpload class="leading"/>Upload .csv
            </app-button>
            <app-button size="medium" kind="subtle" @click="showNewTalentModal = true">
              <SvgPlus class="leading"/>Add talent manually
            </app-button>
          </div>
        </div>

        <app-sticky-footer type="actions">
          <app-button kind="secondary" @click="deleteAll">Start again</app-button>
          <app-button @click="$emit('update:step', 1)">Next</app-button>
        </app-sticky-footer>
      </template>
    </template>

    <!-- Modale d'ajout manuel d'un talent -->
    <app-modal :show="showNewTalentModal" @update:show="showNewTalentModal = false" title="Add new talent" class="new-talent-modal">
      <talent-profile isNew ref="newTalent" @submitted="showNewTalentModal = false; getTalents()"></talent-profile>
      <template v-slot:footer>
        <app-button kind="minimal" @click="showNewTalentModal = false">Cancel</app-button>
        <app-button @click="$refs.newTalent.submit()">Save</app-button>
      </template>
    </app-modal>

    <!-- Modale d'edition d'un talent -->
    <app-modal :show="!!talentBeingEdited" @update:show="talentBeingEdited = null" title="Edit talent" class="new-talent-modal">
      <talent-profile v-if="!!talentBeingEdited" ref="editTalent" editMode :talentId="talentBeingEdited.talentId || null" @submitted="talentBeingEdited = null; getTalents()"></talent-profile>
      <template v-slot:footer>
        <app-button kind="minimal" @click="talentBeingEdited = null">Cancel</app-button>
        <app-button @click="$refs.editTalent.submit()">Save</app-button>
      </template>
    </app-modal>

    <talents-import-modal :show="showImportModal" @update:show="showImportModal = $event" @update:file="file = $event"></talents-import-modal>

  </section>
</template>

<script>
import talentApi from '@/services/api/talent';

import SvgEmptyTalents from '@/assets/img/empty-talents.svg';
import SvgUpload from '@/assets/img/icons/24px/upload.svg?inline';
import SvgPlus from '@/assets/img/icons/24px/plus.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

import TalentsImportModal from '@/views/talents/TalentsImportModal.vue';
import TalentsParsing from '@/views/talents/TalentsParsing.vue';
import TalentProfile from '@/views/talents/TalentProfile.vue';
import TalentIcon from '@/components/TalentIcon.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  components: {
    SvgUpload,
    SvgPlus,
    SvgTriangle,
    TalentsImportModal,
    TalentsParsing,
    TalentProfile,
    TalentIcon,
    Pagination,
  },
  emits: ['update:step'],
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      talents: [],
      metadata: {
        limit: 5,
        offset: 0,
        count: 0,
      },
      loading: false,

      // CSV parsing
      file: null,
      showImportModal: false,
      parsingCSV: false,
      parsingCount: 0,

      showNewTalentModal: false,
      talentBeingEdited: null,

      SvgEmptyTalents,
    };
  },
  watch: {
    file() {
      if (this.file) {
        this.parsingCSV = true;
      }
    },
  },
  methods: {
    async getTalents(limit = this.metadata.limit, offset = this.metadata.offset) {
      this.loading = true;
      try {
        const response = await talentApi.getTalents(
          limit,
          offset,
          this.companyId,
        );
        this.talents = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the talents. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    async deleteTalent(talent) {
      this.loading = true;
      try {
        await talentApi.deleteOne(talent.talentId);
        this.$notification.show({
          text: 'Talent deleted successfully',
        });
        this.getTalents();
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `The talent could not be deleted. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
        this.loading = false;
      }
    },
    async deleteAll() {
      this.loading = true;
      try {
        await talentApi.deleteAll();
        this.getTalents(this.metadata.limit, 0);
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `An error has occured : ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getTalents();
  },
};
</script>

<style lang="sass">
.add-talents
  height: 100%
  display: flex
  flex-direction: column
  .talents-parsing
    flex-grow: 2
  > .table-container
    @include table-container
    flex-grow: 2
    .app-table
      .body .row
        .talent-name
          display: flex
          .talent-info
            margin-left: 0.5rem
            div:first-child
              color: $black
              font-weight: 700
            div:last-child
              font-size: 13px
        .row-actions
          display: flex
          justify-content: flex-end
          .app-button
            margin-left: 1rem
    .actions-container
      display: flex
      align-items: center
      justify-content: center
      margin: 1rem 0
      .app-button
        margin: 0 1rem

    &.empty
      height: 70vh
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      p
        color: $common-gray
      p, .app-button
        width: 400px
        text-align: center
      .app-button
        display: flex
        justify-content: center
        margin: 0.5rem 0

  .new-talent-modal .modal-wrapper
    transform: translate(-50%, -70%)
  .svg-triangle
    margin: 0 0 0 10px
</style>
