import axios from 'axios';
import auth from '../auth';

const API_URL = `${process.env.VUE_APP_API_URL}/cycles`;

const getCycles = async (limit, offset, archived, talentId) => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(archived && { archived }),
        ...(talentId && { talentId }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCycle = async (cycleId) => {
  try {
    const response = await axios.get(`${API_URL}/${cycleId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getTalentsStats = async (cycleId) => {
  try {
    const response = await axios.get(`${API_URL}/${cycleId}/talents-stats`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getBiasStats = async (cycleId, datapool) => {
  try {
    const response = await axios.get(`${API_URL}/${cycleId}/bias-stats`, { params: { datapool } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getWritersStats = async (cycleId) => {
  try {
    const response = await axios.get(`${API_URL}/${cycleId}/writers-stats`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAnswersStats = async (cycleId) => {
  try {
    const response = await axios.get(`${API_URL}/${cycleId}/answers-stats`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createCycle = async (body) => {
  try {
    const response = await axios.post(API_URL, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const editCycle = async (cycleId, body) => {
  try {
    const response = await axios.put(`${API_URL}/${cycleId}`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteCycle = async (cycleId) => {
  try {
    const response = await axios.delete(`${API_URL}/${cycleId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const downloadPDF = async (cycleId) => {
  try {
    const token = auth.getToken();
    window.open(`${API_URL}/${cycleId}/export?token=${token}`, '_blank');
  } catch (error) { throw error; }
};

const api = {};
api.getCycles = getCycles;
api.getCycle = getCycle;
api.createCycle = createCycle;
api.getTalentsStats = getTalentsStats;
api.getBiasStats = getBiasStats;
api.getWritersStats = getWritersStats;
api.getAnswersStats = getAnswersStats;
api.editCycle = editCycle;
api.deleteCycle = deleteCycle;
api.downloadPDF = downloadPDF;

export default api;
