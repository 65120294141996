<template>
  <section class="cycle-typical-bias-across-segments">
    <div class="page">
      <app-subtitle>Typical Bias risks accross the segments</app-subtitle>
      <p>This section details which bias risks are present in the various groups of the cycle. Hover over the bubbles to get details</p>
      <graph-card title="Risk of bias across all segments">
        <app-bubble-chart
          :stats="typicalBiasRisks"
          chartId="typical-bias-risk"
          :options="{
            axisTitle: { yAxis: 'Average intensity of typical biases', xAxis: 'Average presence of typical biases' },
            radius: 16,
          }"
        />
        <bubble-chart-legend type="color" :data="isPDF ? typicalBiasRisks : []"/>
      </graph-card>
      <graph-card v-for="segment in mostAffectedSegments" :key="segment.label" :title="segment.label" subtitle="Review bigget bias">
        <typical-bias v-for="typicalBias in segment.mostPresentTypicalBias" :key="typicalBias.reference" :typicalBias="typicalBias" />
        <p v-if="!segment.mostPresentTypicalBias.length">No typical bias found</p>
      </graph-card>
    </div>
  </section>
</template>

<script>
// Vue mixins
import StatsDisplay from '@/mixins/StatsDisplay.vue';

import BubbleChartLegend from './BubbleChartLegend.vue';
import GraphCard from './GraphCard.vue';
import TypicalBias from './TypicalBias.vue';

export default {
  components: { GraphCard, BubbleChartLegend, TypicalBias },
  mixins: [StatsDisplay],
  props: {
    biasStats: {
      type: Object,
      required: true,
    },
    typicalBiases: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPDF: this.$route.meta.isPDF,
    };
  },
  computed: {
    typicalBiasRisks() {
      return this.biasStats.typicalBiasRisks.map((tb) => ({
        label: this.typicalBiases[tb.reference].label,
        ...tb.biasRisk,
      }));
    },
    mostAffectedSegments() {
      return this.biasStats.segmentsBiasRisk
        .filter((segment) => segment.nbAuthors >= +process.env.VUE_APP_NB_WRITERS_MIN_SEGMENT)
        .filter((segment) => segment.biasRisk.distance)
        .slice(0, 3)
        .map((segmentBiasRisk) => ({
          label: this.getPoolLabel(segmentBiasRisk.pool),
          mostPresentTypicalBias: segmentBiasRisk.typicalBiasRisks.filter((typicalBiasRisk) => typicalBiasRisk.biasRisk.distance).slice(0, 3).map((typicalBiasRisk) => this.typicalBiases[typicalBiasRisk.reference]),
        }));
    },
  },
};
</script>

<style lang="sass">
.cycle-typical-bias-across-segments
  .graph-card-content
    display: flex
    gap: 1rem
    .app-bubble-chart
      flex-grow: 2
      max-width: calc(100vh + 140px)
    .typical-bias
      background-color: $background-color

</style>
