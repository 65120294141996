<template>
  <section class="cycle-talents">
    <div class="page">

      <!-- Pie charts de la répartition des talents dans chaque segment (genre, nationalité, ancienneté, ethnicité si disponible) -->
      <app-subtitle>Talent split</app-subtitle>
      <p>This section shows the demographics of your cycle. It represents the distribution of talents across the different groups</p>
      <div class="talents-stats grid-x align-justify grid-margin-x">
        <graph-card class="cell small-12 medium-6" title="Gender split" v-if="genderData">
          <app-pie-chart :stats="genderData" chartId="talents-gender-split" :pdf="isPDF"></app-pie-chart>
        </graph-card>
        <graph-card class="cell small-12 medium-6" title="Nationality"  v-if="nationalityData">
          <app-pie-chart :stats="nationalityData" chartId="talents-nationality"  :pdf="isPDF"></app-pie-chart>
        </graph-card>
        <graph-card class="cell small-12 medium-6" title="Ethnicity" v-if="ethnicityData">
          <app-pie-chart :stats="ethnicityData" chartId="talents-ethnicity"  :pdf="isPDF"></app-pie-chart>
        </graph-card>
        <graph-card class="cell small-12 medium-6" title="Seniority level" v-if="seniorityData">
          <app-pie-chart :stats="seniorityData" chartId="talents-seniority"  :pdf="isPDF"></app-pie-chart>
        </graph-card>
      </div>

      <!-- Graph sur les réponses des questions configurables -->
      <template v-if="answersStats && answersStats.length">
        <app-subtitle>Values and competencies</app-subtitle>
        <div class="answers-stats grid-x align-justify grid-margin-x">
          <graph-card
            v-for="qAndA in answersStats"
            :key="qAndA.question.questionId"
            :title="`${qAndA.question.title} representation`"
            class="cell small-12 medium-6"
          >
            <div class="answer" v-for="answer in qAndA.answers" :key="answer.group">
              <label>
                <span>{{ answer.group }}</span>
                <span>{{ answer.value | percentage }}</span>
              </label>
              <app-progress-bar :rate="answer.value"></app-progress-bar>
            </div>
          </graph-card>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
// ENUMS
import GenderLabel from '@/services/enums/gender-label.enum';
import NationalityLabel from '@/services/enums/nationality-label.enum';
import EthnicityLabel from '@/services/enums/ethnicity-label.enum';
import SeniorityLabel from '@/services/enums/seniority-label.enum';

// Composants
import GraphCard from './GraphCard.vue';

export default {
  components: { GraphCard },
  props: {
    talentsStats: {
      type: Object,
    },
    answersStats: {
      type: Array,
    },
  },
  data() {
    return {
      genderData: null,
      nationalityData: null,
      ethnicityData: null,
      seniorityData: null,
      isPDF: this.$route.meta.isPDF,
      GenderLabel,
      NationalityLabel,
      EthnicityLabel,
      SeniorityLabel,
    };
  },
  watch: {
    talentsStats() {
      if (this.talentsStats) {
        this.getGenderData();
        this.getNationalityData();
        this.getEthnicityData();
        this.getSeniorityData();
      }
    },
  },
  mounted() {
    if (this.talentsStats) {
      this.getGenderData();
      this.getNationalityData();
      this.getEthnicityData();
      this.getSeniorityData();
    }
  },
  filters: {
    percentage(value) {
      return `${Math.round(Math.abs(value))}%`;
    },
  },
  methods: {
    // Toutes ces méthodes permettent de formatter les données pour les afficher plus facilement
    getGenderData() {
      this.genderData = Object.entries(GenderLabel).map(([key, label]) => {
        const stat = this.talentsStats.gender.details.find(({ group }) => group === key);
        return ({ label, value: stat?.value || 0 });
      });
    },
    getNationalityData() {
      this.nationalityData = this.talentsStats.nationality.details.map((detail) => ({ label: NationalityLabel[detail.group], value: detail.value }));
    },
    getEthnicityData() {
      this.ethnicityData = Object.entries(EthnicityLabel).map(([key, label]) => {
        const stat = this.talentsStats.ethnicity.details.find(({ group }) => group === key);
        return ({ label, value: stat?.value || 0 });
      });
    },
    getSeniorityData() {
      this.seniorityData = Object.entries(SeniorityLabel).map(([key, label]) => {
        const stat = this.talentsStats.seniority.details.find(({ group }) => group === key);
        return ({ label, value: stat?.value || 0 });
      });
    },
  },
};
</script>

<style lang="sass">
.cycle-talents
  .talents-stats
    .graph-card
      margin: 1rem 0
      .graph-card-content
        padding-left: 15%
  .answers-stats
    .graph-card
      margin-top: 1rem
    .answer
      margin-top: 15px
      label
        display: flex
        justify-content: space-between
        span:first-child
          font-weight: 700
      .app-progress-bar
        margin-top: 0.5rem
</style>
