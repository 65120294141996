<template>
  <button class="app-button" :class="[size, kind]" :type="type" @click="handleClick"><slot></slot></button>
</template>

<script>
export default {
  props: {
    size: {
      default: 'large',
      validator: (val) => ['large', 'medium', 'tiny'].indexOf(val) !== -1,
    },
    kind: {
      default: 'primary',
      validator: (val) => ['primary', 'secondary', 'subtle', 'minimal', 'destructive', 'negative'].indexOf(val) !== -1,
    },
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>

<style lang="sass">
.app-button
  display: flex
  align-items: center
  position: relative
  color: $white
  border-radius: 100px
  font-size: 16px
  line-height: 24px
  transition: all ease-in-out 0.2s
  cursor: pointer
  font-weight: 700
  border: 0
  width: fit-content
  height: fit-content
  font-family: Lato
  outline: none
  svg
    margin: 0 0.5rem
    &.leading
      margin-left: 0
    &.trailing
      margin-right: 0
    path
      fill: currentcolor
  &:focus
    box-shadow: 0px 0px 0px 8px rgba($main-color, 0.4)

  &:disabled
    cursor: initial
    pointer-events: none
    opacity: 0.8

  &.large
    padding: 16px 28px
  &.medium
    padding: 8px 20px
  &.tiny
    padding: 2px 8px
    font-size: 13px

  &.primary
    background: $main-color
    &:hover
      background-color: $main-secondary-color

  &.secondary
    border: 2px solid $main-color
    background-color: $white
    color: $main-color
    &.large
      padding: 14px 28px
    &.medium
      padding: 6px 20px
    &.tiny
      padding: 2px 8px
    &:hover
      border-color: $main-secondary-color
      color: $main-secondary-color

  &.subtle
    background-color: $background-color
    color: $common-gray
    &:hover
      background: $light-color

  &.minimal
    background-color: transparent
    color: $common-gray
    &:hover
      background: $light-color

  &.negative
    background-color: transparent
    border: 2px solid $white
    &.large
      padding: 14px 28px
    &.medium
      padding: 6px 20px
    &.tiny
      padding: 2px 8px
    &:hover
      color: $black
      background: $white

  &.destructive
    background-color: $error-color
    &:hover
      background: $error-secondary-color

  &.no-focus
    &:focus
      box-shadow: none
</style>
