<template>
  <section class="cycle-where-do-biases-come-from">
    <div class="page">
      <app-subtitle>{{ title }}</app-subtitle>
      <p>{{ description }}</p>
      <div class="graph-card-container">
        <graph-card v-for="segment in femaleWritersSegments" :key="segment.reference" :title="segment.label">
          <app-bubble-chart :stats="segment.typicalBiasRisks" :options="{ radius: 8 }" :chartId="segment.label"/>
          <bubble-chart-legend v-if="isPDF" type="color" :data="isPDF ? segment.typicalBiasRisks : []"/>
        </graph-card>
      </div>
      <div class="graph-card-container" v-if="!isPDF || !femaleWritersSegments.length">
        <graph-card v-for="segment in maleWritersSegments" :key="segment.reference" :title="segment.label">
          <app-bubble-chart :stats="segment.typicalBiasRisks" :options="{ radius: 8 }" :chartId="segment.label"/>
          <bubble-chart-legend v-if="isPDF" type="color" :data="isPDF ? segment.typicalBiasRisks : []"/>
        </graph-card>
      </div>
      <p v-if="!femaleWritersSegments.length && !maleWritersSegments.length" class="data-placeholder">Not enough data available</p>
    </div>
    <div class="page" v-if="isPDF && femaleWritersSegments.length && maleWritersSegments.length">
      <app-subtitle>{{ title }}</app-subtitle>
      <p>{{ description }}</p>
      <div class="graph-card-container">
        <graph-card v-for="segment in maleWritersSegments" :key="segment.reference" :title="segment.label">
          <app-bubble-chart :stats="segment.typicalBiasRisks" :options="{ radius: 8 }" :chartId="segment.label"/>
          <bubble-chart-legend v-if="isPDF" type="color" :data="isPDF ? segment.typicalBiasRisks : []"/>
        </graph-card>
      </div>
    </div>
  </section>
</template>

<script>
import GenderLabel from '@/services/enums/gender-label.enum';

import GraphCard from './GraphCard.vue';
import BubbleChartLegend from './BubbleChartLegend.vue';

export default {
  name: 'cycle-where-do-biases-come-from',
  components: {
    GraphCard,
    BubbleChartLegend,
  },
  props: {
    biasFemaleStats: {
      type: Object,
      required: true,
    },
    biasMaleStats: {
      type: Object,
      required: true,
    },
    typicalBiases: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: 'Where do biases come from ?',
      description: 'This section explains which authors (female or male) in the cycle generate most bias risks for which talent segments',
      isPDF: this.$route.meta.isPDF,
    };
  },
  computed: {
    femaleWritersSegments() {
      return this.biasFemaleStats.segmentsBiasRisk
        .filter((segment) => segment.nbAuthors >= +process.env.VUE_APP_NB_WRITERS_MIN_SEGMENT)
        .map((segmentBiasRisk) => ({
          label: `${GenderLabel[segmentBiasRisk.pool.gender]} talents with female authors`,
          typicalBiasRisks: segmentBiasRisk.typicalBiasRisks.map((typicalBiasRisk) => ({
            label: this.typicalBiases[typicalBiasRisk.reference].label,
            ...typicalBiasRisk.biasRisk,
          })),
        }));
    },
    maleWritersSegments() {
      return this.biasMaleStats.segmentsBiasRisk
        .filter((segment) => segment.nbAuthors >= +process.env.VUE_APP_NB_WRITERS_MIN_SEGMENT)
        .map((segmentBiasRisk) => ({
          label: `${GenderLabel[segmentBiasRisk.pool.gender]} talents with male authors`,
          typicalBiasRisks: segmentBiasRisk.typicalBiasRisks.map((typicalBiasRisk) => ({
            label: this.typicalBiases[typicalBiasRisk.reference].label,
            ...typicalBiasRisk.biasRisk,
          })),
        }));
    },
  },

};
</script>

<style lang="sass">
.cycle-where-do-biases-come-from
  .graph-card-container
    display: flex
    gap: 1rem
    &:empty
      display: none
    .graph-card
      width: 100%
      max-width: 50%
      margin: 0
  .data-placeholder
    @include stats-placeholder

</style>
