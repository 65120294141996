<template>
  <section class="login grid-container">
    <div class="grid-x">
      <div class="cell medium-5">
        <div class="headline">
          <app-title>
            Login
            <template v-if="isSuperAdmin">Super Admin</template>
          </app-title>
          <app-link size="small" @click="() => redirectToForgotPassword()">I forgot my password</app-link>
        </div>
        <form ref="login" @submit.prevent="login(user)">
          <div>
            <app-label>Email</app-label>
            <app-input autocomplete="email" type="email" required v-model="user.email" :class="{ error: hasError }"/>
          </div>
          <div>
            <app-label>Password</app-label>
            <app-input autocomplete="current-password" type="password" required  v-model="user.password" :class="{ error: hasError }" :helper="errorMessage"/>
          </div>
          <div>
            <app-button type="submit" id="login-btn" :disabled="isLoading">
              Log in
              <app-spinner v-if="isLoading" small currentcolor/>
              <arrowRight v-else />
            </app-button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/services/auth';
import UserRole from '@/services/enums/user-role.enums';
import arrowRight from '../assets/img/icons/24px/arrow-right.svg?inline';

export default {
  name: 'login',
  components: {
    arrowRight,
  },
  props: {
    isSuperAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      hasError: false,
      errorMessage: '',
      isLoading: false,
    };
  },
  methods: {
    async login(user) {
      if (this.$refs.login.checkValidity()) {
        this.isLoading = true;
        try {
          if (this.isSuperAdmin) {
            await auth.loginSuperAdmin(user);
          } else {
            await auth.login(user);
          }

          this.$notification.show({
            text: 'Login successful !',
          });
          const USER = auth.getUser();
          if (USER.role === UserRole.ADMIN) {
            this.$router.push({ name: 'home' });
          } else if (USER.role === UserRole.TALENT) {
            this.$router.push({ name: 'reviews' });
          } else if (USER.role === UserRole.SUPER_ADMIN) {
            this.$router.push({ name: 'companies' });
          }
        } catch (error) {
          this.hasError = true;
          this.errorMessage = 'Wrong credentials: check your login and password';
          this.user.password = '';
          this.isLoading = false;
        }
        this.isLoading = false;
      } else {
        this.$refs.login.reportValidity();
      }
    },
    redirectToForgotPassword() {
      if (this.isSuperAdmin) {
        return this.$router.push({ name: 'super-admin-forgot-password' });
      }
      return this.$router.push({ name: 'forgot-password' });
    },
  },
};
</script>

<style lang="sass">
.login
  height: calc(100vh - 68px)
  .grid-x
    height: 100%
    justify-content: center
    align-items: center
  .headline
    display: flex
    align-items: center
    justify-content: space-between
  .app-button
    display: flex
    margin-top: 48px
    align-items: center
    .app-spinner
      margin: 0 0.9rem 0 1rem
  form svg
    margin-left: 10px
    width: 24px
    path
      fill: #ffffff
</style>
