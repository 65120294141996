<template>
  <button ref="target" class="app-link" :class="size" @click="$emit('click')"><slot></slot></button>
</template>

<script>
export default {
  emits: ['click'],
  props: {
    size: {
      default: 'medium',
      validator: (val) => ['medium', 'small'].indexOf(val) !== -1,
    },
  },
  data() {
    return {
      buttonPosition: null,
    };
  },
  mounted() {
    const inputEl = this.$refs.target;
    const { width, height } = inputEl.getBoundingClientRect();
    this.buttonPosition = { width, height };

    this.setCssVariables();
  },
  methods: {
    setCssVariables() {
      const root = document.documentElement;

      root.style.setProperty('--before-width', `${this.buttonPosition.width}px`);
      root.style.setProperty('--before-height', `${this.buttonPosition.height}px`);
      root.style.setProperty('--after-width', `${this.buttonPosition.width + 5}px`);
      root.style.setProperty('--after-height', `${this.buttonPosition.height + 5}px`);
    },
  },
};
</script>

<style lang="sass">
root
  --before-width: 0
  --before-height: 0
  --after-width: 0
  --after-height: 0

.app-link
  position: relative
  padding: 0
  display: flex
  font-size: 16px
  font-weight: 700
  line-height: 24px
  color: $common-gray
  border: none
  border-bottom: 2px solid $light-color
  background-color: transparent
  cursor: pointer
  transition: all ease-in-out 0.2s
  svg path
    fill: currentcolor
  &:hover
    color: $main-color
    border-color: $main-color
  &:disabled
    cursor: initial
    pointer-events: none
    color: $common-gray
    opacity: 0.3
  &:focus
    &::before
      content: ""
      width: var(--before-width)
      height: var(--before-height)
      top: 2px
      bottom: 0
      left: -3px
      right: 0
      margin: auto
      position: absolute
      border-radius: 4px 4px 0px 0px
      border-style: solid
      border-color: #977CD9
    &::after
      content: ""
      width: var(--after-width)
      height: var(--after-height)
      top: 3px
      bottom: 0
      left: -5px
      right: 0
      margin: auto
      position: absolute
      border-radius: 6px
      border-style: solid
      border-color: #977CD9
  &.small
    font-size: 13px
    line-height: 16px
    svg
      width: 16px
    &::before, &::after
      border-color: #BEADE7
</style>
