<template>
  <section class="review-sent">
    <div class="img-container">
      <img :src="TalentGrowthPlan" />
    </div>
    <Loader v-if="loading" />
    <div v-else class="text-container">
      <app-title>{{ title }}</app-title>
      <p>{{ copyText }}</p>
      <app-button @click="$router.replace({ name: 'reviews' })">
        Back to all Talent Growth Plans
        <SvgArrowRight class="trailing" />
      </app-button>
    </div>
  </section>
</template>

<script>
import copyApi from '@/services/api/copy';
import CopyKey from '@/services/enums/copy-key.enum';
import ReviewStatus from '@/services/enums/review-status.enum';
import TalentGrowthPlan from '@/assets/img/talent-growth-plan.svg';
import SvgArrowRight from '@/assets/img/icons/24px/arrow-right.svg?inline';
import Loader from '../../components/Loader.vue';

export default {
  name: 'review-sent',
  components: { SvgArrowRight, Loader },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copyKey: this.status === ReviewStatus.PROCESSING ? CopyKey.REVIEW_SENT_FOR_BIAS_CHECK : CopyKey.REVIEW_COMPLETED,
      copyText: '',
      title: this.status === ReviewStatus.PROCESSING ? 'All set!' : 'Your Talent Growth Plan has been completed!',
      loading: true,
      TalentGrowthPlan,
    };
  },
  methods: {
    // Requête API : text copy
    async getCopyText() {
      this.loading = true;
      try {
        const response = await copyApi.getCopy(this.copyKey);
        this.copyText = response.data.copyText;
      } catch (error) {
        this.copyText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip';
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getCopyText();
    // Enlève le footer et l'espace supplémentaire du wrapper
    document.querySelector('footer').style = 'display: none';
    document.querySelector('.wrapper').style = 'padding-bottom: 0px';
  },
  destroyed() {
    // Remet le footer et l'espace supplémentaire du wrapper
    document.querySelector('.wrapper').style = 'padding-bottom: var(--wrapper-padding-bottom)';
    document.querySelector('footer').style = 'display: flex';
  },
};
</script>

<style lang="sass">
.review-sent
  height: 100vh
  display: flex
  align-items: center
  .img-container
    height: 100%
    background: $white
    padding: 0 2rem
    img
      transform: translateY(50%)
  .loader
    margin: 0 auto
  .text-container
    width: 40%
    max-width: 900px
    margin: 0 auto
    p
      color: $common-gray
      font-size: 20px
      margin-bottom: 3rem
</style>
