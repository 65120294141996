<template>
  <div class="form-question">
    <!-- Question metadata -->
    <p class="question-title">
      <b>{{ question.title }}</b>
      <small v-if="min === 0">   Recommended</small>
    </p>
    <label class="question-text" :for="question.questionId">
      {{ question.questionText }}
      <template v-if="!disabled && question.type === QuestionType.SELECT && max > 1"> (Max {{ max }})</template>
      <p><small v-if="!disabled && question.type === QuestionType.TEXT && question.answer.value">{{ question.answer.value.replace(/\s+/g, ' ').trim().split(' ').length }} / {{ max }} words</small></p>
    </label>
    <!-- Réponses inputs -->
    <app-input
      v-if="question.type === QuestionType.TEXT"
      :value="question.answer.value"
      :required="min > 0"
      :name="question.questionId"
      :min="min"
      :max="max"
      multiline
      :placeholder="question.placeholder"
      :disabled="disabled"
      errorMainColor
      @input="$emit('input', $event)"
    ></app-input>
    <div v-else class="options grid-x align-justify">
      <template v-if="max > 1">
        <app-checkbox
        v-for="option in question.options.values"
        :name="question.questionId"
        :min="min"
        :max="max"
        :key="option"
        :input="question.answer.value"
        :value="option"
        :disabled="disabled"
        errorMainColor
        class="cell"
        :class="{ 'small-12': question.options.values.length <= 6, 'small-5': question.options.values.length > 6 }"
        @input="$emit('input', $event)"
      >{{ option }}</app-checkbox>
      </template>
      <template v-else>
        <app-radio
          v-for="option in question.options.values"
          :name="question.questionId"
          required
          :key="option"
          :input="question.answer.value"
          :value="option"
          :disabled="disabled"
          errorMainColor
          class="cell"
          :class="{ 'small-12': question.options.values.length <= 6, 'small-5': question.options.values.length > 6 }"
          @input="$emit('input', $event)"
        >{{ option }}</app-radio>
      </template>
    </div>
    <div class="slot">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import QuestionType from '@/services/enums/question-type.enum';

export default {
  name: 'form-question',
  props: {
    question: { // Question
      type: Object,
      required: true,
    },
    // Si la question est éditable
    // (n'appliques pas directement disabled, mais seulement un style particulier pour permettre la validation)
    disabled: {
      type: Boolean,
      default: false,
    },
    min: Number, // Minimum appliqué (selon mandatoryDependency ou pas)
    max: Number, // Maximum appliqué (selon mandatoryDependency ou pas)
  },
  data() {
    return {
      QuestionType,
    };
  },
};
</script>

<style lang="sass">
.form-question
  background: $background-color
  border-radius: $global-border-radius
  padding: 1.5rem
  margin-top: 2rem
  color: $black
  display: flex
  align-items: stretch
  justify-content: flex-end
  flex-wrap: wrap
  .question-title
    width: 100%
  .question-text
    width: 35%
    padding-right: 1rem
  .app-input, .options
    width: 65%
    height: inherit
    min-height: 7rem
  .app-input
    textarea
      min-height: 10rem
    .helper-text
      margin: 0.3rem 0 0 0
  .options
    .app-checkbox, .app-radio
      margin: 0.2rem
      label
        background: $white
        border: 2px solid $light-color
        font-size: 11px
        padding: 0.5rem 1rem
        border-radius: $global-border-radius
        flex-grow: 1
      &:hover
        label
          border-color: $main-color
          cursor: pointer
          .checkmark
            border-color: $main-color
      &.disabled
        label
          background: $light-color
          cursor: initial
          border-color: $light-color
          .checkmark
            border-color: $light-color
      &.error
        label
          border-color: $main-color
        .error-text
          display: none
        &.small-12:last-child
          .error-text
            display: block
        &.small-5:nth-last-child(2)
          .error-text
            display: block
  &:first-child
    margin-top: 0

  .slot
    width: 65%
</style>
