<template>
  <section class="review-create">
    <app-link size="small" @click="handleBackClick"><arrowLeft/>Back to list</app-link>
    <app-title class="cell auto">New Talent Growth Plan</app-title>
    <div class="select-container">
      <div class="person-img">
        <person/>
      </div>
      <p>Select a talent to get started</p>
      <app-button @click="openModal = true"><plus/>Select talent</app-button>
    </div>

    <!-- Modal -->
    <app-modal :show="openModal" @update:show="openModal = false" title="Select talent">
      <talent-select @update:selected-talent="updateTalent" selectingOwnedTalent/>
      <template v-slot:footer >
        <app-button kind="minimal" @click="openModal = false">Cancel</app-button>
        <app-button kind="primary" @click="createReview" :disabled="!selectedTalent">Select and create TGP</app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import api from '@/services/api/review';
import arrowLeft from '../../assets/img/icons/24px/arrow-left.svg?inline';
import person from '../../assets/img/person.svg?inline';
import plus from '../../assets/img/icons/24px/plus.svg?inline';
import TalentSelect from '../talents/TalentSelect.vue';

export default {
  name: 'review-create',
  components: {
    arrowLeft,
    person,
    plus,
    TalentSelect,
  },
  data() {
    return {
      openModal: false,
      selectedTalent: null,
    };
  },
  methods: {
    handleBackClick() {
      this.$router.go(-1);
    },
    updateTalent(talent) {
      this.selectedTalent = talent;
    },
    async createReview() {
      if (this.selectedTalent) {
        try {
          const review = await api.createCycleless(this.selectedTalent.talentId);
          this.$router.push({ name: 'review', params: { reviewId: review.data.reviewId } });
        } catch (error) {
          this.openModal = false;
          const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
          this.$message.show({
            title: 'Error',
            text: `Review couldn't be created. ${messages}`,
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      }
    },
  },
};
</script>

<style lang="sass">
.review-create
  @include screen
  .app-link
    margin-top: 24px
  .app-title
    margin-top: 16px
  .select-container
    height: 65vh
    max-height: 670px
    background: white
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .person-img
      width: 140px
    p
      margin: 2rem 0
      color: $link-color
</style>
