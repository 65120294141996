const CopyKeyLabel = Object.freeze({
  ONBOARDING_START_1: 'ONBOARDING_START_1',
  ONBOARDING_START_2: 'ONBOARDING_START_2',
  ONBOARDING_END: 'ONBOARDING_END',
  CYCLE_SETUP_START: 'CYCLE_SETUP_START',
  CYCLE_SETUP_END: 'CYCLE_SETUP_END',
  REVIEW_SENT_FOR_BIAS_CHECK: 'REVIEW_SENT_FOR_BIAS_CHECK',
  REVIEW_COMPLETED: 'REVIEW_COMPLETED',
  NUDGE_IMPACT_TODAY: 'NUDGE_IMPACT_TODAY',
  NUDGE_IMPACT_TOMORROW: 'NUDGE_IMPACT_TOMORROW',
  NUDGE_PROMOTABILITY: 'NUDGE_PROMOTABILITY',
  NUDGE_GROWTH_IDEAS: 'NUDGE_GROWTH_IDEAS',
  NUDGE_SUMMARY: 'NUDGE_SUMMARY',
});

export default CopyKeyLabel;
