import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/answers`;

/**
 * Updates an answer
 * @param {string} answerId : id of the answer we're trying to get
 */
const updateAnswer = async (answerId, value) => {
  let newValue = value;
  if (!Array.isArray(newValue)) {
    newValue = newValue ? [newValue] : [];
  }
  try {
    const response = await axios.put(`${API_URL}/${answerId}`, {
      value: newValue,
    });
    return response.data;
  } catch (error) { throw error; }
};

const api = {};
api.updateAnswer = updateAnswer;

export default api;
