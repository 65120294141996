<template>
  <section class="assign-owners">
    <!-- Barre de recherche avec boutons de filtres -->
    <div>
      <div class="search-bar">
        <app-search placeholder="Search..." v-model="searchName" :disabled="editingGroup"></app-search>
        <div class="clear-filters-container">
          <app-button v-if="filtersCount > 0" kind="minimal" size="medium" class="clear-filters-button" @click="$refs.talentFilters.clearAll">
            <SvgCross class="leading" />
            Clear filters
          </app-button>
        </div>
        <app-button kind="minimal" size="medium" @click="openFilters = true" :disabled="editingGroup">
          Filters {{ filtersCount ? `(${filtersCount})` : '' }}
          <SvgFilter class="trailing" />
        </app-button>
      </div>

      <div class="table-container">
        <app-table class="table" :loading="loading">
          <template v-slot:headers>
            <div class="cell shrink">
              <app-tooltip position="right" :class="{ active: stepIndex === 0 }">
                <div class="step">1/ 3</div>
                <p class="title">Select a group of people</p>
                <p class="text">You can bulk assign authors by selecting multiple people from the list.</p>
                <template v-slot:app-tooltip-wrapper>
                  <app-checkbox :input="selectedAll" @input="toggleAll" :disabled="editingGroup"></app-checkbox>
                </template>
              </app-tooltip>
            </div>
            <div class="cell auto">Name <SvgTriangle /></div>
            <div class="cell auto">Role title</div>
            <div class="cell auto">Function</div>
            <div class="cell auto">Owned by</div>
          </template>
          <template v-slot:body>
            <div class="row grid-x align-middle" v-for="talent in talents" :key="talent.talentId">
              <div class="cell shrink">
                <app-checkbox :input="isSelected(talent)" @input="toggleTalent(talent)" :disabled="editingGroup"></app-checkbox>
              </div>
              <div class="cell auto talent-name">
                <talent-icon :user="talent"></talent-icon>
                <div class="talent-info">
                  <div>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</div>
                  <div class="talent-email cell">{{ talent.email }}</div>
                </div>
              </div>
              <div class="cell auto">{{ talent.talentVersion.role }}</div>
              <div class="cell auto">{{ talent.talentVersion.function }}</div>
              <div class="cell auto">
                <talent-icon v-if="talent.owner" :user="talent.owner" isLong></talent-icon>
                <div v-else class="pending-owner"></div>
              </div>
            </div>
          </template>
        </app-table>
        <pagination :limit="metadata.limit" :offset="metadata.offset" :count="metadata.count" @update:page="getTalents($event.offset, $event.limit)"/>
        <!-- Filtres de talents -->
        <talent-filters :show="openFilters" @close="openFilters = false" ref="talentFilters"></talent-filters>
      </div>
    </div>

    <app-sticky-footer :type="selectedTalents.length ? 'default' : 'actions'">
      <template v-if="selectedTalents.length">
        {{ selectedTalents.length }} talents selected
        <div>
          <app-button v-if="selectedTalents.length" kind="secondary" @click="selectedTalents = []" :disabled="editingGroup">Deselect</app-button>
          <app-tooltip position="top" :class="{ active: stepIndex === 1 }">
            <div class="step">2 / 3</div>
            <p class="title">Assign author</p>
            <p class="text">Click here to assign an author to the selected talent.</p>
            <template v-slot:app-tooltip-wrapper>
              <app-button :disabled="editingGroup || !selectedTalents.length" @click="assignOwnerModal.open = true; stepIndex += 1">Assign Author</app-button>
            </template>
          </app-tooltip>
        </div>
      </template>
      <template v-else>
        <app-button kind="secondary" @click="$emit('update:step', -1)">Back</app-button>
        <app-button @click="$emit('update:step', 1)">Finish</app-button>
      </template>
    </app-sticky-footer>

    <!-- Modale select owner pour un group de talents -->
    <app-modal :show="assignOwnerModal.open" @update:show="assignOwnerModal.open = false" title="Select author" class="select-owner">
      <talent-select @update:selected-talent="assignOwnerModal.owner = $event; stepIndex += 1"></talent-select>
      <template v-slot:footer>
        <app-button kind="minimal" @click="assignOwnerModal.open = false">Cancel</app-button>
        <app-button @click="assignOwnerToSelected()">Save</app-button>
      </template>
      <template v-slot:modal-overflowing>
        <app-tooltip position="top" :class="{ active: stepIndex === 2 }" class="tooltip-3">
          <div class="step">3 / 3</div>
          <p class="title">Select an author from your talent list</p>
          <p class="text">Assigning talents as authors to themselves is not recommended.</p>
        </app-tooltip>
      </template>
    </app-modal>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import talentApi from '@/services/api/talent';
import utils from '@/services/utils';

import SvgFilter from '@/assets/img/icons/24px/filter.svg?inline';
import SvgCross from '@/assets/img/icons/24px/cross.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

// Components
import TalentIcon from '@/components/TalentIcon.vue';
import Pagination from '@/components/Pagination.vue';
import TalentFilters from '@/views/talents/TalentFilters.vue';
import TalentSelect from '@/views/talents/TalentSelect.vue';

export default {
  components: {
    SvgCross,
    SvgFilter,
    SvgTriangle,
    TalentIcon,
    Pagination,
    TalentFilters,
    TalentSelect,
  },
  data() {
    return {
      searchName: '',
      talents: [],
      user: authApi.getUser(),
      metadata: {
        offset: 0,
        limit: 5,
        count: 0,
      },
      loading: false,
      openFilters: false,
      // Data for group Edition
      editingGroup: false,
      selectedTalents: [],
      groupingRequestsNumber: process.env.VUE_APP_GROUP_REQUESTS_NUMBER,
      groupingRequestsInterval: process.env.VUE_APP_GROUP_REQUESTS_INTERVAL,
      assignOwnerModal: {
        open: false,
        owner: {},
      },
      assignOwnerTimeout: null,
      stepIndex: 0,
    };
  },
  computed: {
    filtersCount() {
      const timeInRole = utils.initArray(this.$route.query.timeInRole);
      const timeInCompany = utils.initArray(this.$route.query.timeInCompany);
      const seniority = utils.initArray(this.$route.query.seniority);
      const filterFunction = utils.initArray(this.$route.query.filterFunction);
      const filterOwner = utils.initArray(this.$route.query.filterOwner);
      const filterCountry = utils.initArray(this.$route.query.filterCountry);
      return filterFunction.length + filterOwner.length + filterCountry.length + timeInRole.length + timeInCompany.length + seniority.length;
    },
    selectedAll() {
      return this.talents.every((talent) => this.selectedTalents.find((t) => t.talentId === talent.talentId));
    },
  },
  watch: {
    $route() {
      this.getTalents(this.$route.query.limit, this.$route.query.offset);
    },
    searchName() {
      this.debouncedUpdateQuery();
    },
    selectedTalents() {
      if (this.stepIndex === 0 && this.selectedTalents.length) {
        this.stepIndex = 1;
      }
    },
  },
  mounted() {
    this.getTalents();
    this.debouncedUpdateQuery = utils.debounce(() => {
      this.getTalents(0);
    }, 400);
  },
  beforeDestroy() {
    clearTimeout(this.assignOwnerTimeout);
  },
  methods: {
    async getTalents(offset = this.metadata.offset, limit = this.metadata.limit) {
      this.loading = true;
      try {
        const response = await talentApi.getTalents(
          limit,
          offset,
          this.user.companyId,
          null,
          this.searchName,
          null,
          this.$route.query.filterCountry,
          this.$route.query.filterFunction,
          this.$route.query.timeInRole,
          this.$route.query.timeInCompany,
          this.$route.query.seniority,
          this.$route.query.filterOwner,
        );
        this.talents = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the talents. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    // Si le talent est sélectionné
    isSelected(talent) {
      const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      return index >= 0;
    },
    // Toggle la sélection d'un talent
    toggleTalent(talent) {
      const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      if (index < 0) {
        this.selectedTalents.push(talent);
      } else {
        this.selectedTalents.splice(index, 1);
      }
    },
    // Sélectionnes ou Déselectionne tous les talents affichés
    toggleAll() {
      if (this.selectedAll) {
        this.talents.forEach((talent) => this.toggleTalent(talent));
      } else {
        this.talents.forEach((talent) => !this.isSelected(talent) && this.selectedTalents.push(talent));
      }
    },
    // Assignes un owner à tous les talents sélectionnés, par groupe
    async assignOwnerToSelected() {
      this.editingGroup = true;
      // Extrait les 10 premiers talents sélectionnés
      let talents = [...this.selectedTalents];
      if (talents.length > this.groupingRequestsNumber) {
        talents = talents.splice(0, this.groupingRequestsNumber);
      }
      // Construction du body
      const body = { ownerId: this.assignOwnerModal.owner.talentId };
      this.assignOwnerModal.open = false;
      // Les supprime en mettant à jour le data
      try {
        await Promise.all(talents.map(async (talent) => {
          await talentApi.editTalent(talent.talentId, body);
          const index = this.selectedTalents.findIndex((t) => talent.talentId === t.talentId);
          this.selectedTalents.splice(index, 1);
        }));
      } catch (e) {
        this.getTalents();
        const { message } = e.response.data;
        this.$message.show({
          title: 'Error',
          text: `Some talents could not be updated: \n ${Array.isArray(message) ? message.join('\n') : message}`,
        });
        this.editingGroup = false;
        this.assignOwnerModal.owner = {};
        return;
      }
      // S'il reste des talents sélectionnés, recommencer après un certain temps
      if (this.selectedTalents.length) {
        this.assignOwnerTimeout = setTimeout(this.assignOwnerToSelected, this.groupingRequestsInterval);
      } else {
        // Sinon, finir l'edition
        this.$notification.show({ text: 'Assigned author to all selected talents successfully' });
        this.editingGroup = false;
        this.assignOwnerModal.owner = {};
        this.getTalents();
      }
    },
  },
};
</script>

<style lang="sass">
.assign-owners
  height: 100%
  display: flex
  flex-direction: column
  > div:first-child
    flex-grow: 2
    .search-bar
      display: flex
      align-items: center
      justify-content: space-between
      background: $white
      padding: 16px 24px
      margin: 8px 0
      .app-search
        width: 50%
      .clear-filters-container
        flex-grow: 1
        display: flex
        justify-content: flex-end
        margin: 0 0.5rem
    .table-container
      @include table-container
      .table
        .app-checkbox
          margin-right: 0.6rem
        .app-tooltip-container
          transform: translateX(-0.6rem)
        .body .row
          @include clickable-row
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .pending-owner
            @include pending-owner
  .app-sticky-footer
    justify-self: flex-end

  .app-tooltip
    .app-tooltip-container
      @include tooltip-with-step
      display: flex
      flex-direction: column
      align-items: flex-start
    &:not(.active)
      .app-tooltip-container
        display: none

  .select-owner
    .app-tooltip
      position: absolute
      top: 0
      left: 0
      right: 0
      .app-tooltip-container
        top: 25px
  .svg-triangle
    margin: 0 0 0 10px
</style>
