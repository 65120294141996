<template>
  <section class="multiple-talents-select">
    <div class="search-bar">
      <app-search placeholder="Search..." v-model="searchName"></app-search>
      <div class="clear-filters-container">
        <app-button v-if="filtersCount > 0" kind="minimal" size="medium" class="clear-filters-button" @click="$refs.talentFilters.clearAll">
          <svg-cross class="leading" />
          Clear filters
        </app-button>
      </div>
      <app-button kind="minimal" size="medium" @click="openFilters = true">
        Filters {{ filtersCount ? `(${filtersCount})` : '' }}
        <svg-filter class="trailing" />
      </app-button>
    </div>
    <div class="table-container">
      <app-table class="table" :loading="loading">
        <template v-slot:headers>
          <div class="cell shrink talent-selector">
            <app-checkbox :input="globalSelect" @input="addOrRemoveAllTalents"/>
          </div>
          <div class="cell auto">Name <svg-triangle /></div>
          <div class="cell auto">Role title</div>
          <div class="cell auto">Function</div>
          <div class="cell auto">Owned by</div>
        </template>
        <template v-slot:body>
          <div class="row grid-x align-middle talents-line" v-for="talent in talents" :key="talent.talentId" :class="{ error: errors.find((e) => e.talentId === talent.talentId) }">
            <div class="cell shrink talent-selector">
              <app-checkbox :input="!!selectedTalents.find((t) => t.talentId === talent.talentId)" @input="addOrRemoveTalent(talent)"/>
            </div>
            <div class="cell auto talent-name">
              <talent-icon :user="talent"></talent-icon>
              <div class="talent-info">
                <div>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</div>
                <div class="talent-email cell">{{ talent.email }}</div>
              </div>
            </div>
            <div class="cell auto">{{ talent.talentVersion.role }}</div>
            <div class="cell auto">{{ talent.talentVersion.function }}</div>
            <div class="cell auto">
              <talent-icon v-if="talent.owner" :user="talent.owner" isLong></talent-icon>
              <div v-else class="pending-owner"></div>
            </div>
          </div>
        </template>
      </app-table>
      <pagination :limit="metadata.limit" :offset="metadata.offset" :count="metadata.count" @update:page="changePage"/>
    </div>
    <!-- Filtres de talents -->
    <talent-filters :show="openFilters" @close="openFilters = false" ref="talentFilters"></talent-filters>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import talentApi from '@/services/api/talent';
import utils from '@/services/utils';

// SVG
import SvgFilter from '@/assets/img/icons/24px/filter.svg?inline';
import SvgCross from '@/assets/img/icons/24px/cross.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

// Components
import TalentIcon from './TalentIcon.vue';
import Pagination from './Pagination.vue';
import TalentFilters from '../views/talents/TalentFilters.vue';

export default {
  name: 'multiple-talents-select',
  components: {
    TalentIcon,
    Pagination,
    TalentFilters,
    'svg-filter': SvgFilter,
    'svg-cross': SvgCross,
    'svg-triangle': SvgTriangle,
  },
  props: {
    oldSelectedTalents: {
      type: Array,
      default() {
        return [];
      },
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      searchName: this.$route.query.searchName || '',
      talents: [],
      user: authApi.getUser(),
      metadata: {
        offset: +this.$route.query.offset || 0,
        limit: +this.$route.query.limit || 5,
        count: 0,
      },
      loading: false,
      oneSmallMenuIsOpened: false,
      openFilters: false,

      selectedTalents: [],
      globalSelect: [],
    };
  },
  computed: {
    filtersCount() {
      const timeInRole = utils.initArray(this.$route.query.timeInRole);
      const timeInCompany = utils.initArray(this.$route.query.timeInCompany);
      const seniority = utils.initArray(this.$route.query.seniority);
      const filterFunction = utils.initArray(this.$route.query.filterFunction);
      const filterOwner = utils.initArray(this.$route.query.filterOwner);
      const filterCountry = utils.initArray(this.$route.query.filterCountry);
      return filterFunction.length + filterOwner.length + filterCountry.length + timeInRole.length + timeInCompany.length + seniority.length;
    },
  },
  watch: {
    $route() {
      this.getTalents(this.$route.query.limit, this.$route.query.offset);
    },
    searchName() {
      this.debouncedUpdateQuery({ offset: 0, searchName: this.searchName });
    },
    oldSelectedTalents() {
      this.selectedTalents = this.oldSelectedTalents.map((ot) => ot);
      this.verifyGlobalSelect();
    },
  },
  mounted() {
    this.getTalents(this.metadata.limit, this.metadata.offset);
    this.debouncedUpdateQuery = utils.debounce((query) => {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    }, 400);
    this.selectedTalents = this.oldSelectedTalents.map((ot) => ot);
  },
  methods: {
    changePage({ limit, offset }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    async getTalents(limit, offset) {
      this.loading = true;
      try {
        const response = await talentApi.getTalents(
          limit,
          offset,
          this.user.companyId,
          null,
          this.searchName,
          null,
          this.$route.query.filterCountry,
          this.$route.query.filterFunction,
          this.$route.query.timeInRole,
          this.$route.query.timeInCompany,
          this.$route.query.seniority,
          this.$route.query.filterOwner,
        );
        this.talents = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the talents. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
      this.verifyGlobalSelect();
    },
    addOrRemoveTalent(talent) {
      const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      if (index < 0) {
        this.selectedTalents.push(talent);
      } else {
        this.selectedTalents.splice(index, 1);
      }
      this.$emit('update:input', this.selectedTalents);
      this.verifyGlobalSelect();
    },
    addOrRemoveAllTalents() {
      let allPageIsSelected = true;
      this.talents.forEach((talent) => {
        if (!this.selectedTalents.find((t) => t.talentId === talent.talentId)) {
          allPageIsSelected = false;
        }
      });
      if (!allPageIsSelected) {
        this.talents.forEach((talent) => {
          if (!this.selectedTalents.find((t) => t.talentId === talent.talentId)) {
            this.selectedTalents.push(talent);
          }
        });
      } else {
        this.talents.forEach((talent) => {
          const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
          this.selectedTalents.splice(index, 1);
        });
      }
      this.$emit('update:input', this.selectedTalents);
      this.verifyGlobalSelect();
    },
    verifyGlobalSelect() {
      let allPageIsSelected = true;
      this.talents.forEach((talent) => {
        if (!this.selectedTalents.find((t) => t.talentId === talent.talentId)) {
          allPageIsSelected = false;
        }
      });
      this.globalSelect = allPageIsSelected;
    },
  },
};
</script>

<style lang="sass">
.multiple-talents-select
  .search-bar
    display: flex
    align-items: center
    justify-content: space-between
    background: $white
    padding: 16px 24px
    margin: 8px 0
    .app-search
       width: 50%
    .clear-filters-container
      flex-grow: 1
      display: flex
      justify-content: flex-end
      margin: 0 0.5rem
  .table-container
    @include table-container
    .table
      .talent-selector
        padding-right: 16px
        .app-checkbox input:not(:checked) ~ span
          border: 2px solid $medium-gray
      .body .row .cell:last-child, .headers .cell:last-child
        width: 2rem
        position: relative
      .body .row
          @include clickable-row
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .pending-owner
            @include pending-owner
          .overflow-button
            @include icon-button
            width: 2.5rem
            height: 2.5rem
      .talents-line
        &.error
          background-color: $error-bg-color
          border: 2px solid $error-color
  .svg-triangle
    margin: 0 0 0 10px
</style>
