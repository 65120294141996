<template>
  <section class="review-pdf">
    <app-spinner v-if="loading"></app-spinner>
    <template v-else-if="review">
      <div class="cover">
        <div class="cover-header">
          <app-title><b>Talent Growth Plan:</b> {{ review.talent.firstname}} {{ review.talent.lastname }}</app-title>
        </div>
        <div class="cover-content">
          <div class="cycle details">
            <p>Talent growth Plan details</p>
            <div class="details-content">
              <div class="details-line"><b>Cycle</b>{{ review.cycle ? review.cycle.name : 'N/A' }}</div>
              <div class="details-line"><b>Cycle start date</b>{{ review.cycle ? utils.formatDate(review.cycle.startDate) : 'N/A' }}</div>
              <div class="details-line"><b>Cycle</b>{{ review.cycle ? utils.formatDate(review.cycle.endDate) : 'N/A' }}</div>
            </div>
          </div>
          <div class="talent details">
            <p>Talent details</p>
            <div class="details-content">
              <div class="details-line"><b>Name</b>{{ review.talent.firstname  | capitalize }}</div>
              <div class="details-line"><b>Surname</b>{{ review.talent.lastname | uppercase }}</div>
              <div class="details-line"><b>Role title</b>{{ review.talent.talentVersion.role }}</div>
              <div class="details-line"><b>Function</b>{{ review.talent.talentVersion.function }}</div>
              <div class="details-line"><b>Author</b>{{ review.writer.firstname }} {{ review.writer.lastname }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section" v-for="(section, index) in sections" :key="section.sectionKey" :style="{ '--page-number': index }">
        <p class="section-title">{{ section.sectionLabel }}</p>
        <div class="question-container" v-for="question in section.questions" :key="question.questionId">
          <p class="question-title">{{ question.title }}</p>
          <p class="question-text">{{ question.questionText }}</p>
          <template v-if="question.type === QuestionType.TEXT">
            <p class="answer-text">{{ question.answer.value }}</p>
          </template>
          <div v-else class="grid-x">
            <template v-if="question.max > 1">
              <app-checkbox
                v-for="option in question.options.values"
                :name="question.questionId"
                :key="option"
                :input="question.answer.value"
                :value="option"
                class="cell small-12"
                disabled>
                {{ option }}
              </app-checkbox>
            </template>
            <template v-else>
              <app-radio
                v-for="option in question.options.values"
                :name="question.questionId"
                required
                :key="option"
                :input="question.answer.value"
                :value="option"
                disabled
                class="cell small-12"
              >{{ option }}</app-radio>
            </template>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import reviewApi from '@/services/api/review';
import utils from '@/services/utils';
import FormSectionLabel from '@/services/enums/form-section-label.enum';
import QuestionType from '@/services/enums/question-type.enum';

export default {
  name: 'review-pdf',
  data() {
    return {
      review: null,
      loading: false,
      sections: [],
      utils,
      FormSectionLabel,
      QuestionType,
    };
  },
  methods: {
    // Requête API : récupère la review
    async getReview() {
      this.loading = true;
      const response = await reviewApi.getReview(this.$route.params.reviewId);
      this.review = response.data;
      this.setSections();
      this.loading = false;
    },
    // Organise les questions en sections
    setSections() {
      this.sections = Object.entries(FormSectionLabel).map(([sectionKey, sectionLabel]) => {
        const questions = this.review.questions
          .filter((question) => question.section === sectionKey)
          .sort((q1, q2) => q1.questionOrder - q2.questionOrder);
        return {
          sectionKey,
          sectionLabel,
          questions,
        };
      });
    },
  },
  created() {
    this.getReview();
  },
};
</script>

<style lang="sass">
.review-pdf
  background-color: $white
  padding: 0 ! important
  .cover
    .cover-header
      background-color: $background-color
      padding: 32px 64px
      .app-title
        font-weight: 400
        b
          font-weight: 700
    .cover-content
      padding: 32px 64px
      .details
        padding: 32px 0
        p
          color: $main-color
          text-transform: uppercase
          margin-top: 0
          font-size: 12px
          font-weight: 900
          letter-spacing: 1.5px
        .details-line
          margin: 27px 0
          color: $common-gray
          b
            display: inline-block
            width: 300px
            color: $black
        &:first-child
          border-bottom: 2px solid $light-color
  .section
    page-break-before: always
    padding: 0 64px
    .section-title
      color: $main-color
      font-weight: 700
      font-size: 18px
    .question-container
      page-break-inside: avoid
      break-inside: avoid
      display: inline-block
      width: 100%
      background: $background-color
      padding: 22px 24px
      margin: 15px 0
      .question-title
        font-weight: 700
        font-size: 18px
        margin-top: 0
      .question-text
        font-size: 12px
      .answer-text
        background: $light-color
        padding: 13px 16px
        color: $common-gray
        font-size: 12px
      .app-checkbox, .app-radio
        margin: 0.2rem
        label
          border: 2px solid $light-color
          font-size: 11px
          padding: 0.5rem 1rem
          border-radius: $global-border-radius
          flex-grow: 1
          background: $light-color
          .checkmark
            border-color: $light-color
@media print
.review-pdf
  -webkit-print-color-adjust: exact
  @page
    size: A4 portait

</style>
