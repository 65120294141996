<template>
  <ul class="bubble-chart-legend" :class="[`direction-${direction}`, isPDF ? 'is-pdf' : '']">
    <li v-for="item in legend" :key="item.key" :class="`${type}-legend`">
      <div>
        <span :class="[`legend-${type}`, item.class]"></span>
        <span class="legend-label">
          <b>{{ item.label | capitalize }}</b>
          <template v-if="type === 'color' && direction != 'row'"> of bias in <template v-if="!item.data.length">segment</template></template>
          <template v-else-if="type === 'size'">
            <br v-if="direction === 'row'">
            of talents concerned
          </template>
        </span>
      </div>
      <ul v-if="item.data">
        <li v-for="data in item.data" :key="data.label">
          {{ data.label }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import RiskLevelLabel from '@/services/enums/risk-level-label.enum';

export default {
  name: 'bubble-chart-legend',
  props: {
    type: {
      type: String,
      validator: (val) => ['color', 'size'].includes(val),
      default: 'color',
      required: true,
    },
    direction: {
      type: String,
      validator: (val) => ['column', 'row'].includes(val),
      default: 'column',
    },
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      isPDF: this.$route.meta.isPDF,
      bulletSizes: [
        {
          label: '70 to 100%',
          key: 'very-large',
          class: 'size-very-large',
        },
        {
          label: '40 to 70%',
          key: 'large',
          class: 'size-large',
        },
        {
          label: '20 to 40%',
          key: 'medium',
          class: 'size-medium',
        }, {
          label: '0 to 20%',
          key: 'small',
          class: 'size-small',
        },
      ],
    };
  },
  computed: {
    legend() {
      switch (this.type) {
        case 'color': {
          const legend = Object.entries(RiskLevelLabel)
            .map(([key, label]) => ({
              key,
              label: `${label} risk`,
              class: `risk-${key.toLowerCase()}`,
              data: this.data.filter((dataline) => dataline.level === key),
            }));
          return this.data.length ? legend.filter((item) => item.data.length) : legend;
        }
        case 'size':
          return this.bulletSizes;
        default: return [];
      }
    },
    dataByLevel() {
      return Object.entries(RiskLevelLabel)
        .map(([key, value]) => ({
          level: key,
          label: value,
          class: key.toLowerCase().replace('_', '-'),
          data: this.data.filter((dataline) => dataline.level === key),
        }))
        .filter((risk) => risk.data.length);
    },
  },
};
</script>

<style lang="sass">
.bubble-chart-legend
  list-style: none
  > li
    margin: 1rem 0
    > div
      display: flex
      align-items: center
      gap: 0.7rem
      .legend-color
        display: inline-block
        width: 16px
        height: 16px
        border-radius: 50%
        opacity: 0.5
        &.risk-very_high
          background-color: $error-color
        &.risk-high
          background-color: $common-orange
        &.risk-medium
          background-color: $common-yellow
        &.risk-low
          background-color: $success-color
    ul
      list-style-type: disc
      color: $common-gray
  li.size-legend > div
    display: grid
    grid-template-columns: 35px 1fr
    font-size: 11px
    b
      font-size: 13px
    .legend-size
      display: inline-block
      width: 16px
      height: 16px
      border-radius: 50%
      opacity: 0.5
      background-color: $error-color
      justify-self: center
      &.size-very-large
        width: 34px
        height: 34px
      &.size-large
        width: 22px
        height: 22px
      &.size-medium
        width: 16px
        height: 16px
      &.size-small
        width: 8px
        height: 8px
  &.direction-column
    padding: 0
  &.direction-row
    margin: 0
    display: flex
    gap: 8px
    background-color: $background-color
    font-size: 13px
    padding: 8px
    li
      margin: 0

  &.is-pdf
    .legend-color, .legend-size
      transform: scale(0.5)
</style>
