<template>
  <div class="app-gauge-scale" :class="[{ small }, { pdf }]">
    <div class="scale important start">
      <p>50</p>
      <div class="scale-infos" v-if="!small">
        Bias against segment
      </div>
    </div>
    <div class="scale intermediate">
      <p>25</p>
    </div>
    <div class="scale important">
      <p>0</p>
      <div class="scale-infos">
        Objective / unbiased reviews
      </div>
    </div>
    <div class="scale intermediate">
      <p>25</p>
    </div>
    <div class="scale important end">
      <p>50</p>
      <div class="scale-infos" v-if="!small">
        Bias against segment
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // TODO : Pareil que le commentaire dans AppGaugeChart.vue
    // Changements à effectuer :
    //    les v-if="!small" en v-if="size === 'small'"
    //    faire apparaitre les .scale.intermediate lorsque la taille est "medium"
    //    sinon reprendre le même style que la version "small"
    //    ne pas oublier de changer l'attribution de la classe de .app-gauge-scale
    small: {
      type: Boolean,
      default: false,
    },
    pdf: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.app-gauge-scale
  width: 100%
  max-width: 640px
  display: flex
  justify-content: space-between
  position: relative
  padding-bottom: 30px
  .scale
    position: relative
    p
      font-size: 16px
      color: $common-gray
    .scale-infos
      position: absolute
      width: 200px
      color: $common-gray
      left: calc(-100px + 50%)
      bottom: -25px
      text-align: center
      &:before
        content: ''
        width: 0
        height: 0
        border-style: solid
        border-width: 0 5px 6px 5px
        border-color: transparent transparent $common-gray transparent
        position: absolute
        top: -14px
        left: calc(50% - 5px)
    &.important
      p
        font-weight: 700
    &:before
      content: ''
      display: block
      width: 6px
      height: 6px
      background: $medium-gray
      position: absolute
      border-radius: 3px
      top: -10px
      left: calc(50% - 3px)
  &:before
    content: ''
    display: block
    width: calc(100% - 20px)
    height: 2px
    background: $light-color
    position: absolute
    top: calc(-10px + 2px)
    left: 10px
  &.small
    width: 100%
    .scale:not(.important)
      display: none
    .scale.start
      &:before
        left: 0
    .scale.end
      &:before
        left: calc(100% - 6px)
    &:before
      width: 100%
      left: 0
    &.pdf
      .scale
        font-size: 12px
        p
          font-size: 12px
  &.pdf:not(.small)
    transform: scale(0.58)
</style>
