import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/thresholds`;

const getThresholds = async () => {
  try {
    const response = await axios.get(`${API_URL}`);
    return response.data;
  } catch (error) { throw error; }
};

const updateThreshold = async (thresholdId, body) => {
  try {
    const response = await axios.put(`${API_URL}/${thresholdId}`, body);
    return response.data;
  } catch (error) { throw error; }
};

const api = {};
api.getThresholds = getThresholds;
api.updateThreshold = updateThreshold;

export default api;
