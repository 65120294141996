<template>
  <form class="talent-profile" :class="{ disabled: !editMode, loading }" ref="talentProfileForm">
    <Loader v-if="loading" />
    <template v-else>
      <div class="form-section">
        <p class="title">PERSONAL DETAILS</p>
        <div class="form-input">
          <label><b>Firstname</b></label>
          <app-input v-if="editMode" v-model="talent.firstname" placeholder="First name" type="text" required></app-input>
          <p class="value" v-else>{{ initialTalent.firstname | capitalize }}</p>
        </div>
        <div class="form-input">
          <label><b>Surname</b></label>
          <app-input v-if="editMode" v-model="talent.lastname" placeholder="Surname" type="text" required></app-input>
          <p class="value" v-else>{{ initialTalent.lastname | uppercase }}</p>
        </div>
      </div>
      <div class="form-section">
        <p class="title">Demographics</p>
        <div class="form-input">
          <label>
            <b>Age</b><br>
            {{ editMode ? 'Recommended' : '' }}</label>
          <app-select v-if="editMode" v-model="talent.talentVersion.ageGroup" :options="ageGroupOptions"></app-select>
          <p class="value" v-else>{{ AgeGroupLabel[initialVersion.ageGroup] || 'N/A' }}</p>
        </div>
        <div class="form-input">
          <label><b>Gender</b></label>
          <app-select v-if="editMode" v-model="talent.talentVersion.gender" :options="genderOptions" required></app-select>
          <p class="value" v-else>{{ GenderLabel[initialVersion.gender] }}</p>
        </div>
        <div class="form-input">
          <label><b>Nationality</b></label>
          <app-select v-if="editMode" v-model="talent.talentVersion.nationality" :options="nationalityOptions" required autocomplete></app-select>
          <p class="value" v-else>{{ NationalityLabel[initialVersion.nationality] }}</p>
        </div>
        <div class="form-input">
          <label>
            <b>Ethnicity</b><br>
            {{ editMode ? 'Recommended' : '' }}</label>
          <app-select v-if="editMode" v-model="talent.talentVersion.ethnicity" :options="ethnicityOptions"></app-select>
          <p class="value" v-else>{{ EthnicityLabel[initialVersion.ethnicity] || 'N/A' }}</p>
        </div>
        <div class="form-input">
          <label><b>Country of residence</b></label>
          <app-select v-if="editMode" v-model="talent.talentVersion.country" :options="countryOptions" required autocomplete></app-select>
          <p class="value" v-else>{{ CountryLabel[initialVersion.country] }}</p>
        </div>
      </div>
      <div class="form-section">
        <p class="title">Role</p>
        <div class="form-input">
          <label><b>Role title</b></label>
          <app-input v-if="editMode" v-model="talent.talentVersion.role" placeholder="Accountant, Brand manager, …" required></app-input>
          <p class="value" v-else>{{ initialVersion.role }}</p>
        </div>
        <div class="form-input">
          <label><b>Fonction</b></label>
          <app-input v-if="editMode" v-model="talent.talentVersion.function" placeholder="Finance, Marketing, …" required></app-input>
          <p class="value" v-else>{{ initialVersion.function }}</p>
        </div>
        <div class="form-input">
          <label>
            <b>Time in Role</b><br/>
            {{ editMode ? 'Recommended' : '' }}
          </label>
          <app-select v-if="editMode" v-model="talent.talentVersion.timeInRole" :options="timeInRoleOptions"></app-select>
          <p class="value" v-else>{{ TimeInRoleLabel[initialVersion.timeInRole] }}</p>
        </div>
        <div class="form-input">
          <label>
            <b>Time in Company</b><br/>
            {{ editMode ? 'Recommended' : '' }}
          </label>
          <app-select v-if="editMode" v-model="talent.talentVersion.timeInCompany" :options="timeInCompanyOptions"></app-select>
          <p class="value" v-else>{{ TimeInCompanyLabel[initialVersion.timeInCompany] }}</p>
        </div>
        <div class="form-input">
          <label><b>Seniority level in the Company</b></label>
          <app-select v-if="editMode" v-model="talent.talentVersion.seniority" :options="seniorityOptions" required></app-select>
          <p class="value" v-else>{{ SeniorityLabel[initialVersion.seniority] }}</p>
        </div>
        <div class="form-input">
          <label><b>International Mobility</b></label>
          <app-select v-if="editMode" v-model="talent.talentVersion.mobility" :options="mobilityOptions" required></app-select>
          <p class="value" v-else>{{ MobilityLabel[initialVersion.mobility] }}</p>
        </div>
      </div>
      <div class="form-section">
        <p class="title">Contact</p>
        <div class="form-input">
          <label><b>Email</b></label>
          <app-input v-if="editMode" v-model="talent.email" placeholder="Email" type="email" required></app-input>
          <p class="value" v-else>{{ initialTalent.email }}</p>
        </div>
        <div class="form-input">
          <label>
            <b>Phone</b><br>
            {{ editMode ? 'Optional' : '' }}
          </label>
          <app-input v-if="editMode" v-model="talent.phone" placeholder="+440000000000" type="tel"></app-input>
          <p class="value" v-else>{{ initialTalent.phone || 'N/A' }}</p>
        </div>
      </div>
      <div class="form-section" v-if="canAssignOwner">
        <p class="title">Talent growth plan</p>
        <div class="form-input owner-input">
          <label><b>Author</b></label>
          <template v-if="editMode">
            <div class="owner">
              <talent-icon v-if="assignedOwner && assignedOwner.talentId" :user="assignedOwner" isLong></talent-icon>
              <div v-else class="pending-owner"></div>
            </div>
            <app-button size="medium" @click="$emit('click-assign-owner')">Assign author</app-button>
          </template>
          <p class="value" v-else>{{ initialTalent.owner ? `${initialTalent.owner.firstname} ${initialTalent.owner.lastname}` : 'Pending' }}</p>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import CountryLabel from '@/services/enums/country-label.enum';
import NationalityLabel from '@/services/enums/nationality-label.enum';
import SeniorityLabel from '@/services/enums/seniority-label.enum';
import TimeInCompanyLabel from '@/services/enums/time-in-company-label.enum';
import TimeInRoleLabel from '@/services/enums/time-in-role-label.enum';
import AgeGroupLabel from '@/services/enums/age-group-label.enum';
import GenderLabel from '@/services/enums/gender-label.enum';
import EthnicityLabel from '@/services/enums/ethnicity-label.enum';
import MobilityLabel from '@/services/enums/mobility-label.enum';
import utils from '@/services/utils';
import talentApi from '@/services/api/talent';

import TalentIcon from '@/components/TalentIcon.vue';

import Loader from '../../components/Loader.vue';

export default {
  name: 'talent-profile',
  components: {
    TalentIcon,
    Loader,
  },
  emits: [
    'submitted', // Form soumis avec succès. Renvoi aussi le talent
    'update:talent', // Talent modifié avec succès. Contient le talent màj
    'create:talent', // Talent créé avec succès.
  ],
  props: {
    // Si l'input owner est disponible
    canAssignOwner: {
      type: Boolean,
      default: false,
    },
    // Fonction à executer au click du bouton Assign Owner
    onAssignOwner: {
      type: Function,
      default: () => null,
    },
    // Owner assigné au talent : c'est le composant parent qui doit gérer l'assignation de l'owner
    // (les façons d'afficher les modales select étant différentes selon les écrans)
    assignedOwner: Object,
    // Si ce formulaire est pour un nouveau talent
    isNew: {
      type: Boolean,
      default: false,
    },
    isCSV: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    // Informations initiales du talent
    initialTalent: {
      type: Object,
    },
    // Pour récupérer toutes les infos directement de l'API
    talentId: String,
  },
  data() {
    return {
      talent: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        talentVersion: {
          mobility: '',
          ageGroup: null,
          gender: '',
          ethnicity: null,
          nationality: '',
          country: '',
          role: '',
          function: '',
          timeInRole: null,
          timeInCompany: null,
          seniority: '',
        },
      },
      loading: false,
      countryOptions: utils.enumToOptions(CountryLabel),
      ageGroupOptions: utils.enumToOptions(AgeGroupLabel, true),
      seniorityOptions: utils.enumToOptions(SeniorityLabel),
      timeInCompanyOptions: utils.enumToOptions(TimeInCompanyLabel, true),
      timeInRoleOptions: utils.enumToOptions(TimeInRoleLabel, true),
      genderOptions: utils.enumToOptions(GenderLabel),
      ethnicityOptions: utils.enumToOptions(EthnicityLabel, true),
      mobilityOptions: utils.enumToOptions(MobilityLabel),
      nationalityOptions: utils.enumToOptions(NationalityLabel),
      CountryLabel,
      AgeGroupLabel,
      SeniorityLabel,
      TimeInCompanyLabel,
      TimeInRoleLabel,
      GenderLabel,
      EthnicityLabel,
      MobilityLabel,
      NationalityLabel,
    };
  },
  computed: {
    initialVersion() {
      return this.initialTalent.talentVersion || {};
    },
  },
  watch: {
    initialTalent() {
      this.talent = { ...this.initialTalent };
    },
  },
  methods: {
    async submit() {
      if (this.$refs.talentProfileForm.reportValidity()) {
        let error = false;
        this.loading = true;
        if (this.isCSV) {
          this.$emit('submitted', this.talent);
        } else {
          if (this.isNew) {
            error = await this.createTalent();
          } else {
            error = await this.editTalent();
          }
          if (!error) this.$emit('submitted', this.talent);
        }
        this.loading = false;
      }
    },
    // Requête API : Création d'un talent
    async createTalent() {
      try {
        await talentApi.createTalent({ ...this.talent, ownerId: this.assignedOwner ? this.assignedOwner.talentId : undefined });
        this.$notification.show({ text: `Talent ${this.talent.firstname} ${this.talent.lastname} was successfully added !` });
        // eslint-disable-next-line
        this.$emit('create:talent');
        return false;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        const text = `The talent could not be added. ${messages}`;
        this.$message.show({
          text,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
        return true;
      }
    },
    // Requête API : Création d'un talent
    async editTalent() {
      try {
        const response = await talentApi.editTalent(this.talent.talentId, { ...this.talent, ownerId: this.assignedOwner ? this.assignedOwner.talentId : undefined });
        this.$notification.show({ text: `Talent ${this.talent.firstname} ${this.talent.lastname} was successfully updated !` });
        this.$emit('update:talent', response.data);
        return false;
      } catch (error) {
        const { message } = error.response.data;
        this.$message.show({
          text: `The talent could not be updated. \n ${Array.isArray(message) ? message.join('.\n') : message}`,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
        return true;
      }
    },
    // Requête API : Récupération du talent
    async getTalent() {
      this.loading = true;
      try {
        const response = await talentApi.getTalent(this.talentId);
        this.talent = response.data;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `An error occured. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    if (!this.isNew) {
      this.talent = { ...this.initialTalent };
      if (this.talentId) {
        this.getTalent();
      }
    }
  },
};
</script>

<style lang="sass">
.talent-profile
  .loader
    margin: 5rem auto
  .form-section
    padding: 1rem 0
    border-bottom: 2px solid $light-color
    .title
      color: $main-color
      text-transform: uppercase
      font-size: 12px
      font-weight: 900
    .form-input
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin: 8px 0
      label
        font-size: 13px
        color: $common-gray
        width: 30%
        position: relative
        top: 1rem
        b
          font-size: 16px
          color: $black
      .app-input
        width: 70%
        max-width: none
      .app-select
        width: 70%
        .container
          max-width: none
  .form-section:last-child
    border-bottom: none
  .owner-input.form-input
    align-items: center
    label
      position: initial
    .owner
      flex-grow: 1
      border: 2px solid $light-color
      padding: 0.5rem
      margin-right: 1rem
      .pending-owner
        @include pending-owner

  &.disabled
    .form-input
      align-items: center
      height: 49px
      label
        position: initial
      .value
        width: 70%
        text-align: left
        color: $common-gray
</style>
