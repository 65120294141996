<template>
    <footer class="grid-x global-footer">
        <div class="cell small-6">
          <p>© 2021 All rights reserved <span class="whitespace"></span>© 2021<a target="_blank" href="https://www.talentuum.io">Talentuum by Ellpha.io<img :src="vector" alt="talentuum"></a>, <a target="_blank" href="https://www.talentuum.io/terms-copy/">Terms <img :src="vector" alt="terms"></a>,<a target="_blank" href="https://www.talentuum.io/privacy/">Privacy <img :src="vector" alt="privacy"></a></p>
        </div>
        <div class="cell small-6">
          <p><a target="_blank" href="http://ellpha.io/">ellpha.io <img :src="vector" alt="ellpha"></a></p>
        </div>
    </footer>
</template>

<script>
import vector from '../assets/img/vector.svg';

export default {
  name: 'bottom',

  data() {
    return {
      vector,
    };
  },
};
</script>

<style lang="sass">
footer
  position: absolute
  bottom: 0
  max-height: 40px
  height: 40px
  width: 100%
  padding: 12px 24px
  background-color: $white
  p, a
    margin: 0
    font-size: 11px
    color: $common-gray
    font-weight: 700
  a
    font-size: 13px
    text-decoration: none
    border-bottom: 2px solid $light-color
  img
    margin-left: 7px
    width: 8px
  .whitespace
    margin: 0 5px
  .cell:first-child
    a
      font-size: 11px
      margin: 0 5px 0 3px
  .cell:last-child
    text-align: right
</style>
