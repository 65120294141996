import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/typical-biases`;

/**
 * Récupère la liste des typical bias
 * @returns liste des typical bias
 */
const getTypicalBiases = async () => {
  try {
    const response = await axios.get(`${API_URL}`);
    return response.data;
  } catch (error) { throw error; }
};

const update = async (typicalBiasId, body) => {
  const updateBody = {
    label: body.label,
    description: body.description,
    cause: body.cause,
    solution: body.solution,
    link: body.link ? body.link : null,
  };
  try {
    const response = await axios.put(`${API_URL}/${typicalBiasId}`, updateBody);
    return response.data;
  } catch (error) { throw error; }
};

const api = {};
api.getTypicalBiases = getTypicalBiases;
api.update = update;

export default api;
