<template>
  <section class="companies" @click="closeSmallMenus">
    <!-- Barre de titre et principales actions disponibles -->
    <div class="title-bar grid-x align-middle grid-margin-x">
      <app-title class="cell auto">Company</app-title>
      <app-button kind="primary" size="medium" class="cell shrink" @click="openNewModal = true">
        <svg-plus class="leading"/>
        Add new company
      </app-button>
    </div>

    <!-- Barre de recherche avec boutons de filtres -->
    <div class="search-bar">
      <app-search placeholder="Search..." v-model="searchName"></app-search>
    </div>
    <div class="table-container">
      <app-table class="table" :loading="loading">
        <template v-slot:headers>
          <div class="cell auto">Company name <SvgTriangle /></div>
          <div class="cell auto">Contact email</div>
          <div class="cell auto">Admins</div>
          <div class="cell auto">Team size</div>
          <div class="cell auto">Subscription</div>
          <div class="cell auto">Setup date</div>
          <div class="cell shrink"></div>
        </template>
        <template v-slot:body>
          <div class="row grid-x align-middle" v-for="company in companies" :key="company.companyId" @click="$router.push({ name: 'company', params: { companyId: company.companyId }})">
            <div class="cell auto">{{ company.name }}</div>
            <div class="cell auto">{{ company.contact.email }}</div>
            <div class="cell auto">{{ company.maxAdmins }}</div>
            <div class="cell auto">{{ company.maxTalents }}</div>
            <div class="cell auto">{{ SubscriptionLabel[company.subscription] }}</div>
            <div class="cell auto">{{ utils.formatDate(company.setupDate) }}</div>
            <!-- Bouton Overflow de fin de ligne -->
            <div class="cell shrink">
              <app-button kind="minimal" size="tiny" class="overflow-button no-focus" @click.stop="openSmallMenu">
                <svg-overflow />
              </app-button>
              <app-tooltip class="edit-suppr-menu" :isMenu="true" position="bottom-right">
                <app-button @click.stop="deleteCompany(company.companyId, company.name)" kind="minimal" size="tiny">
                  <div class="btn-icon">
                    <svg-delete />
                  </div>
                  <span>
                    Delete
                  </span>
                </app-button>
              </app-tooltip>
            </div>
          </div>
        </template>
      </app-table>
      <pagination :limit="metadata.limit" :offset="metadata.offset" :count="metadata.count" @update:page="changePage"/>
    </div>

    <!-- Modale d'ajout manuel d'une entreprise -->
    <app-modal :show="openNewModal" @update:show="openNewModal = false" title="Add new company" class="new-company-modal">
      <company-info isNew ref="companyInfo" @submitted="getCompanies(); openNewModal = false;"></company-info>
      <template v-slot:footer>
        <app-button kind="minimal" @click="openNewModal = false">Cancel</app-button>
        <app-button @click="$refs.companyInfo.submit()">Save</app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import companyApi from '@/services/api/company';
import utils from '@/services/utils';

import SubscriptionLabel from '@/services/enums/subscription-label.enum';

// SVG
import SvgOverflow from '@/assets/img/icons/24px/overflow.svg?inline';
import SvgPlus from '@/assets/img/icons/24px/plus.svg?inline';
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

// Components
import Pagination from '@/components/Pagination.vue';
import CompanyInfo from './CompanyInfo.vue';

export default {
  name: 'companies',
  components: {
    Pagination,
    CompanyInfo,
    SvgPlus,
    SvgDelete,
    SvgOverflow,
    SvgTriangle,
  },
  data() {
    return {
      searchName: this.$route.query.searchName || '',
      companies: [],
      user: authApi.getUser(),
      metadata: {
        offset: +this.$route.query.offset || 0,
        limit: +this.$route.query.limit || 5,
        count: 0,
      },
      loading: false,
      SubscriptionLabel,

      // Data for new company
      showNewTooltip: false,
      openNewModal: false,

      // Data for delete company
      openDeleteModal: false,

      utils,
    };
  },
  watch: {
    $route() {
      this.getCompanies(this.$route.query.limit, this.$route.query.offset, this.$route.query.order);
    },
    searchName() {
      this.debouncedUpdateQuery({ offset: 0, searchName: this.searchName });
    },
  },
  mounted() {
    this.getCompanies(this.metadata.limit, this.metadata.offset, this.metadata.order);
    this.debouncedUpdateQuery = utils.debounce((query) => {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    }, 400);
  },
  methods: {
    openSmallMenu(e) {
      if (!e.currentTarget.nextSibling.classList.contains('opened')) {
        if (this.oneSmallMenuIsOpened) {
          this.closeSmallMenus();
        }
        e.currentTarget.nextSibling.classList.add('opened');
        this.oneSmallMenuIsOpened = true;
      } else {
        this.closeSmallMenus();
      }
    },
    closeSmallMenus() {
      if (this.oneSmallMenuIsOpened) {
        const openedMenus = document.querySelectorAll('.edit-suppr-menu.opened');
        openedMenus.forEach((openedMenu) => {
          openedMenu.classList.remove('opened');
        });
        this.oneSmallMenuIsOpened = false;
      }
    },
    async deleteCompany(companyId, name) {
      this.$message.show({
        title: `You're about to delete company ${name}. Are you sure?`,
        text: 'The company will lose all its Talents, Cycles and TGPs, and will not be able to access Talentuum aymore.?',
        hasCancel: true,
        cancelText: 'Cancel',
        confirmText: 'Yes, delete',
        isDestructive: true,
        onConfirm: async () => {
          try {
            await companyApi.deleteCompany(companyId);
            this.$notification.show({
              text: `Company ${name} deleted successfully`,
            });
            await this.getCompanies(this.$route.query.limit, this.$route.query.offset, this.$route.query.order, this.searchName);
          } catch (error) {
            const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
            this.$message.show({
              title: 'Error',
              text: `The company ${name} could not be deleted. ${messages}`,
              hasCancel: false,
              confirmText: 'Ok',
              isDestructive: false,
              onConfirm: null,
            });
          }
        },
      });
    },
    changePage({ limit, offset }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    async getCompanies(limit, offset) {
      this.loading = true;
      try {
        const response = await companyApi.getCompanies(
          limit || this.$route.query.limit,
          offset || this.$route.query.offset,
          this.searchName,
        );
        this.companies = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the companies. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass">
.companies
  @include screen
  .title-bar
    .app-title
      text-align: left
    .add-company-tooltip
      .app-tooltip-container
        display: none
        transform: translateY(0.55rem)
        width: 183px
        .app-button
          svg
            transform: scale(0.8)
      &:focus-within
        .app-tooltip-container
          display: initial
    .edit
      padding: 0 3px
  .search-bar
    display: flex
    align-items: center
    justify-content: space-between
    background: $white
    padding: 16px 24px
    margin: 8px 0
    .app-search
       width: 50%
  .table-container
    @include table-container
    .table
      .body .row .cell:last-child, .headers .cell:last-child
        width: 2rem
        position: relative
        .edit-suppr-menu
          display: none
          background: $white
          position: absolute
          z-index: 5
          right: -14px
          box-shadow: 0px 2px 8px 0px rgba(103, 102, 98, 0.2)
          border-radius: 4px
          .app-button
            width: 100%
            border-radius: 0
            .btn-icon
              width: 24px
              display: flex
              justify-content: center
              &.edit
                padding: 0 3px
              svg
                margin: 0
            span
              padding-left: 3px
          &.opened
            display: block
      .body .row
          @include clickable-row
          .overflow-button
            @include icon-button
            width: 2.5rem
            height: 2.5rem
  .new-company-modal
    .modal-wrapper
      top: 35%
      max-width: 900px
    .modal-content
      overflow-y: scroll
      max-height: 70vh
      .company-info
        padding: 0 10rem
  .svg-triangle
    margin: 0 0 0 10px
</style>
