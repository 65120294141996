<template>
  <div class="custom-header">
    <router-link :to="to" class="arrow-container no-style">
      <SvgArrowLeft/>
    </router-link>
    <span>{{ title }}</span>
  </div>
</template>

<script>
import SvgArrowLeft from '@/assets/img/icons/24px/arrow-left.svg?inline';

export default {
  name: 'custom-header',
  components: {
    SvgArrowLeft,
  },
  props: {
    to: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  mounted() {
    document.querySelector('header').style.display = 'none';
  },
  beforeDestroy() {
    document.querySelector('header').style.display = 'flex';
  },
};
</script>

<style lang="sass">
.custom-header
  z-index: 500
  display: flex
  align-items: center
  background-color: $header-bg-color
  position: fixed
  width: 100vw
  top: 0
  left: 0
  height: 68px
  color: $white
  font-size: 1.2em
  font-weight: 700
  .arrow-container
    width: 24px
    height: 24px
    opacity: 0.5
    margin: auto 52px auto 28px
    cursor: pointer
    border: none
    svg
      fill: $white
</style>
