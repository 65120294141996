<template>
  <section class="cycle-setup" :class="{ lastScreen: currentStep === 6 }">
    <Loader v-if="loading" />
    <template v-else>
      <custom-header :to="{ name: 'cycles' }" title="Setup a new cycle"></custom-header>

      <app-title class="cell auto" v-if="currentStep > 0 && currentStep < 6"><span class="step-label" v-if="!hasErrors">Step {{ currentStep }}</span> <span class="title">{{ getTitle }}</span></app-title>
      <CycleSetupStart
        v-if="currentStep === 0"
        :text="cycleSetupStartText"
        @update:step="currentStep = $event"
      ></CycleSetupStart>
      <CycleSetupName
        v-else-if="currentStep === 1"
        @update:step="currentStep = $event"
        @update:input="cycleBody.name = $event"
        :oldCycleName="cycleBody.name"
      ></CycleSetupName>
      <CycleSetupForm
        v-else-if="currentStep === 2"
        @update:step="currentStep = $event"
        @update:input="cycleBody.questions = $event.map((q) => q)"
        :oldQuestions="cycleBody.questions"
        :duplicatedQuestions="duplicatedQuestions"
      ></CycleSetupForm>
      <CycleSetupTalents
        v-else-if="currentStep === 3"
        @update:step="currentStep = $event"
        @update:input="cycleBody.talents = $event"
        @update:error="hasErrors = $event"
        :oldTalents="cycleBody.talents"
      ></CycleSetupTalents>
      <CycleTimeline
        v-else-if="currentStep === 4"
        @update:step="currentStep = $event"
        @update:inputStartDate="cycleBody.startDate = $event"
        @update:inputEndDate="cycleBody.endDate = $event"
        :oldDates="{startDate: cycleBody.startDate, endDate: cycleBody.endDate}"
      ></CycleTimeline>
      <CycleSetupOverview
        v-else-if="currentStep === 5"
        :cycleBody="cycleBody"
        @update:step="currentStep = $event"
      ></CycleSetupOverview>
      <CycleSetupEnd
        v-else-if="currentStep === 6"
        :text="cycleSetupEndText"
        :cycle="cycleBody"
      ></CycleSetupEnd>
    </template>
  </section>
</template>

<script>
// API
import copyApi from '@/services/api/copy';
import cycleApi from '@/services/api/cycle';
import CopyKey from '@/services/enums/copy-key.enum';

import CustomHeader from '@/components/CustomHeader.vue';

// TUNNEL
import CycleSetupStart from './cycle-setup-tunnel/CycleSetupStart.vue';
import CycleSetupName from './cycle-setup-tunnel/CycleSetupName.vue';
import CycleSetupForm from './cycle-setup-tunnel/CycleSetupForm.vue';
import CycleSetupTalents from './cycle-setup-tunnel/CycleSetupTalents.vue';
import CycleTimeline from './cycle-setup-tunnel/CycleTimeline.vue';
import CycleSetupOverview from './cycle-setup-tunnel/CycleSetupOverview.vue';
import CycleSetupEnd from './cycle-setup-tunnel/CycleSetupEnd.vue';

import Loader from '../../components/Loader.vue';

export default {
  name: 'cycle-setup',
  components: {
    CustomHeader,
    CycleSetupStart,
    CycleSetupName,
    CycleSetupForm,
    CycleSetupTalents,
    CycleTimeline,
    CycleSetupOverview,
    CycleSetupEnd,
    Loader,
  },
  data() {
    return {
      currentStep: 0,
      cycleSetupStartText: '',
      cycleSetupEndText: '',
      cycleBody: {
        name: null,
        questions: [],
        talents: [],
        startDate: null,
        endDate: null,
      },
      hasErrors: false,
      loading: false,

      // Pour la duplication d'un cycle
      cycle: null,
      shouldFireTemplateWarning: false,
    };
  },
  watch: {
    currentStep() {
      if (this.currentStep === 2 && this.$route.query.duplicateCycleId && this.shouldFireTemplateWarning) {
        this.$message.show({
          title: 'There\'s a new version of the form.',
          text: 'Any form settings on the cycle have been reset. You’ll have to reconfigure the new form.',
          hasCancel: false,
          confirmText: 'Ok',
          onConfirm: () => { this.shouldFireTemplateWarning = false; },
        });
      }
    },
  },
  mounted() {
    document.querySelector('.global-footer').style.display = 'none';
    this.getData();
  },
  beforeDestroy() {
    document.querySelector('.global-footer').style.display = 'flex';
  },
  computed: {
    getTitle() {
      switch (this.currentStep) {
        case 1:
          return 'Name your cycle';
        case 2:
          return 'Form structure';
        case 3:
          if (!this.hasErrors) {
            return 'Select talent';
          }
          return 'Resolve errors';
        case 4:
          return 'Cycle timeline';
        case 5:
          return 'Review your cycle details';
        default:
          return '';
      }
    },
    duplicatedQuestions() {
      if (this.$route.query.duplicateCycleId) {
        return this.cycle.questions;
      }
      return null;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      if (this.$route.query.duplicateCycleId) {
        await Promise.all([this.getCopys(), this.getCycle()]);
      } else {
        await this.getCopys();
      }
      this.loading = false;
    },
    async getCopys() {
      try {
        const copyStart = await copyApi.getCopy(CopyKey.CYCLE_SETUP_START);
        this.cycleSetupStartText = copyStart.data.copyText;

        const copyEnd = await copyApi.getCopy(CopyKey.CYCLE_SETUP_END);
        this.cycleSetupEndText = copyEnd.data.copyText;
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: error,
        });
      }
    },
    async getCycle() {
      try {
        const response = await cycleApi.getCycle(this.$route.query.duplicateCycleId);
        this.cycle = response.data;
        this.shouldFireTemplateWarning = response.templateChanged;
        this.cycleBody.talents = this.cycle.talents;
      } catch (err) {
        const messages = typeof err.response.data.message === 'string' ? err.response.data.message : err.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to duplicate the cycle. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.cycle-setup
  &:not(.lastScreen)
    @include screen
    > .loader
      margin: 5rem auto
  .app-link
    margin-top: 24px
  .app-title
    display: flex
    align-items: flex-end
    .step-label
      font-size: 16px
      font-weight: normal
      margin-right: 24px
    .title
      line-height: 0.9em
</style>
