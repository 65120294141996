<template>
  <section class="cycle-pdf">
    <app-spinner v-if="loading"></app-spinner>
    <template v-else>
      <div class="cover">
        <div>
          <app-title>{{ cycle.name }}</app-title>
          <p class="subtitle">{{ cycle.companyName }}</p>
        </div>
      </div>

      <!-- SECTION : Cycle settings -->
      <div class="page title-page">
        <app-title>Cycle settings</app-title>
      </div>
      <div class="page">
        <app-subtitle>Talent list</app-subtitle>
        <div class="talent-list table-container">
          <app-table class="table" :loading="loading">
            <template v-slot:headers>
              <div class="cell auto">Name</div>
              <div class="cell auto">Role title</div>
              <div class="cell auto">Function</div>
              <div class="cell auto">Author</div>
            </template>
            <template v-slot:body>
              <div class="row grid-x align-middle" v-for="talent in cycle.talents" :key="talent.talentId">
                <div class="cell auto talent-name">
                  <talent-icon :user="talent"></talent-icon>
                  <div class="talent-info">
                    <div>{{ talent.firstname  | capitalize }} {{ talent.lastname | uppercase }}</div>
                    <div class="talent-email cell">{{ talent.email }}</div>
                  </div>
                </div>
                <div class="cell auto">{{ talent.talentVersion.role }}</div>
                <div class="cell auto">{{ talent.talentVersion.function }}</div>
                <div class="cell auto">
                  <talent-icon v-if="talent.owner" :user="talent.owner" isLong></talent-icon>
                  <div v-else class="pending-owner"></div>
                </div>
              </div>
            </template>
          </app-table>
        </div>
        <app-subtitle>Cycle timeline</app-subtitle>
        <div class="cycle-timeline">
          <div>
            <app-label>Cycle release date</app-label>
            <p>{{ cycle.startDate | formatDate }}</p>
          </div>
          <div>
            <app-label>Cycle end date</app-label>
            <p>{{ cycle.endDate | formatDate }}</p>
          </div>
        </div>
      </div>

      <div class="page title-page">
        <app-title>Description of Cycle Talents</app-title>
      </div>
      <CycleTalents isPDF :talentsStats="talentsStats" :answersStats="answersStats" />

      <template v-if="hasEnoughWriters">
        <div class="page title-page">
          <app-title>Segments at risk</app-title>
        </div>
        <CycleSegmentAtRisk :biasStats="biasStats" :typicalBiases="typicalBiases" isPDF/>

        <div class="page title-page">
          <app-title>Typical Bias risks accross the segments</app-title>
        </div>
        <CycleTypicalBiasAcrossSegments :biasStats="biasStats" :typicalBiases="typicalBiases" isPDF/>

        <div class="page title-page">
          <app-title>Where do biases come from ?</app-title>
        </div>
        <CycleWhereDoBiasesComeFrom :biasFemaleStats="biasFemaleStats" :biasMaleStats="biasMaleStats" :typicalBiases="typicalBiases" isPDF />

        <div class="page title-page">
          <app-title>Drive for change</app-title>
        </div>
        <CycleDriveForChange :biasStats="biasStats" isPDF/>

        <div class="page title-page">
          <app-title>Bias in segments</app-title>
        </div>
        <CycleBiasInSegments :segmentsData="biasStats.segmentsBiasRisk" :typicalBiases="typicalBiases" isPDF/>
      </template>
    </template>
  </section>
</template>

<script>
import cycleApi from '@/services/api/cycle';
import typicalBiasesApi from '@/services/api/typical-bias';

import StatsDisplay from '@/mixins/StatsDisplay.vue';

import utils from '@/services/utils';

// Enums
import BiasStatsDatapool from '@/services/enums/bias-stats-datapool.enum';

// Composants
import CycleTalents from '@/views/cycles/cycle-tabs/CycleTalents.vue';
import CycleSegmentAtRisk from '@/views/cycles/cycle-tabs/CycleSegmentAtRisk.vue';
import CycleTypicalBiasAcrossSegments from '@/views/cycles/cycle-tabs/CycleTypicalBiasAcrossSegments.vue';
import CycleWhereDoBiasesComeFrom from '@/views/cycles/cycle-tabs/CycleWhereDoBiasesComeFrom.vue';
import CycleDriveForChange from '@/views/cycles/cycle-tabs/CycleDriveForChange.vue';
import CycleBiasInSegments from '@/views/cycles/cycle-tabs/CycleBiasInSegments.vue';
import TalentIcon from '@/components/TalentIcon.vue';

export default {
  name: 'cycle-pdf',
  mixins: [StatsDisplay], // Pour les fonctions getPoolLabel, getBiasSeverity
  components: {
    TalentIcon,
    CycleTalents,
    CycleSegmentAtRisk,
    CycleTypicalBiasAcrossSegments,
    CycleWhereDoBiasesComeFrom,
    CycleDriveForChange,
    CycleBiasInSegments,
  },
  data() {
    return {
      // Données de l'api
      cycle: null,
      talentsStats: null,
      answersStats: null,
      biasStats: null,
      biasFemaleStats: null,
      biasMaleStats: null,
      typicalBiases: null,

      // Données dynamiques
      loading: false,
      hasEnoughWriters: true,
    };
  },
  computed: {
  },
  watch: {
  },
  filters: {
    formatDate(date) {
      return utils.formatDate(date);
    },
  },
  methods: {
    // Requête API : récupère la cycle
    async getCycle() {
      this.loading = true;
      const response = await cycleApi.getCycle(this.$route.params.cycleId);
      this.cycle = response.data;
      await Promise.all([
        this.getTalentsStats(),
        this.getAnswersStats(),
        this.getBiasStats(),
        this.getTypicalBiases(),
        this.getBiasStats(BiasStatsDatapool.FEMALE_WRITERS),
        this.getBiasStats(BiasStatsDatapool.MALE_WRITERS),
      ]);
      this.loading = false;
    },
    async getTalentsStats() {
      const response = await cycleApi.getTalentsStats(this.cycle.cycleId);
      this.talentsStats = response.data;
    },
    async getAnswersStats() {
      const response = await cycleApi.getAnswersStats(this.cycle.cycleId);
      this.answersStats = response.data;
    },
    async getTypicalBiases() {
      const response = await typicalBiasesApi.getTypicalBiases();
      this.typicalBiases = Object.fromEntries(response.data.map((typicalBias) => [typicalBias.reference, typicalBias]));
    },
    async getBiasStats(datapool) {
      try {
        const response = await cycleApi.getBiasStats(this.cycle.cycleId, datapool);
        switch (datapool) {
          case BiasStatsDatapool.FEMALE_WRITERS: this.biasFemaleStats = response.data; break;
          case BiasStatsDatapool.MALE_WRITERS: this.biasMaleStats = response.data; break;
          default: {
            this.biasStats = response.data;
            if (this.biasStats && this.biasStats.nbWriters < +process.env.VUE_APP_NB_WRITERS_MIN_CYCLE) {
              this.hasEnoughWriters = false;
            }
            break;
          }
        }
      } catch (error) {
        throw error;
      }
    },
  },
  created() {
    this.getCycle();
  },
};
</script>

<style lang="sass">
.cycle-pdf
  background: $background-color
  padding: 0 ! important
  min-height: 100%
  page-break-after: always
  font-size: 11px
  .cover
    background: #1C0032
    padding: 60px
    min-height: 100vh
    display: flex
    align-items: center
    .app-title
      color: $white
      margin: 0
    .subtitle
      color: $white
      opacity: 50%
      font-size: 16px
      margin: 0
  .page
    page-break-before: always
    padding: 0 40px
    &.title-page
      min-height: 100vh
      display: flex
      align-items: center
    .app-title
      padding: 20px
    h2
      font-size: 1.17rem
      color: $main-secondary-color
      margin: 0
      margin-top: 10px
    h3
      font-size: 13px
      margin-top: 5px
    h4
      font-size: 13px
    h5
      font-size: 13px
    > .app-subtitle
        font-size: 24px
        & + p
          max-width: 60%
          margin-bottom: 2rem
          color: $common-gray
    .separator
      margin: 1rem 0
    .intro
      font-size: 12px
      color: $common-gray
    .graph-card
      page-break-inside: avoid
      break-inside: avoid
  .talent-list.table-container
    page-break-inside: avoid
    break-inside: avoid
    @include table-container
    .app-table
      .body .row .cell:first-child, .headers .cell:first-child
        min-width: 200px
      .body .row
        .talent-name
          display: flex
          .talent-info
            margin-left: 0.5rem
            div:first-child
              color: $black
              font-weight: 700
            div:last-child
              font-size: 13px
        &.completed
          @include clickable-row
        &.talent_deleted
          background-color: $light-color
  .cycle-timeline
    page-break-inside: avoid
    padding: 0 24px
    background: $white
    &>div
      display: flex
      align-items: center
      padding: 16px 0
      .app-label
        width: 130px
      p
        margin: 0
        font-size: 16px
        padding-left: 121px
      &:not(:last-of-type)
        border-bottom: 2px solid $light-color
@media print
  .cycle-pdf
    -webkit-print-color-adjust: exact
  @page
    size: A4 portait
</style>
