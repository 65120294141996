import dayjs from 'dayjs';
import BiasCheckLabel from '@/services/enums/bias-check-label.enum';
import ReviewStatusLabel from './enums/review-status-label.enum';

const utils = {};

/**
 * Similaire au _debounce de lodash: Retarde l'execution de la fonction de 'wait'ms à chaque appel afin d'appeler la fonction seulement quand il n'y a plus de changement
 * @param {function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Int} wait : temps d'attente (ms) sans appel à la fonction avant de l'exécuter
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const debounce = (func, wait) => {
  let timeout;
  return function executedFunc(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Permet de limiter la fréquence d'utilisation d'une fonction en ne l'appelant au maximum 1 fois toutes les 'wait'ms
 * @param {Function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Number} wait : temps d'attente minimum entre 2 appels
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const throttle = (func, wait) => {
  let timeout;
  let storedArgs;
  return function executedFunc(...args) {
    storedArgs = args;
    if (!timeout) {
      func(...storedArgs);
      storedArgs = null;
      timeout = setTimeout(() => {
        if (storedArgs) {
          func(...storedArgs);
        }
        timeout = null;
      }, wait);
    }
  };
};

/**
 * facilite l'initialisation des filtres de recherche selon la query
 * @param {string} value : valeur initiale de la query
 * @returns un array vide si la valeur est indéfinie, ou un array de ou des valeurs déjà en query
 */
const initArray = (value) => {
  if (!value) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
};

/**
 * Formatte la date au format DD/MM/YYYY
 * @param {Date} date : date à formatter. Peut être indéfini
 * @returns String de la date ou 'N/A' si date non définie
 */
const formatDate = (date) => (date ? dayjs(date).format('DD/MM/YYYY') : 'N/A');

/**
 * Formatte la date et l'heure au format HH:mm DD/MM/YYYY
 * @param {Date} date : date à formatter. Peut être indéfini
 * @returns String de la date ou 'N/A' si date non définie
 */
const formatTime = (date) => (date ? dayjs(date).format('HH:mm DD/MM/YYYY') : 'N/A');

/**
 * Détermine la prop state à utiliser pour le app-tag du statut éditable de la review
 * @param {DRAFT | NEW | PROCESSING | EDITING | COMPLETED} reviewStatus : statut de la review
 * @returns prop state à donner à l'app-tag du statut éditable de la review
 */
const getReviewEditTagState = (reviewStatus) => {
  switch (ReviewStatusLabel[reviewStatus]) {
    case ReviewStatusLabel.COMPLETED: return 'success';
    case ReviewStatusLabel.TALENT_DELETED: return 'deleted';
    default: return 'default';
  }
};

/**
 * Détermine la prop state à utiliser pour le app-tag du statut bias-check de la review
 * @param {DRAFT | NEW | PROCESSING | EDITING | COMPLETED} reviewStatus : statut de la review
 * @returns prop state à donner à l'app-tag du statut bias-check de la review
 */
const getBiasCheckTagState = (reviewStatus) => {
  let label = 'main';
  switch (BiasCheckLabel[reviewStatus]) {
    case BiasCheckLabel.NEW:
      label = 'default';
      break;
    case BiasCheckLabel.PROCESSING:
      label = 'yellow';
      break;
    default:
      break;
  }
  return label;
};
/**
 * Construit un array d'options pour app-select à partir d'un enum
 * @param {object} enumObject : objet enum label
 * @param {boolean} addNullOption : si true, ajoutes une option null N/A à l'array d'options
 * @returns {array} array d'objets de la forme { name, label } comme demandé par app-select
 */
const enumToOptions = (enumObject, addNullOption = false) => Object.entries(enumObject).map(([name, label]) => ({ name, label })).concat(addNullOption ? [{ name: null, label: 'N/A' }] : []);

utils.debounce = debounce;
utils.throttle = throttle;
utils.initArray = initArray;
utils.formatDate = formatDate;
utils.formatTime = formatTime;
utils.getBiasCheckTagState = getBiasCheckTagState;
utils.getReviewEditTagState = getReviewEditTagState;
utils.enumToOptions = enumToOptions;

export default utils;
