<template>
  <div>
    <transition name="modal">
    <div class="modal-wrapper" v-if="visible">
      <div v-if="title" class="modal-title">
        <app-title>{{ title }}</app-title>
        <app-button v-if="canClose" kind="minimal" size="tiny" @click="$emit('update:show', false)">
          <svg-cross />
        </app-button>
      </div>
      <div class="modal-content">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <slot name="footer"></slot>
      </div>
      <slot name="modal-overflowing"></slot>
    </div>
    </transition>
    <transition name="screen">
      <div class="modal-screen" v-if="visible" @click="$emit('update:show', false)"></div>
    </transition>
  </div>
</template>

<script>
import SvgCross from '@/assets/img/icons/16px/cross.svg?inline';
import AppButton from './AppButton.vue';

export default {
  components: {
    AppButton,
    'svg-cross': SvgCross,
  },
  emits: ['update:show'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    visible() {
      return this.show;
    },
  },
  watch: {
    show: 'updateScroll',
  },
  beforeDestroy() {
    document.documentElement.style.overflow = 'auto';
  },
  methods: {
    updateScroll() {
      document.documentElement.style.overflow = this.show ? 'hidden' : 'auto';
    },
  },
};
</script>

<style lang="sass">
.modal-wrapper
  position: fixed
  top: 50vh
  left: 50vw
  width: 95%
  max-width: 900px
  height: 95%
  max-height: 500px
  border-radius: $global-border-radius
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1)
  z-index: 1000
  transform: translate(-50%, -50%)
  .modal-title
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 1rem
    background: $background-color
    .app-title
      font-size: 20px
    .app-button
      @include icon-button
  .modal-content
    padding: 1rem
    background: white
    z-index: 100
    overflow-y: auto
    max-height: 60vh
    // @include hide-scrollbar
  .modal-footer
    background: $background-color
    display: flex
    align-items: center
    justify-content: center
    padding: 1rem
    z-index: 50
    .app-button
      margin: 0 1rem

.modal-screen
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: rgba(0,0,0,0.4)
  z-index: 800

.screen-enter-active
  transition: opacity .9s

.screen-leave-active
  transition: opacity .3s

.screen-enter, .screen-leave-to
  opacity: 0

.modal-enter-active, .modal-leave-active
  transition: transform .2s, opacity .2s, scale 0.15s ease-in-out

.modal-enter, .modal-leave-to
  opacity: 0
  transform: translateY(-130px) translateX(-50%) scale(0.9)

</style>
