<template>
  <section class="home">
    <div class="background"></div>

    <div class="grid-container">
      <div class="grid-x grid-margin-x align-stretch">
        <div class="cell small-12">
          <app-title class="">
            Welcome,
            <span v-if="user">{{user.firstname}}</span>
            <span v-else>undefined</span>
          </app-title>
        </div>

        <div class="cell small-12 medium-6">
          <div class="navigation-tile talent-tile" @click="$router.push({ name: 'talents' })">
            <div class="image-container">
              <img src="~@/assets/img/users.svg" alt="">
            </div>
            <div class="content-container">
              <h3 class="title">Talent</h3>
              <h4 class="subtitle">My Talents</h4>
              <p>Where you manage your talent lists, upload new employees, edit authorship attributes</p>
            </div>
          </div>
        </div>
        <div class="cell small-12 medium-6">
          <div class="navigation-tile cycle-tile" @click="$router.push({ name: 'cycles' })">
            <div class="image-container">
              <img src="~@/assets/img/cycles.svg" alt="">
            </div>
            <div class="content-container">
              <h3 class="title">Talent Growth Plan</h3>
              <h4 class="subtitle">My Cycles</h4>
              <p>Where you can plan What Talent Growth Plans need to be created by When</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ls from 'local-storage';
import usersImg from '../assets/img/users.svg';
import cyclesImg from '../assets/img/cycles.svg';

export default {
  name: 'home',
  data() {
    return {
      usersImg,
      cyclesImg,
      user: ls('USER'),
    };
  },
};
</script>

<style lang="sass">
.home
  .background
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: calc(38px + 56px + 48px + 68px + (max(65vh, 500px) / 2))
    background-color: $main-color
    z-index: 0
  .grid-container
    position: relative
    z-index: 10
  .app-title
    margin: 56px auto 48px auto
    color: $white
  .navigation-tile
    height: 65vh
    min-height: 500px
    text-align: center
    background-color: $white
    box-shadow: 0px 2px 8px rgba(103, 102, 98, 0.2)
    border-radius: $global-border-radius
    cursor: pointer
    &:hover
      box-shadow: 0px 4px 12px 0px rgba(103, 102, 98, 0.3)
      transform: scale(1.01)
    .image-container
      height: 50%
      background-color: $background-color
      display: flex
      align-items: center
      justify-content: center
      border-radius: $global-border-radius $global-border-radius 0 0
      img
        height: 70%
        width: 100%
        object-fit: contain
    .content-container
      padding: 32px 126px
      h3
        margin: 0
        font-size: 12px
        font-weight: 900
        color: $main-color
        text-transform: uppercase
        letter-spacing: 1.5px
      h4
        margin: 16px 0
        font-size: 32px
        font-weight: 700
        color: $text-color
      p
        margin: 0
        font-size: 20px
        font-weight: 400
        color: $common-gray

@media (max-width: 640px)
  .home
    .cell:nth-child(2)
      margin-bottom: 24px
    .navigation-tile
      .content-container
        padding-left: 1rem
        padding-right: 1rem
</style>
