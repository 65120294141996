import Vue from 'vue';
import Router from 'vue-router';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import Activate from './views/Activate.vue';
import ResetPassword from './views/ResetPassword.vue';
import Companies from './views/companies/Companies.vue';
import Company from './views/companies/Company.vue';
import Account from './views/account/Account.vue';
import AccountEdit from './views/account/AccountEdit.vue';
import Reviews from './views/reviews/Reviews.vue';
import Review from './views/reviews/Review.vue';
import ReviewCreate from './views/reviews/ReviewCreate.vue';
import ReviewSent from './views/reviews/ReviewSent.vue';
import Cycles from './views/cycles/Cycles.vue';
import CycleSettings from './views/cycles/CycleSettings.vue';
import CycleEditTalents from './views/cycles/CycleEditTalents.vue';
import CycleSetup from './views/cycles/CycleSetup.vue';
import Cycle from './views/cycles/Cycle.vue';
import Talent from './views/talents/Talent.vue';
import Talents from './views/talents/Talents.vue';
import TalentsImport from './views/talents/TalentsImport.vue';
import Copies from './views/copies/Copies.vue';
import ReviewPdf from './views/pdf/ReviewPdf.vue';
import CyclePdf from './views/pdf/CyclePdf.vue';
import auth from './services/auth';
import UserRole from './services/enums/user-role.enums';
import Template from './views/templates/Templates.vue';
import Threshold from './views/thresholds/Thresholds.vue';
import TypicalBiases from './views/typical-biases/TypicalBiases.vue';
import Onboarding from './views/Onboarding.vue';
import NotFound from './views/NotFound.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        noFooter: true,
        noNav: true,
        onlyLogo: true,
      },
      component: Login,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: {
        noFooter: true,
        noNav: true,
      },
      component: ForgotPassword,
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        noFooter: true,
      },
      props: (route) => ({ token: route.query.token }),
    },
    // Routes super-admin
    {
      path: '/super-admin/login',
      name: 'super-admin-login',
      component: Login,
      meta: {
        noFooter: true,
        noNav: true,
        onlyLogo: true,
      },
      props: { isSuperAdmin: true },
    },
    {
      path: '/super-admin/forgot-password',
      name: 'super-admin-forgot-password',
      component: ForgotPassword,
      meta: {
        noFooter: true,
        noNav: true,
      },
      props: { isSuperAdmin: true },
    },
    {
      path: '/super-admin/reset-password',
      name: 'super-admin-reset-password',
      component: ResetPassword,
      meta: {
        noFooter: true,
      },
      props: { isSuperAdmin: true },
    },
    {
      path: '/activate-account',
      name: 'activate-account',
      component: Activate,
      meta: {
        noFooter: true,
        noNav: true,
        onlyLogo: true,
      },
    },
    {
      path: '/super-admin/companies',
      name: 'companies',
      component: Companies,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.SUPER_ADMIN],
      },
    },
    {
      path: '/super-admin/companies/:companyId',
      name: 'company',
      component: Company,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.SUPER_ADMIN],
      },
    },
    {
      path: '/super-admin/templates',
      name: 'templates',
      component: Template,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.SUPER_ADMIN],
      },
    },
    {
      path: '/super-admin/thresholds',
      name: 'thresholds',
      component: Threshold,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.SUPER_ADMIN],
      },
    },
    {
      path: '/super-admin/typical-biases',
      name: 'typical-biases',
      component: TypicalBiases,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.SUPER_ADMIN],
      },
    },
    {
      path: '/super-admin/copy',
      name: 'copy',
      component: Copies,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.SUPER_ADMIN],
      },
    },
    // Routes compte
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN, UserRole.TALENT, UserRole.SUPER_ADMIN],
      },
    },
    {
      path: '/account/edit',
      name: 'account-edit',
      component: AccountEdit,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN, UserRole.TALENT, UserRole.SUPER_ADMIN],
      },
    },
    // Routes review
    {
      path: '/reviews',
      name: 'reviews',
      component: Reviews,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN, UserRole.TALENT],
      },
    },
    {
      path: '/reviews/create',
      name: 'review-create',
      component: ReviewCreate,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN, UserRole.TALENT],
      },
    },
    {
      path: '/reviews/success',
      name: 'review-sent',
      component: ReviewSent,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN, UserRole.TALENT],
      },
      props: true, // des props peuvent être envoyées via params
    },
    {
      path: '/reviews/:reviewId',
      name: 'review',
      component: Review,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN, UserRole.TALENT],
      },
    },
    // Routes Cycles
    {
      path: '/cycles',
      name: 'cycles',
      component: Cycles,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
    },
    {
      path: '/cycles/setup',
      name: 'cycle-setup',
      component: CycleSetup,
      props: true,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
    },
    {
      path: '/cycles/:cycleId/settings',
      name: 'cycle-settings',
      component: CycleSettings,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
    },
    {
      path: '/cycles/:cycleId/edit',
      name: 'cycle-edit-talents',
      component: CycleEditTalents,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
    },
    {
      path: '/cycles/:cycleId',
      name: 'cycle',
      component: Cycle,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
    },
    // routes Talents
    {
      path: '/talents',
      name: 'talents',
      component: Talents,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
    },
    {
      path: '/talents/import',
      name: 'talents-import',
      component: TalentsImport,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
      props: true,
    },
    {
      path: '/talents/:talentId',
      name: 'talent',
      component: Talent,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: {
        requiresAuth: true,
        allowedRoles: [UserRole.ADMIN],
      },
      props: true,
    },

    // Routes PDF
    {
      path: '/pdf/reviews/:reviewId',
      name: 'pdf-review',
      component: ReviewPdf,
      meta: {
        isPDF: true,
      },
    },
    {
      path: '/pdf/cycles/:cycleId',
      name: 'pdf-cycle',
      component: CyclePdf,
      meta: {
        isPDF: true,
      },
    },

    // Exemple de route pour une page connectée
    // {
    //   path: '/une-page-connectée',
    //   name: 'logged-page',
    //   meta: { requiresAuth: true },
    // }
  ],
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [`${process.env.VUE_APP_API_URL}`, `${process.env.VUE_APP_FRONT_URL}`],
    }),
  ],
  tracesSampleRate: 1.0,
});

router.beforeEach((to, from, next) => {
  // Vérification du login, navigation au login approprié sinon
  if (to.meta.requiresAuth && !auth.isLoggedIn()) {
    if (to.meta.allowedRoles.includes(UserRole.SUPER_ADMIN)) {
      next({ name: 'super-admin-login' });
    } else {
      next({ name: 'login' });
    }
    // Vérification du role
  } else if (auth.isLoggedIn() && to.meta.allowedRoles) {
    const { role } = auth.getUser();
    if (to.meta.allowedRoles.includes(role)) {
      next();
    } else {
      switch (role) {
        case UserRole.SUPER_ADMIN:
          next({ name: 'companies' });
          break;
        case UserRole.ADMIN:
          next({ name: 'home' });
          break;
        case UserRole.TALENT:
          next({ name: 'reviews' });
          break;
        default:
          next(false);
      }
    }
  } else {
    next();
  }
});

export default router;
