<template>
  <section class="cycle-setup-end">
    <div class="illustration">
      <img :src="TalentGrowthPlan">
    </div>
    <div class="text-content">
      <div>
        <app-title>Well done {{ user.firstname }}, you have created your {{ cycle.name }} cycle !</app-title>
        <p>{{ text }}</p>
        <router-link :to="{ name: 'cycles' }">
          <app-button >View cycle list<arrow-right class="trailing"/></app-button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import TalentGrowthPlan from '@/assets/img/talent-growth-plan.svg';
import arrowRight from '@/assets/img/icons/24px/arrow-right.svg?inline';

export default {
  components: {
    'arrow-right': arrowRight,
  },
  props: {
    text: {
      default: 'Text',
    },
    cycle: {
      required: true,
    },
  },
  data() {
    return {
      user: authApi.getUser(),
      TalentGrowthPlan,
    };
  },
  mounted() {
    document.querySelector('.wrapper').classList.add('hasStickyFooter');
  },
  beforeDestroy() {
    document.querySelector('.wrapper').classList.remove('hasStickyFooter');
  },
};
</script>

<style lang="sass">
.cycle-setup-end
  display: flex
  align-items: center
  height: calc(100vh - 68px) // Screen height - header height - footer height
  .illustration
    background: $white
    height: 100%
    width: 40vw
    max-width: 480px
    display: flex
    justify-content: center
    align-items: center
  .text-content
    width: 80vw
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding-left: 2vw
    &>div
      width: 60vw
      max-width: 800px
      h1
      margin: 0
      font-size: 36px
      p
        margin: 3em 0
        color: $link-color
        font-size: 20px
        line-height: 25px
      .app-button
        font-size: 20px
</style>
