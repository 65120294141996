<template>
  <label class="app-label" :class="{ mandatory: required }"><slot></slot></label>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.app-label
  display: block
  padding: 16px 0 8px 0
  text-align: left
  font-size: 16px
  font-weight: 700
  color: $text-color
  &.mandatory:after
    content: "*"
    display: inline-block
    font-size: 70%
    transform: translateX(0.3rem) translateY(-0.15rem)

</style>
