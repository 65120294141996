<template>
  <div class="app-select" :class="{ open: isOpen, error: showError }">
    <div class="container">
      <input
        v-if="autocomplete"
        type="text"
        class="filter"
        :class="{ visible : filter.length > 0}"
        ref="input"
        @focus="isOpen = true"
        @blur="isOpen = false"
        v-model="filter" />
      <div class="box" @click.stop="changeFocus()">
        <slot v-if="options.find(o => o.name === value)">{{ options.find(o => o.name === value)['label'] }}</slot>
        <slot v-else><span class="placeholder">Choose</span></slot>
        <svg-caret-up />
        <select
          ref="select"
          :value="value"
          :required="required"
          @focus="isOpen = true"
          @blur.capture="isOpen = false"
          @invalid.prevent="showError = true"
          @keypress="simulateAutocomplete($event)"
        >
          <option v-for="option in options" :key="option.name" :value="option.name"></option>
        </select>
      </div>
      <transition name="select">
        <ul v-if="isOpen" class="options" ref="optionsBox">
          <li v-for="option in filteredOptions" :key="option.name" @click="$emit('input', option.name); isOpen = false" :class="{ selected: option.name === value}" :ref="option.name">{{ option.label }}</li>
        </ul>
      </transition>
    </div>
    <p v-if="showError">{{ $refs.select.validationMessage }}</p>
  </div>
</template>

<script>
import SvgCaretUp from '@/assets/img/icons/16px/caret-up.svg?inline';

export default {
  components: {
    'svg-caret-up': SvgCaretUp,
  },
  props: {
    value: [String, Array, Number],
    // Format [{ name: 'lorem', label: 'Lorem Ipsum'}]
    options: [Array],
    required: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      showError: false,
      lastBlur: Date.now(),
      filter: '',
    };
  },
  watch: {
    isOpen(newValue) {
      if (!newValue) {
        this.lastBlur = Date.now();
        this.filter = '';
      }
    },
    value() {
      this.showError = false;
    },
  },
  computed: {
    filteredOptions() {
      return this.options.filter((o) => o.label.toLowerCase().includes(this.filter.toLowerCase()));
    },
  },
  methods: {
    // Pour le cas où on veut fermer la boite en cliquant
    // L'événement blur arrive bien avant l'événement click donc la boite se ferme puis se réouvre
    // le if else permet de ne pas focus la boite si le dernier blur est trop proche dans le temps
    changeFocus() {
      if (this.isOpen) {
        this.isOpen = false;
      } else if (Date.now() - this.lastBlur > 100) {
        this.$refs.select.focus();
      }
    },
    // Focus un input permettant de filtrer les options affichées
    simulateAutocomplete(event) {
      if (this.autocomplete && event.code.startsWith('Key')) {
        this.filter = event.key;
        this.$refs.input.focus();
      }
    },
  },
};
</script>

<style lang="sass">
.app-select
  .container
    position: relative
    max-width: 400px
    background: white
    text-align: left
    border: 2px solid $light-color
    border-radius: $global-border-radius
    transition: all 0.2s ease-in-out
    cursor: pointer
    padding: 0.2rem 0
    &:hover
      border-color: $medium-gray
    &:focus
      outline: 0
      border-color: $main-color

    .filter
      position: absolute
      top: 0
      width: 0
      outline: 0
      border: 0
      opacity: 0
      padding-left: 0.6rem
      &.visible
        margin-left: 0.2rem
        width: 200px
        height: 100%
        opacity: 1
        background-color: $white
        z-index: 50
    .box
      position: relative
      padding: 0.7rem 1rem 0.6rem
      display: flex
      align-items: center
      justify-content: space-between
      font-weight: 400
      svg
        margin-left: 0.5rem
        transform: rotate(180deg)
        path
          fill: $common-gray
      .placeholder
        color: $medium-gray

    .options
      @include select-options

  select
    position: absolute
    opacity: 0
    width: 0
    height: 0
  &.open .container
      border-color: $main-color
      .box svg
        transform: none
  &.error
    .container
      border-color: $error-color
    p
      margin: 8px 0 20px
      font-weight: 700
      color: $error-color

@include select-transition

</style>
