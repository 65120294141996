<template>
  <div class="app-accordion" :class="{ open: isOpen, 'self-manage': selfManage }">
    <div class="accordion-header" :class="{ disabled: !selfManage }" @click="selfOpen = !selfOpen">
      <app-checkbox v-if="withCheckbox" :input="checked" @input="checked = $event"></app-checkbox>
      <div class="label">{{ label }}</div>
      <slot name="before-filler"></slot>
      <div class="filler"></div>
      <slot name="after-filler"></slot>
      <div class="edit-buttons" v-if="isEditForm">
        <app-button
          class="edit-btn"
          @click="edit"
          size="tiny"
          kind="subtle"
          v-if="!onEdit">
          <svg-editing/>
          Edit
        </app-button>
        <div class="on-edit-buttons" v-else>
          <app-button
            class="cancel-btn"
            @click="cancel"
            size="tiny"
            kind="subtle"
          >
            Cancel
          </app-button>
          <app-button
            class="save-btn"
            @click="save"
            size="tiny">
            Save
          </app-button>
        </div>
      </div>
      <app-button size="medium" kind="minimal" class="open-btn" :disabled="!selfManage">
        <svg-chevron-left class="svg-chevron-left"/>
      </app-button>
    </div>
    <transition name="accordion-body">
      <div v-show="isOpen" class="accordion-body">
        <slot name="accordion-body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import Editing from '@/assets/img/icons/24px/edit.svg?inline';
import SvgChevronLeft from '@/assets/img/icons/24px/chevron-left.svg?inline';

export default {
  components: {
    'svg-chevron-left': SvgChevronLeft,
    'svg-editing': Editing,
  },
  props: {
    label: String,
    selfManage: {
      type: Boolean,
      default: true,
    },
    showOpen: { // ouvert si pas self-manage. Valeur open par défaut si self-manage
      type: Boolean,
      default: false,
    },
    withCheckbox: { // adds a checkbox next to the label
      type: Boolean,
      default: false,
    },
    checkboxValue: {
      type: Boolean,
      default: true,
    },
    isEditForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selfOpen: this.showOpen,
      checked: true,
      onEdit: false,
    };
  },
  computed: {
    isOpen() {
      return this.selfManage ? this.selfOpen : this.showOpen;
    },
  },
  mounted() {
    this.checked = this.checkboxValue;
  },
  watch: {
    checked() {
      this.$emit('update:input', this.checked);
    },
    selfOpen() {
      if (!this.selfOpen) {
        this.cancel(null);
      }
    },
  },
  methods: {
    edit(e) {
      e.stopPropagation();
      this.onEdit = true;
      this.selfOpen = true;
      this.$emit('edit');
    },
    cancel(e) {
      if (e) {
        e.stopPropagation();
      }
      this.onEdit = false;
      this.$emit('cancel');
    },
    save(e) {
      e.stopPropagation();
      this.onEdit = false;
      this.$emit('save');
    },
  },
};
</script>

<style lang="sass">
.app-accordion
  width: 100%
  .accordion-header
    height: 48px
    padding: 6px
    display: flex
    align-items: center
    justify-content: flex-start
    background: $background-color
    .app-checkbox
      margin-right: 16px
    .label
      flex-shrink: 1
      font-weight: 700
      color: $black
    .filler
      flex-grow: 1
    .edit-buttons
      margin-right: 16px
      .edit-btn
        font-size: 16px
        padding-right: 16px
      .on-edit-buttons
        display: flex
        .cancel-btn
          margin-right: 16px
        .app-button
          font-size: 16px
          padding-right: 16px
          padding-left: 16px
    .open-btn
      flex-shrink: 1
      self-align: end
      @include icon-button
      .svg-chevron-left
        transform-origin: center
        transform: rotate(90deg)
        transition: all ease-in-out 0.2s
        path
          fill: $common-gray
      &:focus
        box-shadow: none
      &:disabled
        opacity: 1
    &:not(.disabled):hover
      background: $light-color
      cursor: pointer
  .accordion-body
    padding: 24px
    background: $background-color

  &.open
    .accordion-header
      .svg-chevron-left
        transform: rotate(-90deg)

.accordion-body-enter-active, .accordion-body-leave-active
  transition: all 0.25s ease-in-out
.accordion-body-enter, .accordion-body-leave-to
  opacity: 0
  transform: translateY(-10px)
</style>
