<template>
  <div class="app-checkbox" :class="{ error: showValidationMessage && $refs.checkbox.validationMessage, disabled, 'error-main-color': errorMainColor }" @click.stop>
      <label @mouseover="disabled ? null : hover = true" @mouseleave="hover = false">
        <input type="checkbox"
          :checked="checked"
          ref="checkbox"
          v-bind="$attrs"
          :disabled="disabled"
          @invalid.prevent="handleInvalid"
          @change="onChange()"/>
        <span class="checkmark">
          <svg-tick v-if="checked && !hover" />
          <svg-dash v-if="checked && hover" />
        </span>
        <slot></slot>
      </label>
      <p v-if="validationError" class="error-text">{{ validationError }}</p>
  </div>
</template>

<script>
import SvgTick from '@/assets/img/icons/16px/tick.svg?inline';
import SvgDash from '@/assets/img/icons/16px/dash.svg?inline';

export default {
  components: {
    'svg-tick': SvgTick,
    'svg-dash': SvgDash,
  },
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [Array, Boolean],
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: Number,
    max: Number,
    errorMainColor: { // Affiche les errors avec la couleur principale
      type: Boolean,
    },
  },
  data() {
    return {
      hover: false,
      showValidationMessage: false,
      validationError: null,
    };
  },
  computed: {
    checked() {
      return Array.isArray(this.input) ? this.input.includes(this.value) : this.input;
    },
  },
  watch: {
    // Check validity of checkbox
    input() {
      this.checkValidity();
    },
  },
  methods: {
    onChange() {
      if (this.value) { // when the input must be an array
        let response = null;
        response = Array.isArray(this.input) ? this.input : [];

        if (response.includes(this.value)) {
          response.splice(response.indexOf(this.value), 1);
        } else {
          response.push(this.value);
        }
        this.$emit('input', response);
      } else { // when the input must be a boolean
        this.$emit('input', !this.checked);
      }
    },
    handleInvalid() {
      this.validationError = this.$refs.checkbox.validationMessage;
    },
    checkValidity() {
      this.validationError = null;
      if (this.min || this.max) {
        const values = Array.isArray(this.input) ? this.input : [];
        if (values.length < this.min || values.length > this.max) {
          this.$refs.checkbox.setCustomValidity(`Please choose ${this.min} to ${this.max} options.`);
        } else {
          this.$refs.checkbox.setCustomValidity('');
        }
      }
    },
  },
  mounted() {
    this.checkValidity();
  },
};
</script>

<style lang="sass">
.app-checkbox
  display: inline-block
  label
    display: flex
    align-items: center
    position: relative
    cursor: pointer
    user-select: none
    color: $common-gray
    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

      &:checked ~ .checkmark
        background-color: $main-color
        border-color: transparent

      &:checked ~ .checkmark:after
        opacity: 1

    .checkmark
      display: flex
      align-items: center
      margin-right: 5px
      height: 20px
      width: 20px
      border: 1px solid $light-color
      background: white
      border-radius: 2px
      transition: background ease-in-out 0.2s
      svg
        width: 10px
        height: 10px
        margin: auto
        path
          fill: $white

  &.disabled
    label
      cursor: default
      .checkmark
        opacity: 0.5

  .error-text
    color: $error-color
    margin: 0.3rem 0 0 0
    font-weight: 700
  &.error
    .checkmark
      border-color: $error-color

  &.error-main-color
    .error-text
      color: $main-color
    &.error
      .checkmark
        border-color: $main-color

</style>
