<template>
  <div class="form-complete">
    <!-- Accordion: Section du formulaire -->
    <app-accordion v-for="(section, index) in sections" :key="section.key" :label="section.label" :showOpen="index === 0" :id="`section-${index}`">
      <!-- Body de l'accordéon -->
      <template v-slot:accordion-body>
        <!-- formulaire -->
        <form :name="section.key">
          <form-question
            v-for="question in section.questions"
            :key="question.questionId"
            :question="question"
            :max="question.appliedMax"
            :min="question.appliedMin"
            disabled
          >
          </form-question>
        </form>
      </template>
    </app-accordion>
    <app-sticky-footer v-if="review && review.status === ReviewStatus.PROCESSING">
      <app-button disabled>Finish TGP</app-button>
    </app-sticky-footer>
  </div>
</template>

<script>
import ReviewStatus from '@/services/enums/review-status.enum';
import FormSectionLabel from '@/services/enums/form-section-label.enum';
import FormQuestion from './FormQuestion.vue';

export default {
  components: { FormQuestion },
  name: 'form-complete',
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sections: [],
      ReviewStatus,
    };
  },
  methods: {
    // Initialises les sections, ordonne les questions dans chaque section
    initSections() {
      const sections = Object.entries(FormSectionLabel).map(([key, label]) => ({
        key,
        label,
        questions: this.review.questions
          .filter((question) => question.section === key)
          .sort((qA, qB) => qA.questionOrder - qB.questionOrder)
          .map((question) => {
            // vérifie les dépendences
            let { min } = question;
            if (question.mandatoryDependency) {
              const dependency = this.review.questions.find((q) => q.questionReference === question.mandatoryDependency);
              if (dependency.answer.value !== question.mandatoryDependencyValue) {
                min = 0;
              }
            }
            return {
              ...question,
              appliedMin: +min,
              appliedMax: +question.max,
            };
          }),
        open: false,
      }));
      this.sections = sections;
    },
  },
  mounted() {
    this.initSections();
  },
};
</script>

<style lang="sass">
.form-complete
  .app-accordion
    @include form-section
  .app-sticky-footer
    justify-content: center

</style>
