<template>
  <section class="cycle-setup-form">

    <div class="nudge help">
      <SvgInformation />
      <p><b>This screen is to help you setup the review (Talent Growth Plan) your writers will fill in for the cycle and you can browse through the questionnaire below.</b><br><br>
      <b>IMPORTANT</b>: You can add two optional questions which are customised to your business: A question helping you to understand a talent's fit to your business values, and another question to appraise your talents on the key leadership competences which are important to your organisation. Simply ignore those questions if you do not want them in your talent cycle. But if you do, please browse down and tell us what those values and those leadership values and competences are.</p><br>
    </div>

    <Loader v-if="isLoading" />
    <form ref="cycleForm">
      <template v-for="section in sections">
        <section class="form-section" v-if="section.hasOptionalQuestions" :key="section.key">
          <app-subtitle>{{ FormSectionLabel[section.key] }}</app-subtitle>
          <div class="optionals" v-if="section.hasOptionalQuestions">
            <p class="optionals-title">Optional Questions</p>
            <div class="question" v-for="question in section.questions" :key="question.reference">
              <app-accordion :label="question.title" v-if="question.optional" withCheckbox :checkboxValue="!!questions.find((q) => q.questionReference === question.questionReference)" @update:input="addOrRemoveQuestion($event, question)">
                <template v-slot:accordion-body>
                  <p>{{ question.questionText }}</p>
                  <div class="question-options" v-if="question.configurable">
                    <div class="option" v-for="oIndex in question.options.numberOptionsMax" :key="oIndex">
                      <app-label>Option {{ oIndex }}</app-label>
                      <app-input
                        ref="app-input"
                        type="text"
                        :value="(findByReference(question.questionReference) || findTemplateByReference(question.questionReference)).options.values[oIndex - 1] || null"
                        :required="optionRequired(question.questionReference, oIndex)"
                        :disabled="!findByReference(question.questionReference)"
                        @input="addOption($event, question, oIndex - 1)"
                      ></app-input>
                    </div>
                  </div>
                </template>
              </app-accordion>
            </div>
          </div>
        </section>
      </template>
    </form>
    <app-sticky-footer type="error" v-if="hasErrors">
      <svg-alert/><p>One or several configurable question(s) requires more options</p>
      <app-button kind="destructive" size="medium" @click="goToFirstFieldInError">Show me</app-button>
    </app-sticky-footer>
    <app-sticky-footer type="actions">
      <app-button kind="secondary" @click="goPreviousStep">Back</app-button>
      <app-button @click="goNextStep">Next</app-button>
    </app-sticky-footer>
  </section>
</template>

<script>
import templateApi from '@/services/api/template';

import FormSectionLabel from '@/services/enums/form-section-label.enum';

import SvgAlert from '../../../assets/img/icons/24px/alert.svg?inline';
import SvgInformation from '../../../assets/img/icons/24px/information.svg?inline';

import Loader from '../../../components/Loader.vue';

export default {
  components: {
    'svg-alert': SvgAlert,
    Loader,
    SvgInformation,
  },
  props: {
    oldQuestions: {
      type: Array,
      default() {
        return [];
      },
    },
    duplicatedQuestions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      templates: null,
      sections: [],
      FormSectionLabel,
      questions: [],
      hasErrors: false,
      isLoading: false,
    };
  },
  mounted() {
    this.questions = this.oldQuestions.map((oq) => oq);
    this.getTemplates();
  },
  methods: {
    async getTemplates() {
      this.isLoading = true;
      try {
        const response = await templateApi.getTemplates();
        this.templates = response.data;
        this.initSections();
        if (this.questions.length <= 0) {
          let questions = this.templates.filter((q) => !q.optional);
          /** Pour une duplication, pré-remplir le formulaire :
           * - cocher seulement les options optionnelles préalablement choisies
           * - pré-remplir les options pour les questions configurables
           */
          if (this.duplicatedQuestions) {
            questions = [];
            this.templates.forEach((template) => {
              const question = template;
              const duplicate = this.duplicatedQuestions.find((q) => q.questionReference === template.questionReference);
              if (question.optional && !duplicate) return;
              if (question.configurable) {
                question.options.values = duplicate.options.values;
              }
              questions.push(question);
            });
          }
          this.questions = questions.map((template) => ({
            questionReference: template.questionReference,
            options: {
              numberOptionsMax: template.options?.numberOptionsMax,
              numberOptionsMin: template.options?.numberOptionsMin,
              values: template.options?.values ? template.options.values.concat(new Array((template.options.numberOptionsMax || template.options.values.length) - template.options.values.length)) : new Array(10),
            },
            section: template.section,
            optional: template.optional,
            title: template.title,
          }));
        }
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          text: `We are unable to fetch the templates. ${messages}`,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.isLoading = false;
    },
    initSections() {
      this.sections = Object.entries(FormSectionLabel).map(([key, label]) => {
        const section = {
          key,
          label,
          questions: this.templates.filter((question) => question.section === key).sort((qA, qB) => qA.questionOrder - qB.questionOrder),
          open: false,
        };
        return {
          ...section,
          hasOptionalQuestions: section.questions.some((question) => question.optional),
        };
      });
    },
    findByReference(reference) {
      return this.questions.find((q) => q.questionReference === reference);
    },
    findTemplateByReference(reference) {
      const template = this.templates.find((q) => q.questionReference === reference);
      // Si le template n'a pas d'options, les initialiser
      if (template.configurable && !template.options.values) {
        template.options.values = [];
      }
      return template;
    },
    optionRequired(questionReference, oIndex) {
      const question = this.findByReference(questionReference);
      return question && oIndex <= question.options.numberOptionsMin && question.options.values.filter((o) => !!o).length < question.options.numberOptionsMin;
    },
    addOrRemoveQuestion(isAdded, template) {
      if (isAdded) {
        this.questions.push({
          questionReference: template.questionReference,
          options: {
            numberOptionsMin: template.options.numberOptionsMin,
            numberOptionsMax: template.options.numberOptionsMax,
            values: template.options.values,
          },
          section: template.section,
          optional: template.optional,
          title: template.title,
        });
      } else {
        const index = this.questions.findIndex((q) => q.questionReference === template.questionReference);
        if (index >= 0) {
          this.questions.splice(index, 1);
        }
      }
    },
    addOption(input, question, optionIndex) {
      const questionIndex = this.questions.findIndex((q) => q.questionReference === question.questionReference);
      this.$set(this.questions[questionIndex].options.values, optionIndex, input);
    },
    goPreviousStep() {
      this.$emit('update:input', this.questions);
      this.$emit('update:step', 1);
    },
    goNextStep() {
      this.hasErrors = !this.$refs.cycleForm.reportValidity();
      if (!this.hasErrors) {
        this.$emit('update:input', this.questions);
        this.$emit('update:step', 3);
      }
    },
    goToFirstFieldInError() {
      let fieldsInError = [];
      fieldsInError = this.$refs['app-input'].filter((field) => field.validationError);
      if (fieldsInError.length > 0) {
        const firstElement = fieldsInError[0].$parent;
        if ('showOpen' in firstElement) firstElement.selfOpen = true;
        const wrapperContainingScroll = document.querySelector('.wrapper');
        wrapperContainingScroll.scrollTo({ top: firstElement.$el.offsetTop, behavior: 'smooth' });
      }
    },
  },
};
</script>

<style lang="sass">
.cycle-setup-form
  height: calc(100% - 96px)
  display: flex
  flex-direction: column
  .loader, form
    flex-grow: 2
  .loader
    display: flex
    justify-content: center
    align-items: center
  .help
    margin-bottom: 30px
  .app-sticky-footer.error
    .app-button
      margin-left: 24px
  .form-section
    background: $white
    border-left: 4px solid
    border-radius: 4px
    padding: 24px
    margin-bottom: 32px
    .app-subtitle
      font-size: 1em
    .app-accordion
      margin-bottom: 16px
      p:not(.helper-text)
        color: $link-color
      .question-options
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        .option
          width: 48%
          .app-label
            font-size: 1em
    .optionals
      border: 2px solid $light-color
      padding: 24px
      &-title
        margin: 0
        color: $link-color
        text-transform: uppercase
        font-weight: bold
        font-size: 12px
        margin-bottom: 24px
    &:nth-child(1)
      border-color: $main-color
      .app-subtitle
        color: $main-color
    &:nth-child(2)
      border-color: $common-blue
      .app-subtitle
        color: $common-blue
    &:nth-child(3)
      border-color: $common-green
      .app-subtitle
        color: $common-green
    &:nth-child(4)
      border-color: $common-orange
      .app-subtitle
        color: $common-orange
    &:nth-child(5)
      border-color: $common-dark-purple
      .app-subtitle
        color: $common-dark-purple
</style>
