const NationalityLabel = Object.freeze({
  AF: 'Afghan',
  AL: 'Albanian',
  DZ: 'Algerian',
  US: 'American',
  AD: 'Andorran',
  AO: 'Angolan',
  AG: 'Antiguan, Barbudan',
  AR: 'Argentine',
  AM: 'Armenian',
  AU: 'Australian',
  AT: 'Austrian',
  AZ: 'Azerbaijani',
  BS: 'Bahamian',
  BH: 'Bahraini',
  BD: 'Bangladeshi',
  BB: 'Barbadian',
  BY: 'Belarusian',
  BE: 'Belgian',
  BZ: 'Belizean',
  BJ: 'Beninese',
  BT: 'Bhutanese',
  BO: 'Bolivian',
  BA: 'Bosnian, Herzegovinian',
  BR: 'Brazilian',
  GB: 'British',
  BN: 'Bruneian',
  BG: 'Bulgarian',
  BF: 'Burkinabe',
  MM: 'Burmese',
  BI: 'Burundian',
  KH: 'Cambodian',
  CM: 'Cameroonian',
  CA: 'Canadian',
  CV: 'Cape Verdian',
  CF: 'Central African',
  TD: 'Chadian',
  CL: 'Chilean',
  CN: 'Chinese',
  CO: 'Colombian',
  KM: 'Comoran',
  CD: 'Congolese',
  CG: 'Congolese',
  CR: 'Costa Rican',
  HR: 'Croatian',
  CU: 'Cuban',
  CY: 'Cypriot',
  CZ: 'Czech',
  DK: 'Danish',
  DJ: 'Djibouti',
  DM: 'Dominican',
  DO: 'Dominican',
  NL: 'Dutch',
  TL: 'East Timorese',
  EC: 'Ecuadorean',
  EG: 'Egyptian',
  AE: 'Emirati',
  GQ: 'Equatorial Guinean',
  ER: 'Eritrean',
  EE: 'Estonian',
  ET: 'Ethiopian',
  FJ: 'Fijian',
  PH: 'Filipino',
  FI: 'Finnish',
  FR: 'French',
  GA: 'Gabonese',
  GM: 'Gambian',
  GE: 'Georgian',
  DE: 'German',
  GH: 'Ghanaian',
  GR: 'Greek',
  GD: 'Grenadian',
  GT: 'Guatemalan',
  GW: 'Guinea-Bissauan',
  GN: 'Guinean',
  GY: 'Guyanese',
  HT: 'Haitian',
  HN: 'Honduran',
  HU: 'Hungarian',
  KI: 'I-Kiribati',
  IS: 'Icelander',
  IN: 'Indian',
  ID: 'Indonesian',
  IR: 'Iranian',
  IQ: 'Iraqi',
  IE: 'Irish',
  IL: 'Israeli',
  IT: 'Italian',
  CI: 'Ivorian',
  JM: 'Jamaican',
  JP: 'Japanese',
  JO: 'Jordanian',
  KZ: 'Kazakhstani',
  KE: 'Kenyan',
  KG: 'Kirghiz',
  KN: 'Kittitian or Nevisian',
  KW: 'Kuwaiti',
  LA: 'Laotian',
  LV: 'Latvian',
  LB: 'Lebanese',
  LR: 'Liberian',
  LY: 'Libyan',
  LI: 'Liechtensteiner',
  LT: 'Lithuanian',
  LU: 'Luxembourger',
  MK: 'Macedonian',
  MG: 'Malagasy',
  MW: 'Malawian',
  MY: 'Malaysian',
  MV: 'Maldivan',
  ML: 'Malian',
  MT: 'Maltese',
  MH: 'Marshallese',
  MR: 'Mauritanian',
  MU: 'Mauritian',
  MX: 'Mexican',
  FM: 'Micronesian',
  MD: 'Moldovan',
  MC: 'Monegasque',
  MN: 'Mongolian',
  ME: 'Montenegrin',
  MA: 'Moroccan',
  LS: 'Mosotho',
  BW: 'Motswana',
  MZ: 'Mozambican',
  NA: 'Namibian',
  NR: 'Nauruan',
  NP: 'Nepalese',
  NZ: 'New Zealander',
  VU: 'Ni-Vanuatu',
  NI: 'Nicaraguan',
  NG: 'Nigerian',
  NE: 'Nigerien',
  KP: 'North Korean',
  NO: 'Norwegian',
  OM: 'Omani',
  PK: 'Pakistani',
  PW: 'Palauan',
  PA: 'Panamanian',
  PG: 'Papua New Guinean',
  PY: 'Paraguayan',
  PE: 'Peruvian',
  PL: 'Polish',
  PT: 'Portuguese',
  QA: 'Qatari',
  RO: 'Romanian',
  RU: 'Russian',
  RW: 'Rwandan',
  LC: 'Saint Lucian',
  VC: 'Saint Vincentian',
  SV: 'Salvadoran',
  SM: 'Sammarinese',
  WS: 'Samoan',
  ST: 'Sao Tomean',
  SA: 'Saudi Arabian',
  SN: 'Senegalese',
  RS: 'Serbian',
  SC: 'Seychellois',
  SL: 'Sierra Leonean',
  SG: 'Singaporean',
  SK: 'Slovak',
  SI: 'Slovene',
  SB: 'Solomon Islander',
  SO: 'Somali',
  ZA: 'South African',
  KR: 'South Korean',
  SS: 'South Sudanese',
  ES: 'Spanish',
  LK: 'Sri Lankan',
  SD: 'Sudanese',
  SR: 'Surinamer',
  SZ: 'Swazi',
  SE: 'Swedish',
  CH: 'Swiss',
  SY: 'Syrian',
  TJ: 'Tadzhik',
  TZ: 'Tanzanian',
  TH: 'Thai',
  TG: 'Togolese',
  TO: 'Tongan',
  TT: 'Trinidadian',
  TN: 'Tunisian',
  TR: 'Turkish',
  TM: 'Turkmen',
  TV: 'Tuvaluan',
  UG: 'Ugandan',
  UA: 'Ukrainian',
  UY: 'Uruguayan',
  UZ: 'Uzbekistani',
  VA: 'Vatican',
  VE: 'Venezuelan',
  VN: 'Vietnamese',
  YE: 'Yemeni',
  ZM: 'Zambian',
  ZW: 'Zimbabwean',
});

export default NationalityLabel;
