<template>
  <section class="cycles" @click="closeSmallMenus">
    <div class="title-bar">
      <app-title>Talent Growth Builder</app-title>
      <app-button @click="$router.push({ name: 'cycle-setup' })" size="medium">
        <SvgPlus class="leading" />
        Create new talent cycle
      </app-button>
    </div>
    <app-tab :tabs="tabs.map(tab => tab.label)" lastAlignedRight></app-tab>
    <div class="table-container">

      <!-- Tableau des cycles (archivés ou non) -->
      <app-table v-if="showCycles" :loading="tabs[activeTabIndex].loading" :class="{ show: tabs[activeTabIndex].data.length > 0}">
        <template v-slot:headers>
          <div class="cell auto">Cycle name <SvgTriangle /></div>
          <div class="cell auto">Participants</div>
          <div class="cell auto">Release date</div>
          <div class="cell auto">End date</div>
          <div class="cell auto">Authors</div>
          <div class="cell auto">Status</div>
          <div class="cell auto"></div>
        </template>
        <template v-slot:body>
          <div class="row cycle grid-x align-middle" v-for="cycle in tabs[activeTabIndex].data" :key="cycle.cycleId" @click="$router.push({ name: 'cycle', params: { cycleId: cycle.cycleId }})" :class="{ disabled: actionRunning[cycle.cycleId] }">
            <div class="cell auto cycle-name"><b>{{ cycle.name }}</b></div>
            <div class="cell auto">{{ cycle.numberReviews }}</div>
            <div class="cell auto">{{ utils.formatDate(cycle.startDate) }}</div>
            <div class="cell auto">{{ utils.formatDate(cycle.endDate) }}</div>
            <div class="cell auto">{{ cycle.numberWriters }}</div>
            <div class="cell auto">
              <app-tag v-if="cycle.status === CycleStatus.DRAFT">
                <SvgCycleDraft />
                Draft
              </app-tag>
              <app-tag v-else-if="cycle.status === CycleStatus.ONGOING" state="main">
                <SvgCycleOngoing />
                Ongoing
              </app-tag>
              <app-tag v-else state="success">
                <SvgTick />
                Completed
              </app-tag>
            </div>
            <div class="cell auto">
              <!-- Bouton overflow avec menu -->
              <app-tooltip class="suppr-menu" :isMenu="true" position="bottom-right">
                <app-button v-if="cycle.status === CycleStatus.COMPLETED && !cycle.archivedAt" kind="minimal" size="tiny" @click.stop="archiveCycle(cycle.cycleId)">
                  <SvgArchive class="leading"/> Archive
                </app-button>
                <app-button kind="minimal" size="tiny" @click.stop="$router.push({ name: 'cycle-setup', query: { duplicateCycleId: cycle.cycleId } })">
                  <SvgDuplicate class="leading"/> Duplicate
                </app-button>
                <app-button kind="minimal" size="tiny" @click.stop="confirmDeleteCycle(cycle.cycleId)">
                  <SvgDelete class="leading"/> Delete
                </app-button>
                <template v-slot:app-tooltip-wrapper>
                  <app-button kind="minimal" size="tiny" class="overflow-button no-focus" @click.stop="openSmallMenu">
                    <SvgOverflow />
                  </app-button>
                </template>
              </app-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:empty-table class="empty-table" v-if="tabs[activeTabIndex].data.length === 0 && tabs[activeTabIndex].metadata.count === 0">
          <div class="person-img">
            <person-on-bike/>
          </div>
          <p>No Talent Cycles available. Set one up now!</p>
          <app-button size="medium" class="create-cycle-btn" @click="$router.push({ name: 'cycle-setup' })"><SvgPlus/>Create new cycle</app-button>
        </template>
      </app-table>

      <!-- Tableaux des reviews -->
      <app-table v-if="!showCycles" :loading="tabs[1].loading">
        <template v-slot:headers>
          <div class="cell auto talent-name-header">Name</div>
          <div class="cell auto">Role title</div>
          <div class="cell auto">Function</div>
          <div class="cell auto">Status <SvgTriangle /></div>
          <div class="cell auto">Bias check</div>
          <div class="cell auto"></div>
        </template>
        <template v-slot:body>
          <!-- Tableau des reviews COMPLETED -->
          <div class="row grid-x align-middle" :class="review.status.toLowerCase()" v-for="review in listReviews" :key="review.reviewId" @click="$router.push({ name: 'review', params: { reviewId: review.reviewId }})">
            <div class="cell auto talent-name">
              <talent-icon :user="review.talent"></talent-icon>
              <div class="talent-info">
                <div>{{ review.talent.firstname | capitalize }} {{ review.talent.lastname | uppercase }}</div>
                <div class="talent-email cell">{{ review.talent.email }}</div>
              </div>
            </div>
            <div class="cell auto">{{ review.talentVersion.role }}</div>
            <div class="cell auto">{{ review.talentVersion.function }}</div>
            <div class="cell auto">
              <app-tag :state="utils.getReviewEditTagState(review.status)">
                <SvgTick />
                {{ ReviewStatusLabel[review.status] }}
              </app-tag>
            </div>
            <div class="cell auto">
              <app-tag :state="utils.getBiasCheckTagState(review.status)">
                <BiasCheckComplete/>
                {{ BiasCheckLabel[review.status] }}
              </app-tag>
            </div>
            <div class="cell auto">
              <app-tooltip class="suppr-menu" :isMenu="true" position="bottom-right">
                <app-button kind="minimal" size="tiny" @click.stop="$refs['review-delete'].show(review.reviewId)">
                  <SvgDelete class="leading"/> Delete
                </app-button>
                <template v-slot:app-tooltip-wrapper>
                  <app-button kind="minimal" size="tiny" class="overflow-button no-focus" @click.stop="openSmallMenu">
                    <SvgOverflow />
                  </app-button>
                </template>
              </app-tooltip>
            </div>
          </div>
        </template>
      </app-table>
      <pagination v-bind="tabs[activeTabIndex].metadata" @update:page="changePage"/>
    </div>
    <review-delete ref="review-delete" @delete="getReviews(tabs[1].metadata.limit || $route.query.limit, tabs[1].metadata.offset || $route.query.offset)"></review-delete>
  </section>
</template>

<script>
import utils from '@/services/utils';
import cycleApi from '@/services/api/cycle';
import reviewApi from '@/services/api/review';
import authApi from '@/services/auth';

// Components
import Pagination from '@/components/Pagination.vue';
import TalentIcon from '@/components/TalentIcon.vue';
import ReviewDelete from '@/views/reviews/ReviewDelete.vue';

// SVG
import SvgPlus from '@/assets/img/icons/24px/plus.svg?inline';
import SvgCycleDraft from '@/assets/img/icons/24px/cycle-draft.svg?inline';
import SvgCycleOngoing from '@/assets/img/icons/24px/cycle-ongoing.svg?inline';
import SvgTick from '@/assets/img/icons/24px/tick.svg?inline';
import SvgOverflow from '@/assets/img/icons/24px/overflow.svg?inline';
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import SvgDuplicate from '@/assets/img/icons/24px/duplicate.svg?inline';
import SvgArchive from '@/assets/img/icons/24px/archive.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';
import BiasCheckComplete from '@/assets/img/icons/24px/bias-check-complete.svg?inline';
import personOnBike from '@/assets/img/person-on-bike.svg?inline';

// Enums
import ReviewStatusLabel from '../../services/enums/review-status-label.enum';
import BiasCheckLabel from '../../services/enums/bias-check-label.enum';
import CycleStatus from '../../services/enums/cycle-status.enum';

export default {
  name: 'cycles',
  components: {
    SvgPlus,
    SvgCycleDraft,
    SvgCycleOngoing,
    SvgTick,
    SvgOverflow,
    SvgDelete,
    SvgDuplicate,
    SvgArchive,
    SvgTriangle,
    BiasCheckComplete,
    Pagination,
    TalentIcon,
    ReviewDelete,
    'person-on-bike': personOnBike,
  },
  data() {
    return {
      user: authApi.getUser(),
      tabs: [{
        label: 'Cycles',
        data: [],
        loading: false,
        metadata: {
          limit: 10,
          offset: 0,
          count: 0,
        },
      }, {
        label: 'Cycle-less TGPs',
        loading: false,
        data: [],
        metadata: {
          limit: 10,
          offset: 0,
          count: 0,
        },
      }, {
        label: 'Archived cycles',
        loading: false,
        data: [],
        metadata: {
          limit: 10,
          offset: 0,
          count: 0,
        },
      }],
      activeTabIndex: +this.$route.query.activeTab || 0,
      actionRunning: {}, // liste des cycles en cours d'action
      utils,
      ReviewStatusLabel,
      BiasCheckLabel,
      CycleStatus,
    };
  },
  computed: {
    showCycles() {
      return [0, 2].includes(this.activeTabIndex);
    },
    listReviews() {
      return this.tabs[1].data;
    },
  },
  watch: {
    // Surveille le changement d'onglet ou de pagination
    $route() {
      const { limit, offset } = this.$route.query;
      const activeTab = +this.$route.query.activeTab || 0;
      // Si l'onglet change
      if (activeTab !== this.activeTabIndex) {
        this.activeTabIndex = activeTab;
        // To avoid NavigationDuplicated error
        if (+limit !== this.tabs[activeTab].metadata.limit || +offset !== this.tabs[activeTab].metadata.offset) {
          this.$router.push({
            query: {
              ...this.$route.query,
              limit: this.tabs[activeTab].metadata.limit,
              offset: this.tabs[activeTab].metadata.offset,
            },
          });
        }
      } else if (+limit !== this.tabs[this.activeTabIndex].metadata.limit || +offset !== this.tabs[this.activeTabIndex].metadata.offset) {
        switch (this.activeTabIndex) {
          case 0:
            this.getCycles(limit, offset, false);
            break;
          case 1:
            this.getReviews(limit, offset);
            break;
          case 2:
            this.getCycles(limit, offset, true);
            break;
          default:
            break;
        }
      }
    },
  },
  methods: {
    // Changement de pagination (tableaux)
    changePage({ limit, offset }) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    // Requête API : GET /cycles
    async getCycles(limit, offset, archived) {
      const tab = archived ? 2 : 0;
      this.tabs[tab].loading = true;
      try {
        const response = await cycleApi.getCycles(limit, offset, archived);
        this.tabs[tab].data = response.data;
        this.tabs[tab].metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the cycles. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.tabs[tab].loading = false;
    },
    // Requête API : GET /reviews (! seulement les reviews Completed)
    async getReviews(limit, offset) {
      this.tabs[1].loading = true;
      try {
        const response = await reviewApi.getReviews(limit, offset);
        this.tabs[1].data = response.data;
        this.tabs[1].metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the TGPs. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.tabs[1].loading = false;
    },
    // Requête API : archive un cycle
    async archiveCycle(cycleId) {
      this.$set(this.actionRunning, cycleId, true);
      try {
        await cycleApi.editCycle(cycleId, { archived: true });
        this.getCycles(this.tabs[this.activeTabIndex].metadata.limit, this.tabs[this.activeTabIndex].metadata.offset);
        this.$notification.show({ text: 'Cycle archived succesfully!' });
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Error while archiving the cycle. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.$set(this.actionRunning, cycleId, false);
    },
    // Requête API : supprime un cycle
    async deleteCycle(cycleId) {
      this.$set(this.actionRunning, cycleId, true);
      try {
        await cycleApi.deleteCycle(cycleId);
        this.getCycles(this.tabs[this.activeTabIndex].metadata.limit, this.tabs[this.activeTabIndex].metadata.offset);
        this.$notification.show({ text: 'Cycle deleted succesfully!' });
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Error while deleting the cycle. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.$set(this.actionRunning, cycleId, false);
    },

    confirmDeleteCycle(cycleId) {
      this.$message.show({
        title: 'All the great Talent Growth Plans in this cycle will be lost',
        text: `Hi ${this.user.firstname}, are you really really sure you want to delete this cycle? There is no going back and all your great Talent Growth Plans will be lost. Before you press Delete, you might want to print and/or save your TGPs. Your decision, ${this.user.firstname}!`,
        hasCancel: true,
        cancelText: 'Cancel',
        confirmText: 'Delete anyway',
        isDestructive: true,
        onConfirm: async () => { await this.deleteCycle(cycleId); },
      });
    },
    // Ouvre un menu de bout de ligne
    openSmallMenu(e) {
      if (!e.currentTarget.parentElement.previousSibling.classList.contains('opened')) {
        if (this.oneSmallMenuIsOpened) {
          this.closeSmallMenus();
        }
        e.currentTarget.parentElement.previousSibling.classList.add('opened');
        this.oneSmallMenuIsOpened = true;
      } else {
        this.closeSmallMenus();
      }
    },
    // ferme un menu de bout d eligne
    closeSmallMenus() {
      if (this.oneSmallMenuIsOpened) {
        const openedMenus = document.querySelectorAll('.suppr-menu .opened');
        openedMenus.forEach((openedMenu) => {
          openedMenu.classList.remove('opened');
        });
        this.oneSmallMenuIsOpened = false;
      }
    },
  },
  mounted() {
    const activeTab = +this.$route.query.activeTab || 0;
    this.tabs[activeTab].metadata.limit = +this.$route.query.limit || this.tabs[activeTab].metadata.limit;
    this.tabs[activeTab].metadata.offset = +this.$route.query.offset || this.tabs[activeTab].metadata.offset;

    this.getCycles(this.tabs[0].metadata.limit, this.tabs[0].metadata.offset, false);
    this.getReviews(this.tabs[1].metadata.limit, this.tabs[1].metadata.offset);
    this.getCycles(this.tabs[2].metadata.limit, this.tabs[2].metadata.offset, true);
  },
};
</script>

<style lang="sass">
.cycles
  @include screen
  .title-bar
    display: flex
    align-items: center
    justify-content: space-between
  .table-container
    @include table-container
    .app-table
      &:not(.show) .body
        display: flex
        flex-direction: column
        align-items: center
      .body .row
        @include clickable-row
        width: 100%
        b
          color: $black
        .talent-name
          display: flex
          .talent-info
            margin-left: 0.5rem
            div:first-child
              color: $black
              font-weight: 700
            div:last-child
              font-size: 13px
        .pending-owner
          @include pending-owner
        .cell:last-child
          display: flex
          justify-content: flex-end
          .app-tooltip-container
            display: none
            background: $white
            position: absolute
            z-index: 5
            right: -7px !important
            box-shadow: 0px 2px 8px 0px rgba(103, 102, 98, 0.2)
            border-radius: 4px
            .app-button
              width: 100%
              border-radius: 0
            &.opened
              display: block
          .overflow-button
            @include icon-button
            width: 2.5rem
            height: 2.5rem
      .row.disabled
        opacity: 0.3
      .empty-table
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        p
          margin: 2rem 0
          color: $link-color
        .create-cycle-btn
          width: 350px
          display: flex
          justify-content: center
  .svg-triangle
    margin: 0 0 0 10px
</style>
