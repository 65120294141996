<template>
  <datepicker class="app-datepicker" :placeholder="placeholder" format="dd / MM / yy" :value="input" @selected="onSelect" clear-button monday-first :disabled-dates="disabledDates"></datepicker>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    Datepicker,
  },
  props: {
    value: [Date],
    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password'].indexOf(val) !== -1,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabledDates: {
      type: Object,
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
  methods: {
    onSelect(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="sass">
.app-datepicker
  position: relative
  display: inline-block
  width: 100%
  max-width: 400px
  input
    width: 100%
    padding: 0.95rem 1rem 0.8rem
    background: white
    font-size: $global-font-size
    border-radius: $global-border-radius
    border: 2px solid $light-color
    transition: all 0.2s ease-in-out
    &:hover
      border-color: darken($light-color, 10%)
    &:focus
      outline: 0
      border-color: $main-color
    &::placeholder
      color: $medium-gray

  .vdp-datepicker__calendar
    box-shadow: 0px 2px 8px 0px rgba(103, 102, 98, 0.2)
    border-radius: $global-border-radius
    z-index: 1000
    header
      width: 298px !important
      background: $white !important
      position: relative !important
      >span
        border-radius: $global-border-radius
    .cell
      border-radius: $global-border-radius
      &:hover:not(.disabled)
        background: $light-color
        border: 0px !important
      &.selected
        background: $main-color
        color: $white
        &:hover
          background: $main-secondary-color
  .vdp-datepicker__clear-button, .vdp-datepicker__calendar-button
    position: absolute
    top: 50%
    right: 10px
    margin-top: -10px
</style>
