<template>
  <div class="copy-line">
    <app-subtitle>{{ CopyKeyLabel[this.copy.key] }}</app-subtitle>
    <app-input ref="copyInput" :class="{ active: isActive }" :multiline="true" v-model="tmpText"></app-input>

    <template  v-if="isActive">
      <app-button @click="handleSave" size="medium">Save</app-button>
      <app-button class="cancel" size="medium" kind="secondary" @click="handleCancel">Cancel</app-button>
    </template>
    <app-button v-else @click="handleActive" size="medium">Edit</app-button>
  </div>
</template>

<script>
import copyApi from '../../services/api/copy';
import CopyKeyLabel from '../../services/enums/copy-key-label.enum';

export default {
  name: 'copy-line',
  props: {
    copy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tmpText: '',
      isActive: false,
      CopyKeyLabel,
    };
  },
  async mounted() {
    this.getText();
  },
  methods: {
    getText() {
      this.tmpText = this.copy.copyText;
    },
    handleCancel() {
      this.tmpText = this.copy.copyText;
      this.handleActive();
    },
    handleActive() {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.$refs.copyInput.focus();
      }
    },
    async handleSave() {
      try {
        const response = await copyApi.updateCopy(this.copy.copyId, this.tmpText);
        this.$notification.show({ text: `Copy "${CopyKeyLabel[this.copy.key]}" successfully updated !` });
        this.$emit('copy-line-text-updated', response.data.copyText);
        this.handleActive();
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to update the copy "${CopyKeyLabel[this.copy.key]}". ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.copies
  @include screen
  .container
    padding: 51px 39px
    background-color: $white
    .copy
      padding: 24px
      background-color: $background-color
      .app-subtitle
        margin: 0 0 12px 24px
        color: $main-color
        font-size: 18px
      .app-input
        pointer-events: none
        textarea
          min-height: 164px
          background-color: $light-color
          &:focus
            background-color: $white
        &.active
          pointer-events: initial

      ~ .copy
        margin-top: 39px
      .app-button
        float: right
        margin: 12px 0 auto auto
        &.cancel
          margin-right: 12px
</style>
