<template>
  <section class="cycle-setup-talents">
    <multiple-talents-select @update:input="handleChange($event)" :oldSelectedTalents="selectedTalents" :errors="errors" v-if="!isResolveErrorsPhase"></multiple-talents-select>
    <resolve-talents-errors @update:input="handleChangeOnError($event)" @update:talentToRemove="removeOne($event)" @update:talentToAssignTo="showOwnerSelectForOne($event)" @update:exit="exitResolveErrors" v-else :talents="errors" :selectedErrors="selectedErrors"></resolve-talents-errors>
    <app-sticky-footer type="error" class="errorFooter" v-if="errors.length > 0 && !isResolveErrorsPhase">
      <svg-alert/><p>{{errors.length}} of your selected Talent Growth Plans {{ errors.length === 1 ? 'is' : 'are' }} missing an author. Click resolve to either assign authors, or remove them from your selection.</p>
      <app-button kind="destructive" size="medium" @click="resolveErrors">Resolve</app-button>
    </app-sticky-footer>
    <app-sticky-footer type="actions" v-if="!isResolveErrorsPhase" :class="{onSelect: selectedTalents.length > 0}">
      <div class="counter">
        <p>{{ selectedTalents.length }} talents selected</p>
      </div>
      <div>
        <app-button kind="secondary" class="goBackBtn" @click="goPreviousStep">Back</app-button>
        <app-button kind="secondary" class="deselectBtn" @click="deselectAll">Deselect</app-button>
        <app-button @click="goNextStep" :disabled="errors.length > 0 || selectedTalents.length < 1">Next</app-button>
      </div>
    </app-sticky-footer>
    <app-sticky-footer type="actions" v-else :class="{onSelect: selectedErrors.length > 0}">
      <div class="counter">
        <p>{{ selectedErrors.length }} talents selected</p>
      </div>
      <div>
        <app-button kind="secondary" class="goBackBtn" @click="exitResolveErrors">Back</app-button>
        <app-button kind="secondary" class="deselectBtn" @click="deselectAllErrors">Deselect</app-button>
        <app-button kind="secondary" class="removeBtn" @click="removeAll">Remove talent</app-button>
        <app-button class="goNextBtn" @click="exitResolveErrors" :disabled="errors.findIndex((e) => !e.owner) > -1">Next</app-button>
        <app-button class="assignOwnerBtn" @click="showOwnerSelect = true">Assign author</app-button>
      </div>
    </app-sticky-footer>
    <app-modal title="Select talent" :show="showOwnerSelect" @update:show="showOwnerSelect = false">
      <talent-select @update:selected-talent="tmpOwner = $event"></talent-select>
      <template v-slot:footer>
        <app-button @click="showOwnerSelect = false" kind="minimal">Cancel</app-button>
        <app-button @click="assignOwner(singularTalentToAssignOwnerTo)" :disabled="isAssigning || !tmpOwner">
          <div v-if="isAssigning" class="loader-assigning">Assigning author<app-spinner class="spinner-in-btn"></app-spinner></div>
          <span v-else>Save</span>
        </app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import talentApi from '@/services/api/talent';

import MultipleTalentsSelect from '@/components/MultipleTalentsSelect.vue';
import ResolveTalentsErrors from '@/components/ResolveTalentsErrors.vue';

import SvgAlert from '../../../assets/img/icons/24px/alert.svg?inline';
import TalentSelect from '../../talents/TalentSelect.vue';

export default {
  components: {
    MultipleTalentsSelect,
    ResolveTalentsErrors,
    TalentSelect,
    'svg-alert': SvgAlert,
  },
  props: {
    oldTalents: {
      type: Array,
    },
  },
  data() {
    return {
      selectedTalents: [],
      errors: [],
      selectedErrors: [],
      singularTalentToAssignOwnerTo: null,
      isResolveErrorsPhase: false,
      showOwnerSelect: false,
      tmpOwner: null,
      assignStep: process.env.VUE_APP_GROUP_REQUESTS_NUMBER,
      assignInterval: process.env.VUE_APP_GROUP_REQUESTS_INTERVAL,
      isAssigning: false,
      timeoutFunction: null,
    };
  },
  watch: {
    selectedTalents() {
      if (this.errors.length > 0) {
        this.verifyErrors();
      }
    },
  },
  mounted() {
    this.$emit('update:error', false);
    if (this.oldTalents) {
      this.selectedTalents = this.oldTalents;
    }
  },
  beforeDestroy() {
    if (Object.keys(this.$route.query).length > 0) {
      this.$router.push({
        query: null,
      });
    }
    if (this.timeoutFunction) {
      clearTimeout(this.timeoutFunction);
    }
  },
  methods: {
    handleChange(selectedTalents) {
      this.selectedTalents = selectedTalents;
    },
    handleChangeOnError(selectedErrors) {
      this.selectedErrors = selectedErrors;
    },
    deselectAll() {
      // Deselects all talents on the first screen
      this.selectedTalents = [];
    },
    deselectAllErrors() {
      // Deselects all talents on the Resolve Errors screen
      this.selectedErrors = [];
    },
    resolveErrors() {
      // Opens the Resolve Errors screen
      if (this.errors.length > 0) {
        this.$emit('update:error', true);
        this.isResolveErrorsPhase = true;
      }
    },
    exitResolveErrors() {
      // Closes the Resolve Errors screen
      this.isResolveErrorsPhase = false;
      this.$emit('update:error', false);
      this.verifyErrors();
    },
    removeAll() {
      // Removes all the selected errors from the selected talents
      this.selectedErrors.forEach((talent) => {
        const indexSelectedTalents = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
        this.selectedTalents.splice(indexSelectedTalents, 1);

        const indexErrors = this.errors.findIndex((t) => t.talentId === talent.talentId);
        this.errors.splice(indexErrors, 1);

        this.selectedErrors = [];

        this.verifyErrors();
      });
    },
    removeOne(talent) {
      // Removes one error from the selected talents
      const indexSelectedTalents = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      this.selectedTalents.splice(indexSelectedTalents, 1);
      const indexErrors = this.errors.findIndex((t) => t.talentId === talent.talentId);
      this.errors.splice(indexErrors, 1);

      this.verifyErrors();
    },
    showOwnerSelectForOne(talent) {
      // Opens the owner selector for one talent
      this.singularTalentToAssignOwnerTo = talent;
      this.showOwnerSelect = true;
    },
    async assignOwner(singularTalent) {
      // Assign an owner to one of several talents
      this.isAssigning = true;
      if (singularTalent) {
        // Assign an owner to one talent if the params singularTalent isn't empty
        try {
          const newTalent = await talentApi.putTalent(singularTalent.talentId, this.tmpOwner.talentId);

          const indexErrors = this.errors.findIndex((t) => t.talentId === singularTalent.talentId);
          this.errors[indexErrors].owner = newTalent.data.owner;

          const indexSelectedErrors = this.selectedErrors.findIndex((t) => t.talentId === singularTalent.talentId);
          this.selectedErrors.splice(indexSelectedErrors, 1);

          this.singularTalentToAssignOwnerTo = false;
        } catch (error) {
          const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
          this.$message.show({
            text: `The talent could not be updated. ${messages}`,
            title: 'Error',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      } else {
        // Assign an owner to all the selected errors
        const selectedErrorsStep = this.selectedErrors.map((se) => se);
        // We take the first batch of the number entered at this.assignStep
        selectedErrorsStep.splice(this.assignStep - 1, selectedErrorsStep.length - this.assignStep);
        await Promise.all(selectedErrorsStep.map(async (talent) => {
          // We update each talents
          try {
            const newTalent = await talentApi.putTalent(talent.talentId, this.tmpOwner.talentId);

            // Then we update them on the errors list
            const indexErrors = this.errors.findIndex((t) => t.talentId === talent.talentId);
            this.errors[indexErrors].owner = newTalent.data.owner;

            // Finally, we remove them from selection
            const indexSelectedErrors = this.selectedErrors.findIndex((t) => t.talentId === talent.talentId);
            this.selectedErrors.splice(indexSelectedErrors, 1);

            return newTalent;
          } catch (error) {
            const { message } = error.response.data;
            this.$message.show({
              text: `${talent.firstname} ${talent.lastname} could not be updated. \n ${Array.isArray(message) ? message.join('.\n') : message}`,
              title: 'Error',
              confirmText: 'Ok',
              hasCancel: false,
            });
            this.isAssigning = false;
            return null;
          }
        }));
      }
      if (this.selectedErrors.length > 0) {
        // And we do it again, as long as there are selectedErrors left
        this.timeoutFunction = setTimeout(this.assignOwner, this.assignInterval);
      } else {
        this.showOwnerSelect = false;
        this.isAssigning = false;
        this.tmpOwner = false;
      }
    },
    goPreviousStep() {
      this.$emit('update:input', this.selectedTalents);
      this.$emit('update:step', 2);
    },
    verifyErrors() {
      // Verify if there's still errors. Resets the errors list.
      this.errors = this.selectedTalents.filter((talent) => !talent.owner);
    },
    goNextStep() {
      this.verifyErrors();
      if (this.errors.length < 1) {
        this.$emit('update:input', this.selectedTalents);
        this.$emit('update:step', 4);
      }
    },
  },
};

</script>

<style lang="sass">
.cycle-setup-talents
  .multiple-talents-select
    margin-bottom: 60px
    min-height: calc(100vh - 332px)
  .resolve-talents-errors
    min-height: calc(100vh - 272px)
  .app-sticky-footer
    &:not(.onSelect)
      .counter
        display: none
      .deselectBtn
        display: none
      .removeBtn
        display: none
      .assignOwnerBtn
        display: none
    &.onSelect
      justify-content: space-between
      .goBackBtn
        display: none
      .goNextBtn
        display: none
  .errorFooter
    .app-button
      margin-left: 24px
  .loader-assigning
    display: flex
    align-items: center
    .spinner-in-btn
      width: 20px
      height: 20px
      margin-left: 16px
      span
        border-color: $white
        border-width: 2px
</style>
