import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/copies`;

/**
 * Updates an answer
 * @param {string} answerId : id of the answer we're trying to get
 */
const getCopy = async (copyKey) => {
  try {
    const response = await axios.get(`${API_URL}/${copyKey}`);
    return response.data;
  } catch (error) { throw error; }
};

/**
 * Get all copies
 */
const getCopies = async () => {
  try {
    const response = await axios.get(`${API_URL}`);
    return response.data;
  } catch (error) { throw error; }
};

/**
 *  Update a copy
 *
 * @param {*} copyId: string uuid
 * @param {*} copyText: string body
 * @return {*}
 */
const updateCopy = async (copyId, copyText) => {
  try {
    const response = await axios.put(`${API_URL}/${copyId}`, { copyText });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getCopy = getCopy;
api.getCopies = getCopies;
api.updateCopy = updateCopy;

export default api;
