<template>
  <section class="typical-biases">
    <div class="title-bar grid-x align-middle grid-margin-x">
      <app-title class="cell auto">Typical bias</app-title>
    </div>
    <div class="grid-x container align-center">
      <Loader v-if="loading" />

      <div v-else class="cell medium-12 copy">
        <app-accordion
          v-for="(typicalBias, index) in typicalBiases"
          :key="typicalBias.typicalBiasId"
          :label="typicalBias.label"
          :isEditForm="true"
          @edit="edit(index)"
          @cancel="cancel(index)"
          @save="save(index)"
          :id="`typical-bias-${index}`"
          class="typical-bias-accordion"
        >
        <template v-slot:accordion-body>
          <typical-bias-edit :typicalBias="typicalBias" ref="typicalBias" @save="updateTypicalBias($event)"/>
        </template>
        </app-accordion>
      </div>
    </div>
  </section>
</template>

<script>
import typicalBiasApi from '@/services/api/typical-bias';

import Loader from '../../components/Loader.vue';
import TypicalBiasEdit from './TypicalBiasEdit.vue';

export default {
  name: 'typical-biases',
  components: {
    Loader,
    TypicalBiasEdit,
  },
  data() {
    return {
      typicalBiases: [],
      typicalBiasesOnEdit: [],
      loading: false,
    };
  },
  mounted() {
    this.getTypicalBias();
  },
  methods: {
    async getTypicalBias() {
      this.loading = true;
      try {
        const response = await typicalBiasApi.getTypicalBiases();
        this.typicalBiases = response.data;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Typical biases could not be fetched. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    async updateTypicalBias(typicalBias) {
      try {
        const response = await typicalBiasApi.update(typicalBias.typicalBiasId, typicalBias);
        const index = this.typicalBiases.findIndex((i) => i.typicalBiasId === typicalBias.typicalBiasId);
        this.$set(this.typicalBiases, index, response.data);
        this.$notification.show({ text: 'Typical bias updated successfully !' });
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Typical bias could not be updated. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    edit(index) {
      this.$refs.typicalBias[index].edit();
    },
    cancel(index) {
      this.$refs.typicalBias[index].cancelTypicalBias();
    },
    save(index) {
      this.$refs.typicalBias[index].saveTypicalBias();
    },
  },
};
</script>

<style lang="sass">
.typical-biases
  @include screen
  .container
    .app-subtitle
      margin: 0 0 12px 24px
      color: $main-color
      font-size: 20px
  .app-accordion
    @include form-section
    .accordion-header, .accordion-body
      color: $main-color !important
    .accordion-header
      padding: 0 24px
    .label
      color: $main-secondary-color !important
      font-size: 20px
</style>
