<template>
  <div class="form-edit">

    <form name="formEdit">
      <!-- Accordion: Section du formulaire -->
      <app-accordion v-for="(section, index) in sections" :key="section.key" :label="section.label" :showOpen="index === 0" :id="`section-${index}`">
        <template v-slot:after-filler>
          <app-tag v-if="flagsBySection[section.key].length" state="main">
            <SvgFlag />
            {{ flagsBySection[section.key].length }} flags
            ({{ flagsBySection[section.key].filter(flag => !flag.resolved).length }} unresolved)
          </app-tag>
        </template>
        <!-- Body de l'accordéon -->
        <template v-slot:accordion-body>
          <form-question
            ref="app-input"
            v-for="question in section.questions"
            :key="question.questionId"
            :question="question"
            :max="+questionsExtraProps[question.questionIndex].appliedMax"
            :min="+questionsExtraProps[question.questionIndex].appliedMin"
            :disabled="questionsExtraProps[question.questionIndex].disabled"
            @input="question.answer.value = $event"
            >
            <flag-panel
              :flags="flagsByAnswer[question.answer.answerId]"
              :questionDisabled="questionsExtraProps[question.questionIndex].disabled"
              :savingAnswer="questionsExtraProps[question.questionIndex].savingAnswer"
              @update:flag="$set(flags, $event.index, $event)"
              @update:disabled="setQuestionDisabled(question.questionIndex, $event)"
              @cancel="restoreAnswer(question.questionIndex)"
              @save="saveAnswer(question.questionIndex)"
            ></flag-panel>
          </form-question>
        </template>
      </app-accordion>
    </form>

    <!-- Error sticky footer -->
    <app-sticky-footer v-if="error" type="error">
      <SvgAlert /><p>{{ error }}</p>
      <app-button kind="destructive" size="medium" @click="goToFirstFieldInError">Show me</app-button>
    </app-sticky-footer>

    <!-- Sticky footer -->
    <app-sticky-footer v-if="sections.length" type="actions">
      <app-button kind="secondary" :disabled="savingReview" @click="saveAndGoBack">Save and continue later</app-button>
      <app-button :disabled="savingReview" @click="completeReview">Finish TGP</app-button>
    </app-sticky-footer>
  </div>
</template>

<script>
import answerApi from '@/services/api/answer';
import flagApi from '@/services/api/flag';

import SvgFlag from '@/assets/img/icons/24px/flag.svg?inline';
import SvgAlert from '@/assets/img/icons/24px/alert.svg?inline';

import FormSectionLabel from '@/services/enums/form-section-label.enum';
import FormQuestion from './FormQuestion.vue';
import FlagPanel from '../FlagPanel.vue';
import AppStickyFooter from '../../../basics/components/AppStickyFooter.vue';

export default {
  name: 'form-edit',
  components: {
    SvgAlert,
    FormQuestion,
    FlagPanel,
    SvgFlag,
    AppStickyFooter,
  },
  props: {
    review: Object,
  },
  emits: [
    'update:last-saved',
    'update:last-saved-waiting',
    'update:numberUnresolvedFlags',
    'update:review',
  ],
  data() {
    return {
      sections: [],
      questions: this.review.questions.map((question, index) => ({
        ...question, disabled: true, questionIndex: index, cachedAnswer: '', savingAnswer: false,
      })),
      flags: [],
      error: '',
      savingReview: false,
    };
  },
  computed: {
    // Flags triés par réponses
    flagsByAnswer() {
      const sortedFlags = Object.fromEntries(this.questions.map((question) => ([question.answer.answerId, []])));
      this.flags.forEach((flag, index) => {
        sortedFlags[flag.answerId].push({
          ...flag,
          index,
        });
      });
      return sortedFlags;
    },
    // Flags triés par section
    flagsBySection() {
      const sortedFlags = Object.fromEntries(Object.entries(FormSectionLabel).map(([key]) => ([key, []])));
      this.flags.forEach((flag, index) => {
        const { section } = this.questions.find((q) => q.answer.answerId === flag.answerId);
        sortedFlags[section].push({
          ...flag,
          index,
        });
      });
      return sortedFlags;
    },
    // map sur les questions pour avoir la réactivité de certaines props
    questionsExtraProps() {
      const questionsExtraProps = this.questions.map((question) => {
        const {
          min, max, mandatoryDependency, mandatoryDependencyValue, disabled, cachedAnswer, savingAnswer,
        } = question;
        let appliedMin = min;
        if (mandatoryDependency) {
          const dependency = this.questions.find((q) => q.questionReference === mandatoryDependency);
          if (dependency.answer.value !== mandatoryDependencyValue) {
            appliedMin = 0;
          }
        }
        return ({
          disabled,
          cachedAnswer,
          savingAnswer,
          appliedMin,
          appliedMax: max,
        });
      });
      return questionsExtraProps;
    },
    numberUnresolvedFlags() {
      return this.flags.filter((f) => !f.resolved).length;
    },
  },
  watch: {
    flags() {
      this.$emit('update:numberUnresolvedFlags', this.numberUnresolvedFlags);
    },
  },
  methods: {
    // Initialises les sections, ordonne les questions dans chaque section
    initSections() {
      const sections = Object.entries(FormSectionLabel).map(([key, label]) => ({
        key,
        label,
        questions: this.questions.filter((question) => question.section === key).sort((qA, qB) => qA.questionOrder - qB.questionOrder),
        open: false,
      }));
      this.sections = sections;
    },
    // Met la question en edit/disabled et gère la réponse cachée en fonction
    setQuestionDisabled(index, disabled) {
      this.$set(this.questions[index], 'disabled', disabled);
      // Si la question est remis en disabled, vider le cache
      if (disabled) {
        this.$set(this.questions[index], 'cachedAnswer', '');
      } else {
        // Si la question est éditable, garder la réponse en cache
        const answer = this.questions[index].answer.value;
        this.$set(this.questions[index], 'cachedAnswer', Array.isArray(answer) ? [...answer] : answer); // Deep copy de l'array nécessaire
      }
    },
    // Restaure la réponse du cache
    restoreAnswer(index) {
      const { cachedAnswer } = this.questionsExtraProps[index];
      this.$set(this.questions[index].answer, 'value', cachedAnswer);
      this.setQuestionDisabled(index, true);
    },

    /* *** SAUVEGARDE *** */

    // Sauvegarde la réponse éditée
    async saveAnswer(index) {
      const { answer } = this.questions[index];
      this.$set(this.questions[index], 'savingAnswer', true);
      try {
        await answerApi.updateAnswer(answer.answerId, answer.value);
        this.$notification.show({ text: 'Response successfully updated !' });
        this.setQuestionDisabled(index, true);
      } catch (error) {
        // Montre un footer erreur pendant 3s
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        const displayedError = `A error occured while saving your response. ${messages}`;
        this.error = displayedError;
        setTimeout(() => {
          if (this.error === displayedError) this.error = '';
        }, 3000);
      }
      this.$set(this.questions[index], 'savingAnswer', false);
    },
    // Sauvegarde toutes les réponses
    async saveAll() {
      this.$emit('update:last-saved-waiting', true);
      Promise.all(this.questions.map((q) => answerApi.updateAnswer(q.answer.answerId, q.answer.value)))
        .then(() => this.$emit('update:last-saved'))
        .finally(() => this.$emit('update:last-saved-waiting', false));
    },
    async saveAndGoBack() {
      this.savingReview = true;
      try {
        await this.saveAll();
        this.$router.go(-1);
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        const displayedError = `Your TGP could not be saved. ${messages}`;
        this.error = displayedError;
        setTimeout(() => {
          if (this.error === displayedError) this.error = '';
        }, 3000);
      }
      this.savingReview = false;
    },
    async completeReview() {
      this.error = '';
      // Vérification de la validity du form
      // la prop disabled des éléments empêche de les valider donc
      // on parcours les éléments du form pour les valider en enlevant l'éventuelle prop disabled
      let formValid = true;
      const elements = Array.from(document.forms.formEdit.elements);
      for (let i = 0; i < elements.length; i += 1) {
        const el = elements[i];
        const { disabled } = el;
        el.disabled = false;
        const elValid = el.checkValidity();
        el.disabled = disabled;
        formValid = formValid && elValid;
      }
      if (formValid) {
        this.savingReview = true;
        try {
          await this.saveAll();
          await this.confirmFinishReview();
        } catch (error) {
          const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
          const displayedError = `Your TGP could not be saved. ${messages}`;
          this.error = displayedError;
          setTimeout(() => {
            if (this.error === displayedError) this.error = '';
          }, 3000);
        }
        this.savingReview = false;
      } else {
        this.error = 'Please complete all required fields.';
      }
    },
    // Affiche un message pour confirmer la complétion du TGP
    // Envoie un événement pour compléter le TGP
    confirmFinishReview() {
      const message = {
        title: 'Complete your Talent Growth Plan',
        text: 'You have resolved all bias signals (flags) identified by Talentuum and yourTalent Growth Plan is ready to be finalised. You just need to complete it and it will be stored in its final version.',
        confirmText: 'Confirm',
        hasCancel: true,
        onConfirm: () => this.$emit('update:review'),
      };
      if (this.numberUnresolvedFlags) {
        message.title = `There are still ${this.numberUnresolvedFlags} unresolved flags`;
        message.text = 'Are you sure you are ready to save? You still have unresolved flags which might signal potential bias so make sure you have reviewed them before you save. If you have, go ahead! ';
        message.confirmText = 'Finish anyway';
      }
      this.$message.show(message);
    },

    // Requête API : récupère la liste des flags de la review
    async getFlags() {
      try {
        const response = await flagApi.getFlags(this.review.reviewId);
        this.flags = response.data;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        const text = error.response.status === '403' ? 'You are not allowed to view the flags of this review.' : `We ran into an unexpected error while fetchnig the flags. ${messages}`;
        this.$message.show({
          text,
          title: 'Error',
          hasCancel: false,
          confirmText: 'Ok',
          onConfirm: this.$router.go(-1),
        });
      }
    },
    goToFirstFieldInError() {
      let fieldsInError = [];
      // Récupération des champs en erreur
      fieldsInError = this.$refs['app-input'].filter((field) => field.$children[0].error);
      if (fieldsInError.length > 0) {
        // Récupération du parent du premier champ en erreur
        const firstFieldInError = fieldsInError[0].$el;
        const parentOfFirstElement = fieldsInError[0].$parent;
        // Si le parent accordion n'est pas ouvert alors on l'ouvre

        if ('showOpen' in parentOfFirstElement) parentOfFirstElement.selfOpen = true;
        // Récupération du contenant possédant le scroll
        const wrapperContainingScroll = document.querySelector('.wrapper');
        // Scroll vers le premier champ en erreur
        // 71 = hauteur de la barre ".message-bar"
        // 83 = margin+padding de ".form-question" + margin+hauteurTexte de ".question-title"
        setTimeout(() => {
          wrapperContainingScroll.scrollTo({ top: firstFieldInError.offsetTop - 71 - 83, behavior: 'smooth' });
        }, 200);
      }
    },
  },
  // Initialises les sections, flags et lance la sauvegarde automatique
  mounted() {
    this.initSections();
    this.getFlags();
    // Sauvegarde la section ouverte toutes les 5 min
    this.autosaveInterval = setInterval(() => {
      this.saveAll();
    }, 1000 * 60 * 5);
  },
  beforeDestroy() {
    clearInterval(this.autosaveInterval);
  },
};
</script>

<style lang="sass">
.form-edit
  .app-accordion
    @include form-section
    .accordion-body
      transition: none
  .app-sticky-footer.error
    .app-button
      margin-left: 24px

</style>
