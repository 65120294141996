<template>
  <section class="cycle-bias-in-segments">
    <div class="page">
      <app-subtitle>Bias in segments</app-subtitle>
      <p>The following matrix is an exhaustive overview of bias risks across the different groups of the cycle. Each line represents a group, each column represents a bias risk. The intersection shows the probability of a given group to be impacted by a bias</p>
      <template v-if="segments.length">
        <div class="table-whole-container" v-if="!isPDF">
          <table>
            <thead>
              <tr>
                <th>Segments</th>
                <th>Number of talents</th>
                <th v-for="tb in typicalBiasList" :key="tb.reference">
                  <a v-if="tb.link" :href="tb.link" target="_blank" class="no-style">{{ tb.label }}</a>
                  <template v-else>{{ tb.label }}</template>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="segment in segments" :key="segment.label">
                <th>{{ segment.label }}</th>
                <th>{{ segment.nbTalents }}</th>
                <td
                  class="bias-level"
                  :class="tbRisk.level.toLowerCase()"
                  v-for="tbRisk in segment.typicalBiasRisks"
                  :key="tbRisk.reference">
                  {{ RiskLevelLabel[tbRisk.level] | capitalize }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-container" v-else>
          <table v-for="(tbList, listIndex) in slicedTypicalBiasList" :key="`table-${listIndex}`">
          <thead>
            <tr>
              <th>Segments</th>
              <th>Number of talents</th>
              <th v-for="tb in tbList" :key="tb.reference">{{ tb.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="segment in segments" :key="segment.label">
              <th>{{ segment.label }}</th>
              <th>{{ segment.nbTalents }}</th>
              <td
                class="bias-level"
                :class="segment.typicalBiasRisks[nbColumnsMax * listIndex + tbIndex].level.toLowerCase()"
                v-for="(tb, tbIndex) in tbList"
                :key="segment.typicalBiasRisks[nbColumnsMax * listIndex + tbIndex].reference">
                {{ RiskLevelLabel[segment.typicalBiasRisks[nbColumnsMax * listIndex + tbIndex].level] | capitalize }}
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </template>
      <p class="data-placeholder" v-else>Not enough data available</p>
    </div>
    <div class="page" v-if="isPDF">
      <app-subtitle>Bias definition</app-subtitle>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      <ul>
        <li class="tb-description" v-for="typicalBias in typicalBiasList" :key="typicalBias.key">
          <strong>{{ typicalBias.label }}:</strong>
          <p>{{ typicalBias.description }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import StatsDisplay from '@/mixins/StatsDisplay.vue';
import RiskLevelLabel from '@/services/enums/risk-level-label.enum';

export default {
  props: {
    segmentsData: {
      type: Array,
      required: true,
    },
    typicalBiases: {
      type: Object,
      required: true,
    },
  },
  mixins: [StatsDisplay],
  data() {
    return {
      nbColumnsMax: 7, // Pour un PDF sans compter les colonnes headers
      isPDF: this.$route.meta.isPDF,
      RiskLevelLabel,
    };
  },
  computed: {
    typicalBiasList() {
      return Object.values(this.typicalBiases);
    },
    slicedTypicalBiasList() {
      const nbTables = Math.ceil(this.typicalBiasList.length / this.nbColumnsMax);
      const slicedTypicalBiasList = [];
      for (let i = 0; i < nbTables; i += 1) {
        slicedTypicalBiasList.push(this.typicalBiasList.slice(i * this.nbColumnsMax, (i + 1) * this.nbColumnsMax));
      }
      return slicedTypicalBiasList;
    },
    segments() {
      return this.segmentsData
        .filter((segment) => segment.nbAuthors >= +process.env.VUE_APP_NB_WRITERS_MIN_SEGMENT)
        .map((segmentData) => {
          const segment = {
            label: this.getPoolLabel(segmentData.pool),
            nbTalents: segmentData.nbTalents,
            typicalBiasRisks: this.typicalBiasList.map((typicalBias) => {
              const tbRisk = segmentData.typicalBiasRisks.find((tb) => tb.reference === typicalBias.reference);
              return {
                reference: typicalBias.reference,
                ...tbRisk.biasRisk,
              };
            }),
          };
          return segment;
        });
    },

  },
};
</script>

<style lang="sass">
.cycle-bias-in-segments
  table
    border-collapse: collapse
    page-break-inside: avoid
    break-inside: avoid
    table-layout: fixed
    width: max-content
    td, th
      border: 1px solid $medium-gray
      padding: 8px 4px
      &:first-child
        padding: 8px 16px
        width: 30ch
    thead
      th
        font-size: 11px
        font-weight: 700
        a, a:visited
          color: black
    tbody
      font-size: 13px
      th
        font-weight: normal
        left: 0
        &:first-child
          text-align: left
      td
        text-align: center
        min-width: 13ch
        &.bias-level
          font-weight: 700
          &.very_high, &.high
            color: $error-secondary-color
            background: $error-bg-color
          &.medium
            color: #634B0F
            background: #FAF1D9
          &.low
            color: $success-secondary-color
            background: $success-bg-color
  .table-whole-container
    position: relative
    overflow-x: scroll
    width: 100%
    table-layout: fixed
    table
      border-collapse: separate
      border-spacing: 0 !important
      tbody, thead
        th, td
          border: 1px solid $medium-gray
          border-left: 0
          border-top: 0
        th:first-child, th:nth-child(2)
          position: sticky
          background-color: $background-color
        th:first-child
          left: 0
          border-left: 1px solid $medium-gray
        th:nth-child(2)
          left: 30ch
      thead th
        border-top: 1px solid $medium-gray
        &:nth-child(2)
          left: 35.45ch
  .table-container
    display: flex
    flex-direction: column
    gap: 1rem
    background: $background-color
    table
      table-layout: fixed
      width: max-content
      break-inside: avoid
      page-break-inside: avoid
      th
        font-size: 10px
        &:first-child
          padding: 4px 8px
      td
        font-size: 12px
      thead
        th
          width: 11ch
          &:first-child
            width: 16ch
          &:nth-child(2)
            width: 5ch
      tbody
        th
          width: 5ch
          padding: 0
          &:first-child
            width: 16ch
        td
          width: 11ch
          min-width: unset

  .data-placeholder
    @include stats-placeholder

  ul
    display: flex
    flex-direction: column
    list-style: none
    padding: 0
    gap: 2rem
    li
      display: flex
      align-items: flex-start
      strong
        color: $main-color
        width: 30ch
      p
        margin: 0
        white-space: pre
        color: $common-gray
</style>
