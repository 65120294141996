<template>
  <section class="welcome">
    <img :src="SvgOnboarding" />
    <Loader v-if="loading" />
    <div v-else class="text-container">
      <app-title>Welcome to Talentuum</app-title>
      <p>{{ copyText }}</p>
      <app-button v-if="firstOnboarding" @click="$emit('update:step', 1)">
        Setup your account
        <SvgArrowRight class="trailing" />
      </app-button>
      <div class="btns-container" v-else>
        <app-button @click="$router.push({ name: 'talents' })">
          View talent list
          <SvgArrowRight class="trailing" />
        </app-button>
        <app-button kind="minimal" @click="$router.push({ name: 'home' })">
          Go to home page
        </app-button>
      </div>
    </div>
  </section>
</template>

<script>
import copyApi from '@/services/api/copy';
import CopyKey from '@/services/enums/copy-key.enum';

import SvgOnboarding from '@/assets/img/onboarding.svg';
import SvgArrowRight from '@/assets/img/icons/24px/arrow-right.svg?inline';

import Loader from '../../components/Loader.vue';

export default {
  components: {
    SvgArrowRight,
    Loader,
  },
  props: {
    firstOnboarding: {
      required: true,
      type: Boolean,
    },
  },
  emits: ['update:step'],
  data() {
    return {
      loading: false,
      copyText: '',
      SvgOnboarding,
    };
  },
  methods: {
    // Requête API : text copy
    async getCopyText() {
      this.loading = true;
      try {
        const response = await copyApi.getCopy(this.firstOnboarding ? CopyKey.ONBOARDING_START_1 : CopyKey.ONBOARDING_START_2);
        this.copyText = response.data.copyText;
      } catch (error) {
        this.copyText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip';
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getCopyText();
    // Enlève l'espace supplémentaire du wrapper
    document.querySelector('.wrapper').style = 'padding-bottom: 0px';
  },
  destroyed() {
    // Remet l'espace supplémentaire du wrapper
    document.querySelector('.wrapper').style = 'padding-bottom: var(--wrapper-padding-bottom)';
  },
};
</script>

<style lang="sass">
.welcome
  height: calc(100vh - #{$header-height})
  padding-bottom: 40px
  display: flex
  align-items: center
  img
    height: calc(100vh - #{$header-height} - 40px)
  .loader
    margin: 0 auto
  .text-container
    width: 40%
    max-width: 900px
    margin: 0 auto
    p
      color: $common-gray
      font-size: 20px
      margin-bottom: 3rem
    .btns-container
      display: flex
      .app-button
        margin-right: 1rem
</style>
