<template>
  <div class="app-radio" :class="{ error: validationError, disabled, 'error-main-color': errorMainColor }">
      <label>
        <input type="radio"
          :checked="checked"
          ref="radio"
          v-bind="$attrs"
          :value="value"
          :disabled="disabled"
          @invalid.prevent="handleInvalid"
          @click="onChange()"/>
        <span class="checkmark">
          <svg-tick v-if="checked" />
        </span>
        <slot></slot>
      </label>
      <p v-if="validationError" class="error-text">{{ validationError }}</p>
  </div>
</template>

<script>
import SvgTick from '@/assets/img/icons/16px/tick.svg?inline';

export default {
  components: {
    'svg-tick': SvgTick,
  },
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Array, Number, Object],
    value: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMainColor: { // Affiche les errors avec la couleur principale
      type: Boolean,
    },
  },
  data() {
    return {
      validationError: null,
    };
  },
  computed: {
    checked() {
      return this.input === this.value;
    },
  },
  watch: {
    input() {
      this.validationError = null;
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.checked ? null : this.value);
    },
    handleInvalid() {
      this.validationError = this.$refs.radio.validationMessage;
    },
  },
};
</script>

<style lang="sass">
.app-radio
  label
    display: flex
    align-items: center
    position: relative
    cursor: pointer
    user-select: none
    color: $common-gray
    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

      &:checked ~ .checkmark
        background-color: $main-color
        border-color: transparent

      &:checked ~ .checkmark:after
        opacity: 1

    .checkmark
      display: inline-block
      margin-right: 5px
      height: 20px
      width: 20px
      border: 2px solid $light-color
      background: white
      border-radius: 50%
      svg
        width: 8px
        height: 8px
        margin: 25%
        path
          fill: $white
      &:hover
        border-color: $main-color

  &.disabled
    label
      cursor: none
      .checkmark
        opacity: 0.5

  .error-text
    color: $error-color
    margin: 0.3rem 0 0 0
    font-weight: 700
  &.error
    .checkmark
      border-color: $error-color

  &.error-main-color
    .error-text
      color: $main-color
    &.error
      .checkmark
        border-color: $main-color

</style>
