<template>
  <section class="talents" @click="closeSmallMenus">
    <div class="section-content">
      <!-- Barre de titre et principales actions disponibles -->
      <div class="title-bar grid-x align-middle grid-margin-x">
        <app-title class="cell auto">Talent</app-title>
        <template v-if="editMode">
          <app-button size="medium" @click="editMode = false" :disabled="editingGroup">Close edit mode</app-button>
        </template>
        <template v-else>
          <app-button kind="minimal" size="medium" class="cell shrink" @click="downloadTemplate">
            <svg-download class="leading"/>
            CSV template
          </app-button>
          <app-button kind="secondary" size="medium" class="cell shrink" @click="editMode = true">
            <svg-edit class="leading edit"/>
            Edit talent list
          </app-button>
          <app-tooltip class="add-talent-tooltip" isMenu position="bottom-right">
            <app-button kind="minimal" size="tiny" @click="closeAddTalentTooltip(); openImportModal = true">
              <svg-upload class="leading"/>
              Upload CSV
            </app-button>
            <app-button kind="minimal" size="tiny" @click="newModal.open = true; closeAddTalentTooltip()">
              <svg-plus class="leading"/>
              Add talent manually
            </app-button>
            <template v-slot:app-tooltip-wrapper>
              <app-button kind="primary" size="medium" class="cell shrink">
                  <svg-plus class="leading"/>
                  Add new talent
                </app-button>
            </template>
          </app-tooltip>
        </template>
      </div>

      <!-- Barre de recherche avec boutons de filtres -->
      <div class="search-bar">
        <app-search placeholder="Search..." v-model="searchName" :disabled="editingGroup"></app-search>
        <div class="clear-filters-container" v-if="$refs.talentFilters">
          <app-button v-if="filtersCount > 0" kind="minimal" size="medium" class="clear-filters-button" @click="$refs.talentFilters.clearAll">
            <svg-cross class="leading" />
            Clear filters
          </app-button>
        </div>
        <app-button kind="minimal" size="medium" @click="openFilters = true" :disabled="editingGroup">
          Filters {{ filtersCount ? `(${filtersCount})` : '' }}
          <svg-filter class="trailing" />
        </app-button>
      </div>
      <div class="table-container">
        <app-table class="table" :loading="loading">
          <template v-slot:headers>
            <div class="cell shrink" v-if="editMode">
              <app-checkbox :input="selectedAll" @input="toggleAll" :disabled="editingGroup"></app-checkbox>
            </div>
            <div class="cell auto">Name <svg-triangle/></div>
            <div class="cell auto">Role title</div>
            <div class="cell auto">Function</div>
            <div class="cell auto">Author</div>
            <div class="cell shrink"></div>
          </template>
          <template v-slot:body>
            <div class="row grid-x align-middle" v-for="talent in talents" :key="talent.talentId" @click="!editMode && $router.push({ name: 'talent', params: { talentId: talent.talentId }})">
              <div class="cell shrink" v-if="editMode">
                <app-checkbox :input="isSelected(talent)" @input="toggleTalent(talent)" :disabled="editingGroup"></app-checkbox>
              </div>
              <div class="cell auto talent-name">
                <talent-icon :user="talent"></talent-icon>
                <div class="talent-info">
                  <div>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</div>
                  <div class="talent-email cell">{{ talent.email }}</div>
                </div>
              </div>
              <div class="cell auto">{{ talent.talentVersion.role }}</div>
              <div class="cell auto">{{ talent.talentVersion.function }}</div>
              <div class="cell auto">
                <talent-icon v-if="talent.owner" :user="talent.owner" isLong></talent-icon>
                <div v-else class="pending-owner"></div>
              </div>
              <!-- Bouton Overflow de fin de ligne -->
              <div class="cell shrink">
                <template v-if="!editMode">
                  <app-button kind="minimal" size="tiny" class="overflow-button no-focus" @click.stop="openSmallMenu">
                    <svg-overflow />
                  </app-button>
                  <app-tooltip class="edit-suppr-menu" :isMenu="true" position="bottom-right">
                    <app-button @click.stop="$router.push({ name: 'talent', params: { talentId: talent.talentId }, query: { activeTab: 0, editing: 'true'}})" kind="minimal" size="tiny">
                        <svg-edit class="leading"/>
                        Edit
                    </app-button>
                    <app-button @click.stop="deleteTalent(talent.talentId)" kind="minimal" size="tiny">
                        <svg-delete class="leading"/>
                        Delete
                    </app-button>
                  </app-tooltip>
                </template>
              </div>
            </div>
          </template>
        </app-table>
        <pagination :limit="metadata.limit" :offset="metadata.offset" :count="metadata.count" @update:page="changePage"/>
      </div>

      <!-- Modale d'ajout manuel d'un talent -->
      <app-modal :show="newModal.open" @update:show="newModal.open = false" title="Add new talent" class="new-talent-modal">
        <div class="select-owner" v-show="newModal.assigningOwner">
          <div class="select-owner-subtitle">
            <app-link @click="newModal.tmpOwner = {}; newModal.assigningOwner = false;" size="small">
              <svg-arrow-right />
              Back
            </app-link>
            <b>Select Author</b>
          </div>
          <talent-select @update:selected-talent="newModal.tmpOwner = $event"></talent-select>
        </div>
        <talent-profile v-show="!newModal.assigningOwner" canAssignOwner isNew :assignedOwner="newModal.assignedOwner" ref="talentProfile" @submitted="newModal.open = false" @click-assign-owner="newModal.assigningOwner = true" @create:talent="getTalents(metadata.limit, metadata.offset)"></talent-profile>
        <template v-slot:footer>
          <app-button kind="minimal" @click="newModal.assigningOwner ? newModal.assigningOwner = false : newModal.open = false">Cancel</app-button>
          <app-button v-if="!newModal.assigningOwner" @click="submitNewTalent">Save</app-button>
          <app-button v-else @click="newModal.assignedOwner=newModal.tmpOwner; newModal.assigningOwner = false" :disabled="!newModal.tmpOwner.talentId">Assign Author</app-button>
        </template>
      </app-modal>

      <!-- Modale Import -->
      <talents-import-modal :show="openImportModal" @update:show="openImportModal = $event" @update:file="$router.push({ name: 'talents-import', params: { inputFile: $event } });"></talents-import-modal>
      <!-- Modale select owner pour un group de talents -->
      <app-modal :show="assignOwnerModal.open" @update:show="assignOwnerModal.open = false" title="Select owner">
        <talent-select @update:selected-talent="assignOwnerModal.owner = $event"></talent-select>
        <template v-slot:footer>
          <app-button kind="minimal" @click="assignOwnerModal.open = false">Cancel</app-button>
          <app-button @click="assignOwnerToSelected">Save</app-button>
        </template>
      </app-modal>

      <!-- Filtres de talents -->
      <talent-filters :show="openFilters" @close="openFilters = false" ref="talentFilters"></talent-filters>

    </div>

    <app-sticky-footer v-if="editMode" :type="selectedTalents.length ? 'default' : 'actions'">
      <template v-if="selectedTalents.length">
        {{ selectedTalents.length }} talents selected
      </template>
      <div>
        <app-button v-if="selectedTalents.length" kind="secondary" @click="selectedTalents = []" :disabled="editingGroup">Deselect</app-button>
        <app-button kind="secondary" :disabled="editingGroup || !selectedTalents.length" @click="deleteSelected">
          <svg-delete class="leading" />Delete
        </app-button>
        <app-button :disabled="editingGroup || !selectedTalents.length" @click="assignOwnerModal.open = true">Assign Author</app-button>
      </div>
    </app-sticky-footer>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import talentApi from '@/services/api/talent';
import utils from '@/services/utils';

// SVG
import SvgOverflow from '@/assets/img/icons/24px/overflow.svg?inline';
import SvgDownload from '@/assets/img/icons/24px/download.svg?inline';
import SvgUpload from '@/assets/img/icons/24px/upload.svg?inline';
import SvgEdit from '@/assets/img/icons/24px/edit.svg?inline';
import SvgPlus from '@/assets/img/icons/24px/plus.svg?inline';
import SvgFilter from '@/assets/img/icons/24px/filter.svg?inline';
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import SvgCross from '@/assets/img/icons/24px/cross.svg?inline';
import SvgArrowRight from '@/assets/img/icons/16px/arrow-right.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

// Components
import TalentIcon from '@/components/TalentIcon.vue';
import Pagination from '@/components/Pagination.vue';
import TalentProfile from './TalentProfile.vue';
import TalentFilters from './TalentFilters.vue';
import TalentsImportModal from './TalentsImportModal.vue';
import TalentSelect from './TalentSelect.vue';

export default {
  name: 'talents',
  components: {
    TalentIcon,
    Pagination,
    TalentProfile,
    TalentFilters,
    TalentsImportModal,
    'svg-overflow': SvgOverflow,
    'svg-download': SvgDownload,
    'svg-edit': SvgEdit,
    'svg-plus': SvgPlus,
    'svg-filter': SvgFilter,
    'svg-delete': SvgDelete,
    'svg-cross': SvgCross,
    'svg-arrow-right': SvgArrowRight,
    'svg-upload': SvgUpload,
    'svg-triangle': SvgTriangle,
    TalentSelect,
  },
  data() {
    return {
      searchName: this.$route.query.searchName || '',
      talents: [],
      user: authApi.getUser(),
      metadata: {
        offset: +this.$route.query.offset || 0,
        limit: +this.$route.query.limit || 5,
        count: 0,
      },
      loading: false,
      oneSmallMenuIsOpened: false,
      openFilters: false,

      // Data for new talent
      showNewTooltip: false,
      newModal: {
        open: false,
        assigningOwner: false,
        assignedOwner: {},
        tmpOwner: {},
      },
      openImportModal: false,

      // Data for group Edition
      editMode: false,
      editingGroup: false,
      selectedTalents: [],
      groupingRequestsNumber: process.env.VUE_APP_GROUP_REQUESTS_NUMBER,
      groupingRequestsInterval: process.env.VUE_APP_GROUP_REQUESTS_INTERVAL,
      assignOwnerModal: {
        open: false,
        owner: {},
      },
      assignOwnerTimeout: null,
    };
  },
  computed: {
    filtersCount() {
      const timeInRole = utils.initArray(this.$route.query.timeInRole);
      const timeInCompany = utils.initArray(this.$route.query.timeInCompany);
      const seniority = utils.initArray(this.$route.query.seniority);
      const filterFunction = utils.initArray(this.$route.query.filterFunction);
      const filterOwner = utils.initArray(this.$route.query.filterOwner);
      const filterCountry = utils.initArray(this.$route.query.filterCountry);
      return filterFunction.length + filterOwner.length + filterCountry.length + timeInRole.length + timeInCompany.length + seniority.length;
    },
    selectedAll() {
      return this.talents.every((talent) => this.selectedTalents.find((t) => t.talentId === talent.talentId));
    },
  },
  watch: {
    $route() {
      this.getTalents(this.$route.query.limit, this.$route.query.offset);
    },
    searchName() {
      this.debouncedUpdateQuery({ offset: 0, searchName: this.searchName });
    },
    'newModal.open': {
      handler(value) {
        if (!value) {
          this.newModal.assigningOwner = false;
          this.newModal.assignedOwner = {};
        }
      },
    },
  },
  mounted() {
    this.getTalents(this.metadata.limit, this.metadata.offset);
    this.debouncedUpdateQuery = utils.debounce((query) => {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    }, 400);
  },
  beforeDestroy() {
    clearTimeout(this.assignOwnerTimeout);
    clearTimeout(this.deleteTimeout);
  },
  methods: {
    openSmallMenu(e) {
      if (!e.currentTarget.nextSibling.classList.contains('opened')) {
        if (this.oneSmallMenuIsOpened) {
          this.closeSmallMenus();
        }
        e.currentTarget.nextSibling.classList.add('opened');
        this.oneSmallMenuIsOpened = true;
      } else {
        this.closeSmallMenus();
      }
    },
    closeSmallMenus() {
      if (this.oneSmallMenuIsOpened) {
        const openedMenus = document.querySelectorAll('.edit-suppr-menu.opened');
        openedMenus.forEach((openedMenu) => {
          openedMenu.classList.remove('opened');
        });
        this.oneSmallMenuIsOpened = false;
      }
    },
    // Si le talent est sélectionné
    isSelected(talent) {
      const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      return index >= 0;
    },
    // Toggle la sélection d'un talent
    toggleTalent(talent) {
      const index = this.selectedTalents.findIndex((t) => t.talentId === talent.talentId);
      if (index < 0) {
        this.selectedTalents.push(talent);
      } else {
        this.selectedTalents.splice(index, 1);
      }
    },
    // Sélectionnes ou Déselectionne tous les talents affichés
    toggleAll() {
      if (this.selectedAll) {
        this.talents.forEach((talent) => this.toggleTalent(talent));
      } else {
        this.talents.forEach((talent) => !this.isSelected(talent) && this.selectedTalents.push(talent));
      }
    },
    async deleteTalent(talentId) {
      try {
        await talentApi.deleteOne(talentId);
        this.$notification.show({
          text: 'Talent deleted successfully',
        });
        this.getTalents(this.$route.query.limit, this.$route.query.offset);
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `The talent could not be deleted. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    changePage({ limit, offset }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    async getTalents(limit, offset) {
      this.loading = true;
      try {
        const response = await talentApi.getTalents(
          limit,
          offset,
          this.user.companyId,
          null,
          this.searchName,
          null,
          this.$route.query.filterCountry,
          this.$route.query.filterFunction,
          this.$route.query.timeInRole,
          this.$route.query.timeInCompany,
          this.$route.query.seniority,
          this.$route.query.filterOwner,
        );
        this.talents = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the talents. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    submitNewTalent() {
      this.$refs.talentProfile.submit();
    },
    closeAddTalentTooltip() {
      document.activeElement.blur();
    },
    async downloadTemplate() {
      await talentApi.downloadTemplate();
    },
    async deleteSelected() {
      this.editingGroup = true;
      // Extrait les 10 premiers talents sélectionnés
      let talents = [...this.selectedTalents];
      if (talents.length > this.groupingRequestsNumber) {
        talents = talents.splice(0, this.groupingRequestsNumber);
      }
      // Les supprime en mettant à jour le data
      try {
        await Promise.all(talents.map(async (talent) => {
          await talentApi.deleteOne(talent.talentId);
          const index = this.selectedTalents.findIndex((t) => talent.talentId === t.talentId);
          this.selectedTalents.splice(index, 1);
        }));
      } catch (e) {
        this.getTalents(this.metadata.limit, this.metadata.offset);
        const { message } = e.response.data;
        this.$message.show({
          title: 'Error',
          text: `Some talents could not be deleted: \n ${Array.isArray(message) ? message.join('\n') : message}`,
        });
        this.editingGroup = false;
        return;
      }
      // S'il reste des talents sélectionnés, recommencer après un certain temps
      if (this.selectedTalents.length) {
        this.deleteTimeout = setTimeout(this.deleteSelected, this.groupingRequestsInterval);
      } else {
        // Sinon, finir l'edition
        this.$notification.show({ text: 'All selected talents deleted successfully' });
        this.editingGroup = false;
        this.getTalents(this.metadata.limit, this.metadata.offset);
      }
    },
    async assignOwnerToSelected() {
      this.editingGroup = true;
      // Extrait les 10 premiers talents sélectionnés
      let talents = [...this.selectedTalents];
      if (talents.length > this.groupingRequestsNumber) {
        talents = talents.splice(0, this.groupingRequestsNumber);
      }
      // Construction du body
      const body = { ownerId: this.assignOwnerModal.owner.talentId };
      this.assignOwnerModal.open = false;
      // Les supprime en mettant à jour le data
      try {
        await Promise.all(talents.map(async (talent) => {
          await talentApi.editTalent(talent.talentId, body);
          const index = this.selectedTalents.findIndex((t) => talent.talentId === t.talentId);
          this.selectedTalents.splice(index, 1);
        }));
      } catch (e) {
        this.getTalents(this.metadata.limit, this.metadata.offset);
        const { message } = e.response.data;
        this.$message.show({
          title: 'Error',
          text: `Some talents could not be updated: \n ${Array.isArray(message) ? message.join('\n') : message}`,
        });
        // this.editingGroup = false;
        this.assignOwnerModal.owner = {};
        return;
      }
      // S'il reste des talents sélectionnés, recommencer après un certain temps
      if (this.selectedTalents.length) {
        this.assignOwnerTimeout = setTimeout(this.assignOwnerToSelected, this.groupingRequestsInterval);
      } else {
        // Sinon, finir l'edition
        this.$notification.show({ text: 'Assigned owner to all selected talents successfully' });
        this.editingGroup = false;
        this.assignOwnerModal.owner = {};
        this.getTalents(this.metadata.limit, this.metadata.offset);
      }
    },
  },
};
</script>

<style lang="sass">
.talents
  @include screen
  display: flex
  flex-direction: column
  .section-content
    flex-grow: 1
  .title-bar
    .app-title
      text-align: left
    .add-talent-tooltip
      .app-tooltip-container
        display: none
        transform: translateY(0.55rem)
        width: 183px
        .app-button
          svg
            transform: scale(0.8)
      &:focus-within
        .app-tooltip-container
          display: initial
  .search-bar
    display: flex
    align-items: center
    justify-content: space-between
    background: $white
    padding: 16px 24px
    margin: 8px 0
    .app-search
       width: 50%
    .clear-filters-container
      flex-grow: 1
      display: flex
      justify-content: flex-end
      margin: 0 0.5rem
  .table-container
    @include table-container
    .table
      .body .row .cell:last-child, .headers .cell:last-child
        width: 2rem
        position: relative
        .edit-suppr-menu
          display: none
          background: $white
          position: absolute
          z-index: 5
          right: -14px
          box-shadow: 0px 2px 8px 0px rgba(103, 102, 98, 0.2)
          border-radius: 4px
          .app-button
            width: 100%
            border-radius: 0
            .btn-icon
              width: 24px
              display: flex
              justify-content: center
              &.edit
                padding: 0 3px
              svg
                margin: 0
            span
              padding-left: 3px
          &.opened
            display: block
      .body .row
          @include clickable-row
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .pending-owner
            @include pending-owner
          .overflow-button
            @include icon-button
            width: 2.5rem
            height: 2.5rem
      .svg-triangle
        margin: 0 0 0 10px
  .new-talent-modal
    .modal-wrapper
      transform: translate(-50%, -40vh)
      max-width: 900px
    .modal-content
      overflow-y: scroll
      max-height: 70vh
      @include hide-scrollbar
      .talent-profile
        padding: 0 10rem
      .select-owner
        padding: 1.5rem 1rem
        .select-owner-subtitle
          display: flex
          align-items: flex-start
          margin-bottom: 1rem
          height: 30px
          .app-link
            position: relative
            top: 2px
            svg
              transform: rotate(180deg)
              width: 10px
              margin-right: 5px
              position: relative
              top: 2px
          b
            font-size: 16px
            margin: 0 1rem

</style>
