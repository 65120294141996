<template>
  <div class="app-pie-chart" :class="[{ small }, { pdf }]">
    <div class="app-chart-container">
      <app-chart :data="data" :chartId="chartId"/>
    </div>
    <div class="legend-container">
      <div class="legend-line" :key="legend.label" v-for="(legend, index) in sortedStats.truncated">
        <div class="legend-color" :style="{ '--color': colors[index] }"></div>
        <span><p><b>{{ legend.label }} </b> {{ Math.round(legend.value) }}%</p></span>
        <span v-if="!pdf && index === sortedStats.truncated.length - 1 && sortedStats.others.length">
          <info>
            <ul>
              <li v-for="otherLegend in sortedStats.others" :key="otherLegend.label"><p><b>{{ otherLegend.label }}</b> {{ Math.round(otherLegend.value) }}%</p></li>
            </ul>
          </info>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * Composant AppPieChart :
 * Affiche une pie chart en 2 couleurs avec la légende à côté
 */
import Info from '@/components/Info.vue';
import AppChart from './AppChart.vue';

export default {
  name: 'AppPieChart',
  components: {
    AppChart,
    Info,
  },
  props: {
    stats: { // Attendu sour la forme [{ label: string, value: number }]
      type: Array,
      required: true,
    },
    chartId: { // THE ID MUST BE UNIQUE
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    pdf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: ['#2C1171', '#AA93E3', '#91EDE7', '#ED9D83', '#E0E7FD'],
    };
  },
  computed: {
    nbLegendsMax() {
      return this.colors.length;
    },
    sortedStats() {
      const sortedStats = Array.from(this.stats).filter((stat) => stat.value).sort((statA, statB) => statB.value - statA.value);
      let others = [];
      let truncated = [];
      if (sortedStats.length > this.nbLegendsMax) {
        truncated = sortedStats.splice(0, this.nbLegendsMax - 1);
        others = sortedStats;
        truncated.push({
          label: 'Others',
          value: others.reduce((acc, stat) => acc + stat.value, 0),
        });
      } else {
        truncated = sortedStats;
      }
      return {
        truncated,
        others,
      };
    },
    data() {
      const stats = [...this.sortedStats.truncated].reverse();
      const colors = ['#2C1171', '#AA93E3', '#91EDE7', '#ED9D83', '#E0E7FD'].slice(0, stats.length).reverse();
      return {
        type: 'doughnut',
        data: {
          labels: stats.map((stat) => stat.label),
          datasets: [
            {
              label: 'writers-gender',
              data: stats.map((stat) => stat.value),
              backgroundColor: colors,
              borderWidth: 0,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '80%',
          animation: {
            animateRotate: false,
          },
          plugins: {
            segmentShowStroke: false,
            legend: {
              display: false,
            },
          },
          events: [],
        },
      };
    },
  },
};
</script>
<style lang="sass">
.app-pie-chart
  display: flex
  .app-chart
    width: 250px
  .legend-container
    padding-top: 5%
    margin-left: 2rem
    .legend-line
      margin-top: 16px
      display: flex
      align-items: center
      .legend-color
        min-width: 16px
        min-height: 16px
        border-radius: 16px
        background: var(--color, #2C1171)
      p
        color: $common-gray
        margin: 0 0 0 0.5rem
        b
          color: $black
      .app-tooltip-container
        ul
          margin: 0
          padding: 0
          list-style: none
          li p b, li p
            color: $white
            margin-right: 0.5rem

  &.small
    .app-chart
      width: 150px
      height: 150px
  &.pdf
    .app-chart
      width: 100px
      height: 100px
    .legend-container
      height: 100px
      display: flex
      flex-direction: column
      padding-bottom: 10px
      padding-top: 3%
      margin-left: 1.5em
      .legend-line
        font-size: 10px
        margin: 0
      .legend-color
        min-width: 10px
        min-height: 10px
</style>
