<!--
*************
*** PROPS ***
*************

* loading : Boolean - sert à changer l'affichage du tableau par le contenu passé dans le slot "loading"

*************
*** SLOTS ***
*************

Doc sur les scope-slots
- https://fr.vuejs.org/v2/guide/components-slots.html#Slots-avec-portee
- https://www.digitalocean.com/community/tutorials/vuejs-scoped-component-slots

Il est possible de personnaliser plusieurs éléments du tableau grâce aux "scope-slots". On peut indiquer une balise "template" pour personnaliser l'affichage des cases d'une colonne, sinon on garde l'affichage par défaut qui est un simple <p>

* headers - Ce slot comprend les headers du tableau. Ils sont déjà contenu dans un div de class "grid-x headers"

* body - Ce slot comprend le 'body' du tableau. Il est attendu plusieurs élements de classe 'row'

* empty-table - Avec ce slot on indique le contenu que l'on souhaite afficher lorsqu'aucun composant n'est fourni au slot "body". On aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="empty-table">
      <p>Oups, c'est vide !</p>
    </template>
  </app-table>

* loading - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque le props "loading" est à true. De la même manière que pour le slot "empty-table", on aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="loading">
      <p>LOADING...</p>
    </template>
  </app-table>
-->

<template>
  <section class="app-table">
    <div class="grid-x headers">
      <slot name="headers"></slot>
    </div>

    <div v-if="loading" class="loading">
      <slot name="loading">
        <Loader />
      </slot>
    </div>
    <div v-else class="body">
      <slot name="body">
        <slot name="empty-table">
          <div class="empty">
            No results found.
          </div>
        </slot>
      </slot>
    </div>
  </section>
</template>

<script>
import Loader from '../../components/Loader.vue';

export default {
  name: 'app-table',
  components: { Loader },
  props: {
    loading: [Boolean],
  },
};
</script>

<style lang="sass">
.app-table
  width: 100%
  background: $white
  .headers, .body .row
    padding: 0 1rem
  .headers
    color: $black
    font-weight: 700
  .body
    .row
      height: 64px
      border-radius: $global-border-radius
      background: $background-color
      color: $common-gray
      margin: 8px 0
  .empty
    text-align: center
    padding: 2rem 0
  .loading
    display: flex
    justify-content: center
    align-items: center
    padding: 2rem 0
</style>
