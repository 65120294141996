<template>
  <section id="app" :class="{ 'has-pdf': $route.meta.isPDF }">
    <header :class="[{ 'only-logo': this.$route.name === 'login' || this.$route.name === 'super-admin-login' || this.$route.name === 'activate-account'}, {'no-bar': this.$route.name === 'home'}]">
      <div class="logo-container">
        <router-link :to="{ name: homeBtnLocation }" class="logo">
          <img src="./assets/img/logo.svg" alt="Talentuum"/>
          <p>Talentuum</p>
        </router-link>
      </div>
      <navigation v-if="!$route.meta.noNav"></navigation>
    </header>

    <div class="wrapper" :style="$route.meta.onlyLogo ? {
      margin: 0, padding: 0
    } : ''">
      <router-view class="router-view"/>
    </div>

    <!-- Global basics components integration -->
    <Footer v-if="!$route.meta.noFooter" />

    <app-message/>
    <app-notifications/>
  </section>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import ls from 'local-storage';
import UserRole from './services/enums/user-role.enums';

export default {
  name: 'app',
  components: {
    Navigation,
    Footer,
  },
  data() {
    return {
      homeBtnLocation: this.getHomeBtnLocation(),
    };
  },
  watch: {
    $route() {
      this.homeBtnLocation = this.getHomeBtnLocation();
    },
  },
  methods: {
    getHomeBtnLocation() {
      const connectedUser = ls('USER');
      if (connectedUser) {
        if (connectedUser.role === UserRole.ADMIN) {
          return 'home';
        }
        if (connectedUser.role === UserRole.SUPER_ADMIN) {
          return 'companies';
        }
        if (connectedUser.role === UserRole.TALENT) {
          return 'reviews';
        }
      }
      return 'login';
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/sass/app.sass"

#app
  --wrapper-padding-bottom: calc(3 * 40px)

  position: relative
  min-height: 100vh
  color: $text-color
  background: $background-color
  header
    background-color: $header-bg-color
    display: flex
    align-items: center
    height: $header-height
    width: 100vw
    position: fixed
    z-index: 500
    .logo-container
      .logo
        display: flex
        text-align: center
        align-items: center
        padding-right: 2em
        border-right: 2px solid #FFFFFF33
        p
          font-size: $global-font-size
          font-weight: bold
          color: $white
        img
          display: block
          width: 100%
          max-width: 200px
          margin: 0 1em
          max-height: 3em
    &.only-logo
      background: transparent
      padding: 0
      p
        display: none
      .navigation
        display: none
      .logo
        padding-right: 0
        border-right: none
        background: $header-bg-color
        img
          margin: 1em
    &.no-bar
      .logo-container
        .logo
          border-right-color: transparent
  .router-view
    padding-top: $header-height
  .wrapper
    //margin-top: 72px
    min-height: 100vh
    padding-bottom: var(--wrapper-padding-bottom)
    overflow-x: hidden
    &.hasStickyFooter
      height: 100vh
      padding-bottom: 0 !important
      & ~ .global-footer
        display: none !important

  &.has-pdf
    header
      display: none
    .global-footer
      display: none !important
    .wrapper
      padding-bottom: 0
      background-color: $white
      overflow: initial
</style>
