<template>
  <div class="form-new">

    <!-- Accordion: Section du formulaire -->
    <app-accordion v-for="(section, index) in sections" :key="section.key" :label="section.label" :showOpen="index === openSectionIndex" :id="`section-${index}`">
      <template v-slot:after-filler>
        <app-tag :state="sectionCompletionRate[index].nbQuestionsCompleted === sectionCompletionRate[index].nbQuestionsTotal ? 'success' : 'default'">
          <SvgTick v-if="sectionCompletionRate[index].nbQuestionsCompleted === sectionCompletionRate[index].nbQuestionsTotal" />
          {{ sectionCompletionRate[index].nbQuestionsCompleted }} of {{ sectionCompletionRate[index].nbQuestionsTotal }} completed
        </app-tag>
      </template>

      <!-- Body de l'accordéon -->
      <template v-slot:accordion-body>
        <!-- nudge -->
        <nudge :text="nudges[section.key]" v-if="nudges[section.key]">
        </nudge>
        <!-- formulaire -->
        <form :name="section.key">
          <form-question
            ref="app-input"
            v-for="question in section.questions"
            :key="question.questionId"
            :question="questions[question.questionIndex]"
            :max="questionLimits[question.questionIndex].appliedMax"
            :min="questionLimits[question.questionIndex].appliedMin"
            @input="questions[question.questionIndex].answer.value = $event"
          >
          </form-question>
        </form>
      </template>
    </app-accordion>

    <!-- Sticky footer -->
    <app-sticky-footer v-if="sections.length">
      <app-tag :state="formCompletionRate.nbQuestionsCompleted === formCompletionRate.nbQuestionsTotal ? 'success' : 'default'">
        <SvgTick v-if="formCompletionRate.nbQuestionsCompleted === formCompletionRate.nbQuestionsTotal" />
        {{ formCompletionRate.nbQuestionsCompleted }} of {{ formCompletionRate.nbQuestionsTotal }} completed
      </app-tag>
      <div class="action-btns">
        <app-button kind="secondary" @click="saveAndGoBack" :disabled="savingForm">Save and continue later</app-button>
        <app-button :disabled="savingForm" @click="goToPreview">Preview</app-button>
      </div>
    </app-sticky-footer>
  </div>
</template>

<script>
import answerApi from '@/services/api/answer';
import copyApi from '@/services/api/copy';

import FormSectionLabel from '@/services/enums/form-section-label.enum';
import CopyKey from '@/services/enums/copy-key.enum';

import SvgTick from '@/assets/img/icons/24px/tick.svg?inline';

import Nudge from '@/components/Nudge.vue';
import FormQuestion from './FormQuestion.vue';

export default {
  name: 'form-new',
  components: {
    Nudge,
    SvgTick,
    FormQuestion,
  },
  props: {
    initialQuestions: Array,
  },
  emits: [
    'update:last-saved', // Emis lorsqu'une section est auvegardée pour màj Autosaved
    'update:last-saved-waiting', // Emis lorsqu'une section est en train (true) e-ou a fini d'être sauvegardé (false)
    'update:preview', // Emis lorsque le bouton Preview est cliqué
  ],
  data() {
    return {
      sections: [],
      questions: this.initialQuestions,
      nudges: Object.fromEntries(Object.entries(FormSectionLabel).map(([key]) => ([key, null]))),
      savingForm: false,
      openSectionIndex: +this.$route.hash.slice('#section-'.length) || 0,
      showErrors: this.$route.query.showErrors || false, // Si les erreurs de vérification sont affichées au début
    };
  },
  computed: {
    // Génère pour chaque section le nombre de questions complétées et total
    sectionCompletionRate() {
      return this.sections.map((section) => {
        const nbCompleted = section.questions.reduce((sum, question) => {
          const { answer } = this.questions[question.questionIndex];
          if (Array.isArray(answer.value)) {
            return answer.value.length ? sum + 1 : sum;
          }
          return answer.value ? sum + 1 : sum;
        }, 0);
        return {
          nbQuestionsCompleted: nbCompleted,
          nbQuestionsTotal: section.questions.length,
        };
      });
    },
    // Génère pour tout le formulaire le nombre de questions complétées et total
    formCompletionRate() {
      return this.sectionCompletionRate.reduce((sectionRate, formRate) => ({
        nbQuestionsCompleted: sectionRate.nbQuestionsCompleted + formRate.nbQuestionsCompleted,
        nbQuestionsTotal: sectionRate.nbQuestionsTotal + formRate.nbQuestionsTotal,
      }));
    },
    // Limites min/max de chaque question, en prenant en compte sa dépendence éventuelle à d'autres questions
    questionLimits() {
      return this.questions.map((question) => {
        let { min } = question;
        if (question.mandatoryDependency) {
          const dependency = this.questions.find((q) => q.questionReference === question.mandatoryDependency);
          if (dependency.answer.value !== question.mandatoryDependencyValue) {
            min = 0;
          }
        }
        return { appliedMin: +min, appliedMax: +question.max };
      });
    },
  },
  watch: {
    // Envoie un événement au parent pour afficher l'enregistrement
    savingForm(value) {
      this.$emit('update:last-saved-waiting', value);
    },
  },
  methods: {
    // Initialises les sections, ordonne les questions dans chaque section en sauvegardant leur index de départ (leur index dans questions)
    initSections() {
      const sections = Object.entries(FormSectionLabel).map(([key, label]) => ({
        key,
        label,
        questions: this.questions
          .map((question, index) => ({ ...question, questionIndex: index }))
          .filter((question) => question.section === key)
          .sort((qA, qB) => qA.questionOrder - qB.questionOrder),
        open: false,
      }));
      this.sections = sections;
    },
    // Requêtes API : récupère les nudges de chaque section
    async initNudges() {
      try {
        await Promise.all(Object.entries(FormSectionLabel).map(async ([key]) => {
          const response = await copyApi.getCopy(CopyKey[`NUDGE_${key}`]);
          this.nudges[key] = response.data.copyText;
        }));
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          text: `We are unable to fetch the nudges. ${messages}`,
          title: 'Error',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    // Sauvegarde et revient à la liste
    async saveAndGoBack() {
      this.saveWholeForm().then(() => this.$router.go(-1));
    },
    // Sauvegarde et indique au composant Parent d'afficher la preview
    async goToPreview() {
      this.saveWholeForm().then(() => {
        this.$router.replace({ hash: '' });
        this.$emit('update:preview', true);
      });
    },
    // Sauvegarde tout le formulaire
    async saveWholeForm() {
      this.savingForm = true;
      try {
        await Promise.all(this.sections.map(async (section) => {
          await Promise.all(
            section.questions.map((question) => {
              const { answer } = this.questions[question.questionIndex];
              return answerApi.updateAnswer(answer.answerId, answer.value);
            }),
          );
        }));
        this.$emit('update:last-saved');
      } catch (error) {
        // Do nothing
      }
      this.savingForm = false;
    },
    showValidationErrors() {
      // Affiche les erreurs de validation si besoin
      if (this.showErrors) {
        this.sections.forEach((section) => {
          document.forms[section.key].reportValidity();
        });
      }
    },
  },
  // Initialises les sections, nudges et lance la sauvegarde automatique
  async mounted() {
    this.initSections();
    await this.initNudges();
    // Sauvegarde tout le formulaire toutes les 5 min
    this.autosaveInterval = setInterval(() => {
      this.saveWholeForm();
    }, 1000 * 60 * 5);

    this.showValidationErrors();
  },
  beforeDestroy() {
    clearInterval(this.autosaveInterval);
  },
};
</script>

<style lang="sass">
.form-new
  .app-accordion
    @include form-section
    .accordion-body
      transition: none
  .app-sticky-footer.error
    .app-button
      margin-left: 24px

</style>
