<template>
  <section class="onboarding" :class="{ screen: !!stepTitle }">
    <!-- Cache une partie de la navigation pour la customiser -->
    <div class="header-overlap">
      <h2>{{ navTitle }}</h2>
    </div>
    <!-- Titre du step -->
    <div class="title-container" v-if="!!stepTitle">
      <app-title>
        <small>Step {{ currentStepIndex }} </small>
        {{ stepTitle }}
      </app-title>
      <app-link @click="currentStepIndex = 3">Skip this step</app-link>
    </div>

    <!-- Composant de chaque step -->
    <div class="step-container">
      <Welcome v-if="currentStep.key === 'welcome'" :firstOnboarding="firstOnboarding" @update:step="currentStepIndex += $event"></Welcome>
      <AddTalent v-if="currentStep.key === 'add-talent'" :companyId="user.companyId" @update:step="currentStepIndex += $event"></AddTalent>
      <AssignOwners v-if="currentStep.key === 'assign-owners'" @update:step="currentStepIndex += $event"></AssignOwners>
      <OnboardingCompleted v-if="currentStep.key === 'completed'"></OnboardingCompleted>
    </div>
  </section>
</template>

<script>
import auth from '@/services/auth';
import Welcome from './onboarding/Welcome.vue';
import AddTalent from './onboarding/AddTalent.vue';
import AssignOwners from './onboarding/AssignOwners.vue';
import OnboardingCompleted from './onboarding/OnboardingCompleted.vue';

export default {
  name: 'onboarding',
  components: {
    Welcome,
    AddTalent,
    AssignOwners,
    OnboardingCompleted,
  },
  props: {
    firstOnboarding: {
      // required: true,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      steps: [
        {
          key: 'welcome',
          navTitle: 'Welcome',
        }, {
          key: 'add-talent',
          stepTitle: 'Add talent',
        }, {
          key: 'assign-owners',
          stepTitle: 'Assign authors',
        }, {
          key: 'completed',
        },
      ],
      currentStepIndex: 0,
      user: auth.getUser(),
    };
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    navTitle() {
      return this.currentStep.navTitle || 'Account setup';
    },
    stepTitle() {
      return this.currentStep.stepTitle;
    },
  },
};
</script>

<style lang="sass">
.onboarding
  &.screen
    @include screen
    display: flex
    flex-direction: column
  .header-overlap
    height: $header-height
    position: fixed
    top: 0
    left: 176px
    right: 130px
    z-index: 600
    background: $header-bg-color
    color: $white
    padding: 0 1.5rem
    display: flex
    align-items: center
    h2
      font-size: 20px
      font-weight: 700

  .title-container
    display: flex
    justify-content: space-between
    align-items: center
    .app-title
      small
        font-size: 16px
        font-weight: 400
        margin-right: 1rem
    .app-link
      font-size: 13px

  .step-container
    flex-grow: 2

</style>
