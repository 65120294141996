<template>
  <div class="flag-panel" :class="{ 'no-flag': !flags.length }">
    <!-- Header : nombre de flags, boutons d'édition/dismiss etc -->
    <div class="flag-panel-header">
      <!-- Composant du header gardé à gauche -->
      <div>
        <p v-if="flags.length">
          <SvgFlag />
          <b>    {{ flags.length }} flags detected</b>
        </p>
        <p v-else>
          <SvgSuccess />
          <b>    No flags detected</b>
        </p>
        <info v-if="flags.length" position="right" message="Resolve these flags by clicking on the thumbs up or down buttons, to provide feedback on their usefulness."></info>
        <app-button v-if="flags.length && allResolved" kind="minimal" size="tiny" @click="expand = !expand">{{ expand ? 'Hide' : 'See' }} resolved flags</app-button>
      </div>
      <!-- Composants du headers gardés à droite -->
      <div>
        <template v-if="questionDisabled">
          <app-button size="medium" @click="$emit('update:disabled', false); expand = true">Edit response</app-button>
          <app-button size="medium" kind="secondary" v-if="!allResolved" @click="resolveAll">Dismiss all flags</app-button>
        </template>
        <template v-else>
          <app-button size="medium" kind="minimal" @click="$emit('cancel')" :disabled="savingAnswer">Cancel</app-button>
          <app-button size="medium" @click="onSaveResponse" :disabled="savingAnswer">Save</app-button>
        </template>
      </div>
    </div>
    <!-- Flags -->
    <flag
      v-for="flag in shownFlags"
      :key="flag.flagId"
      :flag="flag"
      :loading="loading[flag.flagId]"
      @update:flag="updateFlag($event.flagId, $event.useful)"
    ></flag>
    <!-- Bouton d'expansion pour voir tous les flags-->
    <app-button v-if="!allResolved && flags.length > maxCollapsed && !expand" size="tiny" kind="minimal" @click="expand = true" class="expand-btn">See all flags</app-button>

    <!-- Modale de demande de feedback -->
    <app-modal :show="showFlagModal" :canClose="false" title="How relevant were these flags?">
      <flag
        v-for="flag in unresolvedFlags"
        :key="flag.flagId"
        :flag="flag"
        :loading="loading[flag.flagId]"
        expand
        @update:flag="updateModal($event.flagId, $event.useful)"
      ></flag>
      <template v-slot:footer>
        <app-button @click="onSkipAndResolve">Skip and resolve flags</app-button>
      </template>
    </app-modal>

  </div>
</template>

<script>
import flagApi from '@/services/api/flag';
import SvgFlag from '@/assets/img/icons/24px/flag.svg?inline';
import SvgSuccess from '@/assets/img/icons/24px/success.svg?inline';
import Info from '@/components/Info.vue';
import Flag from './Flag.vue';

export default {
  name: 'flag-panel',
  components: {
    Flag,
    Info,
    SvgSuccess,
    SvgFlag,
  },
  emits: [
    'update:flag', // Flag résolu et/ou feedback
    'update:disabled', // Changement du mode édition de la réponse (true -> disabled, false -> editing)
    'save', // Edition de la réponse à sauvegarder
    'cancel', // Edition de la réponse annulée
  ],
  props: {
    flags: { // Liste des flags
      type: Array,
      required: true,
    },
    questionDisabled: { // Si la question attachée est disabled
      type: Boolean,
      required: true,
    },
    savingAnswer: { // Si la réponse est en cours de sauvegarde
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false, // Montrer tous les flags (ou non)
      maxCollapsed: 2, // Nombre max de flags à afficher en mode collapsed
      showFlagModal: false, // Montrer la modale de demande de feedback
      loading: {}, // liste de flags en cours de modification
    };
  },
  computed: {
    // Flags affichés
    shownFlags() {
      const sortedFlags = Array.from(this.flags).sort((fA, fB) => {
        switch (true) {
          case fA.resolved === fB.resolved:
            return 0;
          case fA.resolved:
            return 1;
          case fB.resolved:
            return -1;
          default:
            return 0;
        }
      });
      const collapsedFlags = this.allResolved ? [] : sortedFlags.slice(0, this.maxCollapsed);
      return this.expand ? sortedFlags : collapsedFlags;
    },
    // Si tous les flags sont résolus
    allResolved() {
      return this.flags.every((f) => f.resolved);
    },
    unresolvedFlags() {
      return this.flags.filter((f) => !f.resolved);
    },
  },
  methods: {
    onEditResponse() {
      this.$emit('update:disabled', false);
      if (!this.allResolved) {
        this.expand = true;
      }
    },
    onSaveResponse() { // Montrer la modale s'il reste des flags non résolus
      if (this.unresolvedFlags.length) {
        this.showFlagModal = true;
      } else {
        this.$emit('save');
      }
    },
    // Requête API: donne un feedback du flag
    async updateFlag(flagId, useful, resolved) {
      this.$set(this.loading, flagId, true);
      const { index } = this.flags.find((f) => f.flagId === flagId);
      try {
        const response = await flagApi.resolveFlag(flagId, useful, resolved);
        const flag = response.data;
        this.$emit('update:flag', { ...flag, index });
      } catch (error) {
        throw error;
      } finally {
        this.$set(this.loading, flagId, false);
      }
    },
    // Vérifie si la modale doit être fermée (en résolvant le dernier flag)
    async updateModal(flagId, useful) {
      const isLastFlag = this.unresolvedFlags.length <= 1;
      this.updateFlag(flagId, useful).then(() => {
        if (isLastFlag) {
          this.showFlagModal = false;
          this.$emit('save');
        }
      });
    },
    // Résout tous les flags restants (pas de feedback)
    async resolveAll() {
      return Promise.all(this.unresolvedFlags.map((flag) => this.updateFlag(flag.flagId, null, true)));
    },
    async onSkipAndResolve() {
      this.resolveAll().then(() => {
        this.$emit('save');
        this.showFlagModal = false;
      });
    },
  },
};
</script>

<style lang="sass">
.flag-panel
  width: 100%
  margin-top: 1rem
  border: 2px solid $main-color
  border-radius: $global-border-radius
  padding: 1rem
  background: $white
  .flag-panel-header
    display: flex
    align-items: center
    justify-content: space-between
    & > div
      display: flex
      align-items: center
      .app-button
        margin-left: 1rem
      p
        color: $main-color
        svg
          vertical-align: bottom
          path
            fill: currentcolor
  .flag
    margin-top: 0.5rem
  .expand-btn
    margin: 0.3rem auto 0 auto

  &.no-flag
    border-color: $success-color
    .flag-panel-header
      p
        color: $success-color

  .modal-wrapper
    .modal-content
      padding: 2rem 3rem
</style>
