<template>
  <div class="template-form-question">
    <!-- Question metadata -->
    <p class="question-title">
      <b>{{ question.title }}</b>
    </p>
    <app-input
      :class="{ active: isActive }"
      ref="templateQuestionInput"
      v-model="tmpText"
      :name="question.questionId"
      multiline
    ></app-input>
    <div class="buttons"  v-if="isActive">
      <app-button :disabled="disabled" @click="handleSave" size="medium">Save</app-button>
      <app-button :disabled="disabled" class="cancel" size="medium" kind="secondary" @click="handleCancel">Cancel</app-button>
    </div>
    <app-button :disabled="disabled" v-else @click="handleActive" size="medium">Edit</app-button>
  </div>
</template>

<script>
import templateApi from '@/services/api/template';

export default {
  name: 'template-form-question',
  emits: ['update:question'],
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tmpText: null,
      isActive: false,
      disabled: false,
    };
  },
  async mounted() {
    this.getText();
  },
  watch: {
    question() {
      this.getText();
    },
  },
  methods: {
    getText() {
      this.tmpText = this.question.questionText;
    },
    handleCancel() {
      this.tmpText = this.question.questionText;
      this.handleActive();
    },
    handleActive() {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.$refs.templateQuestionInput.focus();
      }
    },
    async handleSave() {
      this.disabled = true;
      try {
        const response = await templateApi.update(this.question.templateId, this.tmpText);
        this.$emit('update:question', response.data);
        this.handleActive();
        this.$notification.show({ text: `Question "${this.question.title}" successfully updated !` });
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to update the question's text "${this.question.title}". ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.disabled = false;
    },
  },
};
</script>

<style lang="sass">
.template-form-question
  display: flex
  flex-direction: column
  background: $background-color
  border-radius: $global-border-radius
  padding: 1.5rem
  margin-top: 2rem
  color: $black
  .buttons
    button.app-button
      float: right
      margin: 12px 0 auto auto
    &.cancel
      margin-right: 12px
  .question-title
    width: 100%
  .app-input, .options
    width: 100%
    height: inherit
    min-height: 7rem
  .app-input
    pointer-events: none
    textarea
      min-height: 164px
      background-color: $light-color
      &:focus
        background-color: $white
    &.active
      pointer-events: initial
</style>
