<template>
  <section class="activate">
    <div class="grid-x">
      <div class="cell medium-5">
        <div class="headline">
          <app-title>
            New Account
          </app-title>
          <app-link size="small" @click="redirectToLogin">Already have an account?</app-link>
        </div>
        <form ref="activate" @submit.prevent="activate(user)">
          <div>
            <app-label>Company</app-label>
            <app-input autocomplete="off" :disabled=true type="text" required :value="user.companyName"/>
          </div>
          <div>
            <app-label>Email</app-label>
            <app-input autocomplete="email" :disabled=true type="email" required :value="user.email"/>
          </div>
          <div>
            <app-label>First name</app-label>
            <app-input autocomplete="off" type="text" required v-model="user.firstname"/>
          </div>
          <div>
            <app-label>Surname</app-label>
            <app-input autocomplete="off" type="text" required v-model="user.lastname"/>
          </div>
          <div>
            <app-label>Password</app-label>
            <app-input autocomplete="new-password" type="password" required  v-model="user.password" :class="{ error: hasError }"/>
          </div>
          <div>
            <app-label>Confirm password</app-label>
            <app-input autocomplete="off" type="password" required  v-model="user.confirmationPassword" :class="{ error: hasError }"/>
          </div>

          <div v-if="hasError">
            <ul>
              <li class="error" v-for="(message, index) in errorMessages" :key="`reset-password-error-${index}`">{{ message }}</li>
            </ul>
          </div>

          <div>
            <app-button type="submit" id="login-btn" :disabled="isLoading">Create account</app-button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import UserRole from '@/services/enums/user-role.enums';

export default {
  name: 'activate',
  data() {
    return {
      user: {
        firstname: '',
        lastname: '',
        password: '',
        confirmationPassword: '',
        email: '',
        companyName: '',
      },
      hasError: false,
      errorMessages: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getActivationInfo();
  },
  methods: {
    async getActivationInfo() {
      this.loading = true;
      try {
        this.user = await authApi.getActivationInfo(this.$route.query.token);
      } catch (error) {
        if (error.response.status === 409) {
          this.$notification.show({ text: 'This account has already been activated' });
          this.redirectToLogin();
        } else {
          const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
          this.$message.show({
            title: 'Error',
            text: `We are unable to fetch the information. ${messages}`,
            confirmText: 'Ok',
            hasCancel: false,
            onConfirm: () => {},
          });
        }

        this.loading = false;
      }
    },
    redirectToLogin() {
      return this.$router.push({ name: 'login' });
    },
    async activate() {
      if (this.$refs.activate.checkValidity()) {
        this.isLoading = true;
        this.hasError = false;
        this.errorMessages = [];
        try {
          const {
            firstname,
            lastname,
            password,
            confirmationPassword,
          } = this.user;
          const { firstOnboarding, user } = await authApi.postActivationInfo(this.$route.query.token, {
            password,
            confirmationPassword,
            firstname,
            lastname,
          });
          this.$notification.show({ text: 'Your activation was successful !' });
          // Redirection
          if (user.role === UserRole.ADMIN) {
            this.$router.push({ name: 'onboarding', params: { firstOnboarding } });
          } else {
            this.$router.push({ name: 'reviews' });
          }
        } catch (error) {
          this.hasError = true;
          this.errorMessages = ['An issue occured, please see the help below:'];
          if (error.response.data.message && error.response.data.message.length > 0) {
            if (typeof error.response.data.message === 'string') this.errorMessages.push((error.response.data.message));
            else this.errorMessages = [...this.errorMessages, ...error.response.data.message];
          }
        }
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.activate
  min-height: calc(100vh - 68px)
  padding-bottom: 68px
  .grid-x
    height: 100%
    justify-content: center
    align-items: center
  .headline
    display: flex
    align-items: center
    justify-content: space-between
  .app-button
    display: flex
    margin-top: 48px
    align-items: center
  form svg
    margin-left: 10px
    width: 24px
    path
      fill: #ffffff
  .error
    color: $error-color
    font-weight: 700
    font-size: $global-font-size
    &:first-child
      list-style: none
      margin-left: -17px
    &:first-letter
      text-transform: capitalize
</style>
