<template>
    <app-modal class="review-filters" :show="show" :title="`Filters ${nbFilters ? `(${nbFilters})` : ''}`" @update:show="$emit('close')">
      <app-accordion label="Cycle" class="country-filter" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="filterCycle.length">{{ filterCycle.length }}</div>
          <app-button class="clear-btn" v-if="filterCycle.length" kind="minimal" size="tiny" @click.stop="filterCycle = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-search v-model="searchCycle" @search="addSearchCycle('filterCycle', $event)"></app-search>
          <div class="search-filters">
            <app-tag v-for="(search, index) in filterCycle" :key="search" class="main" removable @delete="filterCycle.splice(index, 1)">{{ search }}</app-tag>
          </div>
        </template>
      </app-accordion>
      <app-accordion label="Status" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="status.length">{{ status.length }}</div>
          <app-button v-if="status.length && !isStatusDisabled" class="clear-btn" kind="minimal" size="tiny" @click.stop="status = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-checkbox v-for="option in Object.keys(ReviewStatusLabel)" :key="option" :input="status" :value="option" :disabled=isStatusDisabled>
            {{ ReviewStatusLabel[option].toLowerCase().charAt(0).toUpperCase() + ReviewStatusLabel[option].toLowerCase().slice(1).replace('_', ' ') }}
          </app-checkbox>
        </template>
      </app-accordion>
      <app-accordion label="Talent" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="search.length">{{ search.length }}</div>
          <app-button v-if="search.length" class="clear-btn" kind="minimal" size="tiny" @click.stop="search = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-search v-model="searchTalent" @search="addSearchTalent('search', $event)"></app-search>
          <div class="search-filters">
            <app-tag v-for="(searchValue, index) in search" :key="searchValue" class="main" removable @delete="search.splice(index, 1)">{{ searchValue }}</app-tag>
          </div>
        </template>
      </app-accordion>
      <template v-slot:footer>
        <div v-if="nbFilters > 0 && !isStatusDisabled" class="clear-footer">
          <app-button kind="subtle" @click="clearAll">
            <svg-cross class="leading" />
            Clear all filters
          </app-button>
        </div>
        <div v-else-if="(isStatusDisabled && (filterCycle.length > 0 || search.length > 0))" class="clear-footer">
          <app-button kind="subtle" @click="clearAll">
            <svg-cross class="leading" />
            Clear all filters
          </app-button>
        </div>
      </template>
    </app-modal>
</template>

<script>
import SvgCross from '@/assets/img/icons/24px/cross.svg?inline';
import utils from '@/services/utils';
import ReviewStatusLabel from '@/services/enums/review-status.enum';

export default {
  name: 'review-filters',
  components: {
    'svg-cross': SvgCross,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchCycle: '',
      searchTalent: '',
      filterCycle: utils.initArray(this.$route.query.filterCycle),
      search: utils.initArray(this.$route.query.search), // Filtre sur le talent
      status: utils.initArray(this.$route.query.status),
      ReviewStatusLabel: { ...ReviewStatusLabel },
      isStatusDisabled: false,
    };
  },
  computed: {
    nbFilters() {
      return this.filterCycle.length + this.status.length + this.search.length;
    },
  },
  watch: {
    filterCycle() { this.updateQuery({ filterCycle: this.filterCycle }); },
    status() { this.updateQuery({ status: this.status }); },
    search() { this.updateQuery({ search: this.search }); },
  },
  created() {
    this.cleanReviewStatusLabel();
  },
  methods: {
    addSearchCycle() {
      this.filterCycle.push(this.searchCycle);
      this.searchCycle = '';
    },
    addSearchTalent() {
      this.search.push(this.searchTalent);
      this.searchTalent = '';
    },
    clearAll() {
      if (!this.isStatusDisabled) {
        Object.assign(this.$data, {
          ...(this.filterCycle.length && { filterCycle: [] }),
          ...((this.status.length && { status: [] })),
          ...(this.search.length && { search: [] }),
        });
      } else {
        Object.assign(this.$data, {
          ...(this.filterCycle.length && { filterCycle: [] }),
          ...(this.search.length && { search: [] }),
        });
      }
    },
    updateQuery(newQuery) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...newQuery,
        },
      });
    },
    cleanReviewStatusLabel() {
      delete this.ReviewStatusLabel.DRAFT;
      delete this.ReviewStatusLabel.TALENT_DELETED;
    },
  },
};
</script>

<style lang="sass">
.review-filters
  .modal-wrapper
    width: 480px
    height: 100vh
    max-height: none
    top: 0
    bottom: 0
    right: 0
    left: initial
    transform: none
    display: flex
    flex-direction: column
    .modal-content
      flex-grow: 1
      max-height: none
      overflow-y: scroll
      .app-accordion
        .accordion-header
          background-color: transparent
          .count-tag
            width: 20px
            height: 20px
            font-size: 11px
            text-align: center
            line-height: 20px
            color: $common-gray
            background: $light-color
            border-radius: 12px
            margin: 0 0.5rem
          .clear-btn
            font-size: 13px
            color: $common-gray
          &:hover
            background: $light-color
            .count-tag
              background: $white
        .accordion-body
          display: flex
          flex-direction: column
          .search-filters
            display: flex
            align-items: center
            justify-content: flex-start
            flex-wrap: wrap
            margin-top: 1rem
            .app-tag
              margin: 0.2rem
          .app-checkbox
            margin: 0.5rem 0
            color: $common-gray
            &:last-child
              margin-bottom: 0
            &:first-child
              margin-top: 0
        &.country-filter .app-search
          @include select-transition
          position: relative
          .country-options
            @include select-options
            transform: translateY(2px)
            max-height: 240px
            overflow-y: auto
            b
              color: $black
            li:hover
              cursor: pointer
    .modal-footer
      background: $white
      box-shadow: 0px 4px 12px rgba(103, 102, 98, 0.3)
      .clear-footer
        padding: 1rem
        .app-button
          margin: 0 auto
</style>
