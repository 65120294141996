<template>
  <form class="admin-profile" :class="{ disabled: !editMode, loading }" ref="talentProfileForm">
    <Loader v-if="loading" />
    <template v-else>
      <div class="form-section">
        <p class="title">PERSONAL DETAILS</p>
        <div class="form-input">
          <label><b>First name</b></label>
          <app-input v-if="editMode" v-model="talent.firstname" placeholder="First name" type="text" required></app-input>
          <p class="value" v-else>{{ initialTalent.firstname }}</p>
        </div>
        <div class="form-input">
          <label><b>Surname</b></label>
          <app-input v-if="editMode" v-model="talent.lastname" placeholder="Surname" type="text" required></app-input>
          <p class="value" v-else>{{ initialTalent.lastname }}</p>
        </div>
      </div>
      <div class="form-section">
        <p class="title">Role</p>
        <div class="form-input">
          <label><b>Fonction</b></label>
          <app-input v-if="editMode" v-model="talent.talentVersion.function" placeholder="Fonction" required></app-input>
          <p class="value" v-else>{{ initialVersion.function }}</p>
        </div>
      </div>
      <div class="form-section">
        <p class="title">Contact</p>
        <div class="form-input">
          <label><b>Email</b></label>
          <app-input v-if="editMode" v-model="talent.email" placeholder="Email" type="email" required></app-input>
          <p class="value" v-else>{{ initialTalent.email }}</p>
        </div>
        <div class="form-input">
          <label>
            <b>Phone</b><br>
            {{ editMode ? 'Optional' : '' }}
          </label>
          <app-input v-if="editMode" v-model="talent.phone" placeholder="+440000000000" type="tel"></app-input>
          <p class="value" v-else>{{ initialTalent.phone }}</p>
        </div>
      </div>

      <div v-if="hasErrors">
        <ul>
          <li class="error" v-for="(message, index) in errorMessages" :key="`reset-password-error-${index}`">{{ message }}</li>
        </ul>
      </div>
    </template>
  </form>
</template>

<script>
import companyApi from '../../services/api/company';
import Loader from '../../components/Loader.vue';

export default {
  name: 'admin-profile',
  emits: [
    'submitted', // Form soumis avec succès. Renvoi aussi le talent
    'update:admin', // Talent modifié avec succès. Contient le talet màj
  ],
  components: {
    Loader,
  },
  props: {
    // Si ce formulaire est pour un nouveau talent
    isNew: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    // Informations initiales du talent
    initialTalent: {
      type: Object,
    },
  },
  data() {
    return {
      talent: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        talentVersion: {
          function: '',
        },
      },
      hasErrors: false,
      errorMessages: [],
      loading: false,
    };
  },
  computed: {
    initialVersion() {
      return this.initialTalent.talentVersion || {};
    },
  },
  watch: {
    initialTalent() {
      this.talent = { ...this.initialTalent };
    },
  },
  methods: {
    async submit() {
      if (this.$refs.talentProfileForm.reportValidity()) {
        this.hasError = false;
        this.errorMessages = [];
        this.loading = true;
        if (this.isNew) {
          this.hasErrors = await this.createAdmin();
        } else {
          this.hasErrors = await this.editAdmin();
        }
        this.$emit('submitted', this.hasErrors);
        this.loading = false;
      }
    },
    // Requête API : Création d'un talent
    async createAdmin() {
      try {
        const {
          firstname,
          lastname,
          email,
          phone,
        } = this.talent;
        await companyApi.createAdmin(this.$route.params.companyId, {
          firstname,
          lastname,
          email,
          phone,
          function: this.talent.talentVersion.function,
        });
        this.$notification.show({ text: `Admin ${this.talent.firstname} ${this.talent.lastname} was successfully added !` });
        return false;
      } catch (error) {
        this.hasError = true;
        this.errorMessages = ['The admin could not be added. Please check errors below.'];
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errorMessages.push((error.response.data.message));
          else this.errorMessages = [...this.errorMessages, ...error.response.data.message];
        }
        return true;
      }
    },
    // Requête API : Edition d'un talent
    async editAdmin() {
      try {
        const {
          firstname,
          lastname,
          email,
          phone,
        } = this.talent;
        const response = await companyApi.editAdmin(this.$route.params.companyId, this.initialTalent.talentId, {
          firstname,
          lastname,
          email,
          phone,
          function: this.talent.talentVersion.function,
        });
        this.$notification.show({ text: `Admin ${this.talent.firstname} ${this.talent.lastname} was successfully updated !` });
        this.$emit('update:admin', response.data);
        return false;
      } catch (error) {
        this.hasError = true;
        this.errorMessages = ['The admin could not be updated. Please check errors below.'];
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errorMessages.push((error.response.data.message));
          else this.errorMessages = [...this.errorMessages, ...error.response.data.message];
        }
        return true;
      }
    },
  },
  mounted() {
    if (!this.isNew) {
      this.talent = { ...this.initialTalent };
    }
  },
};
</script>

<style lang="sass">
.admin-profile
  .loader
    margin: 5rem auto
  .form-section
    padding: 1rem 0
    border-bottom: 2px solid $light-color
    .title
      color: $main-color
      text-transform: uppercase
      font-size: 12px
      font-weight: 900
    .form-input
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin: 8px 0
      label
        font-size: 13px
        color: $common-gray
        width: 30%
        position: relative
        top: 1rem
        b
          font-size: 16px
          color: $black
      .app-input
        width: 70%
        max-width: none
      .app-select
        width: 70%
        .container
          max-width: none
  .form-section:last-child
    border-bottom: none
  .owner-input.form-input
    align-items: center
    label
      position: initial
    .owner
      flex-grow: 1
      border: 2px solid $light-color
      padding: 0.5rem
      margin-right: 1rem
      .pending-owner
        @include pending-owner

  &.disabled
    .form-input
      align-items: center
      height: 49px
      label
        position: initial
      .value
        width: 70%
        text-align: left
        color: $common-gray
  .error
    color: $error-color
    font-weight: 700
    font-size: $global-font-size
    &:first-letter
      text-transform: capitalize
</style>
