<template>
  <div class="flag" :class="{ loading, resolved: flag.resolved, useful: isUseful, useless: isUseless, expand }">
    <p>
      {{ flag.message }}
    </p>
    <div class="feedback-btns">
      <app-button kind="minimal" size="tiny" @click="giveFeedback(true)" class="like-btn">
        <SvgLike :class="{ leading: expand }"/>
        <template v-if="expand">Useful</template>
      </app-button>
      <app-button kind="minimal" size="tiny" @click="giveFeedback(false)" class="dislike-btn">
        <SvgLike :class="{ leading: expand }"/>
        <template v-if="expand">Not useful</template>
      </app-button>
    </div>
  </div>
</template>

<script>

import SvgLike from '@/assets/img/icons/24px/like.svg?inline';

export default {
  name: 'flag',
  components: {
    SvgLike,
  },
  props: {
    flag: Object, // Flag à afficher
    expand: { // Affichage du flag en mode 'expand'
      type: Boolean,
      default: false,
    },
    loading: { // Si le flag est en cours d'envoi de feedback
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:flag'], // Envoi d'un feedback
  computed: {
    isUseful() {
      return typeof this.flag.useful === 'boolean' && this.flag.useful;
    },
    isUseless() {
      return typeof this.flag.useful === 'boolean' && !this.flag.useful;
    },
  },
  methods: {
    giveFeedback(useful) {
      this.$emit('update:flag', { useful, flagId: this.flag.flagId });
    },
  },
};
</script>

<style lang="sass">
.flag
  display: flex
  justify-content: space-between
  align-items: center
  border: 2px solid #EFEDEC
  border-radius: $global-border-radius
  padding: 1rem
  background: $white
  width: 100%
  p
    color: $common-gray
  .feedback-btns
    display: flex
    justify-content: flex-end
    align-items: center
    margin-left: 1rem
    .dislike-btn
      margin-left: 0.3rem
      svg
        transform: rotate(180deg)

  &.expand
    .feedback-btns, .app-spinner
      width: 25rem
  &:not(.expand)
    .feedback-btns
      .app-button
        @include icon-button
  &.resolved
    background: $background-color
    border: none
  &.useful
    .like-btn
      color: $success-color
      svg path
        fill: $success-color
    .dislike-btn
      svg
        opacity: 0.3
  &.useless
    .dislike-btn
      color: $error-color
      svg path
        fill: $error-color
    .like-btn
      svg
        opacity: 0.3

  &.loading
    opacity: 0.3
</style>
