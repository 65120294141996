import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/super-admins`;

const getMe = async () => {
  try {
    const response = await axios.get(`${API_URL}/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modification du mot de passe du super admin
 *
 * @param {*} oldPassword string
 * @param {*} newPassword string
 * @param {*} confirmNewPassword string
 * @returns
 */
const updatePassword = async (oldPassword, newPassword, confirmNewPassword) => {
  try {
    const response = await axios.put(`${API_URL}/change-password`, {
      oldPassword,
      newPassword,
      confirmNewPassword,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getMe = getMe;
api.updatePassword = updatePassword;

export default api;
