<template>
  <section class="cycle-drive-for-change">
    <div class="page">
      <app-subtitle>Drive for change</app-subtitle>
      <p>This metric allows you to understand how writers acted upon Talentuum's feedback. It calculates the difference in bias risks across the whole cycle before and after authors amended their inputs into TGPs</p>
      <graph-card title="Score">
        <app-score-chart :score="biasStats.improvement" chartId="globalIndex-datapool-all"></app-score-chart>
        <p><b>Writers</b> managed to <b>{{ reductionLevelText }}</b> their bias flags on <b>all groups</b> reviews</p>
      </graph-card>
    </div>
  </section>
</template>

<script>
// Vue mixins
import StatsDisplay from '@/mixins/StatsDisplay.vue';

// Composants
import GraphCard from './GraphCard.vue';

export default {
  mixins: [StatsDisplay],
  components: {
    GraphCard,
  },
  props: {
    biasStats: {
      type: Object,
    },
  },
  data() {
    return {
      isPDF: this.$route.meta.isPDF,
    };
  },
  computed: {
    reductionLevelText() {
      if (this.biasStats.improvement < 20) {
        return 'moderately reduce';
      }
      if (this.biasStats.improvement < 99) {
        return 'signficantly reduce';
      }
      return 'remove all';
    },
  },
};
</script>

<style lang="sass">
.cycle-drive-for-change
  .graph-card-content
    display: flex
    gap: 5rem
    > p
      max-width: 35ch
      margin-top: 3rem
      line-height: 1.6
</style>
