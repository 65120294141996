const BiasCheckLabel = Object.freeze({
  DRAFT: 'Not sent',
  NEW: 'Not sent',
  PROCESSING: 'Processing',
  EDITING: 'Checked',
  COMPLETED: 'Checked',
  TALENT_DELETED: 'Checked',
});

export default BiasCheckLabel;
