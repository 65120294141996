import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/templates`;

const getTemplates = async () => {
  try {
    const response = await axios.get(`${API_URL}`);
    return response.data;
  } catch (error) { throw error; }
};

/**
 * Met à jour le texte d'une question d'un template
 *
 * @param {*} templateId string
 * @param {*} questionText string
 * @returns
 */
const update = async (templateId, questionText) => {
  try {
    const response = await axios.put(`${API_URL}/${templateId}`, { questionText });
    return response.data;
  } catch (error) { throw error; }
};

const api = {};
api.getTemplates = getTemplates;
api.update = update;

export default api;
