<template>
  <section class="pagination">
    <div class="metadata">
      <span>Showing {{ display.min }} - {{ display.max }} of {{ count }}</span>
      <app-select :value="limit" :options="limitOptions" @input="changeLimit"></app-select>
    </div>
    <div class="pages grid-x">
      <app-button kind="minimal" size="tiny" class="first" :disabled="isFirstPage" @click="changePage(1)"><svg-skip-left /></app-button>
      <app-button kind="minimal" size="tiny" class="previous" :disabled="isFirstPage" @click="changePage(selectedPage - 1)"><svg-chevron-left /></app-button>
      <app-button
        v-for="page in pagesDisplayed"
        :key="page"
        kind="minimal"
        size="tiny"
        class="number"
        :class="{ selected: page === selectedPage }"
        :disabled="!page"
        @click="changePage(page)"
      >
        {{ page || '...' }}
      </app-button>
      <app-button kind="minimal" size="tiny" class="next" :disabled="isLastPage" @click="changePage(selectedPage + 1)"><svg-chevron-left /></app-button>
      <app-button kind="minimal" size="tiny" class="last" :disabled="isLastPage" @click="changePage(nbPages)"><svg-skip-left /></app-button>
    </div>
  </section>
</template>

<script>
import SvgSkipLeft from '@/assets/img/icons/16px/skip-left.svg?inline';
import SvgChevronLeft from '@/assets/img/icons/16px/chevron-left.svg?inline';

export default {
  name: 'pagination',
  emits: ['update:page'],
  components: {
    'svg-skip-left': SvgSkipLeft,
    'svg-chevron-left': SvgChevronLeft,
  },
  props: {
    limit: {
      type: Number,
      default: 2,
    },
    offset: {
      type: Number,
      default: 0,
    },
    count: [Number],
  },
  data() {
    return {
      pagesDisplayed: null,
      pageMaxShown: 7,
      limitOptions: [5, 10, 15, 20].map((n) => ({ name: n, label: n.toString() })),
    };
  },
  computed: {
    display() {
      return {
        min: this.count ? this.offset + 1 : 0,
        max: Math.min(this.offset + this.limit, this.count),
      };
    },
    // nombre de page max
    nbPages() {
      return Math.max(1, Math.ceil(this.count / this.limit));
    },
    // true si c'est la 1ere page du tableau
    isFirstPage() {
      return (this.offset === 0);
    },
    // true si c'est la dernière page du tableau
    isLastPage() {
      return ((this.offset + this.limit + 1) > this.count);
    },
    selectedPage() {
      return Math.ceil((this.offset + 1) / this.limit);
    },
  },
  watch: {
    offset() {
      this.setPagesDisplayed();
    },
    count() {
      this.setPagesDisplayed();
    },
    limit() {
      this.setPagesDisplayed();
    },
  },
  mounted() {
    this.setPagesDisplayed();
  },
  methods: {
    changePage(page) {
      this.$emit('update:page', {
        offset: (page - 1) * this.limit,
        limit: this.limit,
      });
    },
    changeLimit(e) {
      this.$emit('update:page', {
        offset: 0,
        limit: e,
      });
    },
    pageChange(page) {
      if (page !== this.selectedPage && page > 0 && page <= this.nbPages) {
        this.selectedPage = page;
        this.setPagesDisplayed();
      }
    },
    setPagesDisplayed() {
      // nombre de page à montrer de chaque coté des points ou de la page
      const nbInRange = Math.floor((this.pageMaxShown - 1) / 2);
      // Si le nombre de page est assez bas, tout montrer
      if (this.nbPages <= this.pageMaxShown) {
        this.pagesDisplayed = this.getRange(1, this.nbPages);
      // Si la page sélectionnée est bien au milieu, montrer les pages autour
      } else if (this.selectedPage > nbInRange && this.selectedPage < this.nbPages - nbInRange + 1) {
        this.pagesDisplayed = this.getRange(this.selectedPage - nbInRange, this.selectedPage + nbInRange);
      // Sinon, montrer le début et la fin, avec ... pour le milieu
      } else {
        const firstRange = this.getRange(1, nbInRange);
        const lastRange = this.getRange(this.nbPages - nbInRange + 1, this.nbPages);
        this.pagesDisplayed = firstRange.concat([null]).concat(lastRange);
      }
    },
    getRange(from, to) {
      const range = Array.from(Array(to).keys(), (x) => x + 1);
      if (from <= 1) {
        return range;
      }
      range.splice(0, from - 1);
      return range;
    },
  },
};
</script>

<style lang="sass">
  .pagination
    width: 100%
    margin-top: 2.5rem
    display: flex
    align-items: center
    justify-content: space-between
    .metadata
      display: flex
      align-items: center
      .app-select
        width: 4.5rem
        margin-left: 1rem
    .pages
      .app-button
        @include icon-button
        height: 2.5rem
        width: 2.5rem
        margin: 0 0.5rem
        svg
          path
            fill: $link-color
        &.last, &.next
          svg
            transform: rotate(180deg)
        &.number.selected
          background: $main-color
          color: $white
        &:focus
          box-shadow: none

</style>
