<template>
  <div class="typical-bias">
    <div class="typical-bias-title">
      <h3>{{ typicalBias.label }}</h3>
      <info v-if="!isPDF" :message="typicalBias.description" />
    </div>
    <div class="typical-bias-description" v-if="isPDF">
      <div class="icon-container"><SvgInformation /></div>
      <p>{{ typicalBias.description }}</p>
    </div>
    <div class="typical-bias-cause">
      <div class="icon-container"><SvgNegative /></div>
      <p>{{ typicalBias.cause }}</p>
    </div>
    <div class="typical-bias-solution">
      <div class="icon-container"><SvgLightbulb /></div>
      <p>{{ typicalBias.solution }}</p>
    </div>
  </div>
</template>

<script>
import Info from '@/components/Info.vue';
import SvgInformation from '@/assets/img/icons/24px/information.svg?inline';
import SvgNegative from '@/assets/img/icons/24px/negative.svg?inline';
import SvgLightbulb from '@/assets/img/icons/24px/lightbulb.svg?inline';

export default {
  name: 'typical-bias',
  components: {
    Info, SvgNegative, SvgLightbulb, SvgInformation,
  },
  props: {
    typicalBias: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPDF: this.$route.meta.isPDF,
    };
  },
};
</script>

<style lang="sass">
.typical-bias
  width: 100%
  padding: 2rem
  border-radius: $global-border-radius
  background: $white
  display: flex
  flex-direction: column
  gap: 2rem
  > div
    display: flex
    align-items: flex-start
    justify-content: flex-start
    gap: 0.5rem
    .icon-container
      width: 32px
      height: 32px
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      flex-shrink: 0
      transform: translateY(-5px)
      svg
        width: 24px
    p
      margin: 0
      white-space: pre-wrap
  .typical-bias-title
    align-items: center
    h3
      margin: 0
  &-description
    .icon-container
      background-color: #E7E1F6
      svg
        transform: translateX(2px)
  &-cause
    .icon-container
      background-color: $error-bg-color
  &-solution
    .icon-container
      background-color: $success-bg-color
</style>
