<template>
  <form class="cycle-setup-name" ref="cycleName" @submit.prevent="goNextStep">
      <div class="name-container">
        <app-label>Cycle name</app-label>
        <app-input type="text" placeholder="E.g. Cycle name" required v-model="cycleName"></app-input>
      </div>
      <app-sticky-footer type="actions">
        <app-button kind="secondary" @click="goPreviousStep">Back</app-button>
        <app-button :disabled="!cycleName" type="submit">Next</app-button>
      </app-sticky-footer>
  </form>
</template>

<script>
export default {
  data() {
    return {
      cycleName: null,
      error: null,
    };
  },
  props: {
    oldCycleName: {
      default: null,
    },
  },
  mounted() {
    this.cycleName = this.oldCycleName;
  },
  methods: {
    goPreviousStep() {
      this.$emit('update:input', this.cycleName);
      this.$emit('update:step', 0);
    },
    goNextStep() {
      if (this.cycleName) {
        this.$emit('update:input', this.cycleName);
        this.$emit('update:step', 2);
      }
    },
  },
};
</script>

<style lang="sass">
.cycle-setup-name
  padding-top: 30px
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%
  .name-container
    height: calc(100vh - 350px)
    background: white
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0 30% 5% 30%
    gap: 2rem
    .app-label
      font-size: 30px
</style>
