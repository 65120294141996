<template>
 <div class="app-score-chart">
    <app-chart :data="data" :chartId="chartId"/>
    <div class="app-score-legend">
      <span>{{ Math.round(absScore) }}%</span>
      <p>Improvement</p>
    </div>
  </div>
</template>

<script>

/**
 * Composant AppScore:
 * Affiche une pie-chart verte avec score en pourcentage au milieu
 */

import AppChart from './AppChart.vue';

export default {
  name: 'AppScoreChart',
  components: {
    AppChart,
  },
  props: {
    score: {
      type: Number,
      required: true,
    },
    chartId: { // THE ID MUST BE UNIQUE
      type: String,
      required: true,
    },
  },
  computed: {
    absScore() {
      return Math.abs(this.score);
    },
    data() {
      return {
        type: 'doughnut',
        data: {
          labels: ['Rest', 'Improvement'],
          datasets: [
            {
              label: 'bias-improvement',
              data: [100 - this.absScore, this.absScore],
              backgroundColor: ['#E3F7E9', '#15A93F'],
              borderWidth: 0,
              borderRadius: [0, 100],
              spacing: -10,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '80%',
          animation: {
            animateRotate: false,
          },
          plugins: {
            segmentShowStroke: false,
            legend: {
              display: false,
            },
          },
          events: [],
        },
      };
    },
  },
};
</script>

<style lang="sass">
.app-score-chart
  position: relative
  width: 250px
  .app-score-legend
    position: absolute
    display: flex
    flex-direction: column
    align-items: center
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    span
      font-weight: 700
      font-size: 32px
    p
      color: $common-gray
      margin: 0
</style>
