<template>
  <section class="company" @click="closeSmallMenus">
    <app-link @click="$router.push({ name: 'companies' })">
      <SvgArrowRight />
      Back to Company list
    </app-link>

    <!-- Loader -->
    <Loader v-if="loading" />

    <!-- Affichage du talent -->
    <template v-else>
      <!-- Titre avec boutons d'actions -->
      <div class="title-bar">
        <app-title>{{ company.name }}</app-title>
        <template v-if="tabs[activeTab].key === 'details'">
          <template v-if="editing">
            <app-button kind="minimal" size="medium" @click="editing = false">Cancel</app-button>
            <app-button kind="primary" size="medium" @click="$refs.companyProfile.submit()">Save</app-button>
          </template>
          <app-button v-else size="medium" @click="editing = true">Edit company details</app-button>
        </template>
        <template v-else-if="tabs[activeTab].key === 'admins'">
          <app-button kind="primary" size="medium" @click="() => { openAdminModal = true; isAdminNew = true; }">
            <SvgPlus class="leading"/>
            Add new Admin
          </app-button>
        </template>
      </div>

      <app-tab :tabs="tabLabels" ref="tabs"></app-tab>

      <div class="tab-container" :class="tabs[activeTab].key">
        <!-- Onglet Details -->
        <template v-if="tabs[activeTab].key === 'details'">
          <company-info :initialCompany="company" :editMode="editing" ref="companyProfile" @submitted="editing = false" @update:company="company = $event"></company-info>
        </template>
        <!-- Onglet Admins -->
        <template v-if="tabs[activeTab].key === 'admins'">
          <div class="search-bar">
            <app-search placeholder="Search..." v-model="tabs[activeTab].searchAll"></app-search>
          </div>
          <div class="table-container">
            <app-table :loading="tabs[activeTab].loading">
              <template v-slot:headers>
                <div class="cell auto">Name <SvgTriangle /></div>
                <div class="cell auto">Role title</div>
                <div class="cell auto">Function</div>
                <div class="cell auto">Owned by</div>
                <div class="cell shrink"></div>
              </template>
              <template v-slot:body>
                <div class="row grid-x align-middle clickable" v-for="(admin, index) in tabs[activeTab].data" :key="admin.talentId" @click="isAdminNew = false; adminSelected = index; openAdminModal = true">
                  <div class="cell auto talent-name">
                    <talent-icon :user="admin" :isLong=false></talent-icon>
                    <div class="talent-info">
                      <div>{{ admin.firstname | capitalize }} {{ admin.lastname | uppercase }}</div>
                      <div class="talent-email cell">{{ admin.email }}</div>
                    </div>
                  </div>
                  <div class="cell auto">{{ admin.talentVersion.role }}</div>
                  <div class="cell auto">{{ admin.talentVersion.function }}</div>
                  <div class="cell auto">
                    <talent-icon v-if="admin.owner" :user="admin.owner" :isLong=true></talent-icon>
                    <div v-else class="pending-owner"></div>
                  </div>
                  <!-- Bouton Overflow de fin de ligne -->
                  <div class="cell shrink">
                    <app-tooltip class="suppr-menu" :isMenu="true" position="bottom-right">
                      <app-button @click.stop="() => loginImpersonate(admin.talentId)" kind="minimal" size="tiny">
                        <span>
                          Sign-in as
                        </span>
                      </app-button>
                      <app-button @click.stop="() => revokeAdminRights(admin.talentId)" kind="minimal" size="tiny">
                        <SvgDelete class="leading"/>
                        <span>
                          Delete
                        </span>
                      </app-button>
                      <template v-slot:app-tooltip-wrapper>
                        <app-button kind="minimal" size="tiny" class="overflow-button no-focus" @click.stop="openSmallMenu">
                          <svg-overflow />
                        </app-button>
                      </template>
                    </app-tooltip>
                  </div>
                </div>
              </template>
            </app-table>
            <pagination v-bind="tabs[activeTab].metadata" @update:page="changePage"></pagination>
          </div>
        </template>
        <!-- Onglet Talents -->
        <template v-if="tabs[activeTab].key === 'talents'">
          <div class="search-bar">
            <app-search placeholder="Search..." v-model="tabs[activeTab].searchAll"></app-search>
          </div>
          <div class="table-container">
            <app-table :loading="tabs[activeTab].loading">
              <template v-slot:headers>
                <div class="cell auto">Name <SvgTriangle /></div>
                <div class="cell auto">Role title</div>
                <div class="cell auto">Function</div>
                <div class="cell auto">Owned by</div>
                <div class="cell shrink"></div>
              </template>
              <template v-slot:body>
                <div class="row grid-x align-middle" v-for="talent in tabs[activeTab].data" :key="talent.talentId">
                  <div class="cell auto talent-name">
                    <talent-icon :user="talent" :isLong=false></talent-icon>
                    <div class="talent-info">
                      <div>{{ talent.firstname }} {{ talent.lastname }}</div>
                      <div class="talent-email cell">{{ talent.email }}</div>
                    </div>
                  </div>
                  <div class="cell auto">{{ talent.talentVersion.role }}</div>
                  <div class="cell auto">{{ talent.talentVersion.function }}</div>
                  <div class="cell auto">
                    <talent-icon v-if="talent.owner" :user="talent.owner" :isLong=true></talent-icon>
                    <div v-else class="pending-owner"></div>
                  </div>
                  <!-- Bouton Overflow de fin de ligne -->
                  <div class="cell shrink">
                    <app-tooltip class="suppr-menu" :isMenu="true" position="bottom-right">
                      <app-button @click.stop="() => loginImpersonate(talent.talentId)" kind="minimal" size="tiny">
                        <span>
                          Sign-in as
                        </span>
                      </app-button>
                      <template v-slot:app-tooltip-wrapper>
                        <app-button kind="minimal" size="tiny" class="overflow-button no-focus" @click.stop="openSmallMenu">
                          <svg-overflow />
                        </app-button>
                      </template>
                    </app-tooltip>
                  </div>
                </div>
              </template>
            </app-table>
            <pagination v-bind="tabs[activeTab].metadata" @update:page="changePage"></pagination>
          </div>
        </template>
      </div>
    </template>
     <div v-if="editing && tabs[activeTab].key === 'details'" class="profile-edit-footer">
      <app-button kind="minimal" @click="editing = false">Cancel</app-button>
      <app-button kind="primary" @click="$refs.companyProfile.submit()">Save</app-button>
    </div>

    <!-- Modale pour ajouter/modifier un admin -->
    <app-modal class="admin-modal" :show="openAdminModal" @update:show="openAdminModal = false" :title="isAdminNew ? 'Add new admin' : `Admin ${tabs[activeTab].data[adminSelected].firstname} ${tabs[activeTab].data[adminSelected].lastname}`">
      <admin-profile :isNew=isAdminNew :initialTalent="isAdminNew ? {} : tabs[activeTab].data[adminSelected]" ref="adminProfile" @submitted="(hasErrors) => handleAdminProfileSubmission(hasErrors)"></admin-profile>
      <template v-slot:footer>
        <app-button kind="minimal" @click="openAdminModal = false">Cancel</app-button>
        <app-button @click="$refs.adminProfile.submit()">Save</app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import utils from '@/services/utils';
import companyApi from '@/services/api/company';
import talentApi from '@/services/api/talent';
import authApi from '@/services/auth';

import SvgArrowRight from '@/assets/img/icons/16px/arrow-right.svg?inline';
import SvgOverflow from '@/assets/img/icons/24px/overflow.svg?inline';
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import SvgPlus from '@/assets/img/icons/24px/plus.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

import Pagination from '@/components/Pagination.vue';
import TalentIcon from '@/components/TalentIcon.vue';
import CompanyInfo from './CompanyInfo.vue';
import AdminProfile from '../talents/AdminProfile.vue';

import Loader from '../../components/Loader.vue';

export default {
  name: 'company',
  components: {
    SvgArrowRight,
    SvgOverflow,
    CompanyInfo,
    Pagination,
    TalentIcon,
    SvgDelete,
    SvgTriangle,
    AdminProfile,
    SvgPlus,
    Loader,
  },
  data() {
    return {
      companyId: this.$route.params.companyId,
      company: {},
      loading: true,
      editing: false,
      editingAdmin: false,
      activeTab: this.$route.query.activeTab || 0,
      tabs: [{
        key: 'details',
        label: 'Details',
      }, {
        key: 'admins',
        label: 'Admins',
        loading: false,
        data: [],
        metadata: {
          limit: 10,
          offset: 0,
          count: null,
        },
        searchAll: '',
      }, {
        key: 'talents',
        label: 'Talents',
        loading: false,
        data: [],
        metadata: {
          limit: 10,
          offset: 0,
          count: null,
        },
        searchAll: '',
      }],
      oneSmallMenuIsOpened: false,
      openAdminModal: false,
      isAdminNew: true,
      adminSelected: 0,
    };
  },
  computed: {
    tabLabels() {
      return this.tabs.map((tab) => tab.label);
    },
    adminsSearchAllToWatch() {
      return this.tabs[1].searchAll;
    },
    talentsSearchAllToWatch() {
      return this.tabs[2].searchAll;
    },
  },
  watch: {
    editing() {
      if (this.editing && this.activeTab !== 0) {
        this.$refs.tabs.setTab(0);
      }
    },
    adminsSearchAllToWatch(newValue, oldValue) {
      const { activeTab } = this.$route.query;
      if (newValue !== oldValue) this.debouncedUpdateQuery({ offset: 0, searchAll: this.tabs[activeTab].searchAll });
    },
    talentsSearchAllToWatch(newValue, oldValue) {
      const { activeTab } = this.$route.query;
      if (newValue !== oldValue) this.debouncedUpdateQuery({ offset: 0, searchAll: this.tabs[activeTab].searchAll });
    },
    // Surveille le changement d'onglet ou de pagination
    $route() {
      const {
        activeTab,
        limit,
        offset,
        searchAll,
      } = this.$route.query;
      // Si l'onglet change
      if (+activeTab !== this.activeTab) {
        this.activeTab = +activeTab;
        this.editing = this.editing && this.activeTab === 0;
        // To avoid NavigationDuplicated error
        if (this.tabs[activeTab].metadata && (+limit !== this.tabs[activeTab].metadata.limit || +offset !== this.tabs[activeTab].metadata.offset)) {
          this.$router.push({
            query: {
              ...this.$route.query,
              limit: this.tabs[activeTab].metadata.limit,
              offset: this.tabs[activeTab].metadata.offset,
            },
          });
        }
      } else if (+limit !== this.tabs[this.activeTab].metadata.limit || +offset !== this.tabs[this.activeTab].metadata.offset || +searchAll !== this.tabs[this.activeTab].searchAll) {
        switch (this.activeTab) {
          case 1:
            this.getAdmins(limit, offset);
            break;
          case 2:
            this.getTalents(limit, offset);
            break;
          default:
            this.$router.push({
              query: null,
            });
            break;
        }
      }
    },
  },
  mounted() {
    this.getCompany();
    this.debouncedUpdateQuery = utils.debounce((query) => {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    }, 400);
    const { limit, offset, activeTab } = this.$route.query;
    switch (+activeTab) {
      case 1:
        this.getAdmins(limit, offset);
        this.getTalents();
        break;
      case 2:
        this.getAdmins();
        this.getTalents(limit, offset);
        break;
      default:
        this.getAdmins();
        this.getTalents();
    }
  },
  methods: {
    // Changement de pagination (tableaux)
    changePage({ limit, offset }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    async getCompany() {
      this.loading = true;
      try {
        const response = await companyApi.getCompany(this.companyId);
        this.company = response.data;
      } catch (error) {
        if (error.response.status === 404) {
          this.$router.push({ name: 'not-found' });
        }
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `The company could not be fetched. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    async getAdmins(limit, offset) {
      this.tabs[1].loading = true;
      try {
        const response = await talentApi.getTalents(
          limit,
          offset,
          this.companyId,
          null,
          null,
          null,
          this.$route.query.filterCountry,
          this.$route.query.filterFunction,
          this.$route.query.timeInRole,
          this.$route.query.timeInCompany,
          this.$route.query.seniority,
          this.$route.query.filterOwner,
          true,
          this.tabs[1].searchAll,
          null,
        );
        this.tabs[1].data = response.data;
        this.tabs[1].metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the admins. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.tabs[1].loading = false;
    },
    async getTalents(limit, offset) {
      this.tabs[2].loading = true;
      try {
        const response = await talentApi.getTalents(
          limit,
          offset,
          this.companyId,
          null,
          null,
          null,
          this.$route.query.filterCountry,
          this.$route.query.filterFunction,
          this.$route.query.timeInRole,
          this.$route.query.timeInCompany,
          this.$route.query.seniority,
          this.$route.query.filterOwner,
          false,
          this.tabs[2].searchAll,
          null,
        );
        this.tabs[2].data = response.data;
        this.tabs[2].metadata = response.metadata;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the talents. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.tabs[2].loading = false;
    },
    openSmallMenu(e) {
      if (!e.currentTarget.parentElement.previousSibling.classList.contains('opened')) {
        if (this.oneSmallMenuIsOpened) {
          this.closeSmallMenus();
        }
        e.currentTarget.parentElement.previousSibling.classList.add('opened');
        this.oneSmallMenuIsOpened = true;
      } else {
        this.closeSmallMenus();
      }
    },
    closeSmallMenus() {
      if (this.oneSmallMenuIsOpened) {
        const openedMenus = document.querySelectorAll('.suppr-menu .opened');
        openedMenus.forEach((openedMenu) => {
          openedMenu.classList.remove('opened');
        });
        this.oneSmallMenuIsOpened = false;
      }
    },
    async revokeAdminRights(talentId) {
      this.$message.show({
        title: 'You\'re about the revoke of rights to this Admin. Are you sure?',
        text: 'Do you want to proceed?',
        hasCancel: true,
        cancelText: 'Cancel',
        confirmText: 'Yes, delete',
        isDestructive: true,
        onConfirm: async () => {
          try {
            await companyApi.revokeAdminRights(this.companyId, talentId);
            this.$notification.show({
              text: 'Admin\'s rights revoked successfully',
            });
            await this.getAdmins();
          } catch (error) {
            this.$message.show({
              title: 'Error',
              text: 'You cannot revoke rights of this admin.',
              hasCancel: false,
              confirmText: 'Ok',
              isDestructive: false,
              onConfirm: null,
            });
          }
        },
      });
    },
    async handleAdminProfileSubmission(hasErrors) {
      if (hasErrors) return;
      await this.getAdmins();
      this.openAdminModal = false;
    },
    async loginImpersonate(talentId) {
      try {
        await authApi.loginImpersonate(talentId);
        this.$notification.show({
          text: 'Login successful !',
        });
        // Redirection vers l'accueil du compte
        this.$router.go();
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `Login failed: ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.company
  @include screen
  > .loader
    margin: 5rem auto
  .app-link
    @include back-link
  .title-bar
    display: flex
    align-items: center
    .app-title
      flex-grow: 1
    .app-button
      margin-left: 16px
  .admin-profile
    padding: 16px 24px
  .profile-edit-footer
    display: flex
    justify-content: center
    padding: 1.5rem
    margin-top: 2px
    background-color: $white
    .app-button
      margin: 0 2rem
  .search-bar
    display: flex
    align-items: center
    justify-content: space-between
    background: $white
    padding: 16px 24px
    margin: 8px 0
    .app-search
       width: 50%
    .clear-filters-container
      flex-grow: 1
      display: flex
      justify-content: flex-end
      margin: 0 0.5rem
  .tab-container
    background: $white
    border-radius: $global-border-radius
    padding: 2rem
    &.details
      display: flex
      padding-right: 12%
      .talent-icon
        margin-right: 12%
      .company-info
        flex-grow: 1
      .modal-content
        padding: 2rem
    &.admins, &.talents
      .table-container
        @include table-container
      .app-table
        .body .row .cell:last-child, .headers .cell:last-child
          width: 2rem
        .body .row
          &.clickable
            @include clickable-row
          b
            color: $black
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .pending-owner
            @include pending-owner
          .suppr-menu
            .app-tooltip-container
              display: none
              background: $white
              position: absolute
              z-index: 5
              right: -7px !important
              box-shadow: 0px 2px 8px 0px rgba(103, 102, 98, 0.2)
              border-radius: 4px
              .app-button
                width: 100%
                border-radius: 0
              &.opened
                display: block
            .overflow-button
              @include icon-button
              width: 2.5rem
              height: 2.5rem
  .svg-triangle
    margin: 0 0 0 10px
</style>
