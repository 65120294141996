<template>
  <div class="nudge">
    <SvgInformation />
    <p>
      <b>Talentuum Tip: </b>
      {{ text }}
    </p>
  </div>
</template>

<script>
import SvgInformation from '@/assets/img/icons/24px/information.svg?inline';

export default {
  name: 'nudge',
  components: {
    SvgInformation,
  },
  props: {
    text: String,
  },
};
</script>

<style lang="sass">
.nudge
  display: flex
  align-items: flex-start
  justify-content: flex-start
  padding: 1rem
  background: #D5FAF8
  border-radius: $global-border-radius
  margin-bottom: 1rem
  p
    flex-shrink: 40
    margin: 0
    color: #195855
  svg
    width: 21px
    margin-right: 0.5rem
    path
      fill: #195855

</style>
