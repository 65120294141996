const ReviewStatus = Object.freeze({
  DRAFT: 'DRAFT',
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  EDITING: 'EDITING',
  COMPLETED: 'COMPLETED',
  TALENT_DELETED: 'TALENT_DELETED',
});

export default ReviewStatus;
