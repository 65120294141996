import axios from 'axios';
import ls from 'local-storage';
import UserRole from './enums/user-role.enums';

// Utilisation de require pour contourner un bug de webpack
const router = require('../router');

const API_URL = process.env.VUE_APP_API_URL;
let TOKEN = ls('TOKEN') || false;

// Récupération du user
const getUser = () => ls('USER');

// Récupération du token
const getToken = () => ls('TOKEN');

// L'utilisateur est-il loggué
const isLoggedIn = () => !!getToken();

const redirectRouteName = (isLoggedIn() && getUser().role === UserRole.SUPER_ADMIN) ? 'super-admin-login' : 'login';

// Token du local storage
if (TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${TOKEN}`;
}

// Récupération par axios d'une 401 (unauthorized) et redirection login
axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    ls.remove('TOKEN');
    ls.remove('USER');
    if (router.default.history.current.name !== redirectRouteName) {
      router.default.push({ name: redirectRouteName });
      return new Promise(() => {});
    }
  }
  return Promise.reject(error);
});

// Login
const login = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/talents/auth/login`, {
      email: user.email,
      password: user.password,
    });

    TOKEN = response.data.token;
    ls('TOKEN', TOKEN);
    axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;

    ls('USER', response.data.user);

    return true;
  } catch (error) {
    throw error;
  }
};

const resetPasswordTalent = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/talents/auth/reset-password`, { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const setNewPasswordTalent = async (password, confirm, token) => {
  try {
    const response = await axios.post(`${API_URL}/talents/auth/reset-password/new`, { password, confirm }, {
      params: {
        token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Login SuperAdmin
const loginSuperAdmin = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/super-admins/auth/login`, {
      email: user.email,
      password: user.password,
    });

    TOKEN = response.data.token;
    ls('TOKEN', response.data.token);
    axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;

    ls('USER', response.data.user);

    return true;
  } catch (error) {
    throw error;
  }
};

const resetPasswordSuperAdmin = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/super-admins/auth/reset-password`, { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const setNewPasswordSuperAdmin = async (password, confirm, token) => {
  try {
    const response = await axios.post(`${API_URL}/super-admins/auth/reset-password/new`, { password, confirm }, {
      params: {
        token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère les informations pour pré-remplir les champs
 *
 * @param {token} token
 * @returns
 */
const getActivationInfo = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/talents/auth/activate`, {
      params: {
        token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
  * Active le compte
  *
  * @param {*} token
  * @param {{password: string, confirmationPassword: string, firstname: string, lastname: string}} body
  * @returns true
  */
const postActivationInfo = async (token, body) => {
  try {
    const response = await axios.post(`${API_URL}/talents/auth/activate`, body, {
      params: {
        token,
      },
    });
    TOKEN = response.data.token;
    ls('TOKEN', TOKEN);
    axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
    ls('USER', response.data.user);

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Logout
const logout = () => {
  ls.remove('TOKEN');
  ls.remove('USER');
  delete axios.defaults.headers.common.Authorization;
};

// Login impersonate
const loginImpersonate = async (talentId) => {
  try {
    const response = await axios.post(`${API_URL}/super-admins/auth/impersonate`, { talentId });

    TOKEN = response.data.token;
    ls('TOKEN', TOKEN);
    axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;

    ls('USER', response.data.user);

    return true;
  } catch (error) {
    throw error;
  }
};

// Authorization Header
const getAuthorizationHeader = () => axios.defaults.headers.common.Authorization;

const auth = {};
auth.login = login;
auth.isLoggedIn = isLoggedIn;
auth.logout = logout;
auth.getToken = getToken;
auth.getUser = getUser;
auth.getAuthorizationHeader = getAuthorizationHeader;
auth.loginSuperAdmin = loginSuperAdmin;
auth.resetPasswordTalent = resetPasswordTalent;
auth.setNewPasswordTalent = setNewPasswordTalent;
auth.resetPasswordSuperAdmin = resetPasswordSuperAdmin;
auth.setNewPasswordSuperAdmin = setNewPasswordSuperAdmin;
auth.getActivationInfo = getActivationInfo;
auth.postActivationInfo = postActivationInfo;
auth.loginImpersonate = loginImpersonate;

export default auth;
