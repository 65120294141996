<template>
  <h1 class="app-title"><slot></slot></h1>
</template>

<script>
export default {
};
</script>

<style lang="sass">
.app-title
  display: inline-block
  position: relative
  font-size: 32px
  font-weight: 700
  color: $text-color
</style>
