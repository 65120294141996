<template>
  <section class="talents-parsing">
    <!-- Table des talents à importer -->
    <div class="table-container">
      <div class="table-title">
        {{ file ? file.name : '' }}
        <app-button kind="subtle" size="medium" @click="$refs.fileInput.click()">
          <SvgUpload class="leading" />
          Upload new file
        </app-button>
        <input class="hidden" type="file" accept=".csv" ref="fileInput" @change="$emit('update:file', $refs.fileInput.files[0])" />
      </div>
      <app-table :loading="parsing">
        <template v-slot:headers>
          <div class="cell auto">Name</div>
          <div class="cell auto">Role title</div>
          <div class="cell auto">Function</div>
          <div class="cell auto"></div>
        </template>
        <template v-slot:body>
          <div class="row grid-x align-middle" v-for="(talent, index) in talentsDisplayed" :key="talent.talentId">
            <div class="cell auto talent-name">
              <talent-icon :user="talent"></talent-icon>
              <div class="talent-info">
                <div>{{ talent.firstname | capitalize }} {{ talent.lastname | uppercase }}</div>
                <div class="talent-email cell">{{ talent.email }}</div>
              </div>
            </div>
            <div class="cell auto">{{ talent.talentVersion.role }}</div>
            <div class="cell auto">{{ talent.talentVersion.function }}</div>
            <div class="cell auto row-actions">
              <app-button kind="minimal" size="medium" @click="talentEdited = talent; showEditModal = true">Edit</app-button>
              <app-button kind="minimal" size="medium" @click="deleteTalent(index)">Delete</app-button>
            </div>
          </div>
        </template>
      </app-table>
      <pagination :limit="limit" :offset="offset" :count="count" @update:page="limit = $event.limit; offset = $event.offset"></pagination>
    </div>

    <!-- Modale d'édition -->
    <app-modal :show="showEditModal" @update:show="showEditModal = false; talentEdited = null" title="Add new talent">
      <div class="select-owner" v-show="assigningOwner">
        <div class="select-owner-subtitle">
          <app-link @click="tmpOwner = null; assigningOwner = false;" size="small">
            <SvgArrowRight />
            Back
          </app-link>
          <b>Select Author</b>
        </div>
        <talent-select @update:selected-talent="tmpOwner = $event"></talent-select>
      </div>

      <talent-profile
        v-show="!assigningOwner"
        :initialTalent="talentEdited"
        canAssignOwner
        editMode
        isCSV
        ref="talentProfile"
        :assignedOwner="assignedOwner"
        @submitted="saveTalent($event)"
        @click-assign-owner="assigningOwner = true"
      ></talent-profile>

      <template v-slot:footer>
        <app-button kind="minimal" @click="showEditModal = false">Cancel</app-button>
        <app-button v-if="!assigningOwner" @click="$refs.talentProfile.submit()">Save</app-button>
        <app-button v-else @click="assignedOwner = tmpOwner; assigningOwner = false;">Assign Author</app-button>
      </template>
    </app-modal>

    <!-- Footer d'erreurs -->
    <app-sticky-footer type="error" v-if="errors.length">
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </app-sticky-footer>
  </section>
</template>

<script>
import * as Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import talentApi from '@/services/api/talent';

import GenderLabel from '@/services/enums/gender-label.enum';
import CountryLabel from '@/services/enums/country-label.enum';
import AgeGroupLabel from '@/services/enums/age-group-label.enum';
import EthnicityLabel from '@/services/enums/ethnicity-label.enum';
import MobilityLabel from '@/services/enums/mobility-label.enum';
import SeniorityLabel from '@/services/enums/seniority-label.enum';
import TimeInCompanyLabel from '@/services/enums/time-in-company-label.enum';
import TimeInRoleLabel from '@/services/enums/time-in-role-label.enum';

import SvgArrowRight from '@/assets/img/icons/16px/arrow-right.svg?inline';
import SvgUpload from '@/assets/img/icons/24px/upload.svg?inline';

import TalentIcon from '@/components/TalentIcon.vue';
import Pagination from '@/components/Pagination.vue';
import TalentProfile from './TalentProfile.vue';
import TalentSelect from './TalentSelect.vue';

export default {
  name: 'talents-parsing',
  components: {
    TalentIcon,
    Pagination,
    TalentProfile,
    TalentSelect,
    SvgArrowRight,
    SvgUpload,
  },
  emits: [
    'update:count', // nombre de lignes du fichier csv
    'update:file', // nouveau fichier sélectionné
    'uploaded', // CSV importé
  ],
  props: {
    file: File,
  },
  data() {
    return {
      talents: [],
      limit: 10,
      offset: 0,
      parsing: false,
      talentEdited: null,
      showEditModal: false,
      assigningOwner: false,
      assignedOwner: null,
      tmpOwner: null,
      errors: [],
    };
  },
  computed: {
    count() {
      return this.talents.length;
    },
    talentsDisplayed() {
      return this.talents.slice(this.offset, this.offset + this.limit);
    },
  },
  watch: {
    showEditModal() {
      if (!this.showEditModal) {
        this.talentEdited = null;
        this.assigningOwner = false;
        this.assignedOwner = null;
        this.tmpOwner = null;
      } else {
        this.assignedOwner = this.talentEdited.owner;
      }
    },
    count() {
      this.$emit('update:count', this.count);
    },
    file() {
      this.parseFile(this.file);
    },
  },
  methods: {
    // Supprime un talent affiché selon son index
    deleteTalent(index) {
      const talentIndex = this.offset + index;
      this.talents.splice(talentIndex, 1);
    },
    // Sauvegarde les modifications apportés au talent
    saveTalent(newTalent) {
      const talentIndex = this.talents.findIndex((talent) => talent.talentId === this.talentEdited.talentId);
      this.$set(this.talents, talentIndex, { ...newTalent, owner: this.assignedOwner });
      this.showEditModal = false;
    },

    // Reconstruit le CSV et fait une requête à l'API
    async uploadCSV() {
      // Construction du csv avec modifications
      const csv = Papa.unparse(this.talents.map((talent) => {
        const { talentVersion, ...talentOnly } = talent;
        return {
          ...talentOnly,
          ...talentVersion,
          ownerId: talent.owner ? talent.owner.talentId : undefined,
          owner: undefined,
        };
      }));

      // Envoi à l'API
      try {
        await talentApi.uploadCSV(csv);
        this.$notification.show({ text: `File ${this.file.name} successfullly imported.` });
        this.$emit('uploaded');
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `An unexpected error while importing your file. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },

    // Parse le fichier CSV
    async parseFile(file) {
      this.parsing = true;
      Papa.parse(file, {
        header: true,
        comments: '#',
        skipEmptyLines: 'greedy',
        complete: (results) => {
          // Vérification des headers
          const errors = [];
          const headers = results.meta.fields;
          const requiredHeaders = [
            'firstname',
            'lastname',
            'email',
            'gender',
            'nationality',
            'country',
            'role',
            'function',
            'seniority',
            'mobility',
          ];
          requiredHeaders.forEach((field) => {
            if (!headers.includes(field)) {
              errors.push(`The required field ${field} was not found in your file.`);
            }
          });

          this.talents = results.data.map((talent, index) => {
            // Vérification des champs de la ligne
            const line = index + 1;
            requiredHeaders.forEach((field) => {
              if (!talent[field]) {
                errors.push(`Line ${line}: required field ${field} empty`);
              }
            });

            if (talent.gender && !GenderLabel[talent.gender]) {
              errors.push(`Line ${line}: gender not recognized`);
            }
            if (talent.nationality && !CountryLabel[talent.nationality]) {
              errors.push(`Line ${line}: nationality not recognized`);
            }
            if (talent.country && !CountryLabel[talent.country]) {
              errors.push(`Line ${line}: country not recognized`);
            }
            if (talent.timeInRole && !TimeInRoleLabel[talent.timeInRole]) {
              errors.push(`Line ${line}: timeInRole not recognized`);
            }
            if (talent.timeInCompany && !TimeInCompanyLabel[talent.timeInCompany]) {
              errors.push(`Line ${line}: timeInCompany not recognized`);
            }
            if (talent.seniority && !SeniorityLabel[talent.seniority]) {
              errors.push(`Line ${line}: seniority not recognized`);
            }
            if (talent.mobility && !MobilityLabel[talent.mobility]) {
              errors.push(`Line ${line}: mobility not recognized`);
            }
            if (talent.ageGroup && !AgeGroupLabel[talent.ageGroup]) {
              errors.push(`Line ${line}: ageGroup not recognized`);
            }
            if (talent.ethnicity && !EthnicityLabel[talent.ethnicity]) {
              errors.push(`Line ${line}: ethnicity not recognized`);
            }

            // Construction du talent
            return {
              talentId: uuidv4(),
              firstname: talent.firstname,
              lastname: talent.lastname,
              email: talent.email,
              phone: talent.phone,
              talentVersion: {
                role: talent.role,
                function: talent.function,
                timeInRole: talent.timeInRole,
                timeInCompany: talent.timeInCompany,
                seniority: talent.seniority,
                ageGroup: talent.ageGroup,
                ethnicity: talent.ethnicity,
                mobility: talent.mobility,
                gender: talent.gender,
                nationality: talent.nationality,
                country: talent.country,
              },
              owner: null,
            };
          });
          this.errors = errors;
          this.parsing = false;
        },
        error: (error) => this.$message.show({
          title: 'Parsing error',
          text: `Error while parsing the file : ${error}`,
          onConfirm: () => this.$router.go(-1),
        }),
      });
    },
  },
  mounted() {
    this.parseFile(this.file);
  },
};
</script>

<style lang="sass">
.talents-parsing
  display: flex
  flex-direction: column
  justify-content: space-between
  .table-container
    @include table-container
    .table-title
      font-size: 20px
      margin-bottom: 2rem
      display: flex
      justify-content: space-between
    .app-table
      .body .row
        .talent-name
          display: flex
          .talent-info
            margin-left: 0.5rem
            div:first-child
              color: $black
              font-weight: 700
            div:last-child
              font-size: 13px
        .row-actions
          display: flex
          justify-content: flex-end
          .app-button
            margin-left: 1rem

  .modal-wrapper
    top: 35%
    .modal-content
      overflow-y: scroll
      max-height: 70vh
      .talent-profile
        padding: 0 10rem
      .select-owner
        padding: 1.5rem 1rem
        .select-owner-subtitle
          display: flex
          align-items: flex-start
          margin-bottom: 1rem
          height: 30px
          .app-link
            position: relative
            top: 2px
            svg
              transform: rotate(180deg)
              width: 10px
              margin-right: 5px
              position: relative
              top: 2px
          b
            font-size: 16px
            margin: 0 1rem

  .hidden
    width: 0
    height: 0
    display: none
</style>
