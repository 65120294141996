<!--
Composant affiché en gris par défaut.

Pour changer de couleur, modifier l'état du composant avec les valeurs suivantes :
.default (gris),
.main (violet),
.success (vert),
.error (rouge),
.yellow (jaune)
.transparent (transaprent)

-->

<template>
  <div class="app-tag" :class="state">
    <slot></slot>
    <div v-if="removable" class="delete-button" @click="$emit('delete')">
      <svg-cross />
    </div>
  </div>
</template>

<script>
import SvgCross from '@/assets/img/icons/16px/cross.svg?inline';

export default {
  components: {
    'svg-cross': SvgCross,
  },
  props: {
    state: {
      type: String,
      default: 'default',
      validator: (val) => ['default', 'main', 'success', 'error', 'yellow', 'black', 'transparent', 'deleted'].indexOf(val) !== -1,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.app-tag
  padding: 6px 12px
  border-radius: 16px
  font-weight: 700
  display: flex
  background-color: $light-color
  align-items: center
  justify-content: center
  width: fit-content
  img, .app-spinner
    width: $global-font-size
  img, .app-spinner, svg
    margin-right: 5px
  svg path
    fill: currentcolor
  .delete-button
    width: 24px
    height: 24px
    position: relative
    right: -0.6em
    padding: 0.2em
    border-radius: 20px
    display: flex
    align-items: center
    justify-content: center
    &:hover
      background: rgba(0, 0, 0, 0.05)
      cursor: pointer
    svg
      position: static
      height: initial
      width: initial
      margin-right: 0
      path
        fill: currentcolor
  &.default
    color: $common-gray
  &.main
    background-color: $main-bg-color
    color: $main-secondary-color
  &.success
    background-color: $success-bg-color
    color: $success-secondary-color
  &.error
    background-color: $error-bg-color
    color: $error-secondary-color
  &.yellow
    background-color: #faf1d9
    color: #634b0f
  &.transparent
    background-color: transparent
    color: $black
  &.deleted
    background-color: $background-color
    color: $common-gray
  &.black
    background-color: $black-bias-bg
    color: $black
</style>
