<!-- COMPOSANT APP TAB

##########USAGE##########

  <app-tab
    @click="handleClick"
    :tabs="['Overview', 'Talent', 'Bias by talent group', 'Writers', 'Drive for change', 'TGPs']"
  />
  <div>
    {{ content[activeTab]  }}
  </div>

  data() {
    return {
      activeTab: Number(this.$route.query.activeTab) || 0,
      content: [1, 2, 3, 4, 5, 6],
    };
  },

  methods: {
    handleClick(value) {
      this.activeTab = value;
    },
  },

********
To have the last tab aligned on the far right, add lastAlignedRight prop
-->

<template>
  <section class="app-tab">
    <ul :class="{ 'has-placeholder': lastAlignedRight }">
      <li :class="[{active: activeTab === index}]" @click="handleClick(index)" v-for="(tab, index) in tabs" :key="`tab-${componentId}-index-${index}`">{{ tab }}</li>
      <li v-if="lastAlignedRight" class="placeholder"></li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    // Make the last tab align far right
    lastAlignedRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentId: 0,
      activeTab: Number(this.$route.query.activeTab) || 0,
    };
  },
  watch: {
    $route() {
      this.activeTab = Number(this.$route.query.activeTab) || 0;
    },
  },
  mounted() {
    // eslint-disable-next-line
    this.componentId = this._uid;
    this.activeTab = Number(this.$route.query.activeTab) || 0;
  },
  methods: {
    handleClick(index) {
      this.$emit('click', Number(index));
      this.$router.replace({ query: { ...this.$route.query, activeTab: Number(index) } });
    },
  },
};
</script>

<style lang="sass">
.app-tab
  ul
    display: flex
    list-style: none
    padding: 0
    li
      line-height: 21px
      padding: 0 12px 12px 12px
      font-size: $global-font-size
      color: $common-gray
      border-bottom: 2px solid $light-color
      cursor: pointer
      transition: all ease-in-out 0.2s
      z-index: 1
      &:hover
        color: $main-secondary-color
        border-bottom-color: $main-color
      &.active
        color: $main-secondary-color
        border-bottom-color: $main-color
      &:first-child
        padding: 0 12px 12px 0
      &:last-child
        padding: 0 0 12px 12px
    &.has-placeholder
      li
        &.placeholder
          order: 1
          flex-grow: 1
          padding: 0
          cursor: initial
          &:hover
            border-bottom-color: $light-color
        &:nth-last-child(2)
          order: 2
          padding: 0 0 12px 12px
</style>
