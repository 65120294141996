<template>
  <div class="graph-card">
    <h3>{{ title }}</h3>
    <h4 v-if="subtitle">{{ subtitle }}</h4>
    <div class="separator"></div>
    <div class="graph-card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'graph-card',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};

</script>

<style lang="sass">
.graph-card
  background: $white
  margin: 2rem 0
  padding: 2rem
  padding-top: 1rem
  border-radius: $global-border-radius
  h3
    margin-bottom: 0
  h4
    font-size: 1.17rem
    color: $common-gray
    margin: 0
    font-weight: normal
  .separator
    width: 100%
    height: 2px
    background: $light-color
    margin: 2rem 0
</style>
