import Vue from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';

import Basics from './basics/index';

Vue.config.productionTip = false;

Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('uppercase', (value) => {
  if (!value) return '';
  return value.toUpperCase();
});

Vue.use(Basics);

// Google Analytics
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID },
}, router);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
