<template>
  <div class="cycle-reviews">
    <!-- Barre de recherche avec boutons de filtres -->
    <div class="filter-container">
      <app-search v-model="search"></app-search>
      <div class="clear-filters-container">
        <app-button v-if="filtersCount > 0 && $refs.talentFilters" kind="minimal" size="medium" class="clear-filters-button" @click="$refs.talentFilters.clearAll">
          <svg-cross class="leading" />
          Clear filters
        </app-button>
      </div>
      <app-button kind="minimal" size="medium" @click="openFilters = true" :disabled="editingGroup">
        Filters {{ filtersCount ? `(${filtersCount})` : '' }}
        <svg-filter class="trailing" />
      </app-button>
    </div>
    <div class="table-container">
      <app-table :loading="loading">
        <template v-slot:headers>
          <div class="cell auto">Name</div>
          <div class="cell auto">Role title</div>
          <div class="cell auto">Function</div>
          <div class="cell auto">Cycle</div>
          <div class="cell auto">End date <SvgTriangle /></div>
          <div class="cell auto">Status</div>
          <div class="cell auto">Bias check</div>
        </template>
        <template v-slot:body>
          <div
            v-for="review in reviews"
            :key="review.reviewId"
            class="row grid-x align-middle"
            :class="review.status.toLowerCase()"
            @click="goToReview(review)"
          >
            <div class="cell auto talent-name">
              <talent-icon :user="review.talent"></talent-icon>
              <div class="talent-info">
                <div>{{ review.talent.firstname | capitalize }} {{ review.talent.lastname | uppercase }}</div>
                <div class="talent-email cell">{{ review.talent.email }}</div>
              </div>
            </div>
            <div class="cell auto">{{ review.talentVersion.role }}</div>
            <div class="cell auto">{{ review.talentVersion.function }}</div>
            <div class="cell auto">{{ review.cycle.name }}</div>
            <div class="cell auto">{{ utils.formatDate(review.cycle.endDate) }}</div>
            <div class="cell auto">
              <app-tag :state="utils.getReviewEditTagState(review.status)">
                <template v-if="review.status === ReviewStatus.COMPLETED"><Complete/></template>
                <template v-else-if="review.status === ReviewStatus.TALENT_DELETED"><SvgDelete /></template>
                <template v-else><Editing/></template>
                {{ ReviewStatusLabel[review.status] }}
              </app-tag>
            </div>
            <div class="cell auto">
              <app-tag :state="utils.getBiasCheckTagState(review.status)">
                <template v-if="BiasCheckLabel[review.status] === BiasCheckLabel.NEW"><BiasCheckNotSent/></template>
                <template v-else-if="BiasCheckLabel[review.status] === BiasCheckLabel.PROCESSING"><BiasCheckProcessing/></template>
                <template v-else><BiasCheckComplete/></template>
                {{ BiasCheckLabel[review.status] }}
              </app-tag>
            </div>
          </div>
        </template>
      </app-table>
      <pagination :count="count" :limit="limit" :offset="offset" @update:page="getReviews($event.offset, $event.limit)"></pagination>
    </div>

    <!-- Filtres de talents -->
    <talent-filters :show="openFilters" @close="openFilters = false" ref="talentFilters"></talent-filters>
  </div>
</template>

<script>
import utils from '@/services/utils';
import reviewApi from '@/services/api/review';

// SVG
import Editing from '@/assets/img/icons/24px/edit.svg?inline';
import BiasCheckComplete from '@/assets/img/icons/24px/bias-check-complete.svg?inline';
import BiasCheckNotSent from '@/assets/img/icons/24px/bias-check-not-sent.svg?inline';
import BiasCheckProcessing from '@/assets/img/icons/24px/bias-check-processing.svg?inline';
import Complete from '@/assets/img/icons/24px/tick.svg?inline';
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import SvgCross from '@/assets/img/icons/24px/cross.svg?inline';
import SvgFilter from '@/assets/img/icons/24px/filter.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';

// ENUMS
import ReviewStatusLabel from '@/services/enums/review-status-label.enum';
import BiasCheckLabel from '@/services/enums/bias-check-label.enum';
import ReviewStatus from '@/services/enums/review-status.enum';

// Components
import Pagination from '@/components/Pagination.vue';
import TalentIcon from '@/components/TalentIcon.vue';
import TalentFilters from '../../talents/TalentFilters.vue';

export default {
  components: {
    Editing,
    BiasCheckComplete,
    BiasCheckNotSent,
    BiasCheckProcessing,
    Complete,
    SvgDelete,
    SvgTriangle,
    Pagination,
    TalentIcon,
    TalentFilters,
    'svg-filter': SvgFilter,
    'svg-cross': SvgCross,
  },
  data() {
    return {
      loading: false,
      reviews: [],
      search: this.$route.query.search || '',
      count: 0,
      offset: this.$route.query.offset || 0,
      limit: this.$route.query.limit || 10,
      utils,
      ReviewStatus,
      ReviewStatusLabel,
      BiasCheckLabel,

      openFilters: false,
      editingGroup: false,
    };
  },
  watch: {
    search() {
      this.debouncedUpdateQuery({ offset: 0, search: this.search });
    },
    $route() {
      this.getReviews(this.$route.query.offset, this.$route.query.limit);
    },
  },
  methods: {
    changePage({ limit, offset }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    goToReview(review) {
      if (review.status === ReviewStatus.COMPLETED) {
        this.$router.push({ name: 'review', params: { reviewId: review.reviewId } });
      }
    },
    async getReviews(offset = this.offset, limit = this.limit) {
      this.loading = true;
      try {
        const response = await reviewApi.getReviews(
          limit,
          offset,
          this.$route.params.cycleId,
          null,
          null,
          null,
          null,
          this.search,
          this.$route.query.filterCountry,
          this.$route.query.filterFunction,
          this.$route.query.timeInRole,
          this.$route.query.timeInCompany,
          this.$route.query.seniority,
          this.$route.query.filterOwner,
        );
        this.reviews = response.data;
        this.limit = response.metadata.limit;
        this.offset = response.metadata.offset;
        this.count = response.metadata.count;
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getReviews();
    this.debouncedUpdateQuery = utils.debounce((query) => {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    }, 400);
  },
  computed: {
    filtersCount() {
      const timeInRole = utils.initArray(this.$route.query.timeInRole);
      const timeInCompany = utils.initArray(this.$route.query.timeInCompany);
      const seniority = utils.initArray(this.$route.query.seniority);
      const filterFunction = utils.initArray(this.$route.query.filterFunction);
      const filterOwner = utils.initArray(this.$route.query.filterOwner);
      const filterCountry = utils.initArray(this.$route.query.filterCountry);
      return filterFunction.length + filterOwner.length + filterCountry.length + timeInRole.length + timeInCompany.length + seniority.length;
    },
  },
};
</script>

<style lang="sass">
.cycle-reviews
  .filter-container
    display: flex
    align-items: center
    justify-content: space-between
    background: $white
    padding: 16px 24px
    margin: 2rem 0 2rem 0
    border-radius: $global-border-radius
    .app-search
       width: 50%
  .table-container
    @include table-container
    .app-table
      .body .row .cell:first-child, .headers .cell:first-child
        min-width: 200px
      .body .row
        .talent-name
          display: flex
          .talent-info
            margin-left: 0.5rem
            div:first-child
              color: $black
              font-weight: 700
            div:last-child
              font-size: 13px
        &.completed
          @include clickable-row
        &.talent_deleted
          background-color: $light-color
  .svg-triangle
    margin: 0 0 0 10px
</style>
