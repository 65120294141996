<template>
  <form class="cycle-timeline" ref="cycleDates" @submit.prevent="goNextStep">
      <div class="name-container">
        <div>
          <app-label>Cycle release date</app-label>
          <app-datepicker placeholder="DD / MM / YY" required v-model="cycleStartDate" :disabledDates="{to: yesterday, from: cycleEndDate}"/>
        </div>
        <div>
          <app-label>Cycle end date</app-label>
          <app-datepicker placeholder="DD / MM / YY" required v-model="cycleEndDate" :disabledDates="{to: cycleStartDate}"/>
        </div>
      </div>
      <app-sticky-footer type="actions">
        <app-button kind="secondary" @click="goPreviousStep">Back</app-button>
        <app-button :disabled="!cycleStartDate || !cycleEndDate" type="submit">Next</app-button>
      </app-sticky-footer>
  </form>
</template>

<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      cycleStartDate: null,
      cycleEndDate: null,
      error: null,
      yesterday: dayjs().subtract(1, 'day').$d,
    };
  },
  props: {
    oldDates: {
      default: null,
    },
  },
  mounted() {
    this.cycleStartDate = this.oldDates?.startDate;
    this.cycleEndDate = this.oldDates?.endDate;
  },
  methods: {
    goPreviousStep() {
      this.$emit('update:inputStartDate', this.cycleStartDate);
      this.$emit('update:inputEndDate', this.cycleEndDate);
      this.$emit('update:step', 3);
    },
    goNextStep() {
      if (this.$refs.cycleDates.reportValidity()) {
        if (this.cycleStartDate <= this.cycleEndDate) {
          this.$emit('update:inputStartDate', this.cycleStartDate);
          this.$emit('update:inputEndDate', this.cycleEndDate);
          this.$emit('update:step', 5);
        } else {
          this.$message.show({
            title: 'Error',
            text: 'Cycle end date must be after cycle start date',
          });
        }
      }
    },
  },
};
</script>

<style lang="sass">
.cycle-timeline
  padding-top: 30px
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%
  .name-container
    height: calc(100vh - 350px)
    background: white
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0 30% 5% 30%
    gap: 2rem
    .app-label
      font-size: 28px
    .app-datepicker
      max-width: 600px
</style>
