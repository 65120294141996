const CopyKeyLabel = Object.freeze({
  ONBOARDING_START_1: 'Onboarding start 1 (no talents yet)',
  ONBOARDING_START_2: 'Onboarding start 2 (talents already added)',
  ONBOARDING_END: 'Onboarding end',
  CYCLE_SETUP_START: 'Cycle setup start',
  CYCLE_SETUP_END: 'Cycle setup end',
  REVIEW_SENT_FOR_BIAS_CHECK: 'Review sent for bias check',
  REVIEW_COMPLETED: 'Review completed',
  NUDGE_IMPACT_TODAY: 'Nudge impact today',
  NUDGE_IMPACT_TOMORROW: 'Nudge impact tomorrow',
  NUDGE_PROMOTABILITY: 'Nudge promotability',
  NUDGE_GROWTH_IDEAS: 'Nudge growth ideas',
  NUDGE_SUMMARY: 'Nudge summary',
});

export default CopyKeyLabel;
