<template>
  <section class="copies">
    <div class="title-bar grid-x align-middle grid-margin-x">
      <app-title class="cell auto">Copy</app-title>
    </div>
    <div class="grid-x container align-center">
      <Loader v-if="loading" />
      <div v-else class="cell medium-12 copy" v-for="(copy, index) in copies" :key="`copies-index-${index}`">
        <copy-line
          :copy="copy"
          @copy-line-text-updated="(newText) => handleUpdateText(newText, index)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import copyApi from '../../services/api/copy';
import copyLine from './CopyLine.vue';
import Loader from '../../components/Loader.vue';

export default {
  name: 'copies',
  components: {
    copyLine,
    Loader,
  },
  data() {
    return {
      copies: [],
      loading: false,
    };
  },
  async mounted() {
    await this.getCopies();
  },
  methods: {
    async getCopies() {
      this.loading = true;
      try {
        const response = await copyApi.getCopies();
        this.copies = response.data;
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the copies. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.loading = false;
    },
    handleUpdateText(newText, index) {
      this.copies[index].copyText = newText;
    },
  },
};
</script>

<style lang="sass">
.copies
  @include screen
  .container
    padding: 51px 39px
    background-color: $white
    .copy
      padding: 24px
      background-color: $background-color
      .app-subtitle
        margin: 0 0 12px 24px
        color: $main-color
        font-size: 20px
      .app-input
        textarea
          min-height: 164px
          background-color: $light-color
          &:focus
            background-color: $white
      ~ .copy
        margin-top: 39px
</style>
