<template>
  <section class="account">
    <div class="account-details">
      <div class="account-details-title">
        <app-subtitle>Account details</app-subtitle>
        <div>
          <router-link :to="{ name: 'account-edit' }">
            <app-button :class="['medium']">Edit</app-button>
          </router-link>
        </div>
      </div>
      <div class="account-details-infos" v-if="userInfos">
        <div class="avatar">
          <talent-icon :user="getConnectedUser"/>
        </div>
        <div v-if="!isSuperAdmin" class="account-details-info">
          <p class="info-label">First name</p>
          <p class="info">{{ userInfos.firstname }}</p>
        </div>
        <div v-if="!isSuperAdmin" class="account-details-info">
          <p class="info-label">Surname</p>
          <p class="info">{{ userInfos.lastname }}</p>
        </div>
        <div class="account-details-info">
          <p class="info-label">Email</p>
          <p class="info">{{ userInfos.email }}</p>
        </div>
        <div class="account-details-info">
          <p class="info-label">Password</p>
          <p class="info">●●●●●●●●●●</p>
        </div>
        <div v-if="!isSuperAdmin" class="account-details-info">
          <p class="info-label">Phone</p>
          <p class="info">{{ userInfos.phone }}</p>
        </div>
      </div>
      <div class="subscription-details" v-if="userInfos && !isSuperAdmin">
        <div class="subscription-details-info">
          <p class="info-label">Subscription</p>
          <p class="info">{{ getSubscriptionLabel }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import talentApi from '@/services/api/talent';
import superAdminApi from '@/services/api/super-admin';
import SubscriptionLabel from '@/services/enums/subscription-label.enum';
import TalentIcon from '@/components/TalentIcon.vue';
import ls from 'local-storage';
import UserRole from '../../services/enums/user-role.enums';

export default {
  components: { TalentIcon },
  name: 'account',
  data() {
    return {
      userInfos: null,
      userId: '',
      isSuperAdmin: false,
    };
  },
  mounted() {
    this.getUserInfos();
  },
  methods: {
    async getUserInfos() {
      const user = ls('USER');

      if (user) {
        let infos;
        try {
          if (user.role !== UserRole.SUPER_ADMIN) {
            infos = await talentApi.getMe();
            this.userInfos = infos.data;
            this.userId = user.talentId;
            this.isSuperAdmin = false;
          } else {
            infos = await superAdminApi.getMe();
            this.userInfos = infos.data;
            this.userId = user.superAdminId;
            this.isSuperAdmin = true;
          }
        } catch (error) {
          const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
          this.$message.show({
            title: 'Error',
            text: `We are unable to fetch the information. ${messages}`,
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      }
    },
  },
  computed: {
    getSubscriptionLabel() {
      switch (this.userInfos.company.subscription) {
        case 'BASIC':
          return SubscriptionLabel.BASIC;
        case 'STANDARD':
          return SubscriptionLabel.STANDARD;
        case 'PRO':
          return SubscriptionLabel.PRO;
        default:
          return 'None';
      }
    },
    getConnectedUser() {
      if (this.isSuperAdmin) {
        return {
          superAdminId: this.userInfos.superAdminId,
          role: this.userInfos.role,
        };
      }
      return {
        talentId: this.userInfos.talentId,
        firstname: this.userInfos.firstname,
        lastname: this.userInfos.lastname,
        role: this.userInfos.role,
      };
    },
  },
};
</script>

<style lang="sass">
.account
  padding: 1rem
  display: flex
  justify-content: center
  .account-details
    width: 50vw
    .account-details-title
      display: flex
      align-items: center
      justify-content: space-between
      border-bottom: 2px solid $light-color
    .account-details-infos
      padding: 34px 0
      border-bottom: 2px solid $light-color
      position: relative
      .avatar
        position: absolute
        left: -150px
        transform: scale(2)
      .account-details-info
        display: flex
        .info-label
          width: 40%
          font-weight: 700
        .info
          color: $link-color
  .subscription-details
    padding: 34px 0
    .subscription-details-info
      display: flex
      .info-label
        width: 40%
        font-weight: 700
      .info
        color: $link-color
        text-transform: capitalize
</style>
