<template>
  <div class="loader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; background: transparent; display: block; shape-rendering: auto;"
      width="150px"
      height="150px"
      viewBox="0 0 150 150"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <rect class="one"></rect>
        <rect class="two"></rect>
        <rect class="three"></rect>
        <rect class="four"></rect>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="sass">
.loader
  g
    transform: translate(40px, 0px)
    .one, .two, .three, .four
      height: 40px
      width: 11px
      rx: 1px
      y: 110px
      x: 0px
      animation: bar-move 1.5s infinite
    .one
      fill: #fa693a
      animation-delay: -1s
    .two
      fill: #2bd7cc
      x: 20px
      animation-delay: -0.60s
    .three
      fill: #5c33c3
      x: 40px
      animation-delay: -0.30s
    .four
      fill: #87cef5
      x: 60px
      animation-delay: 0s

@keyframes bar-move
  0%
    height: 40px
    y: 110px
  50%
    height: 70px
    y: 80px
  100%
    height: 40px
    y: 110px
</style>
