<template>
  <section class="reviews" :class="{loading: isLoading}" @click="closeSmallMenus">

    <template>
      <div class="grid-x head">
        <app-title class="cell auto">My Talent Growth Plans</app-title>
        <div class="cell shrink">
          <router-link :to="{ name: 'review-create' }"><app-button size="medium"><SvgPlus/>Create new TGP</app-button></router-link>
        </div>
      </div>

      <app-tab :tabs="['Current cycles', 'Archive']"/>

      <div class="search-bar">
        <div class="grid-x filters">
          <app-tag @click.native="setAllOrActionRequired('all')" :state="!allOrActionRequired ? 'main' : 'default'">All</app-tag>
          <app-tag :class="[{ actionRequired: allOrActionRequired}]" @click.native="setAllOrActionRequired('required')" :state="allOrActionRequired ? 'main' : 'default'">Action required</app-tag>
        </div>
        <div class="clear-filters-container" v-if="$refs.reviewFilters">
          <app-button v-if="filtersCount > 0" kind="minimal" size="medium" class="clear-filters-button" @click="() => $refs.reviewFilters.clearAll()">
            <svg-cross class="leading" />
            Clear filters
          </app-button>
        </div>
        <app-button kind="minimal" size="medium" @click="openFilters = true">
          Filters {{ filtersCount ? `(${filtersCount})` : '' }}
          <svg-filter class="trailing" />
        </app-button>
      </div>

      <div class="table-container">
        <app-table class="table" :loading="isLoading">
          <template v-slot:headers>
            <div class="cell auto talent-name-header">Name</div>
            <div class="cell shrink number-flags-header"></div>
            <div class="cell auto">Role title</div>
            <div class="cell auto">Function</div>
            <div class="cell auto">Cycle</div>
            <div class="cell auto">End date</div>
            <div class="cell auto">Status <SvgTriangle /></div>
            <div class="cell auto">Bias check</div>
            <div class="cell shrink"></div>
          </template>
          <template v-slot:body>
            <div class="row grid-x align-middle" :class="review.status.toLowerCase()" v-for="review in data" :key="review.reviewId" @click="$router.push({ name: 'review', params: { reviewId: review.reviewId }})">
              <div class="cell auto talent-name">
                <talent-icon :user="review.talent"></talent-icon>
                <div class="talent-info">
                  <div>{{ review.talent.firstname | capitalize }} {{ review.talent.lastname | uppercase }}</div>
                  <div class="talent-email cell">{{ review.talent.email }}</div>
                </div>
              </div>
              <div class="cell shrink number-flags"><app-tag state="error" v-if="Number(review.numberFlags) > 0">{{ Number(review.numberFlags) > 0 ? `${review.numberFlags} flags` : '' }}</app-tag></div>
              <div class="cell auto">{{ review.talentVersion.role }}</div>
              <div class="cell auto">{{ review.talentVersion.function }}</div>
              <div class="cell auto">{{ review.cycle ? review.cycle.name : 'N/A' }}</div>
              <div class="cell auto">{{ utils.formatDate(review.cycle && review.cycle.endDate) }}</div>
              <div class="cell auto">
                <app-tag :state="utils.getReviewEditTagState(review.status)">
                  <template v-if="ReviewStatusLabel[review.status] === ReviewStatusLabel.COMPLETED"><Complete/></template>
                  <template v-else><Editing/></template>
                  {{ ReviewStatusLabel[review.status] }}
                </app-tag>
              </div>
              <div class="cell auto">
                <app-tag :state="utils.getBiasCheckTagState(review.status)">
                  <template v-if="BiasCheckLabel[review.status] === BiasCheckLabel.NEW"><BiasCheckNotSent/></template>
                  <template v-else-if="BiasCheckLabel[review.status] === BiasCheckLabel.PROCESSING"><BiasCheckProcessing/></template>
                  <template v-else><BiasCheckComplete/></template>
                  {{ BiasCheckLabel[review.status] }}
                </app-tag>
              </div>
              <div class="cell shrink">
                <app-tooltip class="suppr-menu" :isMenu="true" position="bottom-right" v-if="!review.cycle">
                  <app-button @click.stop="$refs['review-delete'].show(review.reviewId)" kind="minimal" size="tiny">
                      <svg-delete class="leading"/>
                      Delete
                  </app-button>
                  <template v-slot:app-tooltip-wrapper>
                    <app-button kind="minimal" size="tiny" class="overflow-button no-focus" @click.stop="openSmallMenu">
                      <svg-overflow />
                    </app-button>
                  </template>
                </app-tooltip>
              </div>
            </div>
          </template>
        </app-table>
        <pagination v-if="data" :limit="metadata.limit" :offset="metadata.offset" :count="metadata.count" @update:page="changePage"/>
      </div>
    </template>
    <review-delete ref="review-delete" @delete="getData(metadata.limit || $route.query.limit, metadata.offset || $route.query.offset)"></review-delete>

    <!-- Filtres de talents -->
    <review-filters :show="openFilters" @close="openFilters = false" ref="reviewFilters"></review-filters>
  </section>
</template>

<script>
import ls from 'local-storage';
import utils from '@/services/utils';

import Pagination from '@/components/Pagination.vue';
import TalentIcon from '@/components/TalentIcon.vue';

import SvgOverflow from '@/assets/img/icons/24px/overflow.svg?inline';
import SvgPlus from '@/assets/img/icons/24px/plus.svg?inline';
import SvgTriangle from '@/assets/img/icons/16px/triangle.svg?inline';
import SvgFilter from '@/assets/img/icons/24px/filter.svg?inline';
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import SvgCross from '@/assets/img/icons/24px/cross.svg?inline';

import Editing from '@/assets/img/icons/24px/edit.svg?inline';
import BiasCheckComplete from '@/assets/img/icons/24px/bias-check-complete.svg?inline';
import BiasCheckNotSent from '@/assets/img/icons/24px/bias-check-not-sent.svg?inline';
import BiasCheckProcessing from '@/assets/img/icons/24px/bias-check-processing.svg?inline';
import Complete from '@/assets/img/icons/24px/tick.svg?inline';
import ReviewDelete from './ReviewDelete.vue';
import reviewApi from '../../services/api/review';
import ReviewStatusLabel from '../../services/enums/review-status-label.enum';
import BiasCheckLabel from '../../services/enums/bias-check-label.enum';
import ReviewFilters from './ReviewFilters.vue';

export default {
  name: 'reviews',
  components: {
    TalentIcon,
    SvgPlus,
    SvgOverflow,
    SvgTriangle,
    Complete,
    Editing,
    BiasCheckComplete,
    BiasCheckNotSent,
    BiasCheckProcessing,
    Pagination,
    ReviewDelete,
    ReviewFilters,
    'svg-delete': SvgDelete,
    'svg-filter': SvgFilter,
    'svg-cross': SvgCross,
  },
  data() {
    return {
      user: null,
      metadata: {
        offset: +this.$route.query.offset || 0,
        limit: +this.$route.query.limit || 5,
        count: 0,
      },
      data: [],
      isLoading: true,
      activeTab: Number(this.$route.query.activeTab) || 0,
      utils,
      ReviewStatusLabel,
      BiasCheckLabel,
      allOrActionRequired: 0, // all = 0, ActionRequired = 1
      oneSmallMenuIsOpened: false,
      searchCycle: this.$route.query.searchCycle || '',
      status: this.$route.query.status || [],
      openFilters: false,
    };
  },
  computed: {
    filtersCount() {
      const filterCycle = utils.initArray(this.$route.query.filterCycle);
      const status = utils.initArray(this.$route.query.status);
      const filterTalent = utils.initArray(this.$route.query.search);
      return filterCycle.length + status.length + filterTalent.length;
    },
  },
  watch: {
    async $route() {
      const { limit, offset } = this.$route.query;
      if (this.metadata.limit !== +limit || this.metadata.offset !== +offset || offset === '0') {
        this.getData(+limit, +offset);
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            offset: 0,
          },
        });
      }
    },
    async allOrActionRequired() {
      this.getData(this.metadata.limit || this.$route.query.limit, this.metadata.offset || this.$route.query.offset);
    },
    search() {
      this.debouncedUpdateQuery({ offset: 0, search: this.search });
    },
  },
  async mounted() {
    await this.getUser();
    await this.getData(this.metadata.limit || this.$route.query.limit, this.metadata.offset || this.$route.query.offset);
    this.debouncedUpdateQuery = utils.debounce((query) => {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    }, 400);
  },
  methods: {
    getUser() {
      this.user = ls('USER');
    },
    async getReviews(limit, offset, status, archived, searchTalent, filterCycle) {
      let reviews;
      try {
        const response = await reviewApi.getReviews(limit, offset, null, null, this.user.talentId, status, archived, searchTalent, null, null, null, null, null, null, filterCycle);
        reviews = {
          data: response.data,
          metadata: response.metadata,
        };
      } catch (error) {
        const messages = typeof error.response.data.message === 'string' ? error.response.data.message : error.response.data.message.join('. ');
        this.$message.show({
          title: 'Error',
          text: `We are unable to fetch the TGPs. ${messages}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      return reviews;
    },
    async getData(limit, offset) {
      this.isLoading = true;

      const tmpStatus = !this.allOrActionRequired ? this.$route.query.status || null : ['NEW', 'EDITING'];
      let tmpArchived = false;

      switch (+this.activeTab) {
        case 0:
          tmpArchived = false;
          break;
        default:
          tmpArchived = true;
          break;
      }

      const tmpData = await this.getReviews(limit, offset, tmpStatus, tmpArchived, this.$route.query.search, this.$route.query.filterCycle);
      this.data = tmpData.data;
      this.metadata = tmpData.metadata;

      this.isLoading = false;
    },
    setAllOrActionRequired(filterLabel) {
      switch (filterLabel) {
        case 'required':
          this.allOrActionRequired = 1;
          // Activation des filtres NEW et EDITING car la tab action required retourne uniquement les reviews avec ces statuts
          this.$refs.reviewFilters.status = ['NEW', 'EDITING'];
          // Désactivation de la possibilité de filtrer sur d'autres statuts
          this.$refs.reviewFilters.isStatusDisabled = true;
          break;
        default:
          this.allOrActionRequired = 0;
          this.$refs.reviewFilters.isStatusDisabled = false;
          // Nettoyage de la query statut
          this.$refs.reviewFilters.status = [];
          break;
      }
    },
    changePage({ limit, offset }) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          limit,
          offset,
        },
      });
    },
    openSmallMenu(e) {
      if (!e.currentTarget.parentElement.previousSibling.classList.contains('opened')) {
        if (this.oneSmallMenuIsOpened) {
          this.closeSmallMenus();
        }
        e.currentTarget.parentElement.previousSibling.classList.add('opened');
        this.oneSmallMenuIsOpened = true;
      } else {
        this.closeSmallMenus();
      }
    },
    closeSmallMenus() {
      if (this.oneSmallMenuIsOpened) {
        const openedMenus = document.querySelectorAll('.suppr-menu .opened');
        openedMenus.forEach((openedMenu) => {
          openedMenu.classList.remove('opened');
        });
        this.oneSmallMenuIsOpened = false;
      }
    },
  },
};
</script>

<style lang="sass">
.reviews
  @include screen
  .head
    align-items: center
    .cell
      .app-button
        display: flex
        float: right
        svg
          margin: 0 12px 0 0
  ul
    position: relative
    &::after
      content: ''
      position: absolute
      height: calc(100% - 2px)
      width: 100%
      border-bottom: 2px solid #EFEEED
      z-index: 0
  .filters
    .app-tag
      cursor: pointer
      padding: 13px 24px
      border-radius: $global-border-radius * 5
      &:last-child
        margin-left: 8px
  .table-container
    @include table-container
    .table
      .body .row .cell:last-child, .headers .cell:last-child
        width: 2rem
        .suppr-menu
          .app-tooltip-container
            display: none
            background: $white
            position: absolute
            z-index: 5
            right: -7px !important
            box-shadow: 0px 2px 8px 0px rgba(103, 102, 98, 0.2)
            border-radius: 4px
            .app-button
              width: 100%
              border-radius: 0
            &.opened
              display: block

      .body .row
          @include clickable-row
          .talent-name
            display: flex
            .talent-info
              margin-left: 0.5rem
              div:first-child
                color: $black
                font-weight: 700
              div:last-child
                font-size: 13px
          .pending-owner
            @include pending-owner
          .overflow-button
            @include icon-button
            width: 2.5rem
            height: 2.5rem
      .row
        &.editing, &.new
          border-left: 4px solid $main-color
    .body .number-flags, .headers .number-flags-header
      width: 5.5rem
    .body .row .talent-name, .headers .talent-name-header
      min-width: 165px
    .app-tag.flags
      padding: 4px 8px
      font-size: 13px
  .svg-triangle
    margin: 0 0 0 10px
  .search-bar
    display: flex
    align-items: center
    justify-content: space-between
    background: $white
    padding: 16px 24px
    margin: 8px 0
    .app-search
       width: 50%
    .clear-filters-container
      flex-grow: 1
      display: flex
      justify-content: flex-end
      margin: 0 0.5rem
</style>
