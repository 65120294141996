<template>
  <section class="typical-bias-edit">
    <div class="typical-bias-form">
      <div class="section-title">Identification</div>
      <div class="input-line">
        <label class="input-label">Title</label>
        <app-input type="text" class="input-field" v-model="editTypicalBias.label" :disabled="!onEdit"></app-input>
      </div>
      <div class="input-line">
        <label class="input-label">Reference</label>
        <app-input type="text" class="input-field" v-model="editTypicalBias.reference" disabled></app-input>
      </div>
      <div class="input-line">
        <label class="input-label">Link</label>
        <app-input type="text" class="input-field" v-model="editTypicalBias.link" :disabled="!onEdit"></app-input>
      </div>
      <div class="section-title">Copies</div>
      <div class="input-textarea">
        <label class="input-label">Description</label>
        <app-input type="text" class="input-field" v-model="editTypicalBias.description" multiline :disabled="!onEdit"></app-input>
      </div>
      <div class="input-textarea">
        <label class="input-label">Cause</label>
        <app-input type="text" class="input-field" v-model="editTypicalBias.cause" multiline :disabled="!onEdit"></app-input>
      </div>
      <div class="input-textarea">
        <label class="input-label">Solution</label>
        <app-input type="text" class="input-field" v-model="editTypicalBias.solution" multiline :disabled="!onEdit"></app-input>
      </div>
    </div>
    <hr>
    <div class="flag-groups-section">
      <div class="section-title">Flag groups</div>
      <app-table :loading="isLoadingFlagGroups">
        <template v-slot:headers>
          <div class="cell shrink">Group</div>
          <div class="cell shrink">Weight</div>
          <div class="cell auto">Flags</div>
          <div class="cell shrink"></div>
        </template>
        <template v-slot:body>
          <div class="row grid-x align-middle" v-for="(flagGroup, index) in flagGroups" :key="flagGroup.flagGroupId" @click="openEditFlagGroup(flagGroup)">
            <div class="cell shrink">{{ flagGroup.reference }}</div>
            <div class="cell shrink">{{ flagGroup.weight }}</div>
            <div class="cell auto">{{ flagGroup.flags.join(', ') }}</div>
            <div class="cell shrink">
              <app-button size="medium" kind="destructive" @click.stop="showDeleteModal(flagGroup, index)"><SvgDelete class="leading"/>Delete</app-button>
            </div>
          </div>
        </template>
        <template v-slot:empty-table v-if="isFlagGroupError">
          <div class="empty">An error occured while fetching the flag groups. Please try again later.</div>
        </template>
      </app-table>
      <div class="cta-container">
        <app-button size="medium" @click="resetModalFlagGroup(); showGroupFlagModal = true">+ Add group</app-button>
      </div>
    </div>

    <!-- Modale de création/modification de Flag Group -->
    <app-modal canClose :show="showGroupFlagModal" title="Flag Group" @update:show="showGroupFlagModal = $event">
      <template>
        <form :ref="`form-${typicalBias.reference}`">
          <div class="input-line">
            <label class="input-label">Name</label>
            <app-input class="input-field" v-model="modalFlagGroup.reference" required></app-input>
          </div>
          <div class="input-line">
            <label class="input-label">Weight</label>
            <app-input class="input-field" type="number" min="0" step="0.01" v-model="modalFlagGroup.weight" required></app-input>
          </div>
          <div class="input-line">
            <label class="input-label">Flags</label>
            <app-search  class="input-field" v-model="modalFlagGroup.flagInput" @search="modalFlagGroup.flags.push(modalFlagGroup.flagInput); modalFlagGroup.flagInput = ''"></app-search>
          </div>
          <div class="input-line">
            <label class="input-label"></label>
            <div class="input-field flag-list-container">
              <app-tag v-for="(flag, index) in modalFlagGroup.flags" :key="flag" removable @delete="modalFlagGroup.flags.splice(index, 1)">{{ flag }}</app-tag>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <app-button kind="subtle" @click="showGroupFlagModal = false">Cancel</app-button>
        <app-button @click="saveFlagGroup">Save</app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import SvgDelete from '@/assets/img/icons/24px/delete.svg?inline';
import flagGroupApi from '@/services/api/flag-group';

export default {
  name: 'typical-bias-edit',
  components: {
    SvgDelete,
  },
  props: {
    typicalBias: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editTypicalBias: null,
      onEdit: false,
      flagGroups: [],
      isLoadingFlagGroups: false,
      showGroupFlagModal: false,
      modalFlagGroup: {
        flagGroupId: null,
        reference: null,
        weight: null,
        flags: [],
        flagInput: '',
      },
      isFlagGroupError: false,
    };
  },
  created() {
    this.editTypicalBias = {
      ...this.typicalBias,
    };
    this.getFlagGroups();
  },
  watch: {
    showGroupFlagModal() {
      if (!this.showGroupFlagModal) {
        this.resetModalFlagGroup();
      }
    },
  },
  methods: {
    edit() {
      this.onEdit = true;
    },
    cancelTypicalBias() {
      this.onEdit = false;
      this.editTypicalBias = {
        ...this.typicalBias,
      };
    },
    saveTypicalBias() {
      this.onEdit = false;
      this.$emit('save', this.editTypicalBias);
    },
    resetModalFlagGroup() {
      this.modalFlagGroup = {
        flagGroupId: null,
        reference: null,
        weight: null,
        flags: [],
        flagInput: '',
      };
    },
    openEditFlagGroup(flagGroup) {
      this.modalFlagGroup = { ...this.modalFlagGroup, ...flagGroup };
      this.showGroupFlagModal = true;
    },
    saveFlagGroup() {
      if (!this.$refs[`form-${this.typicalBias.reference}`].checkValidity()) {
        return;
      }
      if (this.modalFlagGroup.flagGroupId) {
        this.updateFlagGroup();
      } else {
        this.createFlagGroup();
      }
    },
    showDeleteModal(flagGroup, index) {
      this.$message.show({
        title: 'Are you sure ?',
        text: `Are you sure you want to delete the flag group ${flagGroup.reference} ?`,
        confirmText: 'Yes, delete',
        onConfirm: () => this.deleteFlagGroup(flagGroup, index),
      });
    },
    async getFlagGroups() {
      this.isLoadingFlagGroups = true;
      try {
        const response = await flagGroupApi.getFlagGroups(this.typicalBias.typicalBiasId);
        this.flagGroups = response.data;
      } catch (error) {
        this.isFlagGroupError = true;
      }
      this.isLoadingFlagGroups = false;
    },
    async createFlagGroup() {
      try {
        const response = await flagGroupApi.createFlagGroup(this.typicalBias.typicalBiasId, {
          reference: this.modalFlagGroup.reference,
          weight: +this.modalFlagGroup.weight,
          flags: this.modalFlagGroup.flags,
        });
        this.flagGroups.push(response.data);
        this.$notification.show({ text: 'Flag group created successfully !' });
        this.showGroupFlagModal = false;
      } catch (error) {
        this.$message.show({ title: 'Error', text: `We could not create the flag group.\nError message : ${error.response.data.message}` });
      }
    },
    async updateFlagGroup() {
      try {
        const response = await flagGroupApi.updateFlagGroup(this.modalFlagGroup.flagGroupId, {
          reference: this.modalFlagGroup.reference,
          weight: +this.modalFlagGroup.weight,
          flags: this.modalFlagGroup.flags,
        });
        const index = this.flagGroups.findIndex((fg) => fg.flagGroupId === this.modalFlagGroup.flagGroupId);
        this.$set(this.flagGroups, index, response.data);
        this.$notification.show({ text: 'Flag group updated successfully !' });
        this.showGroupFlagModal = false;
      } catch (e) {
        this.$message.show({ title: 'Error', text: `We could not create the flag group.\nError message : ${e.response.data.message}` });
      }
    },
    async deleteFlagGroup(flagGroup, index) {
      try {
        this.flagGroups.splice(index, 1);
        await flagGroupApi.deleteFlagGroup(flagGroup.flagGroupId);
        this.$notification.show({ text: 'Flag group deleted successfully !' });
      } catch (error) {
        this.flagGroups.splice(index, 0, flagGroup);
        this.$message.show({ title: 'Error', text: `We could not delete the flag group.\nError message : ${error.response.data.message}` });
      }
    },

  },
};

</script>

<style lang="sass">
.typical-bias-edit
  .section-title
    margin: 0
    color: $main-color
    text-transform: uppercase
    font-weight: bold
    font-size: 12px
    margin-bottom: 1.5rem
    letter-spacing: 1.5px
  .typical-bias-form
    max-width: 800px
  .input-line
    display: flex
    align-items: center
    margin-bottom: 16px
    .input-field
      flex: 1
  .input-label
      color: $black
      font-weight: bold
      width: 188px
  .input-textarea
    .input-field
      height: 120px
      margin: 16px 0
  hr
    border: 0
    height: 3px
    background: $light-color
    margin: 2rem 0
  .flag-groups-section
    .app-table
      .cell.shrink
        width: 10rem
        &:last-child .app-button
          float: right
      .row
        @include clickable-row
    .cta-container
      display: flex
      justify-content: flex-end
      margin-top: 2rem
  .modal-content
    .flag-list-container
      display: flex
      gap: 10px
</style>
