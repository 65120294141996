<template>
  <section class="onboarding-completed">
    <div class="illustration">
      <img :src="TalentGrowthPlan">
    </div>
    <div class="text-content">
      <div>
        <app-title>All set!</app-title>
        <p>{{ copyText }}</p>
        <router-link :to="{ name: 'home' }">
          <app-button >Go to home page<arrow-right class="trailing"/></app-button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import copyApi from '@/services/api/copy';
import TalentGrowthPlan from '@/assets/img/talent-growth-plan.svg';
import arrowRight from '@/assets/img/icons/24px/arrow-right.svg?inline';

import CopyKey from '@/services/enums/copy-key.enum';

export default {
  components: {
    'arrow-right': arrowRight,
  },
  data() {
    return {
      TalentGrowthPlan,
      copyText: 'Text loading...',
    };
  },
  methods: {
    // Requête API : text copy
    async getCopyText() {
      this.loading = true;
      try {
        const response = await copyApi.getCopy(CopyKey.ONBOARDING_END);
        this.copyText = response.data.copyText;
      } catch (error) {
        this.copyText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip';
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getCopyText();
    document.querySelector('.wrapper').classList.add('hasStickyFooter');
  },
  beforeDestroy() {
    document.querySelector('.wrapper').classList.remove('hasStickyFooter');
  },

};
</script>

<style lang="sass">
.onboarding-completed
  display: flex
  align-items: center
  height: calc(100vh - #{$header-height})
  .illustration
    background: $white
    height: 100%
    width: 40vw
    max-width: 480px
    display: flex
    justify-content: center
    align-items: center
  .text-content
    width: 60vw
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    &>div
      width: 500px
      h1
        margin: 0
      p
        margin: 3em 0
        color: $link-color
</style>
