<template>
    <app-modal class="talent-filters" :show="show" :title="`Filters ${nbFilters ? `(${nbFilters})` : ''}`" @update:show="$emit('close')">
      <app-accordion label="Country of residence" class="country-filter" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="filterCountry.length">{{ filterCountry.length }}</div>
          <app-button class="clear-btn" v-if="filterCountry.length" kind="minimal" size="tiny" @click.stop="filterCountry = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-search v-model="searchCountry" @search="addSearchCountry" @keypress="addSearchCountry">
            <transition name="select">
              <ul v-if="searchCountry.length" class="country-options">
                <li v-for="option in countryOptions" :key="option.key" @click="addSearchCountry(option.key)">
                  <b>{{ option.label.substring(0, searchCountry.length) }}</b>{{ option.label.slice(searchCountry.length) }}
                </li>
              </ul>
          </transition>
          </app-search>
          <div class="search-filters">
            <app-tag v-for="(search, index) in filterCountry" :key="search" class="main" removable @delete="filterCountry.splice(index, 1)">{{ CountryLabel[search] }}</app-tag>
          </div>
        </template>
      </app-accordion>
      <app-accordion label="Function" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="filterFunction.length">{{ filterFunction.length }}</div>
          <app-button v-if="filterFunction.length" class="clear-btn" kind="minimal" size="tiny" @click.stop="filterFunction = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-search v-model="searchFunction" @search="addSearchFunction"></app-search>
          <div class="search-filters">
            <app-tag v-for="(search, index) in filterFunction" :key="search" class="main" removable @delete="filterFunction.splice(index, 1)">{{ search }}</app-tag>
          </div>
        </template>
      </app-accordion>
      <app-accordion label="Time in role" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="timeInRole.length">{{ timeInRole.length }}</div>
          <app-button v-if="timeInRole.length" class="clear-btn" kind="minimal" size="tiny" @click.stop="timeInRole = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-checkbox v-for="option in Object.keys(TimeInRoleLabel)" :key="option" :input="timeInRole"  :value="option">
            {{ TimeInRoleLabel[option] }}
          </app-checkbox>
        </template>
      </app-accordion>
      <app-accordion label="Time in company" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="timeInCompany.length">{{ timeInCompany.length }}</div>
          <app-button v-if="timeInCompany.length" class="clear-btn" kind="minimal" size="tiny" @click.stop="timeInCompany = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-checkbox v-for="option in Object.keys(TimeInCompanyLabel)" :key="option" :input="timeInCompany"  :value="option">
            {{ TimeInCompanyLabel[option] }}
          </app-checkbox>
        </template>
      </app-accordion>
      <app-accordion label="Seniority level" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="seniority.length">{{ seniority.length }}</div>
          <app-button v-if="seniority.length" class="clear-btn" kind="minimal" size="tiny" @click.stop="seniority = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-checkbox v-for="option in Object.keys(SeniorityLabel)" :key="option" :input="seniority"  :value="option">
            {{ SeniorityLabel[option] }}
          </app-checkbox>
        </template>
      </app-accordion>
      <app-accordion label="Author" showOpen>
        <template v-slot:before-filler>
          <div class="count-tag" v-if="filterOwner.length">{{ filterOwner.length }}</div>
          <app-button v-if="filterOwner.length" class="clear-btn" kind="minimal" size="tiny" @click.stop="filterOwner = []">Clear</app-button>
        </template>
        <template v-slot:accordion-body>
          <app-search v-model="searchOwner" @search="addSearchOwner"></app-search>
          <div class="search-filters">
            <app-tag v-for="(search, index) in filterOwner" :key="search" class="main" removable @delete="filterOwner.splice(index, 1)">{{ search }}</app-tag>
          </div>
        </template>
      </app-accordion>
      <template v-slot:footer>
        <div v-if="nbFilters > 0" class="clear-footer">
          <app-button kind="subtle" @click="clearAll">
            <svg-cross class="leading" />
            Clear all filters
          </app-button>
        </div>
      </template>
    </app-modal>
</template>

<script>
import TimeInRoleLabel from '@/services/enums/time-in-role-label.enum';
import TimeInCompanyLabel from '@/services/enums/time-in-company-label.enum';
import SeniorityLabel from '@/services/enums/seniority-label.enum';
import CountryLabel from '@/services/enums/country-label.enum';
import SvgCross from '@/assets/img/icons/24px/cross.svg?inline';
import utils from '@/services/utils';

export default {
  name: 'talent-filters',
  components: {
    'svg-cross': SvgCross,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchCountry: '',
      searchFunction: '',
      searchOwner: '',
      timeInRole: utils.initArray(this.$route.query.timeInRole),
      timeInCompany: utils.initArray(this.$route.query.timeInCompany),
      seniority: utils.initArray(this.$route.query.seniority),
      filterFunction: utils.initArray(this.$route.query.filterFunction),
      filterOwner: utils.initArray(this.$route.query.filterOwner),
      filterCountry: utils.initArray(this.$route.query.filterCountry),
      TimeInRoleLabel,
      TimeInCompanyLabel,
      SeniorityLabel,
      CountryLabel,
      listCountries: Object.entries(CountryLabel).map(([key, label]) => ({ key, label })),
    };
  },
  computed: {
    nbFilters() {
      return this.filterFunction.length + this.filterOwner.length + this.filterCountry.length + this.timeInRole.length + this.timeInCompany.length + this.seniority.length;
    },
    countryOptions() {
      return this.listCountries.filter(({ label }) => label.toLowerCase().startsWith(this.searchCountry.toLowerCase()));
    },
  },
  watch: {
    timeInRole() { this.updateQuery({ timeInRole: this.timeInRole }); },
    timeInCompany() { this.updateQuery({ timeInCompany: this.timeInCompany }); },
    seniority() { this.updateQuery({ seniority: this.seniority }); },
    filterCountry() { this.updateQuery({ filterCountry: this.filterCountry }); },
    filterFunction() { this.updateQuery({ filterFunction: this.filterFunction }); },
    filterOwner() { this.updateQuery({ filterOwner: this.filterOwner }); },
  },
  methods: {
    addSearchFunction() {
      this.filterFunction.push(this.searchFunction);
      this.searchFunction = '';
    },
    addSearchCountry(value) {
      if (!value) {
        if (this.searchCountry.length > 0) this.filterCountry.push(this.countryOptions[0].key);
      } else {
        this.filterCountry.push(value);
      }
      this.searchCountry = '';
    },
    addSearchOwner() {
      this.filterOwner.push(this.searchOwner);
      this.searchOwner = '';
    },
    clearAll() {
      Object.assign(this.$data, {
        ...(this.filterCountry.length && { filterCountry: [] }),
        ...(this.filterFunction.length && { filterFunction: [] }),
        ...(this.timeInRole.length && { timeInRole: [] }),
        ...(this.timeInCompany.length && { timeInCompany: [] }),
        ...(this.seniority.length && { seniority: [] }),
        ...(this.filterOwner.length && { filterOwner: [] }),
      });
    },
    updateQuery(newQuery) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...newQuery,
        },
      });
    },
  },
};
</script>

<style lang="sass">
.talent-filters
  .modal-wrapper
    width: 480px
    height: 100vh
    max-height: none
    top: 0
    bottom: 0
    right: 0
    left: initial
    transform: none
    display: flex
    flex-direction: column
    .modal-content
      flex-grow: 1
      max-height: none
      overflow-y: scroll
      .app-accordion
        .accordion-header
          background-color: transparent
          .count-tag
            width: 20px
            height: 20px
            font-size: 11px
            text-align: center
            line-height: 20px
            color: $common-gray
            background: $light-color
            border-radius: 12px
            margin: 0 0.5rem
          .clear-btn
            font-size: 13px
            color: $common-gray
          &:hover
            background: $light-color
            .count-tag
              background: $white
        .accordion-body
          display: flex
          flex-direction: column
          .search-filters
            display: flex
            align-items: center
            justify-content: flex-start
            flex-wrap: wrap
            margin-top: 1rem
            .app-tag
              margin: 0.2rem
          .app-checkbox
            margin: 0.5rem 0
            color: $common-gray
            &:last-child
              margin-bottom: 0
            &:first-child
              margin-top: 0
        &.country-filter .app-search
          @include select-transition
          position: relative
          .country-options
            @include select-options
            transform: translateY(2px)
            max-height: 240px
            overflow-y: auto
            b
              color: $black
            li:hover
              cursor: pointer
    .modal-footer
      background: $white
      box-shadow: 0px 4px 12px rgba(103, 102, 98, 0.3)
      .clear-footer
        padding: 1rem
        .app-button
          margin: 0 auto
</style>
